import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useState, useRef, useEffect } from 'react';
import { RefinementList } from 'react-instantsearch';
import { MyRefinementList } from '../../products/ProductList';

const RefinementListDropDown = ({
    attribute,
    placeholder,
    onToggle,
    currentValue = null,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
                onToggle && onToggle(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onToggle]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
        onToggle && onToggle(!isOpen);
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={handleToggle}
                className="w-full flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
            >
                {currentValue
                    ? placeholder + ' : ' + currentValue
                    : placeholder}
                <ChevronDownIcon
                    className="w-5 h-5 ml-2 -mr-1 text-gray-400"
                    aria-hidden="true"
                />
            </button>

            <div
                className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg"
                hidden={!isOpen}
            >
                <div className="py-1">
                    <MyRefinementList
                        showMore={true}
                        attribute={attribute}
                        {...props}
                        classNames={{
                            root: 'max-h-60 overflow-auto',
                            list: 'space-y-1',
                            item: 'flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900',
                            label: 'flex items-center w-full',
                            checkbox:
                                'mr-2 h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded',
                            labelText: 'flex-grow',
                            count: 'ml-auto text-xs text-gray-500',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default RefinementListDropDown;
