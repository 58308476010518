export default function UseDifferentAddressToggle({
  onChange,
  checked = false,
}) {
  return (
    <div className="mt-4 mb-4 flex items-center gap-2">
      <input
        onChange={(e) => onChange(!!e.target.checked)}
        checked={checked}
        type="checkbox"
        id="sameAddress"
        className="w-5 h-5 accent-orange"
      />
      <label htmlFor="sameAddress" className="font-jost">
        Utiliser une adresse de facturation différente
      </label>
    </div>
  );
}
