import { Splide, SplideSlide } from '@splidejs/react-splide';
import { useEffect, useRef, useState } from 'react';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import { fetchWithHeaders } from '../Api/Api';
import SelectAutoComplete from './SelectAutoComplete';
import { Link, useNavigate } from 'react-router-dom';

export default function HeroSection() {
  const ref = useRef();
  const [activeTab, setActiveTab] = useState(1);
  const [index, setIndex] = useState(0);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState('all');
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState('');
  const navigate = useNavigate();
  const [loadingModels, setLoadingModels] = useState(false);

  const updateModels = (brand) => {
    setLoadingModels(true);
    fetchWithHeaders(process.env.VITE_API_URL + 'models?brand=' + brand).then(
      (result) => {
        setLoadingModels(false);
        setModels(
          result.map((model) => ({
            value: model.tempId,
            label:
              !brand || brand === 'all'
                ? model.brand + ' ' + model.model
                : model.model,
            object: model,
          })),
        );
      },
    );
  };

  const resetSearchForm = () => {
    setSelectedBrand('all');
    setSelectedModel('');
  };

  const search = () => {
    if (!selectedModel && !selectedBrand) return;
    if (!selectedModel) {
      navigate('/catalogue?refinementList[brand][0]=' + selectedBrand);
      return;
    }
    const toSearch = models.find((e) => e.value === selectedModel).object;
    navigate(
      '/catalogue?refinementList[brand][0]=' +
        toSearch.brand +
        '&refinementList[globalModel][0]=' +
        toSearch.model,
    );
  };

  useEffect(() => {
    fetchWithHeaders(process.env.VITE_API_URL + 'brands').then((result) => {
      setBrands(result);
    });
  }, []);

  useEffect(() => {
    updateModels(selectedBrand);
    setSelectedModel('');
  }, [selectedBrand]);

  const sliderRef = useRef(null);
  const PrevArrow = () => {
    if (sliderRef.current) {
      const index = sliderRef.current.splide.Components.Controller.getPrev();
      setIndex((prev) => {
        if (index === 0) return 0;
        return prev;
      });
      sliderRef.current.splide.Components.Controller.go(index);
    }
  };

  const NextArrow = () => {
    if (sliderRef.current) {
      const index = sliderRef.current.splide.Components.Controller.getNext();
      setIndex((prev) => {
        if (index === 1) return 1;
        return prev;
      });
      if (index === -1) return;
      sliderRef.current.splide.Components.Controller.go(index);
    }
  };
  return (
    <div className="wrapper relative center max-w-[1920px] z-10">
      <button
        onClick={PrevArrow}
        className={`w-[35px] 900:w-[45px] 1100:w-[56px] aspect-square absolute left-[20px] z-[10] box-shd2 translate-y-[-100px] 900:translate-y-[-80px] lg:translate-y-[-40px] hidden md:center rounded-full ${
          index === 0
            ? 'text-[white] bg-[#0D1F22]'
            : 'bg-[white] text-[#0D1F22]'
        }`}
      >
        <FaArrowLeftLong />
      </button>
      <button
        onClick={NextArrow}
        className={`w-[35px] 900:w-[45px] 1100:w-[56px]  aspect-square absolute right-[20px] z-[10] hidden md:center translate-y-[-100px] 900:translate-y-[-80px] lg:translate-y-[-40px] box-shd2 rounded-full ${
          index === 1
            ? 'text-[white] bg-[#0D1F22]'
            : 'bg-[white] text-[#0D1F22]'
        }`}
      >
        <FaArrowRightLong />
      </button>
      <div className="w-full md:max-h-[700px] px-[10px] md:px-0 mb-[10px] md:mb-0 md:min-h-[500px]">
        <div className="w-full relative aspect-[1/.716] md:hidden center">
          <div className="flex flex-col gap-[48px] center overflow-hidden">
            <Splide
              ref={ref}
              className="w-full z-[10] overflow-hidden"
              options={{
                width: '100%',
                perPage: 1,
                perMove: 1,
                drag: true,
                classes: {
                  pagination: 'splide-pagination',
                },
                pagination: false,
                arrows: false,
                type: 'loop',
                gap: 0,
                padding: 0,
                autoWidth: false,
                interval: 10000,
                autoplay: true,
                pauseOnHover: true,
              }}
            >
              <SplideSlide className="w-full relative center bg">
                {/* Image de fond */}
                <img
                  src="/hero-stopieces-auto-recherche.png"
                  alt="Recherche de pièces automobiles - Stop Pièces Auto Vourles"
                  className="absolute full-width-image h-full z-[-1] object-cover"
                />

                {/* Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-30 z-[0] rounded-[10px]"></div>

                {/* Contenu textuel */}
                <div className="flex flex-col gap-[10px] center text-[white] px-[10px] relative z-[1]">
                  <h1 className="font-[600] text-[48px] font-clash text-center leading-[44px] max-w-[430px]">
                    Trouvez votre <span className="text-orange">pièce</span>{' '}
                    auto en quelques clics
                  </h1>
                  <p className="text-center text-[15px] leading-[22px] max-w-[350px]">
                    Parcourez notre large catalogue en ligne et commandez
                    facilement la pièce qu'il vous faut, où que vous soyez.
                  </p>
                  <Link
                    className="text-[15px] lg:text-[20px] 1400:text-[26px] font-clash font-[600] tracking-[1px] bg-orange px-[24px] py-2 my-6 leading-[1]  text-white rounded-[32px] flex items-center gap-[7px]"
                    to="/catalogue"
                  >
                    Voir le catalogue{' '}
                    <FaArrowRightLong className="rotate-[-45deg]" />
                  </Link>
                </div>
              </SplideSlide>

              <SplideSlide className="w-full relative center bg">
                {/* Image de fond */}
                <img
                  src="/hero-magasin-vourles.png"
                  alt="Magasin Stop Pièces Auto à Vourles - Vue extérieure"
                  className="absolute full-width-image h-full z-[-1] object-cover"
                />

                {/* Overlay */}
                <div className="absolute inset-0 bg-white bg-opacity-10 z-[0]"></div>

                {/* Contenu textuel */}
                <div className="flex flex-col gap-[10px] center text-white px-[10px] relative z-[1]">
                  <h1 className="font-[600] text-[48px] font-clash text-center leading-[44px] max-w-[430px] text-shd">
                    Découvrez Stop Pièces Auto à Vourles
                  </h1>
                  <p className="text-center text-[15px] leading-[22px] max-w-[350px] text-shd">
                    Visitez notre magasin à Vourles et bénéficiez de conseils
                    personnalisés pour trouver les pièces adaptées à votre
                    véhicule.
                  </p>
                  <Link
                    className="text-[15px] lg:text-[20px] 1400:text-[26px] font-clash font-[600] tracking-[1px] bg-orange px-[24px] py-2 my-6 leading-[1]  text-white rounded-[32px] flex items-center gap-[7px]"
                    to="/catalogue"
                  >
                    Voir le catalogue{' '}
                    <FaArrowRightLong className="rotate-[-45deg]" />
                  </Link>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>

        <Splide
          ref={sliderRef}
          className="w-full hidden md:flex px-[5px] " // Add a custom class to Splide for styling
          options={{
            width: '100%',
            perPage: 1, // Show only 1 image at a time
            perMove: 1, // Move 1 image at a time
            drag: true,
            classes: 'splide-images', // Custom class for image slider styling
            pagination: false,
            arrows: false, // Set arrows to navigate through the images
            type: 'loop', // Set the type to 'slide' for image slider
            gap: 0, // No gap between images
            padding: 0, // No padding
            autoWidth: false, // Set autoWidth to false to display full-width images
            autoplay: true,
            pauseOnHover: true,
            interval: 10000,
            breakpoints: {
              768: {
                arrows: false, // Hide arrows on smaller screens if needed
              },
            },
          }}
        >
          <SplideSlide className="w-full relative center bg">
            {/* Image de fond */}
            <img
              src="/hero-stopieces-auto-recherche.png"
              alt="Recherche de pièces automobiles - Large catalogue Stop Pièces Auto"
              className="w-full h-full max-h-[590px] xl:max-h-[660px] 1600:max-h-[860px] object-cover rounded-[10px] px-[0px]"
            />

            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-20 z-10 rounded-[10px]"></div>

            {/* Contenu textuel */}
            <div className="absolute inset-0 z-20 flex flex-col mt-[100px] gap-[16px] items-baseline ml-[100px] text-left text-white px-4 md:px-0">
              <h1 className="text-4xl md:text-6xl font-bold leading-tight text-shd">
                Trouvez votre <span className="text-orange">pièce</span> auto{' '}
                <br /> en quelques clics
              </h1>
              <p className="mt-4 text-lg md:text-xl font-light text-shd">
                Parcourez notre large catalogue en ligne et commandez
                <br /> facilement la pièce qu'il vous faut, où que vous soyez.
              </p>
              <Link
                className="text-[15px] lg:text-[20px] 1400:text-[26px] font-clash font-[600] tracking-[1px] bg-orange px-[24px] py-2 my-6 leading-[1]  text-white rounded-[32px] flex items-center gap-[7px]"
                to="/catalogue"
              >
                Voir le catalogue{' '}
                <FaArrowRightLong className="rotate-[-45deg]" />
              </Link>
            </div>
          </SplideSlide>
          <SplideSlide className="w-full relative center bg">
            {/* Image de fond */}
            <img
              src="/hero-magasin-vourles.png"
              alt="Magasin Stop Pièces Auto Vourles - Conseils personnalisés"
              className="w-full h-full max-h-[590px] xl:max-h-[660px] 1600:max-h-[860px] object-cover rounded-[10px] px-[5px]"
            />

            {/* Overlay */}
            <div className="absolute inset-0 bg-white bg-opacity-10 z-10"></div>

            {/* Contenu textuel */}
            <div className="absolute inset-0 z-20 flex flex-col mt-[100px] gap-[16px] items-baseline ml-[100px] text-left text-white px-4 md:px-0">
              <h1 className="text-4xl md:text-6xl font-bold leading-tight text-shd">
                Découvrez Stop Pièces
                <br /> Auto à Vourles
              </h1>
              <p className="mt-4 text-lg md:text-xl font-light text-shd ">
                Visitez notre magasin à Vourles et bénéficiez de conseils <br />
                personnalisés pour trouver les pièces adaptées à votre véhicule.
              </p>
              <Link
                className="text-[15px] lg:text-[20px] 1400:text-[26px] font-clash font-[600] tracking-[1px] bg-orange px-[24px] py-2 my-6 leading-[1]  text-white rounded-[32px] flex items-center gap-[7px]"
                to="/catalogue"
              >
                Voir le catalogue{' '}
                <FaArrowRightLong className="rotate-[-45deg]" />
              </Link>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}
