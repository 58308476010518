import { Form, Formik, Field, ErrorMessage } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormInput from '../../components/CheckoutForm/FormInput';
import { GoChevronDown, GoChevronUp, GoPencil } from 'react-icons/go';
import { useDispatch } from 'react-redux';
import { informationCheck } from '../../store/Reducers/CheckoutSlice';
import { IoCheckmark } from 'react-icons/io5';
import { authStatus, login, logout, register } from '../../utils/auth';
import { useSelector } from 'react-redux';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';
import PasswordInput from '../../components/Form/PasswordInput';
import FormError from '../../components/Form/FormError';
import { translateError } from '../../utils/translatting';
import {
  initAuthState,
  logout as reduxLogout,
} from '../../store/Reducers/auth';
import { post } from '../../components/Api/Api';
import secureLocalStorage from 'react-secure-storage';
import { useCart } from '../../contexts/CartContext';
import LittleLoader from '../../components/Loader/LittleLoader';
import { Link, useNavigate } from 'react-router-dom';
import Switch from '../../components/CheckoutForm/Switch';
const Information = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editForm, setEditForm] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [loginLoading, setLoginLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const [guestInformationsLoading, setGuestInformationsLoading] =
    useState(false);
  const [loginError, setLoginError] = useState(null);
  const [registerError, setRegisterError] = useState(null);
  const [guestInformationsError, setGuestInformationsError] = useState(null);
  const [initGuestValues, setInitGuestValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    siret: '',
    taxNumber: '',
  });
  const [iAmPro, setIAmPro] = useState(false);

  const { setCartCustomer, cartCustomer, cartLoading } = useCart();
  const {
    isAuthenticated,
    user,
    status: authenticationStatus,
  } = useSelector((state) => {
    return state.auth;
  });

  useEffect(() => {
    dispatch(informationCheck(false));
  }, []);

  useEffect(() => {
    dispatch(informationCheck(!editForm));
  }, [editForm]);

  useEffect(() => {
    if (authenticationStatus !== authStatus.loading && !cartLoading) {
      if (cartCustomer && !isAuthenticated) {
        setShowEditIcon(true);
        setEditForm(false);
        dispatch(informationCheck(true));
        setActiveTab(3);
        setInitGuestValues({
          firstName: cartCustomer.first_name ?? '',
          lastName: cartCustomer.last_name ?? '',
          email: cartCustomer.meta?.email ?? '',
          company: cartCustomer.company_name ?? '',
          siret: cartCustomer.meta?.siret ?? '',
          taxNumber: cartCustomer.vat_no ?? '',
        });
        setIAmPro(
          cartCustomer.company_name &&
            cartCustomer.meta?.siret &&
            cartCustomer.vat_no,
        );
      } else if (isAuthenticated) {
        setShowEditIcon(true);
        setEditForm(false);
        dispatch(informationCheck(true));
      } else {
        setShowEditIcon(false);
        setEditForm(true);
        dispatch(informationCheck(false));
      }
    }
  }, [isAuthenticated, authenticationStatus, cartCustomer, cartLoading]);

  // Validation Schemas
  const loginValidationSchema = Yup.object({
    email: Yup.string().email('Email non valide').required('Email requis'),
    password: Yup.string().required('Mot de passe requis'),
  });

  const getRegisterFieldsValidation = (forPro = false) => {
    const toReturn = {
      firstName: Yup.string()
        .max(255, 'Maximum 255 caractères')
        .required('Prénom requis'),
      lastName: Yup.string()
        .max(255, 'Maximum 255 caractères')
        .required('Nom requis'),
      email: Yup.string()
        .max(255, 'Maximum 255 caractères')
        .email('Email non valide')
        .required('Email requis'),
      password: Yup.string()
        .min(8, 'Le mot de passe doit faire minimum 8 caractères')
        .max(255, 'Maximum 255 caractères')
        .required('Mot de passe requis'),
      confirmPassword: Yup.string()
        .required('Confirmation de mot de passe requis')
        .oneOf(
          [Yup.ref('password')],
          'La confirmation de mot de passe est incorrecte',
        ),
    };
    if (forPro) {
      return {
        ...toReturn,
        company: Yup.string().required("Nom de l'entreprise requis"),
        siret: Yup.string().required('SIRET requis'),
        taxNumber: Yup.string().required('Numéro de TVA requis'),
      };
    }
    return toReturn;
  };

  const getGuestFieldsValidation = (forPro = false) => {
    const toReturn = {
      firstName: Yup.string()
        .max(255, 'Maximum 255 caractères')
        .required('Prénom requis'),
      lastName: Yup.string()
        .max(255, 'Maximum 255 caractères')
        .required('Nom requis'),
      email: Yup.string()
        .max(255, 'Maximum 255 caractères')
        .email('Email non valide')
        .required('Email requis'),
    };
    if (forPro) {
      return {
        ...toReturn,
        company: Yup.string().required("Nom de l'entreprise requis"),
        siret: Yup.string().required('SIRET requis'),
        taxNumber: Yup.string().required('Numéro de TVA requis'),
      };
    }
    return toReturn;
  };

  const [registerValidationSchema, setRegisterValidationSchema] = useState(
    Yup.object(getRegisterFieldsValidation(iAmPro)),
  );

  const [
    guestInformationsValidationSchema,
    setGuestInformationsValidationSchema,
  ] = useState(Yup.object(getGuestFieldsValidation(iAmPro)));

  useEffect(() => {
    setRegisterValidationSchema(
      Yup.object(getRegisterFieldsValidation(iAmPro)),
    );
    setGuestInformationsValidationSchema(
      Yup.object(getGuestFieldsValidation(iAmPro)),
    );
  }, [iAmPro]);

  const handleLoginSubmit = (values) => {
    setLoginLoading(true);
    login(values.email, values.password)
      .then(() => {
        dispatch(initAuthState());
      })
      .catch((e) => {
        setLoginError(translateError(e));
      })
      .finally(() => {
        setLoginLoading(false);
      });
  };

  const handleRegisterSubmit = (values) => {
    setRegisterLoading(true);
    let payload = {
      email: values.email?.trim().length > 0 ? values.email.trim() : null,
      password:
        values.password?.trim().length > 0 ? values.password.trim() : null,
      password_confirmation:
        values.confirmPassword?.trim().length > 0
          ? values.confirmPassword.trim()
          : null,
      first_name:
        values.firstName?.trim().length > 0 ? values.firstName.trim() : null,
      last_name:
        values.lastName?.trim().length > 0 ? values.lastName.trim() : null,
    };
    if (iAmPro) {
      payload = {
        ...payload,
        siret: values.siret?.trim().length > 0 ? values.siret.trim() : null,
        company_name:
          values.company?.trim().length > 0 ? values.company.trim() : null,
        vat_no:
          values.taxNumber?.trim().length > 0 ? values.taxNumber.trim() : null,
      };
    }
    register(payload)
      .then(() => {
        dispatch(initAuthState());
      })
      .catch((e) => {
        setRegisterError(translateError(e));
      })
      .finally(() => {
        setRegisterLoading(false);
      });
  };

  const handleSubmitGuestInformations = (values) => {
    const cartId = secureLocalStorage.getItem('cart_id');
    if (!cartId) {
      setGuestInformationsError('Vous n’avez pas de panier');
      return;
    }
    const headers = { 'cart-id': cartId };
    setGuestInformationsLoading(true);
    let payload = {
      email: values.email?.trim().length > 0 ? values.email.trim() : null,
      first_name:
        values.firstName?.trim().length > 0 ? values.firstName.trim() : null,
      last_name:
        values.lastName?.trim().length > 0 ? values.lastName.trim() : null,
    };
    if (iAmPro) {
      payload = {
        ...payload,
        siret: values.siret?.trim().length > 0 ? values.siret.trim() : null,
        company_name:
          values.company?.trim().length > 0 ? values.company.trim() : null,
        vat_no:
          values.taxNumber?.trim().length > 0 ? values.taxNumber.trim() : null,
      };
    }
    const path = cartCustomer
      ? 'shop/update_customer'
      : 'shop/set_new_customer';
    post(path, payload, headers)
      .then((res) => {
        setCartCustomer(res);
      })
      .catch((e) => {
        setGuestInformationsError(translateError(e));
      })
      .finally(() => {
        setGuestInformationsLoading(false);
      });
  };

  const handleLogout = () => {
    logout();
    dispatch(reduxLogout());
    navigate('/');
  };

  return (
    <div
      className={`bg-gray duration-300 w-full p-8 ${
        editForm && authenticationStatus !== authStatus.loading
          ? 'h-max smm:h-max'
          : 'h-24'
      } overflow-hidden  rounded-xl`}
    >
      <div className="flex justify-between items-center">
        <div className="grid grid-flow-col items-center gap-2">
          <div
            className={`${
              showEditIcon ? 'bg-orange' : 'bg-black'
            } w-9 h-9 grid place-items-center text-white rounded-full`}
          >
            {showEditIcon ? <IoCheckmark /> : 1}
          </div>
          <h3 className="font-jost text-[22px] smm:text-xl font-semibold">
            Identification
          </h3>
          {(authenticationStatus === authStatus.loading || cartLoading) && (
            <LittleLoader className="w-5 h-5" />
          )}
        </div>
        {authenticationStatus !== authStatus.loading && showEditIcon && (
          <button
            className="text-[#CBCDCD] text-2xl"
            onClick={() => setEditForm(!editForm)}
          >
            <GoPencil />
          </button>
        )}
      </div>
      {isAuthenticated ? (
        <div className="mt-7 smm:flex-wrap smm:justify-center">
          Vous êtes connecté en tant que{' '}
          <b>
            {user?.first_name} {user?.last_name}
          </b>{' '}
          (<b>{user?.email}</b>)
          {user?.company_name && (
            <>
              {' '}
              de la société <b>{user.company_name}</b>
            </>
          )}
          .
          <button onClick={handleLogout} className="text-orange mt-3 block">
            Se déconnecter
          </button>
        </div>
      ) : (
        <>
          <div className="mt-7 flex gap-3 smm:flex-wrap smm:justify-center">
            <button
              onClick={() => setActiveTab(1)}
              className={`border border-dark rounded-full  smm:text-sm smm: px-4 h-9 ${
                activeTab === 1 ? 'text-white bg-dark' : ''
              }`}
            >
              Se connecter
            </button>
            <button
              onClick={() => setActiveTab(2)}
              className={`border border-dark rounded-full  smm:text-sm smm: px-4 h-9 ${
                activeTab === 2 ? 'text-white bg-dark' : ''
              }`}
            >
              Créer un compte
            </button>
            <button
              onClick={() => setActiveTab(3)}
              className={`border border-dark rounded-full  smm:text-sm smm: px-4 h-9 ${
                activeTab === 3 ? 'text-white bg-dark' : ''
              }`}
            >
              Continuer en tant qu'invité
            </button>
          </div>
          <div className="w-full mt-5">
            {activeTab === 1 && (
              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={loginValidationSchema}
                onSubmit={handleLoginSubmit}
                validateOnChange={true}
                enableReinitialize={true}
              >
                {({ isValid }) => (
                  <Form>
                    <div className="flex w-full gap-8 smm:flex-col smm:gap-y-5 mb-3">
                      <FormInput name="email" placeholder="E-mail" />
                      <PasswordInput
                        name="password"
                        className="outline-none h-full w-full"
                        placeholder="Mot de passe"
                        forFormik
                      />
                    </div>
                    {/* <small className="text-orange block mt-3">
                  Mot de passe oublié ?
                </small> */}
                    {loginError && <FormError text={loginError} />}
                    <div className="sm:w-full sm:flex justify-end">
                      <button
                        type="submit"
                        className={`mt-3 px-[30px] border
                duration-300 rounded-[8px] py-[16px] sm:w-auto
                w-full text-white inline-flex justify-center gap-3 ${
                  !isValid
                    ? 'bg-zinc-500'
                    : 'bg-orange hover:text-orange border-orange hover:bg-transparent'
                }`}
                        disabled={loginLoading || !isValid}
                      >
                        Continuer
                        {loginLoading && (
                          <LittleWhiteLoader className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {activeTab === 2 && (
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                  password: '',
                  confirmPassword: '',
                  company: '',
                  siret: '',
                  taxNumber: '',
                }}
                validationSchema={registerValidationSchema}
                onSubmit={handleRegisterSubmit}
                enableReinitialize={true}
                validateOnChange={true}
              >
                {({ isValid }) => (
                  <Form>
                    <div className="grid-cols-2 smm:grid-cols-1 grid gap-4 mb-2">
                      <FormInput name="firstName" placeholder="Prénom" />
                      <FormInput name="lastName" placeholder="Nom" />
                      <PasswordInput
                        name="password"
                        className="outline-none h-full w-full"
                        placeholder="Mot de passe"
                        forFormik
                      />
                      <PasswordInput
                        name="confirmPassword"
                        className="outline-none h-full w-full"
                        placeholder="Confirmation de mot de passe"
                        forFormik
                      />
                      <FormInput name="email" placeholder="E-mail" />
                      {iAmPro && (
                        <>
                          <FormInput
                            name="company"
                            placeholder="Raison sociale"
                          />
                          <FormInput name="siret" placeholder="SIRET" />
                          <FormInput
                            name="taxNumber"
                            placeholder="Numéro de TVA"
                          />
                        </>
                      )}
                    </div>
                    <Switch
                      checked={iAmPro}
                      onChange={setIAmPro}
                      text="Je suis un professionnel"
                    />
                    {/* <div className="mt-4 mb-4 flex items-center gap-2">
                  <input
                    defaultChecked
                    type="checkbox"
                    id="newsletter"
                    className="w-5 h-5 accent-orange"
                  />
                  <label htmlFor="newsletter" className="font-jost">
                    Recevoir notre newsletter
                  </label>
                </div> */}
                    <div className="mt-4 mb-4 flex items-center gap-2">
                      <p className="font-jost">
                        En continuant vous acceptez les{' '}
                        <Link to="/cgv-particuliers" className="text-orange">
                          conditions générales
                        </Link>{' '}
                        et la{' '}
                        <Link to="/cgv-particuliers" className="text-orange">
                          politique de confidentialité
                        </Link>
                      </p>
                    </div>
                    {registerError && <FormError text={registerError} />}
                    <div className="sm:w-full sm:flex justify-end">
                      <button
                        type="submit"
                        className={`px-[30px] border
                duration-300 rounded-[8px] py-[16px] sm:w-auto
                w-full text-white inline-flex justify-center gap-3 ${
                  !isValid
                    ? 'bg-zinc-500'
                    : 'bg-orange hover:text-orange border-orange hover:bg-transparent'
                }`}
                        disabled={registerLoading || !isValid}
                      >
                        Continuer
                        {registerLoading && (
                          <LittleWhiteLoader className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
            {activeTab === 3 && (
              <Formik
                initialValues={initGuestValues}
                validationSchema={guestInformationsValidationSchema}
                onSubmit={handleSubmitGuestInformations}
                enableReinitialize={true}
                validateOnChange={true}
              >
                {({ isValid }) => (
                  <Form>
                    <div className="grid-cols-2 smm:grid-cols-1 grid gap-4 mb-2">
                      <FormInput name="firstName" placeholder="Prénom" />
                      <FormInput name="lastName" placeholder="Nom" />
                      <FormInput name="email" placeholder="E-mail" />
                      {iAmPro && (
                        <>
                          <FormInput
                            name="company"
                            placeholder="Raison sociale"
                          />
                          <FormInput name="siret" placeholder="SIRET" />
                          <FormInput
                            name="taxNumber"
                            placeholder="Numéro de TVA"
                          />
                        </>
                      )}
                    </div>
                    {/* <div className="mt-4 mb-4 flex items-center gap-2">
                <input
                  defaultChecked
                  type="checkbox"
                  id="newsletter"
                  className="w-5 h-5 accent-orange"
                />
                <label htmlFor="newsletter" className="font-jost">
                  Recevoir notre newsletter
                </label>
              </div> */}
                    <Switch
                      checked={iAmPro}
                      onChange={setIAmPro}
                      text="Je suis un professionnel"
                    />
                    <div className="mt-4 mb-4 flex items-center gap-2">
                      <p className="font-jost">
                        En continuant vous acceptez les{' '}
                        <Link to="/cgv-particuliers" className="text-orange">
                          conditions générales
                        </Link>{' '}
                        et la{' '}
                        <Link to="/cgv-particuliers" className="text-orange">
                          politique de confidentialité
                        </Link>
                      </p>
                    </div>
                    {guestInformationsError && !guestInformationsLoading && (
                      <FormError text={guestInformationsError} />
                    )}
                    <div className="sm:w-full sm:flex justify-end">
                      <button
                        type="submit"
                        className={`px-[30px] border
                duration-300 rounded-[8px] py-[16px] sm:w-auto
                w-full text-white inline-flex justify-center gap-3 ${
                  !isValid
                    ? 'bg-zinc-500'
                    : 'bg-orange hover:text-orange border-orange hover:bg-transparent'
                }`}
                        disabled={guestInformationsLoading || !isValid}
                      >
                        Continuer
                        {guestInformationsLoading && (
                          <LittleWhiteLoader className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Information;
