import { Dialog } from '@headlessui/react';
import { Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';

import Lottie from 'react-lottie';

import { Form, useFieldAnswer } from '@quillforms/renderer-core';
import '@quillforms/renderer-core/build-style/style.css';
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';
import { validerPlaqueImmatriculation } from '../../helpers/immatriculationHelper';
import tailwindConfig from '../../../tailwind.config';
import './style.css';
import { toast } from 'react-toastify';
import { post } from '../Api/Api';
import { translateError } from '../../utils/translatting';

registerCoreBlocks();

function PartRequest({ setOpened, opened }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    model: '',
    brand: '',
    partNumber: '',
    description: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClose = () => {
    setOpened(false);
    setFormData({
      name: '',
      email: '',
      phone: '',
      model: '',
      brand: '',
      description: '',
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ici, vous pouvez ajouter la logique pour envoyer les données du formulaire
    // console.log('Données du formulaire soumises:', formData);
    // Réinitialiser le formulaire après soumission
    setFormData({
      name: '',
      email: '',
      partNumber: '',
      description: '',
    });
  };

  const immatriculationQuestionAnswer = useFieldAnswer(
    'vehicle-identification',
  );

  return (
    <Transition.Root show={opened} as={Fragment}>
      <Dialog as="div" className="relative z-[100]" onClose={setOpened}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#eeeeeeaa] bg-opacity-75 backdrop-blur-sm transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-[100] w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
                <div className="bg-white px-4 pb-4 pt-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="my-1 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Formulaire de demande de pièces
                      </Dialog.Title>
                      <div className="flex flex-wrap gap-[10px]">
                        <div className="p-2 lg:p-4">
                          <div
                            style={{
                              width: '80vw',
                              maxWidth: '1000px',
                              height: '74vh',
                            }}
                          >
                            <Form
                              formId="1"
                              beforeGoingNext={({
                                setIsFieldValid,
                                setIsPending,
                                currentBlockId,
                                answers,
                                setFieldValidationErr,
                                setIsCurrentBlockSafeToSwipe,
                                goToField,
                                goNext,
                              }) => {
                                // console.log(
                                //   '=> immatriculationQuestionAnswer',
                                //   immatriculationQuestionAnswer,
                                // );
                                if (
                                  currentBlockId === 'vehicle-identification' &&
                                  answers[currentBlockId].value &&
                                  !validerPlaqueImmatriculation(
                                    answers[currentBlockId].value,
                                  )
                                ) {
                                  setIsFieldValid(currentBlockId, false);
                                  setFieldValidationErr(
                                    currentBlockId,
                                    "Cette plaque d'immatriculation n'est pas valide !",
                                  );
                                  setIsCurrentBlockSafeToSwipe(false);
                                } else {
                                  setIsFieldValid(currentBlockId, true);
                                  setFieldValidationErr(currentBlockId, '');
                                  setIsCurrentBlockSafeToSwipe(true);
                                  goNext();
                                }
                              }}
                              formObj={{
                                messages: {
                                  'label.button.ok': 'Suivant',
                                  'label.hintText.enter':
                                    'Touche <strong>Entrée ↵</strong>',
                                  'label.hintText.multipleSelection':
                                    'Choisissez autant que vous le souhaitez',
                                  'block.dropdown.placeholder':
                                    'Tapez ou sélectionnez une option',
                                  'block.dropdown.noSuggestions':
                                    'Aucune suggestion !',
                                  'block.shortText.placeholder':
                                    'Tapez votre réponse ici',
                                  'block.longText.placeholder':
                                    "Donnez nous un maximum d'informations (numéro de série, année du véhicule, motorisation, couleur...)",
                                  'block.longText.hint':
                                    '<strong>Shift ⇧ + Entrée ↵</strong> pour faire un saut de ligne',
                                  'block.number.placeholder':
                                    'Tapez votre réponse ici',
                                  'block.email.placeholder':
                                    'Tapez votre email ici',
                                  'block.defaultThankYouScreen.label':
                                    '<center style="margin-bottom:25px;"><img src="/animations/Animation.gif" /></center>Merci pour votre demande. <br/>Nous vous recontacterons dès que possible.',
                                  'label.hintText.key': 'Touche',
                                  'label.progress.percent':
                                    '{{progress:percent}}% de progression',
                                  'label.errorAlert.required':
                                    'Ce champ est requis !',
                                  'label.errorAlert.selectionRequired':
                                    'Veuillez faire au moins une sélection !',
                                  'label.errorAlert.email': 'Email invalide !',
                                  'label.errorAlert.url': 'URL invalide !',
                                  'label.errorAlert.range':
                                    'Veuillez entrer un nombre entre {{attribute:min}} et {{attribute:max}}',
                                  'label.errorAlert.minNum':
                                    'Veuillez entrer un nombre supérieur à {{attribute:min}}',
                                  'label.errorAlert.maxNum':
                                    'Veuillez entrer un nombre inférieur à {{attribute:max}}',
                                  'label.errorAlert.maxCharacters':
                                    'Nombre de caractères maximum atteint !',
                                  'label.submitBtn': 'Envoyer ma demande',
                                },
                                theme: {
                                  font: 'Roboto',
                                  buttonsBgColor:
                                    tailwindConfig.theme.extend.colors.primary,
                                  logo: {
                                    src: '',
                                  },
                                  questionsColor: '#333',
                                  answersColor:
                                    tailwindConfig.theme.extend.colors.blue,
                                  buttonsFontColor: '#fff',
                                  buttonsBorderRadius: 25,
                                  errorsFontColor: '#fff',
                                  errorsBgColor: '#d85e5e',
                                  progressBarFillColor:
                                    tailwindConfig.theme.extend.colors.primary,
                                  progressBarBgColor: '#ccc',
                                },
                                blocks: [
                                  {
                                    id: 'welcome-screen',
                                    name: 'welcome-screen',
                                    attributes: {
                                      buttonText: "C'est parti !",

                                      label:
                                        '<h1 class="font-clash font-[600] text-[34px] sm:text-[40px] leading-[1.1] sm:leading-[32px] mb-8">Nous trouvons la pièce <br/>qu\'il vous faut</h1>',
                                      description:
                                        '<center style="margin-bottom:10px;"><img style="height:250px;" src="/search-parts.png" /></center> <br/> Nos experts sont là pour vous aider à trouver la pièce dont vous avez besoin. <br/><br/>',
                                      attachment: {
                                        type: 'image',
                                        url: '',
                                      },
                                    },
                                  },
                                  {
                                    name: 'short-text',
                                    id: 'vehicle-identification',
                                    attributes: {
                                      placeholder: 'XX-XXX-XX',
                                      classnames: 'first-block',
                                      label:
                                        "Entrez votre plaque d'immatriculation <br/><font color=#555 size=3>(ou passez à l'étape suivante si vous ne la connaissez pas)</font>",
                                    },
                                  },

                                  ...(typeof immatriculationQuestionAnswer ===
                                    'undefined' ||
                                  immatriculationQuestionAnswer === ''
                                    ? [
                                        {
                                          id: 'brand-model-identification',
                                          name: 'group',
                                          attributes: {
                                            label:
                                              'Dans ce cas, saisissez la marque et modèle de votre véhicule',
                                          },
                                          innerBlocks: [
                                            {
                                              id: 'brand',
                                              name: 'short-text',
                                              attributes: {
                                                label: 'Marque',
                                                required: true,
                                                placeholder:
                                                  'La marque de votre véhicule',
                                              },
                                            },
                                            {
                                              id: 'model',
                                              name: 'short-text',
                                              attributes: {
                                                label: 'Modèle',
                                                required: true,
                                                placeholder:
                                                  'Le modèle de votre véhicule et la phase',
                                              },
                                            },
                                          ],
                                        },
                                      ]
                                    : []),
                                  {
                                    name: 'multiple-choice',
                                    id: 'part-type-selection',
                                    attributes: {
                                      required: true,
                                      multiple: false,
                                      verticalAlign: false,
                                      label:
                                        'De quel type de pièce avez-vous besoin ?',
                                      choices: [
                                        {
                                          label: 'Mécanique',
                                          value: 'mecanique',
                                        },
                                        {
                                          label: 'Carrosserie',
                                          value: 'carrosserie',
                                        },
                                        {
                                          label: 'Mécanique et carrosserie',
                                          value: 'mécanique-électrique',
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    name: 'long-text',
                                    id: 'part-description',
                                    attributes: {
                                      classnames: 'long-text long-text-tafani',
                                      required: true,
                                      label:
                                        "Décrivez la pièce qu'il vous faut",
                                    },
                                  },
                                  {
                                    name: 'group',
                                    id: 'contact-info',
                                    attributes: {
                                      classnames: 'first-block',
                                      required: true,
                                      label:
                                        'Vous y êtes presque ! <br/><br/>Comment pouvons-nous vous recontacter ?',
                                    },

                                    innerBlocks: [
                                      {
                                        id: 'full-name',
                                        name: 'short-text',
                                        attributes: {
                                          label: 'Nom / prénom',
                                          required: true,
                                          placeholder: 'Votre nom et prénom',
                                        },
                                      },
                                      {
                                        id: 'phone-number',
                                        name: 'short-text',
                                        attributes: {
                                          label: 'Votre numéro de téléphone',
                                          required: true,
                                          placeholder:
                                            'Votre numéro de téléphone',
                                        },
                                      },
                                      {
                                        id: 'email-address',
                                        name: 'email',
                                        attributes: {
                                          label: 'Email',
                                          placeholder: 'Votre adresse email',
                                        },
                                      },
                                    ],
                                  },
                                ],
                              }}
                              onSubmit={(
                                data,
                                {
                                  completeForm,
                                  setIsSubmitting,
                                  goToBlock,
                                  setSubmissionErr,
                                },
                              ) => {
                                console.log('data', data);
                                const formattedData = {
                                  vehicleInfo: {
                                    immatriculation:
                                      data.answers['vehicle-identification']
                                        ?.value || null,
                                    marque:
                                      data.answers['brand']?.value || null,
                                    modele:
                                      data.answers['model']?.value || null,
                                  },
                                  typePiece:
                                    data.answers['part-type-selection']
                                      ?.value || null,
                                  description:
                                    data.answers['part-description']?.value ||
                                    null,
                                  contact: {
                                    nom:
                                      data.answers['full-name']?.value || null,
                                    telephone:
                                      data.answers['phone-number']?.value ||
                                      null,
                                    email:
                                      data.answers['email-address']?.value ||
                                      null,
                                  },
                                };
                                post('/contact/part-request', formattedData)
                                  .then((res) => {
                                    setIsSubmitting(false);
                                    completeForm();
                                  })
                                  .catch((err) => {
                                    toast.error(
                                      translateError(
                                        err,
                                        'Une erreur est survenue',
                                      ),
                                    );
                                  })
                                  .finally(() => {
                                    setIsSubmitting(false);
                                  });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 pb-4 sm:flex sm:flex-row sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md  bg-white px-3 py-2 text-[18px] font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpened(false)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                    Retour
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PartRequest;
