import { Form, Formik } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PasswordInput from '../../components/Form/PasswordInput';
import FormError from '../../components/Form/FormError';
import { useState } from 'react';
import * as Yup from 'yup';
import { post } from '../../components/Api/Api';
import { toast } from 'react-toastify';
import { translateError } from '../../utils/translatting';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';

export default function ResetPassword() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(8, 'Le mot de passe doit faire minimum 8 caractères')
      .max(255, 'Maximum 255 caractères')
      .required('Mot de passe requis'),
    confirmPassword: Yup.string()
      .required('Confirmation de mot de passe requis')
      .oneOf(
        [Yup.ref('password')],
        'La confirmation de mot de passe est incorrecte',
      ),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    const payload = {
      token: token,
      email: email,
      password:
        values.password?.trim().length > 0 ? values.password.trim() : null,
      password_confirmation:
        values.confirmPassword?.trim().length > 0
          ? values.confirmPassword.trim()
          : null,
    };

    post('/reset-password', payload)
      .then(() => {
        toast.success('Mot de passe réinitialisé avec succès');
        navigate('/');
      })
      .catch((e) => {
        setError(translateError(e));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="px-2 mx-auto my-10 max-w-md">
      <h1 className="text-2xl font-bold mb-10 text-center">
        Réinitialisation de mot de passe
      </h1>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnChange={true}
      >
        {({ isValid }) => (
          <Form>
            <div className="flex flex-col gap-4 mb-4">
              <PasswordInput
                name="password"
                placeholder="Nouveau mot de passe"
                forFormik
              />
              <PasswordInput
                name="confirmPassword"
                placeholder="Confirmation du nouveau mot de passe"
                forFormik
              />
            </div>
            {error && <FormError className="mb-2" text={error} />}
            <button
              type="submit"
              className={`px-[30px] border
                duration-300 rounded-[8px] py-[16px]
                w-full text-white inline-flex justify-center gap-3 ${
                  !isValid
                    ? 'bg-zinc-500'
                    : 'bg-orange hover:text-orange border-orange hover:bg-transparent'
                }`}
              disabled={loading || !isValid}
            >
              Valider
              {loading && <LittleWhiteLoader className="w-5 h-5" />}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
