import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import TopBanner from '../home-top-banner/TopBanner';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, NavLink } from 'react-router-dom';
import { FaAngleRight } from 'react-icons/fa6';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa6';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CubeIcon, ProfileDetails, ProfileIcon } from '../../Utils/Icons/Icons';
import {
  Configure,
  Hits,
  InstantSearch,
  SearchBox,
  useInstantSearch,
} from 'react-instantsearch';
import './style.css';
import { useCart } from '../../contexts/CartContext';
import { fetchWithHeaders } from '../Api/Api';
import { capitalizeFirstLetter } from '../../utils/formatting';
import {
  getFailoverImage,
  productTypesForBrandsRequest,
} from '../../utils/productHelper';
import LittleLoader from '../Loader/LittleLoader';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { TbLogout } from 'react-icons/tb';
import useClickOutsideDetector from '../../Hooks/useClicOutsideDetector';
import { VscChromeClose, VscNewline } from 'react-icons/vsc';
import PartRequest from '../PartRequest/PartRequest';
import { useSelector } from 'react-redux';
import { authStatus, logout } from '../../utils/auth';
import {
  initAuthState,
  logout as reduxLogout,
} from '../../store/Reducers/auth';
import LoginForm from '../Auth/LoginForm';
import { useDispatch } from 'react-redux';
import Tag from '../Tag';
import RegisterForm from '../Auth/RegisterForm';
import SwitchSelector from '../SwitchSelector';

const EmptyQueryBoundary = ({
  children,
  focus,
  resultRef,
  setShowPartRequest,
}) => {
  const { indexUiState, results } = useInstantSearch();

  if (!indexUiState.query || indexUiState.query.length < 1 || !focus) {
    return null;
  }

  return (
    <div className="absolute top-0 mt-[54px] w-[90vw] lg:w-[60vw] xl:w-[900px] z-[60]">
      <div
        className="relative bg-white w-full rounded-[12px] border-[1px] border-[#00000034] px-[12px] gap-[10px] py-[6px] max-h-[80vh] overflow-auto"
        ref={resultRef}
      >
        {results && results.nbHits === 0 ? (
          <div className="flex items-center justify-center pt-4 pb-8">
            <div className="flex flex-col items-center justify-center gap-4 text-center">
              <div className="text-gray-600">
                <img
                  src="/pictos/noresult.png"
                  alt="Aucun résultat"
                  className="w-48 h-48 mx-auto mb-4 opacity-60"
                />
                <p className="text-lg font-medium mb-2">
                  Aucun résultat trouvé
                </p>
                <div className="w-32 h-[1px] bg-[#E5E7EB] mx-auto opacity-100"></div>
                <p className="text-sm text-gray-500 mt-4 opacity-60">
                  Vous ne trouvez pas ce que vous cherchez ?
                </p>
              </div>
              <button
                onClick={() => setShowPartRequest(true)}
                className="flex items-center gap-2 px-4 py-2 bg-orange text-white rounded-lg hover:bg-orange/90 transition-colors"
              >
                <img
                  src="/pictos/repair.svg"
                  alt=""
                  className="w-5 h-5 filter brightness-0 invert-[1]"
                />
                <span>Faites-nous une demande personnalisée</span>
              </button>
              <p className="text-sm text-gray-500 opacity-60">
                Envoyez-nous une demande et nous chercherons pour vous !
              </p>
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

const SearchComponent = ({ setShowPartRequest }) => {
  const { searchClient } = useMemo(
    () =>
      instantMeiliSearch(
        process.env.VITE_MEILISEARCH_HOST_URL,
        process.env.VITE_MEILISEARCH_API_KEY,
      ),
    [],
  );
  const navigate = useNavigate();
  const location = useLocation();
  const resultRef = useRef(null);
  const searchRef = useRef(null);

  const [focus, setFocus] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setFocus(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getLink = (itemType, $itemId) => {
    const corresp = {
      vehicle: 'vehicules',
      tyre: 'pneus',
      rim: 'jantes',
      part: 'pieces',
    };

    return `/${corresp[itemType]}/${$itemId}`;
  };

  const getProductTypeLabel = (type) => {
    switch (type) {
      case 'vehicle':
        return 'Véhicules';
      case 'tyre':
        return 'Pneus';
      case 'rim':
        return 'Jantes';
      case 'part':
        return 'Pièces';
      default:
        return '';
    }
  };

  const getProductLabel = (hit) => {
    switch (hit.subjectType) {
      case 'vehicle':
        return `${hit.brand} ${hit.model} ${hit.year}`;
      case 'tyre':
        return `${hit.width}/${hit.height} ${hit.diameter}" ${hit.speedLoadIndex} (${hit.season}) - ${hit.brand} ${hit.designation}`;
      case 'rim':
        return `${hit.diameter}/${hit.width}${
          hit.numberOfHoles ? '/' + hit.numberOfHoles + 'T' : ''
        } (${hit.brand} ${hit.familyModel})`;
      case 'part':
        return `${hit.brand} ${hit.model}${hit.year ? ', ' + hit.year : ''}${
          hit.partLabel === 'MOTEUR' && hit.engineType
            ? ' - ' + hit.engineType
            : ''
        }${
          (hit.partLabel === 'BOITE A VITESSE MECANIQUE' ||
            hit.partLabel === 'BOITE A VITESSE AUTOMATIQUE') &&
          hit.gearboxType
            ? ' - ' + hit.gearboxType
            : ''
        }`;
      default:
        return '';
    }
  };

  const handleSearchSubmit = (e) => {
    if (location.pathname !== '/catalogue') {
      navigate(`/catalogue?q=${e.target.querySelector('input').value}`);
      setFocus(false);
    }
  };

  const handleInputChange = (e) => {
    if (e.target.value) {
      setTimeout(() => resultRef.current?.scrollTo(0, 0), 100);
    }
    if (e.target.value && !isFilled) {
      setIsFilled(true);
    } else if (!e.target.value && isFilled) {
      setIsFilled(false);
    }
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={'products_index'}
      insights={false}
    >
      <Configure hitsPerPage={50}></Configure>
      <div
        ref={searchRef}
        className="flex-1 border-[1px] border-[#0d1f224e] rounded-[8px] justify-between h-full w-full relative"
      >
        <SearchBox
          placeholder="Marque, Référence, Modèle.."
          classNames={{
            root: 'flex-1 border-none text-dark h-full',
            form: 'relative flex-1 h-full shadow-[inset_0_1px_4px_0_rgba(119,122,175,0.3)]',
            reset: 'hidden',
            input:
              'flex-1 h-full border-none text-dark lg:min-w-[350px] rounded-[8px] shadow-none bg-transparent',
            submit: `cursor-pointer inline-block ${!isFilled ? 'hidden' : ''}`,
          }}
          style={{
            input: {
              '::placeholder': {
                color: '#9CA3AF', // Couleur grise exemple
              },
            },
          }}
          inputMode="search"
          onFocus={() => setFocus(true)}
          // onBlur={() => setTimeout(() => setFocus(false), 100)}
          onSubmit={handleSearchSubmit}
          onInput={handleInputChange}
          resetIconComponent={() => <></>}
          submitIconComponent={() => (
            <span
              className="cursor-pointer inline-block bg-orange rounded h-full flex items-center px-3"
              title="Rechercher"
            >
              <VscNewline className="text-white" size={24} />
            </span>
          )}
        />
        {/*<input type="text" placeholder="Marque, Référence, Modèle.." className="flex-1 border-none outline-none text-dark" /> <img src="/icon-search.svg" className="max-w-[24px] hover:cursor-pointer" alt="" />*/}
        <EmptyQueryBoundary
          focus={focus}
          resultRef={resultRef}
          setShowPartRequest={setShowPartRequest}
        >
          <Hits
            classNames={{
              root: '',
              list: 'gap-[0px]',
              item: 'align-middle cursor-pointer leading-[1.5] rounded !px-2 m-1',
            }}
            hitComponent={({ hit }) => {
              return (
                <Link
                  onMouseDown={(e) => {
                    if (e.button !== 2) {
                      setTimeout(() => {
                        setFocus(false);
                        window.location.replace(
                          getLink(hit.subjectType, hit.refSource),
                        );
                      }, 100);
                    }
                  }}
                  className="hit w-full cursor-pointer"
                >
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-14 h-14 min-w-[56px] rounded-md overflow-hidden bg-gray flex items-center justify-center">
                      {hit.mainImage ? (
                        <img
                          src={hit.mainImage}
                          alt={getProductLabel(hit)}
                          className="w-full h-full object-contain"
                        />
                      ) : (
                        <img
                          src={'/' + getFailoverImage(hit.subjectType, hit)}
                          alt={getProductLabel(hit)}
                          className="w-10 h-10 opacity-40"
                        />
                      )}
                    </div>
                    <div className="flex-1 min-w-0 flex flex-col justify-center">
                      <p className="!text-[14px] lg:!text-[1rem] !opacity-100 truncate">
                        <b className="font-semibold">
                          {getProductTypeLabel(hit.subjectType)}
                        </b>{' '}
                        {hit.subjectType !== 'vehicle' && (
                          <>
                            {' > '}
                            <b className="font-semibold">{hit.partLabel}</b>
                          </>
                        )}
                        {' > '}
                        {getProductLabel(hit)}
                        {hit.subjectType === 'vehicle' &&
                          hit.procedure === 'PIECES' && (
                            <Tag.ForParts as="span" className="ml-2" />
                          )}
                      </p>
                      {hit.price && (
                        <div className="font-semibold text-orange">
                          {hit.price.toLocaleString('fr-FR')} €
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              );
            }}
          />
        </EmptyQueryBoundary>
      </div>
    </InstantSearch>
  );
};

export default function Header() {
  const ref = useRef(null);
  const { openCart, nbCartItems, cartLoading } = useCart();
  const [openCategoryMenu, setOpenCategoryMenu] = useState(false);
  const [active, setActive] = useState(0);
  const [active2, setActive2] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggleRegisterForm, setToggleRegisterForm] = useState(false);
  const {
    isAuthenticated,
    status: userLoadingStatus,
    user: userData,
  } = useSelector((state) => {
    return state.auth;
  });

  const [showMenu, setShowMenu] = useState(false);
  const menuref = useRef();

  useClickOutsideDetector(menuref, () => {
    setShowMenu(false);
  });

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add('slg:!overflow-hidden');
    } else {
      document.body.classList.remove('slg:!overflow-hidden');
    }
  }, [showMenu]);

  const [vehicleAccidentedBrands, setVehicleAccidentedBrands] = useState(null);
  const [vehicleUsedBrands, setVehicleUsedBrands] = useState(null);
  const [rimBrands, setRimBrands] = useState(null);
  const [partBrands, setPartBrands] = useState(null);
  const [tyreBrands, setTyreBrands] = useState(null);

  const getBrandsByType = async (type) => {
    return await fetchWithHeaders(
      process.env.VITE_API_URL + 'brands?type=' + type,
    ).then((result) => {
      return result;
    });
  };
  const [showPartRequest, setShowPartRequest] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      getBrandsByType(productTypesForBrandsRequest.vehicleAccidented).then(
        (res) => {
          setVehicleAccidentedBrands(res);
          getBrandsByType(productTypesForBrandsRequest.vehicleUsed).then(
            (res) => {
              setVehicleUsedBrands(res);
              getBrandsByType(productTypesForBrandsRequest.rim).then((res) => {
                setRimBrands(res);
                getBrandsByType(productTypesForBrandsRequest.part).then(
                  (res) => {
                    setPartBrands(res);
                    getBrandsByType(productTypesForBrandsRequest.tyre).then(
                      (res) => {
                        setTyreBrands(res);
                      },
                    );
                  },
                );
              });
            },
          );
        },
      );
    }, 3000);
  }, []);

  const logoutFromRedux = () => {
    dispatch(reduxLogout());
  };

  const initReduxData = () => {
    dispatch(initAuthState());
  };

  const handleLogout = (delay = 0) => {
    setShowMenu(false);
    setTimeout(() => {
      logout();
      logoutFromRedux();
    }, delay);
  };

  const onAuth = () => {
    initReduxData();
    setShowMenu(false);
  };

  return (
    <>
      <header className="wrapper">
        <TopBanner />
        <div className="content pb-[10px] xl:pb-0 pt-[12px] xl:pt-0 xl:h-[136px] px-[16px] gap-[10px] sm:gap-[16px] 1300:px-0 center-col">
          <nav className="content flex items-center gap-[24px] sm:gap-[15px] lg:gap-[32px] justify-between w-full">
            {/* Logo  */}
            <Link to={'/'} className="flex-1 lg:flex-none">
              <img
                src="/logo.svg"
                alt=""
                className="w-full h-full max-h-[56px] max-w-[96px]"
              />
            </Link>
            {/* Category Botton  */}
            {/* <div className=" order-1 lg:order-none">
                        <button className="py-[16px] center lg:px-[24px] rounded-[8px] w-[56px] lg:w-auto flex gap-[16px] bg-gray">
                            <img src="/cat-menu.svg" alt="" />
                            <h4 className="font-[600] leading-[24px] text-dark hidden lg:block">
                                Catégories
                            </h4>
                        </button>
                    </div> */}
            <div className="order-1 lg:order-none relative">
              <button
                className="py-[16px] center lg:px-[24px] rounded-[8px] w-[56px] lg:w-auto flex gap-[16px] bg-gray"
                onMouseEnter={() => {
                  setOpenCategoryMenu(true);
                }}
                onMouseLeave={() => {
                  setOpenCategoryMenu(false);
                }}
              >
                <img
                  onClick={() => setOpen(!open)}
                  src="/cat-menu.svg"
                  alt=""
                />
                <h4 className="font-[600] leading-[24px] text-dark hidden lg:block">
                  Catégories
                </h4>
              </button>
              {/* Mega Menu Content */}
              <div
                id="megaMenu"
                className={`absolute top-full pt-[6px] left-[0px] w-[100vw] xl:w-[90vw] z-[50] max-w-[70vw] xl:max-w-[1065px] max-h-[360px] overflow-y-auto hidden ${
                  openCategoryMenu ? 'lg:block' : 'lg:hidden'
                }`}
                onMouseEnter={() => {
                  setOpenCategoryMenu(true);
                }}
                onMouseLeave={() => {
                  setOpenCategoryMenu(false);
                }}
              >
                {/* Add your mega menu content here */}
                {/* Example content */}
                <div className="bg-white w-full rounded-[12px] border-[1px] border-[#00000034] px-[24px] py-[32px] h-[352px] flex gap-[48px]">
                  {/* Left Side  */}
                  <div className="min-w-[254px] max-w-[255px]">
                    <Link
                      onMouseEnter={() => setActive(0)}
                      to={'/vehicules-d-occasion'}
                      onClick={() =>
                        window.location.replace('/vehicules-d-occasion')
                      }
                      className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                        active === 0 && 'bg-gray'
                      }`}
                    >
                      Véhicules d&apos;occasion
                      <FaAngleRight className="opacity-50 group-hover:opacity-[1]" />
                    </Link>
                    {/* <button
                                            onMouseEnter={() =>
                                                setActive(1)
                                            }
                                            className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                                                active === 1 && 'bg-gray'
                                            }`}
                                        >
                                            Deux roues
                                            <FaAngleRight className="opacity-50 group-hover:opacity-[1]" />
                                        </button> */}
                    <Link
                      onMouseEnter={() => setActive(2)}
                      to={'/pieces-d-occasion'}
                      onClick={() =>
                        window.location.replace('/pieces-d-occasion')
                      }
                      className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                        active === 2 && 'bg-gray'
                      }`}
                    >
                      Pièces auto d&apos;occasion
                      <FaAngleRight className="opacity-50 group-hover:opacity-[1]" />
                    </Link>
                    <Link
                      to={'/vehicules-accidentes'}
                      onClick={() =>
                        window.location.replace('/vehicules-accidentes')
                      }
                      onMouseEnter={() => setActive(3)}
                      className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                        active === 3 && 'bg-gray'
                      }`}
                    >
                      Véhicules accidentés
                      <FaAngleRight className="opacity-50 group-hover:opacity-[1]" />
                    </Link>
                    <Link
                      to={'/pneus-d-occasion'}
                      onClick={() =>
                        window.location.replace('/pneus-d-occasion')
                      }
                      onMouseEnter={() => setActive(4)}
                      className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                        active === 4 && 'bg-gray'
                      }`}
                    >
                      Pneus d&apos;occasion
                      <FaAngleRight className="opacity-50 group-hover:opacity-[1]" />
                    </Link>
                    <Link
                      to={'/jantes-d-occasion'}
                      onClick={() =>
                        window.location.replace('/jantes-d-occasion')
                      }
                      onMouseEnter={() => setActive(5)}
                      className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                        active === 5 && 'bg-gray'
                      }`}
                    >
                      Jantes d&apos;occasion
                      <FaAngleRight className="opacity-50 group-hover:opacity-[1]" />
                    </Link>
                  </div>
                  {/* Rrite Side  */}
                  {/* Véhicules d&apos;occasion Content  */}
                  {active === 0 && (
                    <div className="flex-1 space-y-[24px] overflow-hidden">
                      <h1 className="t-clash font-[600] text-[36px] leading-[32px] text-[black]">
                        Véhicules d&apos;occasion
                      </h1>
                      <div className="grid gap-[24px] w-full overflow-auto max-h-[225px] grid-rows-8 grid-cols-3">
                        <Link
                          to={'/vehicules-d-occasion'}
                          onClick={() =>
                            window.location.replace('/vehicules-d-occasion')
                          }
                          className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] flex items-center gap-[10px] group font-bold"
                        >
                          Toutes les marques
                        </Link>
                        {vehicleUsedBrands === null && <LittleLoader />}
                        {vehicleUsedBrands &&
                          vehicleUsedBrands.map((brand) => (
                            <Link
                              to={encodeURI(
                                '/vehicules-d-occasion?refinementList[brand][0]=' +
                                  brand,
                              )}
                              onClick={() =>
                                window.location.replace(
                                  encodeURI(
                                    '/vehicules-d-occasion?refinementList[brand][0]=' +
                                      brand,
                                  ),
                                )
                              }
                              className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                              key={brand}
                            >
                              {capitalizeFirstLetter(brand)}
                            </Link>
                          ))}
                      </div>
                    </div>
                  )}
                  {/* Deux roues Content  */}
                  {/* {active === 1 && (
                                        <div className="flex-1 space-y-[24px]">
                                            <h1 className="t-clash font-[600] text-[36px] leading-[32px] text-[black]">
                                                Deux roues
                                            </h1>
                                        </div>
                                    )} */}
                  {/* Pièces auto d&apos;occasion Content  */}
                  {active === 2 && (
                    <div className="flex-1 space-y-[24px]">
                      <h1 className="t-clash font-[600] text-[36px] leading-[32px] text-[black]">
                        Pièces auto d&apos;occasion
                      </h1>
                      <div className="grid gap-[24px] w-full overflow-auto max-h-[225px] grid-rows-8 grid-cols-3">
                        <Link
                          to={'/pieces-d-occasion'}
                          onClick={() =>
                            window.location.replace('/pieces-d-occasion')
                          }
                          className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] flex items-center gap-[10px] group font-bold"
                        >
                          Toutes les marques
                        </Link>
                        {partBrands === null && <LittleLoader />}
                        {partBrands &&
                          partBrands.map((brand) => (
                            <Link
                              to={encodeURI(
                                '/pieces-d-occasion?refinementList[brand][0]=' +
                                  brand,
                              )}
                              onClick={() =>
                                window.location.replace(
                                  encodeURI(
                                    '/pieces-d-occasion?refinementList[brand][0]=' +
                                      brand,
                                  ),
                                )
                              }
                              className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                              key={brand}
                            >
                              {capitalizeFirstLetter(brand)}
                            </Link>
                          ))}
                      </div>
                    </div>
                  )}
                  {/* Véhicules accidentés  Content  */}
                  {active === 3 && (
                    <div className="flex-1 space-y-[24px]">
                      <h1 className="t-clash font-[600] text-[36px] leading-[32px] text-[black]">
                        Véhicules accidentés
                      </h1>
                      <div className="grid gap-[24px] w-full overflow-auto max-h-[225px] grid-rows-8 grid-cols-3">
                        <Link
                          to={'/vehicules-accidentes'}
                          onClick={() =>
                            window.location.replace('/vehicules-accidentes')
                          }
                          className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] flex items-center gap-[10px] group font-bold"
                        >
                          Toutes les marques
                        </Link>
                        {vehicleAccidentedBrands === null && <LittleLoader />}
                        {vehicleAccidentedBrands &&
                          vehicleAccidentedBrands.map((brand) => (
                            <Link
                              to={encodeURI(
                                '/vehicules-accidentes?refinementList[brand][0]=' +
                                  brand,
                              )}
                              onClick={() =>
                                window.location.replace(
                                  encodeURI(
                                    '/vehicules-accidentes?refinementList[brand][0]=' +
                                      brand,
                                  ),
                                )
                              }
                              className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                              key={brand}
                            >
                              {capitalizeFirstLetter(brand)}
                            </Link>
                          ))}
                      </div>
                    </div>
                  )}
                  {/* Pneus d&apos;occasion content  */}
                  {active === 4 && (
                    <div className="flex-1 space-y-[24px]">
                      <h1 className="t-clash font-[600] text-[36px] leading-[32px] text-[black]">
                        Pneus d&apos;occasion
                      </h1>
                      <div className="grid gap-[24px] w-full overflow-auto max-h-[225px] grid-rows-8 grid-cols-3">
                        <Link
                          to={'/pneus-d-occasion'}
                          onClick={() =>
                            window.location.replace('/pneus-d-occasion')
                          }
                          className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] flex items-center gap-[10px] group font-bold"
                        >
                          Tous les fabricants
                        </Link>
                        {tyreBrands === null && <LittleLoader />}
                        {tyreBrands &&
                          tyreBrands.map((brand) => (
                            <Link
                              to={encodeURI(
                                '/pneus-d-occasion?refinementList[brand][0]=' +
                                  brand,
                              )}
                              onClick={() =>
                                window.location.replace(
                                  encodeURI(
                                    '/pneus-d-occasion?refinementList[brand][0]=' +
                                      brand,
                                  ),
                                )
                              }
                              className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                              key={brand}
                            >
                              {capitalizeFirstLetter(brand)}
                            </Link>
                          ))}
                      </div>
                    </div>
                  )}
                  {/* Jantes d&apos;occasion  content  */}
                  {active === 5 && (
                    <div className="flex-1 space-y-[24px]">
                      <h1 className="t-clash font-[600] text-[36px] leading-[32px] text-[black]">
                        Jantes d&apos;occasion
                      </h1>
                      <div className="grid gap-[24px] w-full overflow-auto max-h-[225px] grid-rows-8 grid-cols-3">
                        <Link
                          to={'/jantes-d-occasion'}
                          onClick={() =>
                            window.location.replace('/jantes-d-occasion')
                          }
                          className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] flex items-center gap-[10px] group font-bold"
                        >
                          Toutes les marques
                        </Link>
                        {rimBrands === null && <LittleLoader />}
                        {rimBrands &&
                          rimBrands.map((brand) => (
                            <Link
                              to={encodeURI(
                                '/jantes-d-occasion?refinementList[brand][0]=' +
                                  brand,
                              )}
                              onClick={() =>
                                window.location.replace(
                                  encodeURI(
                                    '/jantes-d-occasion?refinementList[brand][0]=' +
                                      brand,
                                  ),
                                )
                              }
                              className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                              key={brand}
                            >
                              {capitalizeFirstLetter(brand)}
                            </Link>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Search Bar */}
            <div className="hidden lg:flex w-full h-[56px]">
              <SearchComponent setShowPartRequest={setShowPartRequest} />
              {/*<input type="text" placeholder="Marque, Référence, Modèle.." className="flex-1 border-none outline-none text-dark" /> <img src="/icon-search.svg" className="max-w-[24px] hover:cursor-pointer" alt="" />*/}
            </div>
            {/* Righ Icons  */}
            <div className="flex gap-[24px] sm:gap-[10px] lg:gap-[24px] items-center justify-center">
              <div
                className="cursor-pointer"
                onClick={() => navigate('/wishlist')}
              >
                <img
                  src="/heart.svg"
                  alt=""
                  className="max-w-[24px] sm:max-w-[36px] cursor-pointer hover:opacity-[.6]"
                />
              </div>
              <div className="relative" onClick={() => openCart()}>
                <img
                  src="/shopping-cart.svg"
                  alt=""
                  className="max-w-[24px] sm:max-w-[36px] cursor-pointer hover:opacity-[.6]"
                />
                {cartLoading && (
                  <div className="absolute w-[12px] h-[12px] top-[-5px] right-[-5px] leading-[1]">
                    <LittleLoader />
                  </div>
                )}
                {!cartLoading && !!nbCartItems && (
                  <div className="absolute w-[20px] h-[20px] rounded-full center bg-orange text-[white] text-[13px] top-[-5px] right-[-5px] leading-[1] pt-[1px] cursor-pointer">
                    {nbCartItems}
                  </div>
                )}
              </div>
              <div className="relative z-15" ref={menuref}>
                {userLoadingStatus === authStatus.loading && (
                  <LittleLoader className="w-6 h-6" />
                )}
                {userLoadingStatus !== authStatus.loading &&
                  isAuthenticated && (
                    <svg
                      onClick={() => setShowMenu(!showMenu)}
                      className="cursor-pointer"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="32"
                        height="32"
                        rx="16"
                        fill="#FF5600"
                        fillOpacity="0.15"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.3333 12.6667C13.3333 11.9594 13.6143 11.2811 14.1144 10.781C14.6145 10.281 15.2928 10 16 10C16.7072 10 17.3855 10.281 17.8856 10.781C18.3857 11.2811 18.6667 11.9594 18.6667 12.6667C18.6667 13.3739 18.3857 14.0522 17.8856 14.5523C17.3855 15.0524 16.7072 15.3333 16 15.3333C15.2928 15.3333 14.6145 15.0524 14.1144 14.5523C13.6143 14.0522 13.3333 13.3739 13.3333 12.6667ZM13.3333 16.6667C12.4493 16.6667 11.6014 17.0179 10.9763 17.643C10.3512 18.2681 10 19.1159 10 20C10 20.5304 10.2107 21.0391 10.5858 21.4142C10.9609 21.7893 11.4696 22 12 22H20C20.5304 22 21.0391 21.7893 21.4142 21.4142C21.7893 21.0391 22 20.5304 22 20C22 19.1159 21.6488 18.2681 21.0237 17.643C20.3986 17.0179 19.5507 16.6667 18.6667 16.6667H13.3333Z"
                        fill="#FF5600"
                      />
                    </svg>
                  )}
                {userLoadingStatus !== authStatus.loading &&
                  !isAuthenticated && (
                    <img
                      src="/icon-user.svg"
                      alt=""
                      className="max-w-[24px] sm:max-w-[36px] cursor-pointer hover:opacity-[.6]"
                      onClick={() => setShowMenu(!showMenu)}
                    />
                  )}
                {showMenu && isAuthenticated && (
                  <div className="absolute slg:hidden border border-lightGrey bg-white top-8 right-3 z-30 p-5 w-[280px] slg:w-full h-max rounded-xl">
                    <div className="flex gap-4 items-center">
                      <ProfileIcon />
                      <div className="">
                        <h4 className="font-jost ">
                          {userData.first_name} {userData.last_name}
                        </h4>
                        <h4 className="font-jost text-lightGrey">
                          {userData.email}
                        </h4>
                      </div>
                    </div>

                    <ul className="mt-4">
                      <li>
                        <NavLink
                          to="/profile/profile-details"
                          className={({ isActive }) =>
                            `h-12 w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-lg ${
                              isActive ? '' : ''
                            }`
                          }
                        >
                          <ProfileDetails fill={'stroke-dark'} />
                          Mon profil
                        </NavLink>
                      </li>

                      <li>
                        <NavLink
                          to="/profile/orders"
                          className={({ isActive }) =>
                            `h-12 w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-lg ${
                              isActive ? '' : ''
                            }`
                          }
                        >
                          <CubeIcon fill={'stroke-dark'} />
                          Mes commandes
                        </NavLink>
                      </li>
                      <li>
                        <div
                          onClick={() => handleLogout(0)}
                          className="h-12 border-t border-t-lightGrey text-lightGrey w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-y-lg cursor-pointer"
                        >
                          <TbLogout />
                          Se déconnecter
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
                {showMenu && !isAuthenticated && (
                  <div className="absolute slg:hidden border border-lightGrey bg-white top-8 right-3 z-30 p-5 w-[380px] slg:w-full h-max rounded-xl">
                    <SwitchSelector
                      choice1="Créer un compte"
                      choice2="Se connecter"
                      choice1Value={1}
                      choice2Value={2}
                      choice={toggleRegisterForm ? 1 : 2}
                      onChange={(value) => setToggleRegisterForm(value === 1)}
                      className="mt-0"
                    />
                    {toggleRegisterForm ? (
                      <RegisterForm
                        onSuccess={onAuth}
                        closeModal={() => setShowMenu(false)}
                      />
                    ) : (
                      <LoginForm
                        onSuccess={onAuth}
                        closeModal={() => setShowMenu(false)}
                      />
                    )}
                  </div>
                )}

                <div
                  className={`${
                    showMenu ? 'slg:-translate-x-0' : '-translate-x-full'
                  } fixed block lg:hidden ${
                    isAuthenticated ? 'pt-20' : ''
                  } px-5 top-0 left-0 w-full h-full bg-white duration-300 z-50`}
                >
                  <button
                    onClick={() => setShowMenu(false)}
                    className="text-xl text-black z-40 absolute top-4 rounded-xl bg-gray w-12 flex justify-center items-center h-12 right-4"
                  >
                    <VscChromeClose />
                  </button>
                  {isAuthenticated && (
                    <>
                      <h2 className="text-[32px] font-semibold font-jost">
                        Mon profil
                      </h2>
                      <div className="flex gap-4 items-center">
                        <ProfileIcon />
                        <div className="">
                          <h4 className="font-jost ">
                            {userData.first_name} {userData.last_name}
                          </h4>
                          <h4 className="font-jost text-lightGrey">
                            {userData.email}
                          </h4>
                        </div>
                      </div>

                      <ul className="mt-4">
                        <li>
                          <NavLink
                            onClick={() => setShowMenu(false)}
                            to="/profile/profile-details"
                            className={({ isActive }) =>
                              `h-12 w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-lg ${
                                isActive ? '' : ''
                              }`
                            }
                          >
                            <ProfileDetails fill={'stroke-dark'} />
                            Mon profil
                          </NavLink>
                        </li>

                        <li>
                          <NavLink
                            onClick={() => setShowMenu(false)}
                            to="/profile/orders"
                            className={({ isActive }) =>
                              `h-12 w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-lg ${
                                isActive ? '' : ''
                              }`
                            }
                          >
                            <CubeIcon fill={'stroke-dark'} />
                            Mes commandes
                          </NavLink>
                        </li>
                        <li>
                          <Link
                            onClick={() => handleLogout(300)}
                            to={''}
                            className="h-12 border-t border-t-lightGrey text-lightGrey w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-y-lg"
                          >
                            <TbLogout />
                            Se déconnecter
                          </Link>
                        </li>
                      </ul>
                    </>
                  )}
                  {!isAuthenticated && (
                    <div className="flex flex-col items-center w-full h-full">
                      <SwitchSelector
                        choice1="Créer un compte"
                        choice2="Se connecter"
                        padding="py-2 px-4"
                        choice1Value={1}
                        choice2Value={2}
                        choice={toggleRegisterForm ? 1 : 2}
                        onChange={(value) => setToggleRegisterForm(value === 1)}
                        className="mr-16 mb-2"
                      />
                      <div className="overflow-y-auto h-full w-full my-6">
                        {toggleRegisterForm ? (
                          <RegisterForm
                            onSuccess={onAuth}
                            className="w-full"
                            closeModal={() => setShowMenu(false)}
                          />
                        ) : (
                          <LoginForm
                            onSuccess={onAuth}
                            className="w-full"
                            closeModal={() => setShowMenu(false)}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/*<div>
                                <img
                                    src="/icon-user.svg"
                                    alt=""
                                    className="max-w-[24px] sm:max-w-[36px] cursor-pointer hover:opacity-[.6]"
                                />
                            </div>*/}
            </div>
          </nav>
          {/* Search Bar */}
          <div className="flex lg:hidden w-full h-[56px]">
            <SearchComponent setShowPartRequest={setShowPartRequest} />
          </div>
          {/* header Botton Bar */}
          <div className="content flex justify-between gap-[15px] sm:gap-[10px] flex-col 1200:flex-row items-center ">
            {/* left Section */}
            {/* fOR tABLET AND mOBILEW */}
            <div className="w-full 900:hidden">
              <Splide
                ref={ref}
                className="flex items-center"
                options={{
                  width: '100%',
                  autoWidth: true,
                  perPage: 1, // Show 2 logos by default
                  perMove: 1, // Move 2 logos at a time
                  drag: true,
                  pagination: false,
                  arrows: false,
                  type: '',
                  gap: '24px',
                  padding: '0px',
                }}
              >
                <SplideSlide className="center">
                  {/* 1  */}
                  <div
                    className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
                    onClick={() => {
                      if (window.location.pathname !== '/catalogue')
                        return navigate('/catalogue');
                      window.location.replace('/catalogue');
                    }}
                  >
                    <img
                      src="/pictos/book.svg"
                      alt=""
                      className="h-[20px] lg:h-[24px]"
                    />
                    <p className="font-[500] text-[14px] text-dark">
                      Catalogue
                    </p>
                  </div>
                </SplideSlide>
                <SplideSlide className="center">
                  {/* 2  */}
                  <Link
                    to="/propos"
                    className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
                  >
                    <img
                      src="/pictos/about-outline.svg"
                      alt=""
                      className="h-[20px] lg:h-[24px]"
                    />
                    <p className="font-[500] text-[14px] text-dark">À propos</p>
                  </Link>
                </SplideSlide>
                {/*<SplideSlide className="center">
                  <Link
                    to="/blog"
                    className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
                  >
                    <img
                      src="/pictos/blog.svg"
                      alt=""
                      className="h-[20px] lg:h-[24px]"
                    />
                    <p className="font-[500] text-[14px] text-dark">Blog</p>
                  </Link>
                </SplideSlide>*/}
                {/* <SplideSlide className="center">
                  <Link
                    to="/recrutement"
                    className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
                  >
                    <img
                      src="/pictos/briefcase.svg"
                      alt=""
                      className="h-[20px] lg:h-[24px]"
                    />
                    <p className="font-[500] text-[14px] text-dark">
                      Recrutement
                    </p>
                  </Link>
                </SplideSlide> */}
                <SplideSlide className="center">
                  {/* 5  */}
                  <Link
                    to="/rse"
                    className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
                  >
                    <img
                      src="/pictos/pajamas_nature.svg"
                      alt=""
                      className="h-[24px]"
                    />
                    <p className="font-[500] text-[14px] text-dark">RSE</p>
                  </Link>
                </SplideSlide>
                <SplideSlide className="center">
                  {/* 6  */}
                  <Link
                    to="/aide"
                    className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
                  >
                    <img src="/pictos/help.svg" alt="" className="h-[24px]" />
                    <p className="font-[500] text-[14px] text-dark">Aide</p>
                  </Link>
                </SplideSlide>
                <SplideSlide className="center">
                  {/* 7  */}
                  <div
                    className="flex gap-[5px] xl:gap-[8px] px-[5px] xl:px-[8px] items-center cursor-pointer hover:opacity-[.8] border-[2px] border-orange h-[32px] xl:h-[32px] rounded-[15px]"
                    onClick={() => setShowPartRequest(true)}
                  >
                    <img
                      src="/pictos/repair.svg"
                      alt=""
                      className="h-[24px] w-[24px]"
                    />
                    <p className="font-[500] text-[14px] text-orange">
                      Effectuer une demande de pièce
                    </p>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
            {/* fOR pc */}
            <div className="hidden 900:flex gap-[22px] max-w-[100%]">
              {/* 1  */}
              <div
                className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
                onClick={() => {
                  if (window.location.pathname !== '/catalogue')
                    return navigate('/catalogue');
                  window.location.replace('/catalogue');
                }}
              >
                <img
                  src="/pictos/book.svg"
                  alt=""
                  className="h-[20px] lg:h-[24px]"
                />
                <p className="font-[500] text-[14px] text-dark">Catalogue</p>
              </div>
              {/* 2  */}
              <Link
                to="/propos"
                className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
              >
                <img
                  src="/pictos/about-outline.svg"
                  alt=""
                  className="h-[20px] lg:h-[24px]"
                />
                <p className="font-[500] text-[14px] text-dark">À propos</p>
              </Link>
              {/* 3  */}
              {/*<Link
                to="/blog"
                className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
              >
                <img
                  src="/pictos/blog.svg"
                  alt=""
                  className="h-[20px] lg:h-[24px]"
                />
                <p className="font-[500] text-[14px] text-dark">Blog</p>
              </Link>*/}
              {/* 4  */}
              {/* <Link
                to="/recrutement"
                className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
              >
                <img
                  src="/pictos/briefcase.svg"
                  alt=""
                  className="h-[20px] lg:h-[24px]"
                />
                <p className="font-[500] text-[14px] text-dark">Recrutement</p>
              </Link> */}
              {/* 5  */}
              <Link
                to="/rse"
                className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
              >
                <img
                  src="/pictos/pajamas_nature.svg"
                  alt=""
                  className="h-[24px]"
                />
                <p className="font-[500] text-[14px] text-dark">RSE</p>
              </Link>
              {/* 6  */}
              <Link
                to="/aide"
                className="flex gap-[4px] xl:gap-[8px] items-center cursor-pointer hover:opacity-[.8]"
              >
                <img src="/pictos/help.svg" alt="" className="h-[24px]" />
                <p className="font-[500] text-[14px] text-dark">Aide</p>
              </Link>
              {/* 7  */}
              <div
                onClick={() => setShowPartRequest(true)}
                className="flex gap-[5px] xl:gap-[8px] px-[5px] xl:px-[8px] items-center cursor-pointer hover:opacity-[.8] border-[2px] border-orange h-[32px] xl:h-[32px] rounded-[15px]"
              >
                <img src="/pictos/repair.svg" alt="" className="h-[24px]" />
                <p className="font-[500] text-[14px] text-orange">
                  Effectuer une demande de pièce
                </p>
              </div>
            </div>
            {/* Right  */}
            <div className="flex gap-[5px] xl:gap-[8px] px-[10px] items-center justify-center bg-blue h-[32px] rounded-[15px] w-full sm:w-auto">
              <img
                src="/pictos/tabler-icon-star.svg"
                alt=""
                className="h-[18px]"
              />
              <p className="font-[500] text-[14px] text-white">
                Votre référence en pièces auto depuis{' '}
                {new Date().getFullYear() - 1977} ans
              </p>
            </div>
          </div>
        </div>
      </header>
      {showPartRequest && (
        <PartRequest setOpened={setShowPartRequest} opened={showPartRequest} />
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute right-[10px] top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="relative rounded-md text-gray-300 hover:text-[black] focus:outline-none focus:ring-2 focus:ring-[black]"
                          onClick={() => setOpen(false)}
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-auto bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <Dialog.Title className="text-base font-clash font-semibold leading-6 text-gray-900">
                          Stop Pièces Auto
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6 w-full">
                        <div className="w-full">
                          <div className="">
                            <button
                              onClick={() =>
                                setActive2(active2 === 0 ? null : 0)
                              }
                              className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                                active2 === 0 && 'bg-gray'
                              }`}
                            >
                              Véhicules d&apos;occasion
                              {active2 === 0 ? (
                                <FaAngleUp className="opacity-50 group-hover:opacity-[1]" />
                              ) : (
                                <FaAngleDown className="opacity-50 group-hover:opacity-[1]" />
                              )}
                            </button>
                            {active2 === 0 && (
                              <div className="flex flex-col gap-[8px] w-full max-w-[200px] px-[20px] py-[10px]">
                                <Link
                                  to={'/vehicules-d-occasion'}
                                  onClick={() =>
                                    window.location.replace(
                                      '/vehicules-d-occasion',
                                    )
                                  }
                                  className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] font-bold"
                                >
                                  Toutes les marques
                                </Link>
                                {vehicleUsedBrands === null && <LittleLoader />}
                                {vehicleUsedBrands &&
                                  vehicleUsedBrands.map((brand) => (
                                    <Link
                                      to={encodeURI(
                                        '/vehicules-d-occasion?refinementList[brand][0]=' +
                                          brand,
                                      )}
                                      onClick={() =>
                                        window.location.replace(
                                          encodeURI(
                                            '/vehicules-d-occasion?refinementList[brand][0]=' +
                                              brand,
                                          ),
                                        )
                                      }
                                      className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                      key={brand}
                                    >
                                      {capitalizeFirstLetter(brand)}
                                    </Link>
                                  ))}
                              </div>
                            )}
                            {/* <button
                                                            onClick={() =>
                                                                setActive2(active2 === 1 ? null : 1)
                                                            }
                                                            className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                                                                active2 === 1 &&
                                                                'bg-gray'
                                                            }`}
                                                        >
                                                            Deux roues
                                                            {active2 === 1 ? (
                                                                <FaAngleUp className="opacity-50 group-hover:opacity-[1]" />
                                                            ) : (
                                                                <FaAngleDown className="opacity-50 group-hover:opacity-[1]" />
                                                            )}
                                                        </button>
                                                        {active2 === 1 && (
                                                            <div className="flex flex-col gap-[8px] w-full max-w-[200px] px-[20px] py-[10px]">
                                                                <Link
                                                                    to={'#'}
                                                                    className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                                                >
                                                                    Haval
                                                                </Link>
                                                                <Link
                                                                    to={'#'}
                                                                    className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                                                >
                                                                    Hyundai
                                                                </Link>
                                                                <Link
                                                                    to={'#'}
                                                                    className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                                                >
                                                                    Jetour
                                                                </Link>
                                                                <Link
                                                                    to={'#'}
                                                                    className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                                                >
                                                                    Kaiyi
                                                                </Link>
                                                                <Link
                                                                    to={'#'}
                                                                    className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                                                >
                                                                    Kia
                                                                </Link>
                                                                <Link
                                                                    to={'#'}
                                                                    className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                                                >
                                                                    Mazda
                                                                </Link>
                                                                <Link
                                                                    to={'#'}
                                                                    className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                                                >
                                                                    Mercedes-Benz
                                                                </Link>
                                                                <Link
                                                                    to={'#'}
                                                                    className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                                                >
                                                                    Mitsubishi
                                                                </Link>
                                                            </div>
                                                        )} */}
                            <button
                              onClick={() =>
                                setActive2(active2 === 2 ? null : 2)
                              }
                              className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                                active2 === 2 && 'bg-gray'
                              }`}
                            >
                              Pièces auto d&apos;occasion
                              {active2 === 2 ? (
                                <FaAngleUp className="opacity-50 group-hover:opacity-[1]" />
                              ) : (
                                <FaAngleDown className="opacity-50 group-hover:opacity-[1]" />
                              )}
                            </button>
                            {active2 === 2 && (
                              <div className="flex flex-col gap-[8px] w-full max-w-[200px] px-[20px] py-[10px]">
                                <Link
                                  to={'/pieces-d-occasion'}
                                  onClick={() =>
                                    window.location.replace(
                                      '/pieces-d-occasion',
                                    )
                                  }
                                  className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] font-bold"
                                >
                                  Toutes les marques
                                </Link>
                                {partBrands === null && <LittleLoader />}
                                {partBrands &&
                                  partBrands.map((brand) => (
                                    <Link
                                      to={encodeURI(
                                        '/pieces-d-occasion?refinementList[brand][0]=' +
                                          brand,
                                      )}
                                      onClick={() =>
                                        window.location.replace(
                                          encodeURI(
                                            '/pieces-d-occasion?refinementList[brand][0]=' +
                                              brand,
                                          ),
                                        )
                                      }
                                      className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                      key={brand}
                                    >
                                      {capitalizeFirstLetter(brand)}
                                    </Link>
                                  ))}
                              </div>
                            )}
                            <button
                              onClick={() =>
                                setActive2(active2 === 3 ? null : 3)
                              }
                              className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                                active2 === 3 && 'bg-gray'
                              }`}
                            >
                              Véhicules accidentés
                              {active2 === 3 ? (
                                <FaAngleUp className="opacity-50 group-hover:opacity-[1]" />
                              ) : (
                                <FaAngleDown className="opacity-50 group-hover:opacity-[1]" />
                              )}
                            </button>
                            {active2 === 3 && (
                              <div className="flex flex-col gap-[8px] w-full max-w-[200px] px-[20px] py-[10px]">
                                <Link
                                  to={'/vehicules-accidentes'}
                                  onClick={() =>
                                    window.location.replace(
                                      '/vehicules-accidentes',
                                    )
                                  }
                                  className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] font-bold"
                                >
                                  Toutes les marques
                                </Link>
                                {vehicleAccidentedBrands === null && (
                                  <LittleLoader />
                                )}
                                {vehicleAccidentedBrands &&
                                  vehicleAccidentedBrands.map((brand) => (
                                    <Link
                                      to={encodeURI(
                                        '/vehicules-accidentes?refinementList[brand][0]=' +
                                          brand,
                                      )}
                                      onClick={() =>
                                        window.location.replace(
                                          encodeURI(
                                            '/vehicules-accidentes?refinementList[brand][0]=' +
                                              brand,
                                          ),
                                        )
                                      }
                                      className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                      key={brand}
                                    >
                                      {capitalizeFirstLetter(brand)}
                                    </Link>
                                  ))}
                              </div>
                            )}
                            <button
                              onClick={() =>
                                setActive2(active2 === 4 ? null : 4)
                              }
                              className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                                active2 === 4 && 'bg-gray'
                              }`}
                            >
                              Pneus d&apos;occasion
                              {active2 === 4 ? (
                                <FaAngleUp className="opacity-50 group-hover:opacity-[1]" />
                              ) : (
                                <FaAngleDown className="opacity-50 group-hover:opacity-[1]" />
                              )}
                            </button>
                            {active2 === 4 && (
                              <div className="flex flex-col gap-[8px] w-full max-w-[200px] px-[20px] py-[10px]">
                                <Link
                                  to={'/pneus-d-occasion'}
                                  onClick={() =>
                                    window.location.replace('/pneus-d-occasion')
                                  }
                                  className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] font-bold"
                                >
                                  Tous les fabricants
                                </Link>
                                {tyreBrands === null && <LittleLoader />}
                                {tyreBrands &&
                                  tyreBrands.map((brand) => (
                                    <Link
                                      to={encodeURI(
                                        '/pneus-d-occasion?refinementList[brand][0]=' +
                                          brand,
                                      )}
                                      onClick={() =>
                                        window.location.replace(
                                          encodeURI(
                                            '/pneus-d-occasion?refinementList[brand][0]=' +
                                              brand,
                                          ),
                                        )
                                      }
                                      className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                      key={brand}
                                    >
                                      {capitalizeFirstLetter(brand)}
                                    </Link>
                                  ))}
                              </div>
                            )}
                            <button
                              onClick={() =>
                                setActive2(active2 === 5 ? null : 5)
                              }
                              className={`h-[40px] flex items-center justify-between w-full group px-[8px] text-[#343434] font-[600] hover:bg-gray transitions rounded-[8px] ${
                                active2 === 5 && 'bg-gray'
                              }`}
                            >
                              Jantes d&apos;occasion
                              {active2 === 5 ? (
                                <FaAngleUp className="opacity-50 group-hover:opacity-[1]" />
                              ) : (
                                <FaAngleDown className="opacity-50 group-hover:opacity-[1]" />
                              )}
                            </button>
                            {active2 === 5 && (
                              <div className="flex flex-col gap-[8px] w-full max-w-[200px] px-[20px] py-[10px]">
                                <Link
                                  to={'/jantes-d-occasion'}
                                  onClick={() =>
                                    window.location.replace(
                                      '/jantes-d-occasion',
                                    )
                                  }
                                  className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1] font-bold"
                                >
                                  Toutes les marques
                                </Link>
                                {rimBrands === null && <LittleLoader />}
                                {rimBrands &&
                                  rimBrands.map((brand) => (
                                    <Link
                                      to={encodeURI(
                                        '/jantes-d-occasion?refinementList[brand][0]=' +
                                          brand,
                                      )}
                                      onClick={() =>
                                        window.location.replace(
                                          encodeURI(
                                            '/jantes-d-occasion?refinementList[brand][0]=' +
                                              brand,
                                          ),
                                        )
                                      }
                                      className="text-[16px] leading-[22px] text-dark opacity-80 hover:opacity-[1]"
                                      key={brand}
                                    >
                                      {capitalizeFirstLetter(brand)}
                                    </Link>
                                  ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

const Panel = ({ children, header, footer }) => {
  return (
    <div className="ais-Panel">
      {header && <div className="ais-Panel-header">{header}</div>}
      <div className="ais-Panel-body">{children}</div>
      {footer && <div className="ais-Panel-footer">{footer}</div>}
    </div>
  );
};
