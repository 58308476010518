import { FaArrowRightLong } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { useState } from 'react';

const RestexInformes = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [email, setEmail] = useState('');

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  };

  const handleSubmit = () => {
    if (!validateEmail(email)) {
      toast.dismiss();
      setTimeout(() => {
        toast.error('Email invalide', {
          toastId: 'email-error',
        });
      }, 300);
      return;
    }
    setIsDisabled(true);
    toast.success('Votre email est enregistré !', {
      toastId: 'email-success',
    });
  };

  return (
    <div className="wrapper px-[12px] py-[20px] sm:py-[20px] ">
      <div className="wrapper bg-orange rounded-[12px] max-w-[1920px] center relative min-h-[400px]">
        <img
          src="/pictos/share.png"
          alt=""
          className="max-w-[272px] absolute right-[28%] top-[45px] opacity-[.5] z-[0]"
        />
        <div className="w-full px-[12px] flex flex-col lg:px-[30px] 1500:px-[72px] gap-[24px] 900:gap-[48px] py-[12px] z-[2] lg:py-[50px] 1500:py-[80px]">
          <div className="flex flex-col gap-[24px]">
            <h1 className="font-clash text-[38px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-[white]">
              <span className="hidden sm:flex">
                Ne manquez rien : promos, nouveautés et astuces auto{' '}
              </span>
              <span className="sm:hidden">Contactez-nous</span>
            </h1>
            <p className="text-[20px] leading-[28px] text-[white]">
              Recevez nos meilleures offres, promotions exclusives et actualités
              directement dans votre boîte mail. Inscrivez-vous dès maintenant
              et ne manquez plus aucune opportunité pour votre véhicule !
            </p>
          </div>
          <div className="flex flex-col 900:flex-row gap-[16px] 900:pb-[58px]">
            <div className="flex-1 h-[56px] rounded-[8px] px-[12px] 900:px-[24px] py-[15px] flex justify-between w-full bg-white">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !isDisabled) {
                    handleSubmit();
                  }
                }}
                placeholder="Votre email ici"
                className="flex-1 border-none outline-none text-dark"
              />
            </div>
            <button
              className={`px-[24px] py-[16px] bg-dark center gap-[16px] text-[white] leading-[24px] rounded-[8px] ${
                isDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isDisabled}
              onClick={handleSubmit}
            >
              Abonnez-vous <FaArrowRightLong />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestexInformes;
