const NosEngagements = () => {
    return (
        <>
            <div className="content flex flex-col gap-[40px] lg:gap-[80px] 1400:px-0 px-[16px] lg:py-[22px]">
                <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
                    Nos engagements
                </h1>
                <p className="text-[18px] md:text-[25px]">
                    Stopièces Auto est fière de détenir les certifications les
                    plus reconnues de l'industrie automobile. Ces certifications
                    témoignent de notre engagement envers la qualité, la
                    sécurité et la conformité aux normes réglementaires. Nous
                    sommes constamment soumis à des contrôles rigoureux pour
                    assurer que nos pratiques et nos produits respectent les
                    normes les plus strictes. Notre centre VHU est agréé et nous
                    détenons également la certification SGS qui valide que nos
                    produits et services sont conformes à un référentiel bien
                    précis.
                </p>
                <div className="flex flex-wrap items-center justify-center gap-[12px] sm:gap-[20px] lg:gap-[35px]  xl:gap-[48px]">
                    <div className="w-full 1300:max-w-[603px] px-[10px] gap-[5px] py-[12px] sm:py-[24px] flex-col sm:flex-row flex h-[193px items-center border-[1px] border-[#8080804f] rounded-[12px]">
                        {/* left  */}
                        <div>
                            <img
                                src="/eng1.png"
                                alt=""
                                className="sm:min-w-[105px] max-w-[80px] sm:max-w-[109px]"
                            />
                        </div>
                        {/* Right */}
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex flex-col gap-[4px]">
                                <h3 className="font-clash  font-[600] text-center sm:text-start text-[25px] sm:text-[36px] leading-[32px] text-dark">
                                    Centre VHU agréé
                                </h3>
                                <p className="font-clash font-[300] text-[17px] text-center sm:text-start sm:text-[20px] leading-[18px] text-dark">
                                    N° d’agrément : PR6900054D
                                </p>
                            </div>
                            <p className="text-[14px] font-[700] text-dark text-center sm:text-start leading-[19.6px] opacity-[.8]">
                                Les centres VHU agréés ont {"l'obligation"} de
                                réaliser la dépollution complète du véhicule
                                avant de transmettre les carcasses de véhicule à
                                des broyeurs agréés, qui assurent leurs
                                broyages, puis séparent les matières restantes
                                pour les recycler.
                            </p>
                        </div>
                    </div>
                    <div className="w-full 1300:max-w-[603px] px-[10px] sm:px-[30px] gap-[5px] sm:gap-[30px] py-[12px] sm:py-[28px] flex-col sm:flex-row sm:h-[193px] flex items-center border-[1px] border-[#8080804f] rounded-[12px]">
                        {/* left  */}
                        <div>
                            <img
                                src="/eng2.png"
                                alt=""
                                className="sm:min-w-[80px] max-w-[60px] sm:max-w-[90px]"
                            />
                        </div>
                        {/* Right */}
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex flex-col gap-[4px]">
                                <h3 className="font-clash  font-[600] text-center sm:text-start text-[25px] sm:text-[36px] leading-[32px] text-dark">
                                    Qualicert SGS
                                </h3>
                            </div>
                            <p className="text-[14px] font-[700] text-dark text-center sm:text-start leading-[19.6px] opacity-[.8]">
                                La certification Qualicert permet d’attester que
                                les engagements de services rendus aux clients
                                sont conformes à un référentiel, validé en
                                concertation avec les professionnels, les
                                utilisateurs et les pouvoirs publics.
                            </p>
                        </div>
                    </div>
                    <div className="w-full 1300:max-w-[603px] px-[10px] sm:px-[20px] gap-[5px] sm:gap-[30px] py-[12px] sm:py-[28px] flex-col sm:flex-row sm:h-[183px] flex items-center border-[1px] border-[#8080804f] rounded-[12px]">
                        {/* left  */}
                        <div>
                            <img
                                src="/eng3.png"
                                alt=""
                                className="min-w-[80px] max-w-[80px]"
                            />
                        </div>
                        {/* Right */}
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex flex-col gap-[4px]">
                                <h3 className="font-clash  font-[600] text-center sm:text-start text-[25px] sm:text-[36px] leading-[32px] text-dark">
                                    Made in France
                                </h3>
                            </div>
                            <p className="text-[14px] font-[700] text-dark text-center sm:text-start leading-[19.6px] opacity-[.8]">
                                100% des pièces automobiles provenant de chez
                                stop pièces auto sont produites en France.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NosEngagements;
