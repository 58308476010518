import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { clarity } from 'react-microsoft-clarity';
import { useSelector } from 'react-redux';
export default function MicrosoftClarity() {
  const { pathname } = useLocation();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      clarity.init(process.env.VITE_MICROSOFT_CLARITY_ID);
      clarity.consent();
    }
  }, []);

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      clarity.hasStarted &&
      user?.id
    ) {
      clarity.identify('USER_ID', user.id);
    }
  }, [user]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      clarity.setTag('page', pathname);
    }
  }, [pathname]);

  return null;
}
