import { ErrorMessage, Field } from 'formik';

const FormTextArea = ({ placeholder, name, height = '3.5rem' }) => {
  return (
    <div className="w-full ">
      <Field
        className={`border border-[#CBCDCD] rounded-lg w-full outline-none pl-4 pt-4 h-[${height}] min-h-[${height}]`}
        placeholder={placeholder}
        name={name}
        component="textarea"
      />
      <ErrorMessage name={name}>
        {(error) => {
          return <small className="text-red-400">{error}</small>;
        }}
      </ErrorMessage>
    </div>
  );
};

export default FormTextArea;
