export default function SwitchSelector({
  choice1,
  choice2,
  choice1ClassName = '',
  choice2ClassName = '',
  choice1Value = 1,
  choice2Value = 2,
  choice = 1,
  onChange = (value) => {},
  className = '',
  padding = 'px-6 py-3',
}) {
  return (
    <div
      className={`flex justify-center my-5 bg-slate-200 mx-auto w-fit rounded-full relative ${className}`}
    >
      {typeof choice1 === 'string' ? (
        <div
          onClick={() => onChange(choice1Value)}
          className={`${padding} rounded-full z-10 cursor-pointer ${
            choice === choice1Value ? 'text-white bg-orange' : 'text-black'
          } transition ${choice1ClassName}`}
        >
          {choice1}
        </div>
      ) : (
        choice1
      )}
      {typeof choice2 === 'string' ? (
        <div
          onClick={() => onChange(choice2Value)}
          className={`${padding} rounded-full z-10 cursor-pointer ${
            choice === choice2Value ? 'text-white bg-orange' : 'text-black'
          } transition ${choice2ClassName}`}
        >
          {choice2}
        </div>
      ) : (
        choice2
      )}
    </div>
  );
}
