import { useState } from 'react';
import { toast } from 'react-toastify';
import { postFormData } from '../Api/Api';
import LittleWhiteLoader from '../Loader/LittleWhiteLoader';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: 'qsdq',
    lastName: 'dcxqcqs',
    email: 'dqsdqs@dqs.ds',
    subject: 'cdsvf',
    message: 'vsdfss',
    file: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 10 * 1024 * 1024) {
      toast.error('Le fichier doit faire moins de 10MB');
      e.target.value = '';
      return;
    }
    setFormData({ ...formData, file: file });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    var data = new FormData();
    if (formData.file) {
      data.append('file', formData.file);
    }
    data.append('firstName', formData.firstName);
    data.append('lastName', formData.lastName);
    data.append('email', formData.email);
    data.append('subject', formData.subject);
    data.append('message', formData.message);

    postFormData('contact', data)
      .then((res) => {
        toast.success('Message envoyé');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          subject: '',
          message: '',
          file: null,
        });
      })
      .catch((err) => {
        toast.error("Erreur lors de l'envoi du message");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <h2 className="font-clash py-8 md:py-0 text-[25px] sm:text-[30px] leading-[1] whitespace-nowrap 900:text-[60px] 900:leading-[64px] font-[600] text-dark">
        Formulaire de contact
      </h2>
      <div className="flex justify-center mt-2 ">
        <form
          className=" bg-[#EEF3F9] w-[1020px] px-4 md:px-8 py-4 rounded-lg font-clash"
          onSubmit={handleSubmit}
        >
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <div>
              <label className="block mb-2 text-lg font-medium ">Prénom</label>
              <input
                type="text"
                id="first_name"
                className="bg-gray-50 border-2 border-gray  text-sm rounded-lg  f block w-full p-3 "
                placeholder="Jean-Marc"
                required
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
              />
            </div>
            <div>
              <label className="block mb-2 text-lg font-medium ">Nom</label>
              <input
                type="text"
                id="last_name"
                className="bg-gray-50 border-2 border-gray  text-sm rounded-lg  f block w-full p-3 "
                placeholder="Briner"
                required
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
              />
            </div>
          </div>
          <div className="grid gap-6 mb-6 md:grid-cols-1">
            <div>
              <label className="block mb-2 text-lg font-medium ">E-mail</label>
              <input
                type="email"
                id="email"
                className="bg-gray-50 border-2 border-gray  text-sm rounded-lg  f block w-full p-3 "
                placeholder="Jeanmarc@gmail.com"
                required
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
              />
            </div>
          </div>
          <div className="grid gap-6 mb-6 md:grid-cols-1">
            <div>
              <label className="block mb-2 text-lg font-medium ">Objet</label>
              <input
                type="text"
                id="subject"
                className="bg-gray-50 border-2 border-gray  text-sm rounded-lg  f block w-full p-3 "
                placeholder="Je n’arrive pas à passer commande..."
                required
                value={formData.subject}
                onChange={(e) =>
                  setFormData({ ...formData, subject: e.target.value })
                }
              />
            </div>
          </div>

          <div className="grid gap-6 mb-6 md:grid-cols-1">
            <div>
              <label className="block mb-2 text-lg font-medium ">Message</label>
              <textarea
                id="message"
                className="bg-gray-50 border-2 border-gray text-sm rounded-lg block w-full p-3"
                placeholder="Message"
                required
                rows={12}
                value={formData.message}
                onChange={(e) =>
                  setFormData({ ...formData, message: e.target.value })
                }
              ></textarea>
            </div>
          </div>
          <div className="grid gap-6 mb-6 md:grid-cols-1">
            <div className="relative">
              <label className="block mb-2 text-lg font-medium">
                Pièce jointe (max 10MB)
                <input
                  type="file"
                  id="file-upload"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
              <label htmlFor="file-upload" className="cursor-pointer">
                <div className="relative">
                  <button className="px-6 py-2 bg-[#C9C9C9] text-white font-medium text-lg rounded-lg">
                    {formData.file
                      ? 'Fichier sélectionné'
                      : 'Choisir un fichier'}
                  </button>
                  <input
                    id="file-upload"
                    type="file"
                    className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                    accept=".pdf,.doc,.docx,.jpg,.jpeg,.png,.gif,.bmp,.tiff,.webp"
                    onChange={handleFileChange}
                  />
                </div>
              </label>
              {formData.file && (
                <div className="mt-2 text-sm text-gray-600">
                  {formData.file.name}
                </div>
              )}
            </div>
          </div>

          <div className="grid gap-6 mb-6 md:grid-cols-1">
            <div className=" ">
              <button
                className="px-6 py-3 bg-orange text-white font-bold text-xl rounded-lg disabled:opacity-50 disabled:cursor-progress flex items-center gap-3"
                type="submit"
                disabled={isLoading}
              >
                Envoyer
                {isLoading && <LittleWhiteLoader className="w-5 h-5" />}
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Contact;
