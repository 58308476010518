import { FaChevronDown } from 'react-icons/fa6';
import Information from './Information';
import Address from './Address';
import Methods from './Methods';
import PaymentMethods from './PaymentMethods';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCart } from '../../contexts/CartContext';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { authStatus } from '../../utils/auth';
import Amount from '../../components/commons/Amount/Amount';
import { toast } from 'react-toastify';
import Immat from './Immat';

const Checkout = () => {
  const [showDetails, setShowDetails] = useState(true);
  const [promoCode, setPromoCode] = useState('');
  const {
    cartItems,
    cartAddresses,
    totalProductCost,
    cartLoading,
    cartShippingOption,
    totalCartCost,
    getItemFailover,
  } = useCart();

  const { status: authenticationStatus } = useSelector((state) => {
    return state.auth;
  });

  useEffect(() => {
    if (location.search.includes('error')) {
      switch (location.search.split('=')[1]) {
        case 'order_not_found':
          toast.error('Commande introuvable');
          break;
        case 'payment_failed':
          toast.error('Le paiement a échoué');
          break;
        case 'payment_canceled':
          toast.error('Le paiement a été annulé');
          break;
        default:
          toast.error('Une erreur est survenue');
          break;
      }
    }
  }, []);

  const handleInputChange = (event) => {
    setPromoCode(event.target.value);
  };

  return (
    <div>
      <div className="w-full shadow-[0px_0px_2px_2px#0D1F220A] h-20 relative">
        {(authenticationStatus === authStatus.loading || cartLoading) && (
          <Loader />
        )}
        <div className=" flex justify-start w-11/12 mx-auto p-3 max-w-[1400px]  ">
          <Link to={'/'}>
            <img src="/logo-blanc.png" className="w-24 object-contain" alt="" />
          </Link>
        </div>
      </div>
      <div className="max-w-[1400px] w-11/12  mx-auto pb-20">
        <h2 className="text-4xl smm:text-[32px] font-semibold font-clash mt-10 mb-10">
          Votre commande
        </h2>
        <div className="flex gap-14 slg:flex-col-reverse">
          <div className=" w-full space-y-6 ">
            {!cartLoading && !cartItems.length ? (
              <div className="bg-gray p-5 w-full rounded-xl text-center">
                <p className="text-lg font-medium text-gray-500">
                  Votre panier est vide
                </p>
                <Link
                  to="/catalogue"
                  className="bg-orange text-white px-5 py-4 mt-5 inline-block rounded-lg mx-auto"
                >
                  Retourner au catalogue
                </Link>
              </div>
            ) : (
              <>
                <Information />
                <Immat />
                <Address />
                <Methods />
                <PaymentMethods />
              </>
            )}
          </div>
          <div className="space-y-4 ">
            <div className="bg-gray p-5 w-[340px] slg:w-full rounded-xl">
              <h4 className="font-semibold text-xl font-jost">
                Votre commande
              </h4>
              <div
                className={`${
                  showDetails ? 'h-auto' : 'h-10'
                } duration-200 overflow-hidden`}
              >
                {/* Checkout product details */}
                <div
                  className="flex mt-2 mb-3 justify-between items-center"
                  role="button"
                  onClick={() => setShowDetails(!showDetails)}
                >
                  <span>
                    {cartItems.length} article
                    {cartItems.length > 1 ? 's' : ''}
                  </span>
                  <button
                    className={`text-[#abb3b9] ${
                      showDetails ? '-rotate-180' : 'rotate-0'
                    } duration-300`}
                  >
                    <FaChevronDown />
                  </button>
                </div>

                <div className="mt-4">
                  {cartItems.map((item, index) => (
                    <div key={index} className="flex items-center gap-2 mb-4">
                      <img
                        src={
                          item.purchasable.mainImage ||
                          getItemFailover(item) ||
                          '/failover.png'
                        }
                        className="object-cover w-20 slg:object-cover h-14 rounded-xl"
                        alt={item.purchasable.title}
                      />
                      <div>
                        <h6 className="text-sm">{item.purchasable.title}</h6>
                        <h6 className="text-sm font-semibold text-blue">
                          {item.purchasable.price} €
                        </h6>
                      </div>
                    </div>
                  ))}
                  {cartItems.length === 0 && (
                    <p className="text-lg font-medium text-gray-500 text-center">
                      Votre panier est vide
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-4 pt-4 border-t border-t-[#CBCDCD]">
                <div className="flex justify-between items-center">
                  <h5 className="text-dark">Sous-total</h5>
                  <h5 className="font-semibold">
                    <Amount value={totalProductCost} /> TTC
                  </h5>
                </div>
                <div className="flex justify-between items-center my-2">
                  <h5 className="text-dark">Livraison</h5>
                  {cartShippingOption ? (
                    <h5 className="font-semibold">
                      {(cartItems.length > 1 ||
                        cartAddresses?.shipping_address?.country.iso2 !==
                          'FR') &&
                      !cartShippingOption.collect ? (
                        'Sur devis'
                      ) : cartShippingOption.price.value === 0 ? (
                        'Gratuit'
                      ) : (
                        <>
                          <Amount
                            value={cartShippingOption.price.value / 100}
                          />{' '}
                          TTC
                        </>
                      )}
                    </h5>
                  ) : (
                    <h5 className="font-semibold">
                      Choisissez votre livraison
                    </h5>
                  )}
                </div>
                {/* <div className="flex justify-between items-center">
                                    <h5 className="text-dark">Total HT</h5>
                                    <h5 className="font-semibold">
                                        {totalProductCost} €
                                    </h5>
                                </div> */}
                <div className="flex justify-between items-center mt-4">
                  <h5 className="text-dark text-xl">Total TTC</h5>
                  <h5 className="font-semibold text-xl">
                    <Amount value={totalCartCost} />
                  </h5>
                </div>
              </div>
            </div>

            {/* <div className="bg-gray p-5 w-[340px] slg:w-full rounded-xl flex flex-col gap-y-4">
              <label
                htmlFor="promoCode"
                className="font-semibold text-xl font-jost"
              >
                Code promo
              </label>
              <input
                type="text"
                id="promoCode"
                value={promoCode}
                onChange={handleInputChange}
                className="border border-[#CBCDCD] rounded-lg h-14 outline-none pl-4"
              />
              <button
                className={`h-14 rounded-lg font-semibold ${
                  promoCode ? 'bg-orange text-white' : 'bg-[#E3EAF1] opacity-50'
                }`}
                disabled={!promoCode}
              >
                Appliquer
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
