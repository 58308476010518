import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  CubeIcon,
  InfoIcon,
  LocationIcon,
  ProfileDetails,
  ProfileIcon,
} from '../../Utils/Icons/Icons';
import { TbLogout } from 'react-icons/tb';
import BreadBrumbs from '../../components/Breadcrumbs/BreadBrumbs';
import { useSelector } from 'react-redux';
import { authStatus, logout } from '../../utils/auth';
import Loader from '../../components/Loader/Loader';
import { logout as reduxLogout } from '../../store/Reducers/auth';
import { useDispatch } from 'react-redux';

const ProfileLayout = (props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    user,
    isAuthenticated,
    status: userStatus,
  } = useSelector((state) => state.auth);

  const logoutFromRedux = () => {
    dispatch(reduxLogout());
  };

  const handleLogout = () => {
    logout();
    logoutFromRedux();
    navigate('/');
  };

  return (
    <div className="">
      <div className="max-w-[1400px] mx-auto w-11/12">
        {pathname === '/profile/profile-details' ? (
          <BreadBrumbs link1="Espace personnel" />
        ) : pathname === '/profile/profile-info' ? (
          <BreadBrumbs
            link1="Espace personnel"
            link1path="/profile/profile-details"
            link2="Informations personnelles"
          />
        ) : pathname === '/profile/addresses' ? (
          <BreadBrumbs
            link1="Espace personnel"
            link1path="/profile/profile-details"
            link2="Adresses"
          />
        ) : pathname === '/profile/orders' ? (
          <BreadBrumbs
            link1="Espace personnel"
            link1path="/profile/profile-details"
            link2="Mes commandes"
          />
        ) : (
          ''
        )}

        <h2 className="text-4xl slg:hidden smm:text-[32px] font-semibold font-clash mt-10 mb-10">
          {pathname === '/profile/profile-details'
            ? 'Mon profil'
            : pathname === '/profile/profile-info'
              ? 'Informations personnelles'
              : pathname === '/profile/addresses'
                ? 'Adresses'
                : pathname === '/profile/orders'
                  ? 'Mes commandes'
                  : 'Esapce personnel'}
        </h2>

        {userStatus === authStatus.loading || !isAuthenticated ? (
          <Loader />
        ) : (
          <div className="flex gap-14 pb-20 slg:flex-col">
            <div className="bg-gray p-5 w-[340px] slg:w-full h-max rounded-xl slg:hidden">
              <div className="flex gap-4 items-center">
                <ProfileIcon />
                <div className="">
                  <h4 className="font-jost ">
                    {user.last_name} {user.first_name}
                  </h4>
                  <h4 className="font-jost text-lightGrey">{user.email}</h4>
                </div>
              </div>

              <ul className="mt-4">
                <li>
                  <NavLink
                    to="/profile/profile-details"
                    className={({ isActive }) =>
                      `h-12 w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-lg ${
                        isActive ? 'bg-[#E3EAF1] text-orange' : ''
                      }`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <ProfileDetails
                          fill={isActive ? 'stroke-orange' : 'stroke-dark'}
                        />
                        Mon profil
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/profile/profile-info"
                    className={({ isActive }) =>
                      `h-12 w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-lg ${
                        isActive ? 'bg-[#E3EAF1] text-orange' : ''
                      }`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <InfoIcon
                          fill={isActive ? 'stroke-orange' : 'stroke-dark'}
                        />
                        Informations personnelles
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/profile/addresses"
                    className={({ isActive }) =>
                      `h-12 w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-lg ${
                        isActive ? 'bg-[#E3EAF1] text-orange' : ''
                      }`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <LocationIcon
                          fill={isActive ? 'stroke-orange' : 'stroke-dark'}
                        />
                        Adresses
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/profile/orders"
                    className={({ isActive }) =>
                      `h-12 w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-lg ${
                        isActive ? 'bg-[#E3EAF1] text-orange' : ''
                      }`
                    }
                  >
                    {({ isActive }) => (
                      <>
                        <CubeIcon
                          fill={isActive ? 'stroke-orange' : 'stroke-dark'}
                        />
                        Mes commandes
                      </>
                    )}
                  </NavLink>
                </li>
                <li>
                  <div
                    className="h-12 border-t border-t-lightGrey text-lightGrey w-full flex items-center gap-2 hover:bg-[#E3EAF1] duration-200 pl-3 rounded-y-lg cursor-pointer"
                    onClick={handleLogout}
                  >
                    <TbLogout />
                    Se déconnecter
                  </div>
                </li>
              </ul>
            </div>
            {props.children}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileLayout;
