import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from '@headlessui/react';
import { useEffect, useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AddressForm, {
  disabledCountry,
  getAdditionalFieldsSchema,
  getInitialAddressData,
} from '../components/CheckoutForm/AddressForm';
import LittleWhiteLoader from '../components/Loader/LittleWhiteLoader';

export default function AddressModal({
  showModal,
  setShowModal,
  addressData = null,
  onSubmit,
  modalTitle = "Informations de l'adresse",
  countries = [disabledCountry],
  submitLoading = false,
  errorMessage = null,
}) {
  const [forPro, setForPro] = useState(false);
  const [initialForPro, setInitialForPro] = useState(false);
  const [validationSchema, setValidationSchema] = useState(
    Yup.object().shape(
      getAdditionalFieldsSchema(
        countries.filter((country) => country.value),
        true,
        false,
      ),
    ),
  );

  useEffect(() => {
    const initialForProValue =
      !!(
        addressData?.company &&
        addressData?.taxNumber &&
        addressData?.siret
      ) ||
      !!(
        addressData?.ship_company &&
        addressData?.ship_taxNumber &&
        addressData?.ship_siret
      ) ||
      !!(
        addressData?.bill_company &&
        addressData?.bill_taxNumber &&
        addressData?.bill_siret
      );
    setForPro(initialForProValue);
    setInitialForPro(initialForProValue);
  }, [addressData]);

  useEffect(() => {
    setValidationSchema(
      Yup.object().shape(
        getAdditionalFieldsSchema(
          countries.filter((country) => country.value),
          true,
          forPro,
        ),
      ),
    );
  }, [forPro]);

  return (
    <Dialog
      open={showModal}
      as="div"
      className="relative z-30 focus:outline-none"
      onClose={() => setShowModal(false)}
    >
      <Transition
        appear
        show={showModal}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 z-10 w-screen h-full overflow-y-auto bg-[#0D1F2280]/50 smm:bg-white">
          <div className="flex min-h-full items-center justify-center p-4">
            <button
              className="text-xl hidden text-black z-40 absolute top-4 smm:rounded-xl smm:bg-gray smm:w-12 smm:flex justify-center items-center smm:h-12 right-4"
              onClick={() => setShowModal(false)}
            >
              <VscChromeClose />
            </button>
            <DialogPanel className="w-full max-w-[980px] rounded-xl bg-white text-black p-6 backdrop-blur-2xl">
              <button
                className="text-xl  text-black smm:hidden z-40 absolute top-4 smm:rounded-xl smm:bg-gray flex justify-center items-center smm:h-12 right-4"
                onClick={() => setShowModal(false)}
              >
                <VscChromeClose />
              </button>
              <DialogTitle as="h3" className="text-[22px] font-semibold">
                {modalTitle}
              </DialogTitle>
              <Formik
                initialValues={addressData ?? getInitialAddressData('', true)}
                validationSchema={validationSchema}
                onSubmit={(values) => onSubmit({ ...values, forPro: forPro })}
              >
                {({ dirty, isValid }) => (
                  <Form className="space-y-4 mt-5">
                    <AddressForm
                      countries={countries}
                      forShipping
                      showForPro
                      forProState={[forPro, setForPro]}
                    ></AddressForm>
                    {errorMessage && (
                      <p className="text-red-500">{errorMessage}</p>
                    )}
                    <div className="mt-8 flex smm:w-full smm:flex-col w-[450px] gap-4">
                      <Button
                        type="submit"
                        className={`inline-flex justify-center items-center gap-2 rounded-lg h-12 text-sm font-semibold shadow-inner w-full ${
                          isValid &&
                          (dirty || (!forPro && initialForPro !== forPro))
                            ? 'bg-orange text-white cursor-pointer'
                            : 'bg-[#f8f9fd] text-[#858f90] cursor-not-allowed'
                        }`}
                        disabled={
                          (!dirty && (forPro || initialForPro === forPro)) ||
                          !isValid ||
                          submitLoading
                        }
                      >
                        {submitLoading ? (
                          <LittleWhiteLoader className="w-5 h-5" />
                        ) : (
                          'Enregistrer'
                        )}
                      </Button>
                      <button
                        type="button"
                        className="bg-gray h-12 w-full rounded-lg"
                        onClick={() => setShowModal(false)}
                      >
                        Annuler
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </DialogPanel>
          </div>
        </div>
      </Transition>
    </Dialog>
  );
}
