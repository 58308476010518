const VotreRecycleur = () => {
  return (
    <div className="content flex flex-col gap-[20px] sm:gap-[40px] py-[10px] sm:py-[50px] 1400:px-0 px-[16px]">
      <h1 className="font-clash sm:flex hidden text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
        Stop Pièces Auto : La référence pour vos pièces d’occasion !
      </h1>
      <h1 className="font-clash  sm:hidden text-[40px] sm:text-[50px] leading-[32px] sm:leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
        Comment nous sommes utiles
      </h1>
      <div className="w-full grid sm:grid-cols-4 gap-[12px] sm:gap-[15px] xl:gap-[24px]">
        <div className="border-[1px] sm:col-span-2 1100:col-span-1 border-[#0d1f2229] flex flex-col gap-[12px] sm:gap-[15px] xl:gap-[24px] rounded-[13px] p-[10px] sm:p-[20px] xl:p-[30px]">
          <div className="flex sm:flex-col items-center sm:items-start gap-[10px] sm:gap-[15px] xl:gap-[24px]">
            <img
              src="/pictos/icon-1.png"
              alt=""
              className="max-w-[46px] sm:max-w-[56px] max-h-[46px] sm:max-h-[56px]"
            />
            <h2 className="font-clash font-[600] text-dark text-[24px] sm:text-[36px] leading-[21px] sm:leading-[32px]">
              Trouvez la pièce parfaite, rapidement !
            </h2>
          </div>
          <p className=" leading-[21px] sm:leading-[22.4px] text-dark opacity-[.8]">
            <span className="sm:flex hidden">
              Avec plus de 200 000 références disponibles, trouvez facilement
              les pièces parfaitement adaptées à votre véhicule.
            </span>
            <span className="sm:hidden">
              Les pièces {"d'occasion"} sont beaucoup moins chères que les
              neuves, nous nous soucions du prix et vous aiderons à choisir la
              meilleure option.
            </span>
          </p>
        </div>
        <div className="border-[1px] sm:col-span-2 1100:col-span-1 border-[#0d1f2229] flex flex-col gap-[12px] sm:gap-[15px] xl:gap-[24px] rounded-[13px]  p-[10px] sm:p-[20px] xl:p-[30px]">
          <div className="flex sm:flex-col items-center sm:items-start gap-[10px] sm:gap-[15px] xl:gap-[24px]">
            <img
              src="/pictos/icon-2.png"
              alt=""
              className="max-w-[46px] sm:max-w-[56px] max-h-[46px] sm:max-h-[56px]"
            />
            <h2 className="font-clash font-[600] text-dark text-[24px] sm:text-[36px] leading-[21px] sm:leading-[32px]">
              Des prix imbattables pour votre budget !
            </h2>
          </div>
          <p className=" leading-[21px] sm:leading-[22.4px] text-dark opacity-[.8]">
            Optez pour nos pièces d’occasion à des tarifs jusqu’à 70% moins
            chers que le neuf. Faites des économies sans compromis sur la
            qualité.
          </p>
        </div>
        <div className="border-[1px] sm:col-span-4 1100:col-span-2 border-[#0d1f2229] bg-gray rounded-[13px]  p-[10px] sm:p-[20px] xl:p-[30px] relative overflow-hidden">
          <div className="flex flex-col gap-[12px] sm:gap-[15px] xl:gap-[24px] 100:max-w-[440px]">
            <div className="flex sm:flex-col items-center sm:items-start gap-[10px] sm:gap-[15px] xl:gap-[24px]">
              <img
                src="/recycle.png"
                alt=""
                className="absolute max-w-[337px] top-0 right-[-40px] sm:right-[14%] max-h-[100%] sm:max-h-[100%] "
              />
              <img
                src="/pictos/Frame 367.png"
                alt=""
                className="max-w-[46px] sm:max-w-[56px] max-h-[46px] sm:max-h-[56px]"
              />
              <h2 className="font-clash font-[600] text-dark text-[24px] sm:text-[36px] leading-[21px] sm:leading-[32px]">
                L’éco-responsabilité au cœur de notre métier !
              </h2>
            </div>
            <p className=" leading-[21px] sm:leading-[22.4px] text-dark opacity-[.8]">
              Chez Stop Pièces Auto, nous donnons une nouvelle vie aux véhicules
              tout en préservant la planète. Grâce à notre engagement
              écologique, 98,18% des matériaux des véhicules hors d'usage sont
              recyclés et valorisés. Moins de déchets, plus d’impact positif
              pour un avenir durable !
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VotreRecycleur;
