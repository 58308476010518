export const SHIPPING_OPTION_BASDEL = 'BASDEL';
export const SHIPPING_OPTION_PICKUP = 'PICKUP';

export const SHIPPING_OPTION_IDENTIFIERS = [
  SHIPPING_OPTION_BASDEL,
  SHIPPING_OPTION_PICKUP,
];

export const ORDER_STATUS_PAYMENT_RECEIVED = 'payment-received';

export const ORDER_STATUS_DISPATCHED = 'dispatched';

export const ORDER_STATUS_CANCELED = 'canceled';

export const ORDER_STATUS_AWAITING_PAYMENT = 'awaiting-payment';

export const ORDER_STATUS_ESTIMATE = 'estimate';

export const ORDER_STATUS_TO_RETURN = [
  ORDER_STATUS_PAYMENT_RECEIVED,
  ORDER_STATUS_DISPATCHED,
  ORDER_STATUS_CANCELED,
  ORDER_STATUS_ESTIMATE,
];

export const SHIPPABLE_COUNTRIES = [
  'FR', // France
  'BE', // Belgique
  'CH', // Suisse
  'DE', // Allemagne
  'AT', // Autriche
  'BG', // Bulgarie
  'CY', // Chypre
  'HR', // Croatie
  'DK', // Danemark
  'ES', // Espagne
  'EE', // Estonie
  'FI', // Finlande
  'GR', // Grèce
  'HU', // Hongrie
  'IE', // Irlande
  'IT', // Italie
  'LV', // Lettonie
  'LT', // Lituanie
  'LU', // Luxembourg
  'MT', // Malte
  'MC', // Monaco
  'NL', // Pays-Bas
  'PL', // Pologne
  'PT', // Portugal
  'CZ', // République tchèque
  'RO', // Roumanie
  'SK', // Slovaquie
  'SI', // Slovénie
  'SE', // Suède
  'AD', // Andorre
  'GP', // Guadeloupe
  'GF', // Guyane française
  'MQ', // Martinique
  'RE', // Réunion
  'YT', // Mayotte
  'PM', // Saint-Pierre-et-Miquelon
  'BL', // Saint-Barthélemy
  'MF', // Saint-Martin
  'WF', // Wallis-et-Futuna
  'PF', // Polynésie française
  'NC', // Nouvelle-Calédonie
];
