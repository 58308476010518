import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from '@headlessui/react';
import { VscChromeClose } from 'react-icons/vsc';

export default function Modal({
  showModal,
  setShowModal,
  modalTitle = '',
  children,
  modalStyle = 'default',
  mobileModalStyle = 'default',
  image = null,
  className = '',
}) {
  return (
    <Dialog
      open={showModal}
      as="div"
      className="relative z-30 focus:outline-none"
      onClose={() => setShowModal(false)}
    >
      <Transition
        appear
        show={showModal}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 z-10 w-screen h-full overflow-y-auto bg-[#0D1F2280]/50 smm:bg-white">
          <div className="flex min-h-full items-center justify-center p-4">
            <button
              className="text-xl hidden text-black z-40 absolute top-4 smm:rounded-xl smm:bg-gray smm:w-12 smm:flex justify-center items-center smm:h-12 right-4"
              onClick={() => setShowModal(false)}
            >
              <VscChromeClose />
            </button>
            <DialogPanel
              className={`w-full max-w-[980px] rounded-xl bg-white text-black backdrop-blur-2xl ${
                modalStyle === 'two-columns' ? 'p-0 overflow-hidden' : 'p-6'
              } ${className}`}
            >
              <button
                className="text-xl text-black smm:hidden z-40 absolute top-4 smm:rounded-xl smm:bg-gray flex justify-center items-center smm:h-12 right-4"
                onClick={() => setShowModal(false)}
              >
                <VscChromeClose />
              </button>
              {modalStyle === 'two-columns' ? (
                <div className="flex flex-col sm:flex-row">
                  <div
                    className={`${
                      mobileModalStyle === 'default' ? 'smm:hidden' : ''
                    } w-full py-[60px] sm:px-[25px] px-0`}
                  >
                    {image && (
                      <img
                        src={image}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    )}
                  </div>
                  {!!modalTitle && modalTitle.length > 0 && (
                    <div className="w-full p-6">
                      <DialogTitle
                        as="h3"
                        className="text-[22px] font-semibold pb-7"
                      >
                        {modalTitle}
                      </DialogTitle>
                      {children}
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {!!modalTitle && modalTitle.length > 0 && (
                    <DialogTitle
                      as="h3"
                      className="text-[22px] font-semibold pb-7"
                    >
                      {modalTitle}
                    </DialogTitle>
                  )}
                  {children}
                </>
              )}
            </DialogPanel>
          </div>
        </div>
      </Transition>
    </Dialog>
  );
}
