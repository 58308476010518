import ProfileLayout from './ProfileLayout';
import { useEffect, useState } from 'react';
import { FiUser, FiMapPin, FiPackage } from 'react-icons/fi';
import { GoPencil } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authStatus } from '../../utils/auth';
import Loader from '../../components/Loader/Loader';
import { get } from '../../components/Api/Api';
import LittleLoader from '../../components/Loader/LittleLoader';
import { longDateToDisplay } from '../../utils/formatting';
import {
  ORDER_STATUS_AWAITING_PAYMENT,
  ORDER_STATUS_PAYMENT_RECEIVED,
  ORDER_STATUS_ESTIMATE,
  ORDER_STATUS_DISPATCHED,
} from '../../helpers/shopHelper';

const Card = ({
  title,
  children,
  icon,
  onClickIcon = () => {},
  editButton = false,
}) => (
  <div className="bg-slate-50 p-6 rounded-xl w-[484px] slg:w-full flex flex-col justify-start">
    <div className="flex justify-between items-center mb-6">
      <div className="flex items-center gap-2">
        {icon}
        <h2 className="font-jost font-semibold text-[22px] ">{title}</h2>
      </div>
      {editButton && (
        <button
          className="text-lightGrey text-2xl hover:text-gray-600"
          onClick={onClickIcon}
        >
          <GoPencil />
        </button>
      )}
    </div>
    {children}
  </div>
);

const ProfileDetails = () => {
  const navigate = useNavigate();
  const {
    user,
    isAuthenticated,
    status: userStatus,
  } = useSelector((state) => state.auth);
  const [addressLoading, setAddressLoading] = useState(true);
  const [address, setAddress] = useState(null);
  const [ordersLoading, setOrdersLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    get('shop/addresses')
      .then((res) => {
        setAddress(
          res.find((add) => add.shipping_default) ||
            res.find((add) => add.billing_default) ||
            res[0] ||
            null,
        );
        setAddressLoading(false);
      })
      .catch((err) => {
        setAddressLoading(false);
        setAddress(null);
      });
  }, []);

  useEffect(() => {
    get('shop/orders?limit=5')
      .then((res) => {
        setOrders(res);
        setOrdersLoading(false);
      })
      .catch((err) => {
        setOrdersLoading(false);
        setOrders([]);
      });
  }, []);

  return (
    <ProfileLayout>
      {userStatus === authStatus.loading || !isAuthenticated ? (
        <Loader />
      ) : (
        <div>
          <div className="hidden slg:flex flex-col mb-2 ">
            <h4 className="text-[32px] font-semibold font-jost">
              Espace personnel
            </h4>
          </div>
          <div className="flex gap-4 flex-wrap w-full">
            {/* Personal Information Card */}
            <Card
              title="Informations personnelles"
              icon={<FiUser className="w-4 h-4 text-gray-500" />}
              onClickIcon={() => navigate('/profile/profile-info')}
              editButton
            >
              <div className="space-y-2">
                <p className="">
                  {user.first_name} {user.last_name}
                </p>
                <p className=" text-gray-600">{user.email}</p>
                {user.company_name && user.meta?.siret && (
                  <p className=" text-gray-600">
                    {user.company_name}, {user.meta?.siret}
                  </p>
                )}
              </div>
            </Card>

            {/* Address Card */}
            <Card
              title="Adresses"
              icon={<FiMapPin className="w-4 h-4 text-gray-500" />}
              onClickIcon={() => navigate('/profile/addresses')}
              editButton
            >
              {addressLoading ? (
                <LittleLoader className="w-12 h-12" />
              ) : address ? (
                <div className="space-y-2">
                  <p>
                    {address.first_name} {address.last_name}
                  </p>
                  <p>
                    {address.state} {address.city} {address.country.name}
                  </p>
                  <p>
                    {address.line_one}
                    {address.line_two ? ' - ' + address.line_two : ''}
                    {address.line_three
                      ? ' - ' + address.line_three
                      : ''} - {address.postcode} {address.city}
                  </p>
                  <p>{address.contact_phone}</p>
                </div>
              ) : (
                <p>Aucune adresse enregistrée</p>
              )}
            </Card>

            {/* Orders Card */}
            <Card
              title="Mes dernières commandes"
              icon={<FiPackage className="w-4 h-4 text-gray-500" />}
              onClickIcon={() => navigate('/profile/orders')}
              editButton
            >
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  {ordersLoading ? (
                    <LittleLoader className="w-12 h-12" />
                  ) : orders.length > 0 ? (
                    <div className="space-y-2">
                      {orders.map((order) => (
                        <div className="flex items-center justify-between">
                          <p>
                            Commande {order.reference},{' '}
                            {longDateToDisplay(order.created_at)}
                          </p>
                          <span className="px-3 py-1 text-xs bg-gray-200 rounded-full text-right">
                            {order.status === ORDER_STATUS_DISPATCHED
                              ? 'Terminée'
                              : order.status === ORDER_STATUS_PAYMENT_RECEIVED
                                ? 'En cours'
                                : order.status === ORDER_STATUS_ESTIMATE
                                  ? 'Estimation de livraison en cours'
                                  : order.status ===
                                      ORDER_STATUS_AWAITING_PAYMENT
                                    ? 'En attente de paiement'
                                    : 'Annulée'}
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p>Aucune commande</p>
                  )}
                </div>
              </div>
            </Card>
          </div>
          {/* <button className="text-red-500 text-sm mt-2">
            X Supprimer le compte
          </button> */}
        </div>
      )}
    </ProfileLayout>
  );
};

export default ProfileDetails;
