export default function TagContainer({ children, ...props }) {
    return (
        <div
            className="flex flex-col gap-[8px] absolute right-[5px] top-[5px] text-right z-10"
            {...props}
        >
            {children}
        </div>
    );
}
