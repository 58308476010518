import { MdArrowForwardIos } from 'react-icons/md';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Lightbox from 'react-spring-lightbox';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LiaAngleRightSolid,
  LiaAngleLeftSolid,
  LiaTimesSolid,
} from 'react-icons/lia';
import { MdOutlineMail, MdOutlinePhone, MdInfo } from 'react-icons/md';
import { GoShareAndroid } from 'react-icons/go';
import { Link, useNavigate } from 'react-router-dom';
import ProductDetailsLastSection from '../../components/product-deatils-last/ProductDetailsLastSection';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import Amount from '../../components/Amount/Amount';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  getFailoverImage,
  getProductLinkFromProduct,
} from '../../utils/productHelper';
import {
  filterMarques,
  filterModeles,
  filterEnergie,
  filterProcedure,
  filterPrix,
  filterAnnee,
  filterDimensions,
  filterTypePiece,
} from '../products-page/Filters';
import { MdOutlineShoppingCart } from 'react-icons/md';

import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  OKShareCount,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
  XIcon,
} from 'react-share';
import { del, fetchWithHeaders, get, post } from '../../components/Api/Api';
import { useCart } from '../../contexts/CartContext';
import { useWishList } from '../../contexts/WishListContext';
import BackButton from '../../components/BackButton';
import Tag from '../../components/Tag';
import LittleLoader from '../../components/Loader/LittleLoader';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';
import { numberToDisplay } from '../../utils/formatting';
import { useSelector } from 'react-redux';
import FormInput from '../../components/CheckoutForm/FormInput';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { translateError } from '../../utils/translatting';
import Modal from '../../Modals/Modal';
import ContactForm from '../../components/Form/ContactForm';
import { Helmet } from 'react-helmet-async';

const ProductDetails = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  const [stockNotificationSchema, setStockNotificationSchema] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      setStockNotificationSchema(
        Yup.object().shape({
          email: Yup.string().email('Email invalide').required('Email requis'),
        }),
      );
    } else {
      setStockNotificationSchema(null);
    }
  }, [isAuthenticated]);

  const { addToCart, openCart, addToCartLoading } = useCart();
  const { wishListItems, addToWishList, removeFromWishList, isItemInWishList } =
    useWishList();
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);
  const params = useParams();
  const idOrSlug = params.idOrSlug;
  const [id, setId] = useState(null);
  const isNumeric = /^\d+$/.test(idOrSlug);
  const [lightBoxOpened, setLightBoxOpened] = useState(false);

  useEffect(() => {
    if (isNumeric) {
      // C'est un ID, charger les détails du produit

      setId(idOrSlug);
      // Charger les détails du produit ici
    } else {
      // C'est un slug, extraire l'ID et rediriger
      const match = idOrSlug.match(/^(\d+)-/);
      if (match) {
        const idproduct = match[1];
        setId(idproduct);
      } else {
        navigate('/404'); // Rediriger vers une page 404
      }
    }
  }, [idOrSlug, navigate]);

  useEffect(() => {
    if (id) {
      fetchWithHeaders(process.env.VITE_API_URL + urlAPI + '/' + id).then(
        (result) => {
          /*if(result.mainPicture.indexOf("?lock") != -1){
            result.mainPicture = result.mainPicture.substring(0, result.mainPicture.indexOf("?lock")) +'?lock=' + result.id;   
            result.title = result.make + ' ' + result.model + ' — ' + result.energy;
        }*/
          setProduct(result);
          setUsed(result.type && result.type == 'used' ? true : false);
          let myImages = result.images;

          if (!myImages || myImages.length == 0) {
            myImages = [getFailoverImage(type, result)];
          }
          setImages(myImages);
          if (result.isExpired) setIsExpired(true);
          setLoading(false);
        },
      );
    }
    // Charger les détails du produit avec cet ID
  }, [id]);
  const [product, setProduct] = useState(null);
  const [isUsed, setUsed] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hovered, setHovered] = useState(false);
  const [stockNotificationError, setStockNotificationError] = useState(null);
  const [showStockNotificationSuccess, setShowStockNotificationSuccess] =
    useState(false);
  const [isStockNotified, setIsStockNotified] = useState(false);
  const [stockNotificationLoading, setStockNotificationLoading] =
    useState(false);
  const [deleteStockNotificationLoading, setDeleteStockNotificationLoading] =
    useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      setIsStockNotified(false);
      setShowStockNotificationSuccess(false);
      setStockNotificationError(null);
      setStockNotificationLoading(false);
    }
    if (isAuthenticated && product && !stockNotificationLoading) {
      setShowStockNotificationSuccess(false);
      setStockNotificationError(null);
      setStockNotificationLoading(true);
      get('/stock_notifications/' + product.id)
        .then((result) => {
          setIsStockNotified(!!result);
        })
        .catch((err) => {
          if (err.code === 404) {
            setIsStockNotified(false);
          }
        })
        .finally(() => {
          setStockNotificationLoading(false);
        });
    }
  }, [product, isAuthenticated]);

  const handleStockNotificationSubmit = (values, { setSubmitting }) => {
    const body = !isAuthenticated
      ? {
          email: values.email,
        }
      : {};
    post('/stock_notifications/' + product.id, body)
      .then((result) => {
        setShowStockNotificationSuccess(true);
        setStockNotificationError(null);
      })
      .catch((error) => {
        setShowStockNotificationSuccess(false);
        setStockNotificationError(
          translateError(
            error,
            "Une erreur est survenue lors de l'envoi de la demande",
          ),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleDeleteStockNotification = () => {
    if (!isAuthenticated) return;
    setDeleteStockNotificationLoading(true);
    del('/stock_notifications/' + product.id)
      .then(() => {
        setIsStockNotified(false);
        setShowStockNotificationSuccess(false);
        setStockNotificationError(null);
      })
      .catch((error) => {
        setShowStockNotificationSuccess(false);
        setStockNotificationError(
          translateError(
            error,
            'Une erreur est survenue lors de la suppression de la notifiation de stock',
          ),
        );
      })
      .finally(() => {
        setDeleteStockNotificationLoading(false);
      });
  };

  const url = window.location.href;
  const parts = url.split('/');
  const type = parts[parts.length - 2];
  const procedureForPartsValue = 'PIECES';

  const config = {
    vehicules: {
      title: 'Nos véhicules',
      url: 'vehicles',
      filters: [
        { name: 'Marque', value: '', comp: filterMarques },
        { name: 'Modèle', value: '', comp: filterModeles },
        { name: 'Énergie', value: '', comp: filterEnergie },
        { name: 'Procédure', value: '', comp: filterProcedure },
        { name: 'Prix', value: '', comp: filterPrix },
        {
          name: 'Année de mise en circulation',
          value: '',
          comp: filterAnnee,
        },
      ],
    },
    pneus: {
      title: "Nos pneus d'occasion",
      url: 'tyres',
      filters: [
        { name: 'Marque', value: '', comp: filterMarques },
        { name: 'Modèle', value: '', comp: filterModeles },
        { name: 'Dimension', value: '', comp: filterDimensions },
        { name: 'Prix', value: '', comp: filterPrix },
      ],
    },
    jantes: {
      title: "Nos jantes d'occasion",
      url: 'rims',
      filters: [
        { name: 'Marque', value: '', comp: filterMarques },
        { name: 'Modèle', value: '', comp: filterModeles },
        { name: 'Dimension', value: '', comp: filterDimensions },
        { name: 'Prix', value: '', comp: filterPrix },
      ],
    },
    pieces: {
      title: "Nos pièces d'occasion",
      url: 'parts',
      filters: [
        { name: 'Marque', value: '', comp: filterMarques },
        { name: 'Modèle', value: '', comp: filterModeles },
        { name: 'Type de pièce', value: '', comp: filterTypePiece },
        { name: 'Prix', value: '', comp: filterPrix },
      ],
    },
  };

  const isVehicle = type === 'vehicules';

  const productData = config[type];
  const urlAPI = productData.url;

  const [randomCars, setRandomCars] = useState([]);

  useEffect(() => {
    if (isVehicle) {
      fetchWithHeaders(process.env.VITE_API_URL + urlAPI + '/selection/4').then(
        (result) => {
          setRandomCars(result);
        },
      );
    }
  }, [isVehicle]);

  const isVenteReservedToProfessionnal = (partCode) => {
    /*
        13888	AIRBAG ASSISE ARD
13889	AIRBAG ASSISE ARG
12590	AIRBAG ASSISE AVD
12589	AIRBAG ASSISE AVG
13489	AIRBAG COLONNE DIRECTION
12289	AIRBAG LATERAL ARD
12290	AIRBAG LATERAL ARG
13933	AIRBAG PORTE ARD
13934	AIRBAG PORTE ARG
13589	AIRBAG PORTE AVD
13609	AIRBAG PORTE AVG
11930	AIRBAG SIEGE AVD
11929	AIRBAG SIEGE AVG
11910	AIRBAG TABLEAU DE BORD
11931	AIRBAG TOIT AVD
11932	AIRBAG TOIT AVG
11909	AIRBAG VOLANT
13905	PRETENSIONNEUR ARD
13906	PRETENSIONNEUR ARG
11934	PRETENSIONNEUR AVD
11935	PRETENSIONNEUR AVG
14051	PRETENSIONNEUR CAPOT D
14052	PRETENSIONNEUR CAPOT G
*/

    return [
      13889, 13888, 12590, 12589, 13489, 12289, 12290, 13933, 13934, 13589,
      13589, 11930, 11929, 11910, 11931, 11932, 11909, 13905, 13906, 11934,
      11935, 14051, 14052,
    ].includes(Number(partCode));
  };

  const shareUrl = 'https://stopiece-auto.com/' + type + '/' + id;
  const title = 'Stop-piece autos';

  useEffect(() => {
    /*if(product.mainPicture){
            if(product.mainPicture.indexOf("?lock") != -1){
                product.mainPicture = product.mainPicture.substring(0, product.mainPicture.indexOf("?lock") != -1 ? product.mainPicture.indexOf("?lock") : product.mainPicture.length)+'?random=' + product.id;    
                setProduct(product);
            }
            
        }*/
    setSlideIndex(0);
  }, [product]);

  const isExpiredClasses =
    'grayscale-[90%] backdrop-blur-md bg-white/30 backdrop-brightness-150 pointer-events-none';

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      const splideInstance = sliderRef.current.splide;

      if (splideInstance) {
        splideInstance.on('moved', (newIndex) => {
          setSlideIndex(newIndex);
        });
      }
    }
  }, [sliderRef]);

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.splide.go(slideIndex);
    }
  }, [slideIndex]);

  const prevIndex = () => {
    const newIndex = slideIndex - 1 < 0 ? images.length - 1 : slideIndex - 1;
    setSlideIndex(newIndex);
  };

  const nextIndex = () => {
    const newIndex = slideIndex + 1 > images.length - 1 ? 0 : slideIndex + 1;
    setSlideIndex(newIndex);
  };

  const ImageList = ({ forLightBox = false }) => (
    <div
      className={`${
        forLightBox ? 'flex' : 'grid grid-cols-6'
      } gap-[4px] sm:gap-[10px] max-h-[100px] overflow-x-auto max-w-full`}
    >
      {images.map((item, i) => (
        <div
          onClick={() => setSlideIndex(i)}
          key={i}
          className={`rounded-[10px] overflow-hidden cursor-pointer hover:opacity-70 ${
            i > 5 && !forLightBox && 'hidden'
          } relative`}
        >
          {i > 6 && !forLightBox && (
            <div className="w-full h-full bg-black bg-opacity-50 absolute center top-0 left-0 text-white text-[24px] font-[600] pointer-events-none">
              +{images.length - 6}
            </div>
          )}
          <SingleImage
            src={item}
            type={type}
            product={product}
            className={`rounded-[10px] w-full h-full overflow-hidden border-primary ${
              slideIndex === i ? 'border-2' : ''
            }`}
          />
        </div>
      ))}
    </div>
  );

  const getLibelleProcedure = (value) => {
    if (!value) return 'NC';
    return value;
  };

  const handleWishList = (product) => {
    if (isItemInWishList(product)) {
      removeFromWishList(product);
    } else {
      addToWishList(product);
    }
  };

  const getContentProcedure = (value) => {
    switch (value) {
      case 'pro':
        return (
          <p>
            VÉHICULE VENDU EN L'ÉTAT.
            <br />
            <br />
            <br />
            La vente de Véhicule est réservée aux professionnels de l'automobile
            ou à l'exportation.
            <br />
            Procédure avec suivi d'expert.
            <br />
          </p>
        );
        break;
      case 'pieces':
        return (
          <p>
            Cette voiture ne peut pas être récupérée complète, c'est uniquement
            pour les pièces.
            <br />
            <br />
            <br />
            Nous proposons ce véhicule pour la récupération de pièces.,
            <br />
            Vous pouvez récupérer toutes les pièces que vous souhaitez, seul le
            châssis doit rester sur place pour être détruit et ses documents
            seront également détruits.
            <br />
            <br />
            La coupe peut être autorisée.
            <br />
            N'hésitez pas à demander toutes informations complémentaires.
          </p>
        );
        break;
      case 'part':
        return <p>La vente de ce véhicule est ouverte à tous.</p>;
        break;
      default:
        return 'NC';
    }
  };

  const OptionItem = ({ src, text }) => {
    return (
      <div className="text-center w-[130px]">
        <div className="min-h-[90px] flex items-center justify-center">
          <img
            src={'/options/' + src}
            className="max-w-[100px] sm:max-w-[100]"
          />
        </div>
        <div className="mt-5">{text}</div>
      </div>
    );
  };

  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState(false);
  const cancelButtonRef = useRef(null);

  const [openExpired, setOpenExpired] = useState(false);

  useEffect(() => {
    setOpenExpired(isExpired);
  }, [isExpired]);

  const linkVehiclesList =
    '/vehicules' + (isUsed ? '-d-occasion' : '-accidentes');

  const handleAddToCart = (product) => {
    addToCart(product.id, product.forProfessionnal);
    // openCart();
  };

  return (
    <div className="wrapper">
      {product?.title && (
        <Helmet>
          <title>{product?.title} - Stop Pièces Auto</title>
        </Helmet>
      )}
      <Transition.Root show={openExpired} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={() => navigate(isVehicle ? linkVehiclesList : '/catalogue')}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#eeeeeeaa] bg-opacity-75 backdrop-blur-sm transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="sm:text-left full-width">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 text-center mx-auto"
                        >
                          {isVehicle && <>Ce véhicule n'est plus disponible</>}
                          {!isVehicle && <>Cette pièce n'est plus disponible</>}
                        </Dialog.Title>
                        <div className="mt-10 flex flex-wrap gap-[10px]"></div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <Link
                      to={isVehicle ? linkVehiclesList : '/catalogue'}
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      ref={cancelButtonRef}
                    >
                      {isVehicle && <>Voir les autres véhicules</>}
                      {!isVehicle && <>Voir les autres pièces</>}
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#eeeeeeaa] bg-opacity-75 backdrop-blur-sm transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Partager
                        </Dialog.Title>
                        <div className="mt-10 flex flex-wrap gap-[10px]">
                          <div className="Demo__some-network">
                            <FacebookShareButton
                              url={shareUrl}
                              className="Demo__some-network__share-button"
                            >
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <div></div>
                          </div>

                          <div className="Demo__some-network">
                            <FacebookMessengerShareButton
                              url={shareUrl}
                              appId="521270401588372"
                              className="Demo__some-network__share-button"
                            >
                              <FacebookMessengerIcon size={32} round />
                            </FacebookMessengerShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <TwitterShareButton
                              url={shareUrl}
                              title={title}
                              className="Demo__some-network__share-button"
                            >
                              <XIcon size={32} round />
                            </TwitterShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <TelegramShareButton
                              url={shareUrl}
                              title={title}
                              className="Demo__some-network__share-button"
                            >
                              <TelegramIcon size={32} round />
                            </TelegramShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <WhatsappShareButton
                              url={shareUrl}
                              title={title}
                              separator=":: "
                              className="Demo__some-network__share-button"
                            >
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <EmailShareButton
                              url={shareUrl}
                              subject={title}
                              body="body"
                              className="Demo__some-network__share-button"
                            >
                              <EmailIcon size={32} round />
                            </EmailShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <LinkedinShareButton
                              url={shareUrl}
                              className="Demo__some-network__share-button"
                            >
                              <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <VKShareButton
                              url={shareUrl}
                              image={`${images[0]}`}
                              className="Demo__some-network__share-button"
                            >
                              <VKIcon size={32} round />
                            </VKShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <OKShareButton
                              url={shareUrl}
                              image={`${images[0]}`}
                              className="Demo__some-network__share-button"
                            >
                              <OKIcon size={32} round />
                            </OKShareButton>

                            <div>
                              <OKShareCount
                                url={shareUrl}
                                className="Demo__some-network__share-count"
                              />
                            </div>
                          </div>

                          <div className="Demo__some-network">
                            <RedditShareButton
                              url={shareUrl}
                              title={title}
                              windowWidth={660}
                              windowHeight={460}
                              className="Demo__some-network__share-button"
                            >
                              <RedditIcon size={32} round />
                            </RedditShareButton>

                            <div>
                              <RedditShareCount
                                url={shareUrl}
                                className="Demo__some-network__share-count"
                              />
                            </div>
                          </div>

                          <div className="Demo__some-network">
                            <TumblrShareButton
                              url={shareUrl}
                              title={title}
                              className="Demo__some-network__share-button"
                            >
                              <TumblrIcon size={32} round />
                            </TumblrShareButton>

                            <div></div>
                          </div>

                          <div className="Demo__some-network">
                            <LivejournalShareButton
                              url={shareUrl}
                              title={title}
                              description={shareUrl}
                              className="Demo__some-network__share-button"
                            >
                              <LivejournalIcon size={32} round />
                            </LivejournalShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <MailruShareButton
                              url={shareUrl}
                              title={title}
                              className="Demo__some-network__share-button"
                            >
                              <MailruIcon size={32} round />
                            </MailruShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <ViberShareButton
                              url={shareUrl}
                              title={title}
                              className="Demo__some-network__share-button"
                            >
                              <ViberIcon size={32} round />
                            </ViberShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <WorkplaceShareButton
                              url={shareUrl}
                              quote={title}
                              className="Demo__some-network__share-button"
                            >
                              <WorkplaceIcon size={32} round />
                            </WorkplaceShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <LineShareButton
                              url={shareUrl}
                              title={title}
                              className="Demo__some-network__share-button"
                            >
                              <LineIcon size={32} round />
                            </LineShareButton>
                          </div>

                          <div className="Demo__some-network">
                            <WeiboShareButton
                              url={shareUrl}
                              title={title}
                              image={`${images[0]}`}
                              className="Demo__some-network__share-button"
                            >
                              <WeiboIcon size={32} round />
                            </WeiboShareButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Retour
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="wrapper max-w-[1920px] px-[16px] 1400:px-0 wrapper">
        <div className="content">
          <div className="content flex gap-x-[12px] md:gap-x-[16px] flex-wrap items-center w-full py-[12px] sm:py-[16px] 1400:px-0 px-[16px]">
            <p className="text-dark cursor-pointer transition">
              <Link to="/">Accueil</Link>
            </p>
            <MdArrowForwardIos className="text-[#9EA5A7] text-[12px]" />
            <p className="text-dark cursor-pointer transition">
              {isVehicle && (
                <>
                  {product && (
                    <Link
                      to={
                        '/vehicules' + (isUsed ? '-d-occasion' : '-accidentes')
                      }
                    >
                      Véhicules {isUsed ? "d'occasion" : 'accidentés'}
                    </Link>
                  )}
                </>
              )}
              {!isVehicle && (
                <Link to={'/' + type + '-d-occasion'}>
                  {type == 'jantes' && "Nos jantes d'occasion"}
                  {type == 'pneus' && "Nos pneus d'occasion"}
                  {type == 'pieces' && "Nos pièces d'occasion"}
                </Link>
              )}
            </p>
            {product && (
              <MdArrowForwardIos className="text-[#9EA5A7] text-[12px]" />
            )}
            {product && (
              <p className="text-[#9EA5A7] cursor-pointer transition">
                {isVehicle && (
                  <>
                    {product.brand +
                      ' ' +
                      product.gamme +
                      ' ' +
                      product.model +
                      ' — ' +
                      getLabelEnergy(product.energy)}
                  </>
                )}
                {!isVehicle && <>{product.title}</>}
              </p>
            )}
          </div>
          <BackButton className="mb-[20px] sm:mb-0" />
          {loading && <Loader />}
          {!loading && product && (
            <div className="w-full content sm:mt-[24px]">
              {/* Product images and Details  */}
              <div
                className={
                  'w-full grid lg:grid-cols-2 gap-[24px] xl:gap-[48px] min-h-[600px]' +
                  (isExpired ? ' ' + isExpiredClasses : '')
                }
              >
                {/* Left Side  */}
                <div className="w-full space-y-[12px] md:space-y-[24px]">
                  <div className="w-full relative flex items-center">
                    {product.procedure === procedureForPartsValue && (
                      <Tag.Container>
                        <Tag.ForParts />
                      </Tag.Container>
                    )}
                    <div className="w-[40px]  h-[40px] absolute z-10 center right-0 sm:right-[6px]">
                      <LiaAngleRightSolid
                        onClick={nextIndex}
                        className="text-[white] cursor-pointer text-[30px] sm:text-[50px]"
                      />
                    </div>
                    <div className="w-[40px]  h-[40px] absolute z-10 center left-0 sm:left-[6px]">
                      <LiaAngleLeftSolid
                        onClick={prevIndex}
                        className="text-[white] cursor-pointer text-[30px] sm:text-[50px]"
                      />
                    </div>
                    <Splide
                      ref={sliderRef}
                      className="w-full px-[5px] cursor-pointer" // Add a custom class to Splide for styling
                      options={{
                        width: '100%',
                        perPage: 1, // Show only 1 image at a time by default
                        perMove: 1, // Move 1 image at a time
                        drag: true,
                        pagination: false,
                        arrows: false, // Set arrows to navigate through the images
                        type: 'slide', // Set the type to 'slide' for image slider
                        gap: 0, // No gap between images
                        padding: 0, // No padding
                        autoWidth: false, // Set autoWidth to false to display full-width images
                        breakpoints: {
                          768: {
                            perPage: 1, // For screens of width 768px and above (mobile devices), show 1 slide
                          },
                          1024: {
                            perPage: 1,
                            drag: true, // For screens of width 1024px and above (PC), show maximum 3 slides
                          },
                        },
                      }}
                    >
                      {images.map((item, i) => (
                        <SplideSlide
                          key={i}
                          className="w-full rounded-[12px] overflow-hidden"
                        >
                          <SingleImage
                            onClick={() => {
                              setLightBoxOpened(true);
                            }}
                            src={item}
                            type={type}
                            product={product}
                          />
                        </SplideSlide>
                      ))}
                    </Splide>
                    {
                      <Lightbox
                        isOpen={lightBoxOpened}
                        onPrev={prevIndex}
                        onNext={nextIndex}
                        images={images.map((item, i) => ({
                          src: item,
                          alt: 'image-' + i,
                          className: 'cursor-zoom-in',
                        }))}
                        currentIndex={slideIndex}
                        /* Add your own UI */
                        renderHeader={() => (
                          <LiaTimesSolid
                            className="text-[white] cursor-pointer text-[30px] sm:text-[50px] z-50 absolute top-0 right-0 mt-[10px] mr-[10px]"
                            onClick={() => setLightBoxOpened(false)}
                          />
                        )}
                        renderFooter={() => <ImageList forLightBox />}
                        renderPrevButton={() => (
                          <LiaAngleLeftSolid
                            onClick={prevIndex}
                            className="text-[white] cursor-pointer text-[30px] sm:text-[50px] z-50 ml-[10px]"
                          />
                        )}
                        renderNextButton={() => (
                          <LiaAngleRightSolid
                            onClick={nextIndex}
                            className="text-[white] cursor-pointer text-[30px] sm:text-[50px] z-50 mr-[10px]"
                          />
                        )}
                        // renderImageOverlay={() => (
                        //     <div
                        //         className={`cursor-zoom-${
                        //             true ? 'in' : 'out'
                        //         } w-full h-full absolute`}
                        //     ></div>
                        // )}
                        /* Add styling */
                        style={{
                          background: 'rgba(0, 0, 0, 0.9)',
                        }}
                        /* Handle closing */
                        onClose={() => setLightBoxOpened(false)}
                        /* Use single or double click to zoom */
                        singleClickToZoom
                        /* react-spring config for open/close animation */
                        pageTransitionConfig={{
                          from: {
                            transform: 'scale(0.75)',
                            opacity: 0,
                          },
                          enter: {
                            transform: 'scale(1)',
                            opacity: 1,
                          },
                          leave: {
                            transform: 'scale(0.75)',
                            opacity: 0,
                          },
                          config: {
                            mass: 1,
                            tension: 320,
                            friction: 32,
                          },
                        }}
                      />
                    }
                  </div>
                  <ImageList />

                  {(type == 'jantes' || type == 'pieces') &&
                    product.comments?.trim() && (
                      <div className="!mt-[100px] hidden lg:block">
                        <h1 className="text-[25px] md:text-[40px] font-[600] leading-[36px] text-dark">
                          Commentaire
                        </h1>
                        <p className="whitespace-pre-line mt-5">
                          {product.comments}
                        </p>
                      </div>
                    )}
                </div>
                {/* Right Side  */}
                <div className="w-full">
                  <div className="flex items-start  gap-[10px] sm:gap-0">
                    <h1
                      className={`font-clash font-[600] text-[24px] sm:text-[36px] leading-[1.1] sm:leading-[32px] ${
                        product.shippable &&
                        product.availability === 'unavailable'
                          ? 'opacity-50'
                          : ''
                      }`}
                    >
                      {isVehicle && (
                        <>
                          {product.brand +
                            ' ' +
                            product.gamme +
                            ' ' +
                            product.model +
                            ' — ' +
                            getLabelEnergy(product.energy) +
                            (product.year ? ', ' + product.year : '')}
                        </>
                      )}
                      {!isVehicle && <>{product.title}</>}
                    </h1>
                    <button className="min-w-[25px] ml-4">
                      <GoShareAndroid
                        className="text-[23px] opacity-70"
                        onClick={() => setOpen(true)}
                      />
                    </button>
                  </div>

                  {/* buttons  */}

                  {isVehicle && (
                    <div className="flex flex-wrap my-[20px] gap-[10px] sm:gap-[12px]">
                      {product.availability == 'available' && (
                        <button className="center px-[10px] sm:px-[18px] border-[3px] border-[#45CD5D] text-[#45CD5D] uppercase sm:text-[18px] font-[700] py-[8px] sm:py-[12px] rounded-[8px] cursor-default">
                          Disponible
                        </button>
                      )}
                      {product.availability == 'unavailable' && (
                        <button className="center px-[18px] border-[3px] border-orange text-orange uppercase sm:text-[18px] font-[700] py-[8px] sm:py-[12px] rounded-[8px] cursor-default">
                          INDISPONIBLE
                        </button>
                      )}
                      {product.availability == 'reserved' && (
                        <button className="center px-[18px] border-[3px] border-blue text-blue uppercase sm:text-[18px] font-[700] py-[8px] sm:py-[12px] rounded-[8px] cursor-default">
                          RéSERVé
                        </button>
                      )}
                    </div>
                  )}
                  {/* Price  */}
                  <div
                    className={`grid grid-cols-2 w-full mt-[16px] xl:mt-[24px] ${
                      product.shippable &&
                      product.availability === 'unavailable'
                        ? 'opacity-50'
                        : ''
                    }`}
                  >
                    <div className="content-center">
                      <h1 className="font-clash font-[600] text-[24px] sm:text-[36px] leading-[32px] content-center">
                        <Amount value={product.price} precision={0} />
                      </h1>
                    </div>
                    <div className="text-[#aaa] mt-1 font-clash font-[500] text-[18px] text-right content-center">
                      Réf : {product.refSource}
                    </div>
                  </div>

                  {product.shippable &&
                    product.availability !== 'unavailable' && (
                      <div className="w-full flex gap-[12px] mt-[16px] xl:mt-[24px]">
                        <button
                          className="flex-1 center px-[24px] py-[16px] text-white hover:opacity-80 bg-orange gap-[12px] max-h-[56px] rounded-[8px]"
                          onClick={() => handleAddToCart(product)}
                        >
                          {addToCartLoading ? (
                            <LittleWhiteLoader className="w-5 h-5" />
                          ) : (
                            <>
                              <MdOutlineShoppingCart className="text-[28px]" />
                              <p className="text-[18px] leading-[2] font-[700]">
                                Ajouter au panier
                              </p>
                            </>
                          )}
                        </button>
                        <div
                          className="w-[72px] h-[56px] bg-gray rounded-[8px] cursor-pointer center"
                          onClick={() => handleWishList(product)}
                        >
                          {isItemInWishList(product) ? (
                            <img
                              src="/pictos/liked.png"
                              alt=""
                              className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] cursor-pointer"
                            />
                          ) : (
                            <img
                              src="/pictos/unliked.png"
                              alt=""
                              className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] opacity-[.6] cursor-pointer"
                            />
                          )}
                        </div>
                      </div>
                    )}

                  <Modal
                    modalStyle="two-columns"
                    showModal={contact}
                    setShowModal={setContact}
                    image={'/pictos/contact.png'}
                    mobileModalStyle="default"
                    modalTitle={`Intéressé par
                        ${
                          type === 'pieces'
                            ? 'cette pièce'
                            : type === 'jantes'
                              ? 'cette jante'
                              : type === 'pneus'
                                ? 'ce pneu'
                                : 'ce véhicule'
                        }
                        ?`}
                  >
                    <div className="mt-6">
                      <p>
                        Remplissez ce formulaire et nous vous recontacterons
                        rapidement.
                      </p>
                      <ContactForm
                        submitted={() => setContact(false)}
                        productId={product.id}
                      />
                    </div>
                  </Modal>
                  {!product.shippable &&
                    product.availability !== 'unavailable' && (
                      <div className="w-full flex gap-[12px] mt-[16px] xl:mt-[24px]">
                        <button
                          className="flex-1 center px-[24px] py-[16px] text-white hover:opacity-80 bg-orange gap-[12px] max-h-[56px] rounded-[8px]"
                          onClick={() => setContact(true)}
                          // onMouseEnter={() => setHovered(true)}
                          // onMouseLeave={() => setHovered(false)}
                        >
                          <p className="text-[18px] leading-[2] font-[700]">
                            {hovered
                              ? 'Voir nos coordonnées'
                              : 'Nous contacter'}
                          </p>
                          {!hovered && (
                            <MdOutlineMail className="text-[28px]" />
                          )}
                        </button>
                        <div
                          className="w-[72px] h-[56px] bg-gray rounded-[8px] cursor-pointer center"
                          onClick={() => handleWishList(product)}
                        >
                          {isItemInWishList(product) ? (
                            <img
                              src="/pictos/liked.png"
                              alt=""
                              className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] cursor-pointer"
                            />
                          ) : (
                            <img
                              src="/pictos/unliked.png"
                              alt=""
                              className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] opacity-[.6] cursor-pointer"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  {product.shippable &&
                    product.availability === 'unavailable' && (
                      <div className="w-full flex gap-[12px] mt-[16px] xl:mt-[24px]">
                        <div className="w-full">
                          <p className="font-[700]">
                            Malheureusement ce produit a été victime de son
                            succès.
                          </p>
                          <p>
                            Mais vous pouvez vous inscrire à la liste d'attente
                            pour être informé lorsqu'il sera de nouveau
                            disponible.
                          </p>
                          {showStockNotificationSuccess && (
                            <p className="text-green-600 my-3">
                              Demande envoyée avec succès ✅. Vous recevrez un
                              email lorsque le produit sera de nouveau
                              disponible.
                            </p>
                          )}
                          {stockNotificationError && (
                            <p className="text-red-500 my-3">
                              {stockNotificationError}
                            </p>
                          )}
                          {!stockNotificationLoading &&
                            isStockNotified &&
                            !showStockNotificationSuccess && (
                              <p className="text-green-600 my-3">
                                Vous êtes déjà inscrit pour être informé lorsque
                                ce produit reviendra en stock
                              </p>
                            )}
                          {!stockNotificationLoading &&
                            !isStockNotified &&
                            (!showStockNotificationSuccess ||
                              !isAuthenticated) && (
                              <div>
                                <Formik
                                  initialValues={{ email: '' }}
                                  onSubmit={handleStockNotificationSubmit}
                                  validationSchema={stockNotificationSchema}
                                >
                                  {({ isValid, isSubmitting }) => (
                                    <Form className="flex gap-[12px] sm:flex-row flex-col">
                                      {!isAuthenticated && (
                                        <FormInput
                                          name="email"
                                          placeholder="Votre email"
                                          className="w-full"
                                        />
                                      )}
                                      <button
                                        type="submit"
                                        className={`center text-nowrap flex gap-[12px] w-full lg:w-auto xl:w-full px-[24px] py-[16px] text-white max-h-[56px] rounded-[8px] ${
                                          !isValid
                                            ? 'bg-slate-400'
                                            : 'bg-orange hover:opacity-80'
                                        }`}
                                        disabled={!isValid || isSubmitting}
                                      >
                                        <p className="text-[18px] leading-[2] font-[700]">
                                          Être notifié(e)
                                        </p>
                                        {isSubmitting && (
                                          <LittleWhiteLoader className="w-5 h-5" />
                                        )}
                                      </button>
                                    </Form>
                                  )}
                                </Formik>
                              </div>
                            )}

                          {((showStockNotificationSuccess && isAuthenticated) ||
                            isStockNotified) && (
                            <button
                              className="center text-nowrap flex gap-[12px] w-auto px-[20px] py-[10px] text-white rounded-[8px] bg-red-500 hover:opacity-80"
                              onClick={handleDeleteStockNotification}
                            >
                              <p className="">Ne plus être notifié(e)</p>
                              {deleteStockNotificationLoading && (
                                <LittleWhiteLoader className="w-5 h-5" />
                              )}
                            </button>
                          )}
                        </div>
                        <div
                          className="w-[72px] h-[56px] bg-gray rounded-[8px] cursor-pointer center"
                          onClick={() => handleWishList(product)}
                        >
                          {isItemInWishList(product) ? (
                            <img
                              src="/pictos/liked.png"
                              alt=""
                              className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] cursor-pointer"
                            />
                          ) : (
                            <img
                              src="/pictos/unliked.png"
                              alt=""
                              className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] opacity-[.6] cursor-pointer"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  {!isVehicle &&
                    product.shippable &&
                    product.availability !== 'unavailable' && (
                      <div className="w-full flex gap-[12px] mt-[16px] xl:mt-[24px]">
                        <button
                          className="flex-1 center px-[24px] py-[16px] text-white hover:opacity-80 bg-[#777] gap-[12px] max-h-[56px] rounded-[8px]"
                          onClick={() => setContact(true)}
                          // onMouseEnter={() => setHovered(true)}
                          // onMouseLeave={() => setHovered(false)}
                        >
                          <p className="text-[18px] leading-[2] font-[700]">
                            {hovered
                              ? 'Voir nos coordonnées'
                              : 'Ou nous contacter'}
                          </p>
                          {!hovered && (
                            <MdOutlineMail className="text-[28px]" />
                          )}
                        </button>
                      </div>
                    )}
                  {/* List  */}
                  {isVehicle && (
                    <div className="w-full flex flex-col gap-[16px] xl:py-[23px] mt-[20px] xl:mt-[24px]">
                      <ProductSpecInfo label="Marque" value={product.brand} />
                      <ProductSpecInfo label="Modèle" value={product.gamme} />
                      <ProductSpecInfo label="Finition" value={product.model} />
                      <ProductSpecInfo
                        label="Energie"
                        value={getLabelEnergy(product.energy)}
                        isBlue={true}
                      />
                      <ProductSpecInfo
                        label="Mise en circulation"
                        value={product.dateImmat}
                        isBlue={true}
                      />
                      {product.mileage > 5 && (
                        <ProductSpecInfo
                          label="Kilométrage (non garanti)"
                          value={
                            product.mileage
                              ? numberToDisplay(product.mileage) + ' km'
                              : false
                          }
                        />
                      )}
                      <ProductSpecInfo
                        label="N° de police"
                        value={product.policeNumber}
                        isBlue={true}
                      />
                      <ProductSpecInfo
                        label="Type de véhicule"
                        value={product.vehicleType}
                      />
                      <ProductSpecInfo
                        label="Type Carte Grise"
                        value={product.typeCG}
                      />
                      {!isUsed && (
                        <ProductSpecInfo
                          label="Valeur vénale"
                          value={
                            product.marketValue
                              ? product.marketValue + ' €'
                              : product.marketValue
                          }
                        />
                      )}
                      {!isUsed && (
                        <ProductSpecInfo
                          label="Procédure"
                          value={getLibelleProcedure(product.procedure)}
                          isBlue={true}
                        />
                      )}
                    </div>
                  )}
                  {type == 'pneus' && getPneusInfo(product)}
                  {type == 'jantes' && getJantesInfo(product)}
                  {type == 'pieces' &&
                    (product.carmeca == 'C' || product.carmeca == 'CP') &&
                    getPiecesInfoCar(product)}
                  {type == 'pieces' &&
                    product.carmeca == 'M' &&
                    getPiecesInfoMeca(product)}
                </div>
              </div>
              {/* Vue d’ensemble  */}
              <div
                className={
                  'w-full mb-[100px] grid lg:grid-cols-2 gap-[16px] md:gap-[24px] xl:gap-[48px] mt-[24px] md:mt-[60px]' +
                  (isExpired ? ' ' + isExpiredClasses : '')
                }
              >
                <div className="flex flex-col gap-[12px] md:gap-[24px]">
                  {type !== 'pneus' &&
                    type !== 'jantes' &&
                    type !== 'pieces' && (
                      <h1 className="text-[25px] md:text-[40px] font-[600] leading-[36px] text-dark">
                        Vue d’ensemble
                      </h1>
                    )}
                  {(type == 'jantes' || type == 'pieces') && (
                    <div className="lg:hidden">
                      <h1 className="text-[25px] md:text-[40px] font-[600] leading-[36px] text-dark">
                        Commentaire
                      </h1>
                      <p className="whitespace-pre-line mt-5">
                        {product.comments}
                      </p>
                    </div>
                  )}
                  {product.partCode &&
                    isVenteReservedToProfessionnal(product.partCode) && (
                      <p className="md:text-[19px] md:leading-[28px] text-dark opacity-80">
                        <br />
                        <b>
                          Vente exclusivement réservée aux professionnels de
                          l'automobile.
                        </b>
                      </p>
                    )}
                  {isVehicle && (
                    <>
                      {product.type == 'accidented' && product.damage && (
                        <b>CHOC : {product.damage}</b>
                      )}
                    </>
                  )}
                  <p className="whitespace-pre-line">
                    {isVehicle && product.description}{' '}
                    {!isVehicle &&
                      type !== 'jantes' &&
                      type !== 'pieces' &&
                      product.comments}
                  </p>
                  {isVehicle && (
                    <p className="md:text-[19px] md:leading-[28px] text-dark opacity-80">
                      {product.type == 'used' && (
                        <>
                          <br />
                          <b>
                            Véhicule révisé et garanti 6 mois moteur, boîte et
                            pont.
                          </b>
                        </>
                      )}
                      {product.type == 'accidented' && (
                        <>
                          <br />
                          <b>
                            Véhicule réservé aux professionnels de l'automobile
                            ou à l'exportation.
                          </b>
                        </>
                      )}
                    </p>
                  )}
                </div>
                {isVehicle && (
                  <div className="bg-gray rounded-[12px] space-y-[24px] p-[16px] md:p-[32px] opacity-75">
                    <div className="space-y-[12px] mb-[40px]">
                      <h1 className="text-[25px] md:text-[40px] font-[600] leading-[36px] text-dark">
                        Caractéristiques
                      </h1>
                    </div>
                    {/*<div className="space-y-[12px]">
                                            <h1 className="text-[25px] md:text-[40px] font-[600] leading-[36px] text-dark">
                                                Historique
                                            </h1>
                                            <p className="opacity-80">
                                                Rapport du 13 septembre
                                            </p>
                                        </div>
                                        <div className="flex flex-col gap-[16px]">
                                            <div className="flex gap-[16px]">
                                                <img src="/ic1.png" alt="" className="min-h-[24px] min-w-[24px] max-w-[24px] max-h-[24px]" />
                                                <p>
                                                    7 propriétaires de PTS
                                                </p>
                                            </div>
                                            <div className="flex gap-[16px]">
                                                <img src="/ic2.png" alt="" className="min-h-[24px] min-w-[24px] max-w-[24px] max-h-[24px]" />
                                                <p>
                                                    Vérification des accidents: gravité des dommages, coût des réparations et paiements d&apos;assurance
                                                </p>
                                            </div>
                                            <div className="flex gap-[16px]">
                                                <img src="/ic3.png" alt="" className="min-h-[24px] min-w-[24px] max-w-[24px] max-h-[24px]" />
                                                <p>
                                                    Vérification de la pureté juridique: engagement, recherche et restrictions
                                                </p>
                                        </div>*/}
                    <div className="flex flex-wrap flex-row justify-evenly sm:gap-x-[80px]  sm:gap-y-[60px]">
                      {!!product.clim && (
                        <OptionItem src="clim.png" text={'Climatisation'} />
                      )}
                      {!!product.airbag && (
                        <OptionItem src="airbag.png" text={'Airbag'} />
                      )}
                      {!!product.withoutLicense && (
                        <OptionItem src="microcar.png" text={'Sans permis'} />
                      )}
                      {!!product.radio && (
                        <OptionItem src="radio.png" text={'Autoradio'} />
                      )}
                      {!!product.leather && (
                        <OptionItem src="leather.png" text={'Cuir'} />
                      )}
                      {!!product.frontElectricWindow && (
                        <OptionItem
                          src="front-window.png"
                          text={'Vitres avant électriques'}
                        />
                      )}
                      {!!product.rearElectricWindow && (
                        <OptionItem
                          src="rear-window.png"
                          text={'Vitres arrière électriques'}
                        />
                      )}
                      {!!product.abs && (
                        <OptionItem src="abs.png" text={'ABS'} />
                      )}
                      {!!product.is2Wheels && (
                        <OptionItem src="2wheels.png" text={'Deux roues'} />
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Intéressé(e) ? Venez-nous rendre visite ! */}
              {isVehicle && (
                <div className="w-full grid lg:grid-cols-2 bg-blue px-[12px] md:px-[26px] py-[12px] md:py-[29px] mt-[24px] md:mt-[41px] rounded-[12px]">
                  <div className="text-white lg:pl-[12px] py-[11px] space-y-[12px] sm:space-y-[24px] lg:space-y-[48px]">
                    <h1 className=" text-[25px] sm:text-[30px] leading-[1] xl:text-[40px] font-[600] xl:leading-[50px]">
                      Intéressé(e) ? <br />
                      Venez-nous rendre visite !
                    </h1>
                    <div className="flex w-full flex-col lg:flex-row xl:flex-col gap-[12px] xl:gap-[24px]">
                      <div className="w-full flex flex-col xl:flex-row sm:gap-[5px] lg:gap-[8px] xl:gap-[16px] xl:items-center">
                        <img
                          src="/clock.png"
                          alt=""
                          className="sm:max-h-[47px] max-w-[40px] max-h-[40px] sm:max-w-[47px]"
                        />
                        <p className="font-[500] text-[20px] xl:text-[22px]">
                          Nos horaires :
                        </p>
                        <p className="xl:text-[20px] xl:leading-[28px] opacity-80">
                          Lundi - Jeudi : 8h30-12h / 14h-18h15{' '}
                          <br className="hidden xl:flex" />
                          Vendredi : 8h30-12h / 14h-18h30
                        </p>
                      </div>
                      <div className="w-full flex flex-col xl:flex-row sm:gap-[5px] lg:gap-[8px] xl:gap-[16px] xl:items-center">
                        <img
                          src="/Frame 368.png"
                          alt=""
                          className="sm:max-h-[47px] max-w-[40px] max-h-[40px] sm:max-w-[47px]"
                        />
                        <p className="font-[500] text-[20px] xl:text-[22px]">
                          Notre adresse :
                        </p>
                        <p className="xl:text-[20px] xl:leading-[28px] opacity-80">
                          7, RD 386 - Les 7 chemins 69390{' '}
                          <br className="hidden xl:flex" /> Vourles
                          (Brignais-Sud)
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="relative">
                      <img
                        src={
                          '/site' + (Math.random() < 0.5 ? '' : '2') + '.png'
                        }
                        alt=""
                        className="w-full 1100:max-h-[506px]"
                      />
                      <img
                        src="/logo-blanc.png"
                        alt=""
                        className="max-w-[80px] sm:max-w-[150px] 1100:max-w-[200px] 1500:max-w-[270px] absolute left-[10px] top-[10px] md:left-[20px] md:top-[20px] lg:left-[-110px] lg:top-[-10px]"
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* Products Section  */}
              {isVehicle && (
                <div className="content flex flex-col gap-[24px] sm:gap-[30px] lg:gap-[48px] py-[30px] md:py-[50px] ">
                  <div className="flex flex-col gap-[12px] lg:gap-[48px]">
                    <h1 className="text-[25px] md:text-[40px] font-[600] leading-[36px] text-dark">
                      Autres véhicules
                    </h1>
                  </div>
                  <div className="w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-[12px] 1200:gap-x-[24px] gap-y-[24px] 1200:gap-y-[48px]">
                    {randomCars &&
                      randomCars.map((product) => (
                        <RandomProduct
                          key={'product-' + product.id}
                          title={
                            product.brand +
                            ' ' +
                            product.gamme +
                            ' ' +
                            product.model +
                            (product.year ? ', ' + product.year : '')
                          }
                          price={product.price}
                          liked={Math.random() < 0}
                          src={product.mainImage}
                          id={product.id}
                          product={product}
                        />
                      ))}
                  </div>
                  <div className="w-full center">
                    <Link
                      to={'/vehicules'}
                      className="bg-gray px-[24px] center py-[16px] font-[600] text-dark rounded-[8px] w-full sm:w-auto"
                    >
                      Voir plus
                    </Link>
                  </div>
                </div>
              )}
              <BackButton className="mb-[20px]" />
            </div>
          )}
        </div>
      </div>
      <ProductDetailsLastSection />
    </div>
  );
};

export default ProductDetails;

const SingleImage = ({ src, type, product, ...props }) => {
  var failover = getFailoverImage(type, product);
  if (typeof src !== 'string') src = '';
  return (
    <img
      src={src}
      alt=""
      className="w-full h-full object-contain"
      onError={({ currentTarget }) => {
        //exclude SSL ERROR

        currentTarget.onerror = null; // prevents looping
        currentTarget.src = '/' + failover;
      }}
      {...props}
    />
  );
};
SingleImage.propTypes = {
  src: PropTypes.string.isRequired,
};

const RandomProduct = ({
  title = 'Title',
  price = 0,
  liked = false,
  src = '',
  id = '',
  product = {},
}) => {
  const link = getProductLinkFromProduct(product);
  return (
    <Link to={link} className="w-full flex flex-col gap-[16px] cursor-pointer">
      <img
        src={src}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/failover.png';
        }}
        alt=""
        className="w-full"
      />
      <div className="flex flex-col w-full gap-[4px]">
        <div className="w-full flex items-start justify-between">
          <p className="font-[600] text-[14px] sm:text-[16px] 1200:text-[20px] 1200:leading-[28px] text-dark">
            {title}
          </p>
          {liked ? (
            <img
              src="/pictos/liked.png"
              alt=""
              className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] cursor-pointer"
            />
          ) : (
            <img
              src="/pictos/unliked.png"
              alt=""
              className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] opacity-[.6] cursor-pointer"
            />
          )}
        </div>
        <p className="font-[600] text-[14px] sm:text-[16px] 1200:text-[20px] 1200:leading-[24px] text-blue">
          {!!price && <Amount value={price} precision={0} />}
        </p>
      </div>
    </Link>
  );
};

const OriginalVehicle = () => {
  return (
    <div className="w-full center mt-5">
      <span className="bg-[#777] flex items-center justify-center text-white text-[19px] p-1 rounded-lg opacity-[0.9] ">
        <MdInfo className="text-[20px] mr-2" />
        Véhicule d'origine
      </span>
    </div>
  );
};

const getPiecesInfoCar = (product) => {
  const productSpecInfos = [
    { label: 'partLabel', value: product.partLabel, isBlue: true },
    { label: 'brand', value: product.brand },
    { label: 'gamme', value: product.gamme },
    { label: 'model', value: product.model },
    { label: 'finition', value: product.finition },
    /*{ label: 'typeCG', value: product.typeCG },*/
    { label: 'vehicleColor', value: product.vehicleColor },
    { label: 'colorReference', value: product.colorReference },
    { label: 'dateImmat', value: product.dateImmat },
    {
      label: 'mileage',
      value: product.mileage == 1 || !product.mileage ? false : product.mileage,
    },
    { label: 'damage', value: product.damage },
    {
      label: 'manufacturerReference',
      value: product.manufacturerReference,
      isBlue: true,
    },
    {
      label: 'referencesSerialNumber',
      value: product.referencesSerialNumber,
      isBlue: true,
    },
  ];

  const productCarInfos = [
    { label: 'transmission', value: product.transmission },
    { label: 'gears', value: product.gears },
    { label: 'gearboxType', value: product.gearboxType },
    {
      label: 'energy',
      value: product.energy ? getLabelEnergy(product.energy) : '',
    },
    { label: 'equipmentReference', value: product.equipmentReference },
    { label: 'engineType', value: product.engineType, isBlue: true },
    { label: 'cylinderCapacity', value: product.cylinderCapacity },
    { label: 'powerHp', value: product.powerHp },
  ];

  const translatedProductSpecInfos = productSpecInfos.map((info) => {
    const translatedLabel = translateLabelToFrench(info.label);
    return { ...info, label: translatedLabel };
  });

  const translatedProductSpecOtherInfos = productCarInfos.map((info) => {
    const translatedLabel = translateLabelToFrench(info.label);
    return { ...info, label: translatedLabel };
  });

  return (
    <div
      className={`w-full flex flex-col gap-[16px] xl:py-[23px] mt-[20px] xl:mt-[24px] ${
        product.shippable && product.availability === 'unavailable'
          ? 'opacity-50'
          : ''
      }`}
    >
      {translatedProductSpecInfos.map((info) => (
        <ProductSpecInfo
          key={info.label}
          label={info.label}
          value={info.value}
          isBlue={info.isBlue || false}
        />
      ))}

      <OriginalVehicle />

      {translatedProductSpecOtherInfos.map((info) => (
        <ProductSpecInfo
          key={info.label}
          label={info.label}
          value={info.value}
          isBlue={info.isBlue || false}
        />
      ))}
    </div>
  );
};

const getPiecesInfoMeca = (product) => {
  // const isEngine = product.partLabel === 'MOTEUR';
  const isGearbox =
    product.partLabel === 'BOITE A VITESSE MECANIQUE' ||
    product.partLabel === 'BOITE A VITESSE AUTOMATIQUE';

  let productSpecInfos;
  switch (true) {
    case isGearbox:
      productSpecInfos = [
        { label: 'partLabel', value: product.partLabel, isBlue: true },
        { label: 'gearboxType', value: product.gearboxType, isBlue: true },
        { label: 'cylinderCapacity', value: product.cylinderCapacity },
        { label: 'powerHp', value: product.powerHp },
        { label: 'brand', value: product.brand },
        { label: 'gamme', value: product.gamme },
        { label: 'model', value: product.model },
        { label: 'finition', value: product.finition },
        /*{ label: 'typeCG', value: product.typeCG },*/
        {
          label: 'energy',
          value: product.energy ? getLabelEnergy(product.energy) : '',
        },
        { label: 'dateImmat', value: product.dateImmat },
        {
          label: 'mileage',
          value:
            product.mileage == 1 || !product.mileage ? false : product.mileage,
        },
        { label: 'damage', value: product.damage },
        {
          label: 'referencesSerialNumber',
          value: product.referencesSerialNumber,
          isBlue: true,
        },
        {
          label: 'manufacturerReference',
          value: product.manufacturerReference,
          isBlue: true,
        },
        { label: 'transmission', value: product.transmission },
        { label: 'gears', value: product.gears },
        { label: 'engineType', value: product.engineType },
        { label: 'equipmentReference', value: product.equipmentReference },
      ];
      break;
    default:
      productSpecInfos = [
        { label: 'partLabel', value: product.partLabel, isBlue: true },
        { label: 'engineType', value: product.engineType, isBlue: true },
        { label: 'cylinderCapacity', value: product.cylinderCapacity },
        { label: 'powerHp', value: product.powerHp },
        { label: 'brand', value: product.brand },
        { label: 'gamme', value: product.gamme },
        { label: 'model', value: product.model },
        { label: 'finition', value: product.finition },
        /*{ label: 'typeCG', value: product.typeCG },*/
        {
          label: 'energy',
          value: product.energy ? getLabelEnergy(product.energy) : '',
        },
        { label: 'dateImmat', value: product.dateImmat },
        {
          label: 'mileage',
          value:
            product.mileage == 1 || !product.mileage ? false : product.mileage,
        },
        { label: 'damage', value: product.damage },
        {
          label: 'referencesSerialNumber',
          value: product.referencesSerialNumber,
          isBlue: true,
        },
        {
          label: 'manufacturerReference',
          value: product.manufacturerReference,
          isBlue: true,
        },
        { label: 'transmission', value: product.transmission },
        { label: 'gears', value: product.gears },
        { label: 'gearboxType', value: product.gearboxType },
        { label: 'equipmentReference', value: product.equipmentReference },
      ];
      break;
  }

  const productCarInfos = [
    { label: 'brand', value: product.brand },
    { label: 'model', value: product.model },
    { label: 'gamme', value: product.gamme },
    {
      label: 'energy',
      value: product.energy ? getLabelEnergy(product.energy) : '',
    },
    { label: 'finition', value: product.finition },
    { label: 'dateImmat', value: product.dateFirstCirculation },
    { label: 'vehicleColor', value: product.vehicleColor },
  ];

  const translatedProductSpecInfos = productSpecInfos.map((info) => {
    const translatedLabel = translateLabelToFrench(info.label);
    return { ...info, label: translatedLabel };
  });

  const translatedProductCarInfos = productCarInfos.map((info) => {
    const translatedLabel = translateLabelToFrench(info.label);
    return { ...info, label: translatedLabel };
  });

  return (
    <div
      className={`w-full flex flex-col gap-[16px] xl:py-[23px] mt-[20px] xl:mt-[24px] ${
        product.shippable && product.availability === 'unavailable'
          ? 'opacity-50'
          : ''
      }`}
    >
      {translatedProductSpecInfos.map((info) => (
        <ProductSpecInfo
          key={info.label}
          label={info.label}
          value={info.value}
          isBlue={info.isBlue || false}
        />
      ))}
      <OriginalVehicle />
      {translatedProductCarInfos.map((info) => (
        <ProductSpecInfo
          key={info.label}
          label={info.label}
          value={info.value}
          isBlue={info.isBlue || false}
        />
      ))}
    </div>
  );
};

const getPneusInfo = (product) => {
  const productSpecInfos = [
    { label: 'partLabel', value: product.partLabel, isBlue: true },
    { label: 'width', value: product.width },
    { label: 'height', value: product.height },
    { label: 'diameter', value: product.diameter + '"', isBlue: true },
    { label: 'speedLoadIndex', value: product.speedLoadIndex },
    { label: 'season', value: product.season, isBlue: true },
    { label: 'manufacturer', value: product.manufacturer, isBlue: true },
    { label: 'designation', value: product.designation },
    { label: 'status', value: product.status, isBlue: true },
  ];

  const translatedProductSpecInfos = productSpecInfos.map((info) => {
    const translatedLabel = translateLabelToFrench(info.label);
    return { ...info, label: translatedLabel };
  });

  return (
    <div
      className={`w-full flex flex-col gap-[16px] xl:py-[23px] mt-[20px] xl:mt-[24px] ${
        product.shippable && product.availability === 'unavailable'
          ? 'opacity-50'
          : ''
      }`}
    >
      {translatedProductSpecInfos.map((info) => (
        <ProductSpecInfo
          key={info.label}
          label={info.label}
          value={info.value}
          isBlue={info.isBlue || false}
        />
      ))}
    </div>
  );
};

const getJantesInfo = (product) => {
  const productSpecInfos = [
    { label: 'partLabel', value: product.partLabel, isBlue: true },
    { label: 'diameter', value: product.diameter + ' pouces', isBlue: true },
    { label: 'width', value: product.width + ' pouces' },
    { label: 'numberOfHoles', value: product.numberOfHoles },
    { label: 'deportET', value: product.deportET },
    {
      label: 'rimName',
      value: product.manufacturerReferences,
      isBlue: true,
    },
    { label: 'referenceReadOnthePart', value: product.serialReferences },
    { label: 'manufacturerReferences', value: product.oeReference },
    { label: 'criteria', value: product.criteria },
  ];

  const productCarInfos = [
    { label: 'brand', value: product.brand },
    { label: 'model', value: product.model },
    { label: 'gamme', value: product.gamme },
    { label: 'finish', value: product.finish },
    {
      label: 'energy',
      value: product.energy ? getLabelEnergy(product.energy) : '',
    },
    { label: 'dateFirstCirculation', value: product.dateFirstCirculation },
    { label: 'vehicleColor', value: product.vehicleColor },
  ];

  const translatedProductSpecInfos = productSpecInfos.map((info) => {
    const translatedLabel = translateLabelToFrench(info.label);
    return { ...info, label: translatedLabel };
  });
  const translatedProductCarInfos = productCarInfos.map((info) => {
    const translatedLabel = translateLabelToFrench(info.label);
    return { ...info, label: translatedLabel };
  });

  return (
    <div
      className={`w-full flex flex-col gap-[16px] xl:py-[23px] mt-[20px] xl:mt-[24px] ${
        product.shippable && product.availability === 'unavailable'
          ? 'opacity-50'
          : ''
      }`}
    >
      {translatedProductSpecInfos.map((info) => (
        <ProductSpecInfo
          key={info.label}
          label={info.label}
          value={info.value}
          isBlue={info.isBlue || false}
        />
      ))}

      <OriginalVehicle />
      {translatedProductCarInfos.map((info) => (
        <ProductSpecInfo
          key={info.label}
          label={info.label}
          value={info.value}
          isBlue={info.isBlue || false}
        />
      ))}
    </div>
  );
};

const translateLabelToFrench = (label) => {
  // Implement your translation logic here
  // Return the translated label
  switch (label) {
    case 'partCode':
      return 'Code de la pièce';
    case 'partLabel':
      return 'Article';
    case 'manufacturer':
      return 'Fabricant';
    case 'designation':
      return 'Désignation';
    case 'criteria':
      return 'Critères';
    case 'width':
      return 'Largeur';
    case 'height':
      return 'Hauteur';
    case 'diameter':
      return 'Diamètre';
    case 'speedLoadIndex':
      return 'Indice de vitesse et de charge';
    case 'season':
      return 'Saison';
    case 'status':
      return 'Etat';
    case 'disassemblyDate':
      return 'Date de démontage';
    case 'gamme':
      return 'Gamme';
    case 'model':
      return 'Modèle';
    case 'brand':
      return 'Marque';
    case 'familyModel':
      return 'Modèle de famille';
    case 'finish':
      return 'Finition';
    case 'dateFirstCirculation':
      return 'Date de première circulation';
    case 'vehicleColor':
      return 'Couleur du véhicule';
    case 'manufacturerReferences':
      return 'Référence constructeur';
    case 'serialReferences':
      return 'Référence série';
    case 'rimName':
      return 'Nom de la jante';
    case 'referenceReadOnthePart':
      return 'Références lues sur la pièce';
    case 'oeReference':
      return 'Référence OE';
    case 'numberOfHoles':
      return 'Nombre de trous';
    case 'deportET':
      return 'Déport de jante (ET)';
    case 'type':
      return 'Type';
    case 'refSource':
      return 'Source de référence';
    case 'finition':
      return 'Finition';
    case 'typeCG':
      return 'Type de CG';
    case 'energy':
      return 'Énergie';
    case 'dateImmat':
      return "Date d'immatriculation";
    case 'colorReference':
      return 'Référence de couleur';
    case 'comments':
      return 'Commentaires';
    case 'mileage':
      return 'Kilométrage (non garanti)';
    case 'damage':
      return 'Dommages';
    case 'price':
      return 'Prix';
    case 'availability':
      return 'Disponibilité';
    case 'manufacturerType':
      return 'Type de fabricant';
    case 'referencesSerialNumber':
      return 'Autres références';
    case 'manufacturerReference':
      return 'Référence constructeur';
    case 'transmission':
      return 'Transmission';
    case 'gears':
      return 'Vitesses';
    case 'gearboxType':
      return 'Type de boîte de vitesses';
    case 'engineType':
      return 'Type de moteur';
    case 'equipmentReference':
      return 'Référence équipementier';
    case 'cylinderCapacity':
      return 'Cylindrée';
    case 'powerHp':
      return 'Puissance (ch)';
    default:
      return label;
  }
};

const ProductSpecInfo = ({ label = '', value = false, isBlue = false }) => {
  return (
    <>
      {!!value && (
        <div className="flex justify-between pr-[23px] border-b border-dotted border-[#00000054]">
          <p className=" opacity-70 text-[14px] sm:text-[16px]">{label}</p>
          <p
            className={
              (isBlue ? 'text-blue ' : '') +
              'font-[600] text-[14px] sm:text-[16px]'
            }
          >
            {value}
          </p>
        </div>
      )}
    </>
  );
};

const getLabelEnergy = (value) => {
  switch (value) {
    case 'GO':
      return 'Diesel';
      break;
    case 'ES':
      return 'Essence';
      break;
    case 'EL':
      return 'Électrique';
      break;
    case 'EH':
      return 'Hybride non rechargeable (essence)';
      break;
    case 'EG':
      return 'Bicarburation essence-GPL';
      break;
    case 'FE':
      return 'Superéthanol';
      break;
    default:
      return 'NC';
  }
};
/*Product.propTypes = {
    title: PropTypes.string,
    src: PropTypes.string,
    price: PropTypes.number,
    liked: PropTypes.bool,
    id: PropTypes.number
}*/
