import { MdArrowForwardIos } from 'react-icons/md';
import Votrepartenaire from '../../components/Votre-partenaire/Votrepartenaire';
import NotreHistoire from '../../components/Notrehistoire/NotreHistoire';
import Counter from '../../components/Counter/Counter';
import Missions from '../../components/Missions/Missions';
import NosEngagement from '../../components/nos-engagement/NosEngagement';
import RestexInformes from '../../components/restez-informes/RestexInformes';
import { Link } from 'react-router-dom';

const propos = () => {
  return (
    <>
      <div className="wrapper flex flex-col gap-[8px] mb-[40px]">
        <div className="content flex gap-[12px] md:gap-[24px] items-center w-full py-[16px] 1400:px-0 px-[16px]">
          <p className="text-dark cursor-pointer transition">
            <Link to="/">Accueil</Link>
          </p>
          <MdArrowForwardIos className="text-[#9EA5A7] text-[12px]" />
          <p className="text-dark cursor-pointer transition">À propos</p>
        </div>
        <div className="content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[22px] 1400:px-0 px-[16px]">
          <div className="flex-col gap-[12px] lg:gap-[48px] hidden md:flex">
            <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
              À propos de Stop Pièces Auto
            </h1>
            <div className="flex  border-b-2 border-blue w-96 "></div>
          </div>
        </div>
        <div className="content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[22px] 1400:px-0 px-[16px]">
          <Votrepartenaire />
        </div>
      </div>

      <NotreHistoire />

      <Counter />

      <Missions />

      <div className="wrapper flex flex-col gap-[8px] mb-[40px]">
        <NosEngagement />
      </div>
      <RestexInformes />
    </>
  );
};

export default propos;
