const Amount = ({ value, width = 100, precision = 2 }) => (
    <>
        {isNaN(value) ? (
            <>
                <h3 className="h-4 bg-gray-200 rounded-full dark:bg-gray-700" style={{width: width+'px'}}></h3>
            </>
        ) : (
            Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits: precision, 
                minimumFractionDigits: 0,               

            }).format(value)
        )}
    </>
);

export default Amount;
