import { Link, useNavigate } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext';
import Amount from '../../components/commons/Amount/Amount';
import { useWishList } from '../../contexts/WishListContext';
import { TrashIcon } from '@heroicons/react/24/outline';
import {
  getFailoverImage,
  getProductLinkFromProduct,
} from '../../utils/productHelper';

const WishList = () => {
  const { wishListItems, removeFromWishList } = useWishList();
  const { addToCart, isItemInCart, removeFromCart } = useCart();

  const handleAddToCart = (item) => {
    addToCart(item.productId);
  };
  const navigate = useNavigate();

  return (
    <div className="container mx-auto px-4 py-8 max-w-7xl">
      <h1 className="text-3xl font-bold mb-8 text-center">
        Ma Liste de Souhaits
      </h1>
      {wishListItems.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-600 text-lg mb-6">
            Votre liste de souhaits est vide.
          </p>
          <Link
            to="/catalogue"
            className="flex items-center gap-2 px-4 py-2 bg-orange text-white rounded-lg hover:bg-orange/90 transition-colors text-center center"
          >
            <span>Découvrir nos produits</span>
          </Link>
        </div>
      ) : (
        <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {wishListItems.map((item) => (
            <li
              key={item.id}
              className="border rounded-lg p-6 shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="relative group">
                <img
                  onClick={() => navigate(getProductLinkFromProduct(item))}
                  src={
                    item.mainImage
                      ? item.mainImage
                      : '/' + getFailoverImage(item.subjectType, item)
                  }
                  alt={item.title}
                  className="w-full h-56 object-cover rounded-lg mb-4 cursor-pointer transform transition-transform duration-300 group-hover:scale-105"
                />
              </div>
              <h2
                className="text-xl font-semibold mb-3 cursor-pointer hover:text-orange transition-colors"
                onClick={() => navigate(getProductLinkFromProduct(item))}
              >
                {item.title}
              </h2>
              <p className="text-gray-700 text-lg font-medium mb-6">
                <Amount value={item.price} />
              </p>
              <div className="flex justify-between items-center">
                {isItemInCart(item.productId) ? (
                  <button
                    onClick={() => removeFromCart(item.productId)}
                    className="flex-1 mr-4 bg-white text-orange px-4 py-2.5 rounded-lg border-2 border-orange hover:bg-orange hover:text-white transition-colors"
                  >
                    Retirer du panier
                  </button>
                ) : (
                  item.subjectType != 'vehicle' && (
                    <button
                      onClick={() => handleAddToCart(item)}
                      className="flex-1 mr-4 bg-orange text-white px-4 py-2.5 rounded-lg hover:bg-orange-dark transition-colors"
                    >
                      Ajouter au panier
                    </button>
                  )
                )}
                <button
                  onClick={() => removeFromWishList(item)}
                  className="p-2 text-red-500 hover:text-red-700 hover:bg-red-50 rounded-full transition-colors"
                  title="Retirer de ma liste de souhaits"
                >
                  <TrashIcon className="w-6 h-6" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className="text-center mt-12">
        <Link to="/" className="inline-block text-orange hover:underline">
          Retour à l'accueil
        </Link>
      </div>
    </div>
  );
};

export default WishList;
