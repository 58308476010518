function Votrepartenaire() {
  return (
    <div className="grid grid-cols-12">
      <div className="col-span-12 lg:col-span-6">
        <h2 className="font-clash text-[20px] sm:text-[30px] leading-[1] 900:text-[60px] 900:leading-[64px] font-[600] text-dark">
          La référence en pièces d’occasion depuis 1977
        </h2>

        <div className=" text-[18px] md:text-[20px] lg:max-w-[550px] mt-10">
          <p>
            Située à Vourles, dans le Rhône, Stop Pièces Auto est une entreprise
            familiale spécialisée dans la vente de pièces détachées automobiles
            d’occasion. Depuis plus de 45 ans, nous accompagnons les
            automobilistes et les professionnels dans l’entretien et la
            réparation de leurs véhicules en leur proposant des pièces fiables,
            économiques et respectueuses de l’environnement.
          </p>
          <p>
            Fondée en 1977, Stop Pièces Auto s’est imposée comme un acteur
            incontournable du recyclage automobile en France. Notre mission est
            claire : offrir une seconde vie aux pièces détachées pour réduire
            vos coûts tout en minimisant l’impact écologique.
          </p>
        </div>
      </div>
      <div className="col-span-12 lg:col-span-6">
        <div className="flex justify-center mt-10 md:mt-4">
          <img className="w-full" src="/depuis.png" alt="" />
        </div>
      </div>
    </div>
  );
}

export default Votrepartenaire;
