import Tag from './Tag';

export default function ForPartsTag({
  text = 'Pour pièces uniquement',
  as = 'div',
  ...props
}) {
  return (
    <Tag
      text={text}
      as={as}
      color="primary"
      // iconPath="/pictos/for-parts.svg"
      {...props}
    />
  );
}
