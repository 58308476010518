import React, { useState } from 'react';
import RefinementListDropDown from '../RefinementListDropDown/RefinementListDropDown';

const TyreFilter = ({
  width = '205',
  widthValue = null,
  height = '55',
  heightValue = null,
  diameter = 'R16',
  diameterValue = null,
  loadspeed = '91V',
  loadspeedValue = null,
}) => {
  const [activeFilter, setActiveFilter] = useState(null);

  const handleDropdownToggle = (attribute, isOpen) => {
    setActiveFilter(isOpen ? attribute : null);
  };

  const getHighlightClass = (attribute) => {
    if (activeFilter !== attribute)
      return 'opacity-50 transition-all duration-300';
    return 'opacity-100 transition-all duration-300 ring-2 ring-primary ring-offset-2 bg-white bg-opacity-70 text-primary shadow-lg scale-110 z-10';
  };

  return (
    <div className="bg-white p-5 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4 text-gray-800">
        Sélectionnez les dimensions de vos pneus
      </h2>

      <div className="relative mx-auto mb-6 pt-8 md:pt-0">
        <img
          src="/pneu_tafani.png"
          alt="Pneu dimensions"
          className="w-full h-auto"
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="relative w-full h-full flex justify-between items-start">
            <span
              className={`md:absolute md:top-[70px] md:left-[30px] md:-rotate-[65deg] md:-translate-x-1/2 md:-translate-y-1/2 font-bold text-primary text-lg bg-white bg-opacity-50 px-2 py-0 rounded shadow ${getHighlightClass(
                'width',
              )}`}
            >
              {width}
            </span>
            <span
              className={`md:absolute md:top-[35px] md:left-[57px] md:-rotate-[38deg] md:-translate-x-1/2 md:-translate-y-1/2 font-bold text-primary text-lg bg-white bg-opacity-10 px-2 py-0 rounded `}
            >
              /
            </span>
            <span
              className={`md:absolute md:top-[21px] md:left-[53px] md:-rotate-[8deg] md:translate-x-1/2 md:-translate-y-1/2 font-bold text-primary text-lg bg-white bg-opacity-50 px-2 py-0 rounded shadow ${getHighlightClass(
                'height',
              )}`}
            >
              {height}
            </span>
            <span
              className={`md:absolute md:top-[30px] md:right-[17px] md:rotate-[27deg] md:-translate-x-1/2 md:-translate-y-1/2 font-bold text-primary text-lg bg-white bg-opacity-50 px-2 py-0 rounded shadow ${getHighlightClass(
                'diameter',
              )}`}
            >
              {diameter}
            </span>
            <span
              className={`md:absolute md:top-[43px] md:right-[-22px] md:rotate-[67deg] md:-translate-x-1/2 md:translate-y-1/2 font-bold text-primary text-lg bg-white bg-opacity-50 px-2 py-0 rounded shadow ${getHighlightClass(
                'loadspeed',
              )}`}
            >
              {loadspeed}
            </span>
          </div>
        </div>
      </div>

      <div className="space-y-4">
        <RefinementListDropDown
          attribute="width"
          placeholder="Largeur"
          onToggle={(isOpen) => handleDropdownToggle('width', isOpen)}
          currentValue={widthValue}
        />
        <RefinementListDropDown
          attribute="height"
          placeholder="Hauteur"
          onToggle={(isOpen) => handleDropdownToggle('height', isOpen)}
          currentValue={heightValue}
        />
        <RefinementListDropDown
          attribute="diameter"
          placeholder="Diamètre"
          onToggle={(isOpen) => handleDropdownToggle('diameter', isOpen)}
          currentValue={diameterValue}
        />
        <RefinementListDropDown
          attribute="speedLoadIndex"
          placeholder="Charge / Vitesse"
          onToggle={(isOpen) => handleDropdownToggle('loadspeed', isOpen)}
          currentValue={loadspeedValue}
        />
      </div>
    </div>
  );
};

export default TyreFilter;
