import { useRef, useEffect, useState } from 'react';
import { useCart } from '../../../contexts/CartContext';
import Amount from '../Amount/Amount';
import { getProductLinkFromProduct } from '../../../utils/productHelper';
import { NavLink, useNavigate } from 'react-router-dom';
import LittleLoader from '../../Loader/LittleLoader';
import AbsoluteLoader from '../../Loader/AbsoluteLoader';

const CartSwiper = () => {
  const {
    isCartOpen,
    closeCart,
    cartItems,
    cartAddresses,
    removeFromCart,
    totalProductCost,
    cartLoading,
    cartShippingOption,
    totalCartCost,
    getItemFailover,
  } = useCart();

  const cartRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (isCartOpen) {
      setIsVisible(true);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      const timer = setTimeout(() => setIsVisible(false), 300); // Durée de l'animation
      return () => clearTimeout(timer);
    }
  }, [isCartOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cartRef.current && !cartRef.current.contains(event.target)) {
        closeCart();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeCart]);

  if (!isVisible) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm transition-opacity duration-300 ${
        isCartOpen ? 'opacity-100' : 'opacity-0'
      }`}
    >
      <div
        ref={cartRef}
        className={`fixed inset-y-0 right-0 w-full h-full max-w-xs sm:max-w-sm transform transition-transform duration-300 ${
          isCartOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        {cartLoading && <AbsoluteLoader />}
        <div className="h-full overflow-hidden bg-white">
          <div className="flex flex-col h-full">
            <div className="flex-shrink-0 px-4 py-5">
              <div className="flex items-center justify-between">
                <p className="text-base font-bold text-gray-900">Panier</p>

                <button
                  type="button"
                  className="p-2 -m-2 text-gray-500 transition-all duration-200 bg-transparent rounded-md hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  onClick={() => closeCart()}
                >
                  <svg
                    className="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto">
              <div className="px-4 py-2 sm:px-6">
                <div className="flow-root">
                  <ul className="-my-5 divide-y divide-gray-200 divide-dotted">
                    {cartItems.map((item) => (
                      <li key={item.id} className="flex py-5">
                        <div className="flex-shrink-0">
                          <img
                            onClick={() => {
                              closeCart();
                              navigate(
                                getProductLinkFromProduct(item.purchasable),
                              );
                            }}
                            className="object-cover w-16 h-16 rounded-lg cursor-pointer"
                            src={
                              item.purchasable.mainImage
                                ? item.purchasable.mainImage
                                : '/' + getItemFailover(item)
                            }
                            alt=""
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = '/failover.png';
                            }}
                          />
                        </div>

                        <div className="flex items-stretch justify-between flex-1 ml-5 space-x-5">
                          <div className="flex flex-col justify-between flex-1">
                            <p
                              className="text-sm font-bold text-gray-900 cursor-pointer"
                              onClick={() => {
                                closeCart();
                                navigate(
                                  getProductLinkFromProduct(item.purchasable),
                                );
                              }}
                            >
                              {item.purchasable.title}
                            </p>
                            <p className="mt-1.5 text-sm font-medium text-gray-500">
                              Qté : {item.quantity}
                            </p>
                          </div>

                          <div className="flex flex-col items-end justify-between">
                            <p className="flex-shrink-0 w-20 text-sm font-bold text-right text-gray-600">
                              <Amount value={item.purchasable.price} />
                            </p>

                            <button
                              type="button"
                              className="inline-flex p-2 -m-2 text-gray-400 transition-all duration-200 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:text-gray-900"
                              onClick={() => removeFromCart(item.id)}
                            >
                              <svg
                                className="w-5 h-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </li>
                    ))}
                    {!cartLoading && cartItems.length === 0 && (
                      <li className="py-5">
                        {/* meilleur UI centré */}
                        <p className="text-lg font-medium text-gray-500 text-center">
                          Votre panier est vide
                        </p>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <div className="px-4 py-5 border-t border-gray-200 sm:p-6">
              {cartItems.length !== 0 && (
                <>
                  <ul className="space-y-4">
                    <li className="flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-600">
                        Sous-total
                      </p>

                      {cartLoading ? (
                        <LittleLoader className="w-5 h-5" />
                      ) : (
                        <p className="text-sm font-medium text-gray-600">
                          <Amount value={totalProductCost} /> TTC
                        </p>
                      )}
                    </li>

                    <li className="flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-600">
                        Livraison
                      </p>

                      {cartLoading ? (
                        <LittleLoader className="w-5 h-5" />
                      ) : (
                        <>
                          {cartShippingOption ? (
                            <p className="text-sm font-medium text-gray-600">
                              {(cartItems.length > 1 ||
                                cartAddresses?.shipping_address?.country
                                  .iso2 !== 'FR') &&
                              !cartShippingOption.collect ? (
                                'Sur devis'
                              ) : cartShippingOption.price.value === 0 ? (
                                'Gratuit'
                              ) : (
                                <>
                                  <Amount
                                    value={cartShippingOption.price.value / 100}
                                  />{' '}
                                  TTC
                                </>
                              )}
                            </p>
                          ) : (
                            <p className="text-sm font-medium text-gray-600">
                              Calculée à la commande
                            </p>
                          )}
                        </>
                      )}
                    </li>

                    <li className="flex items-center justify-between">
                      <p className="text-sm font-medium text-gray-900">Total</p>

                      {cartLoading ? (
                        <LittleLoader className="w-5 h-5" />
                      ) : (
                        <p className="text-sm font-bold text-gray-900">
                          <Amount value={totalCartCost} /> TTC
                        </p>
                      )}
                    </li>
                  </ul>

                  <div className="mt-5 space-y-3">
                    <NavLink to="/checkout">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center w-full px-6 py-4 text-sm font-bold text-white transition-all duration-200 bg-orange border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-700"
                        onClick={() => closeCart()}
                      >
                        Passer la commande
                      </button>
                    </NavLink>

                    <button
                      type="button"
                      className="inline-flex items-center justify-center w-full px-6 py-4 text-sm font-bold text-gray-900 transition-all duration-200 bg-transparent border-2 border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-200 focus:bg-gray-200"
                      onClick={() => closeCart()}
                    >
                      Continuer les achats
                    </button>
                  </div>
                </>
              )}
              {!cartLoading && cartItems.length === 0 && (
                <div className="mt-5 space-y-3">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center w-full px-6 py-4 text-sm font-bold text-white transition-all duration-200 bg-orange border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-700"
                    onClick={() => closeCart()}
                  >
                    <svg
                      className="w-5 h-5 mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 19l-7-7m0 0l7-7m-7 7h18"
                      />
                    </svg>
                    Retour à la boutique
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartSwiper;
