const missionData = [
  {
    image: '/pictos/Frame367.png',
    title: 'Proximité et écoute',
    description:
      'Chez Stop Pièces Auto, nous ne sommes pas juste un fournisseur. Nous sommes un partenaire, à votre disposition pour vous conseiller, répondre à vos questions et trouver avec vous les solutions adaptées à vos besoins.',
  },
  {
    image: '/pictos/Frame368.png',
    title: 'Qualité et fiabilité',
    description:
      'Nos pièces détachées, neuves ou d’occasion, sont rigoureusement sélectionnées et testées pour garantir une performance optimale. Notre priorité ? Que vous repartiez satisfait et en toute sécurité.',
  },
  {
    image: '/pictos/Frame369.png',
    title: 'Responsabilité et durabilité',
    description:
      'En favorisant le recyclage et la réutilisation des pièces automobiles, nous participons activement à la réduction des déchets et à la préservation des ressources naturelles. En choisissant nos services, vous faites un geste pour la planète.',
  },
];

function Missions() {
  return (
    <div className="wrapper  ">
      <div className="content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[22px] 1400:px-0 px-[16px]">
        <h3 className="font-clash py-5 md:py-0 text-[20px] sm:text-[30px] 900:text-[60px] 900:leading-[64px] font-[600] text-dark">
          Nos missions et valeurs
        </h3>
        <div className="text-[18px] md:text-[25px]">
          <p>
            Depuis 1977, Stop Pièces Auto s’est donné pour mission de rendre
            l’entretien automobile à la fois accessible, économique et
            respectueux de l’environnement. Nous croyons que chaque
            automobiliste mérite des solutions fiables et adaptées, quel que
            soit son budget ou son véhicule.
          </p>
          <p>
            Avec plus de 45 ans d’expérience, nous avons bâti bien plus qu’une
            entreprise : nous avons construit une relation de confiance avec des
            milliers de clients, particuliers et professionnels. Cette confiance
            repose sur des valeurs fortes qui nous animent chaque jour et qui
            nous permettent de rester à vos côtés avec engagement et
            bienveillance.
          </p>
        </div>

        <div className="grid grid-cols-12 gap-6 py-8 md:py-0">
          {missionData.map((mission, index) => (
            <div
              key={index}
              className={`col-span-12 sm:col-span-${
                index === 2 ? '12' : '6'
              } lg:col-span-4 flex justify-center`}
            >
              <div className="p-6 bg-[#EEF3F9] rounded-lg shadow">
                <h5 className="mb-2 text-2xl font-bold tracking-tight">
                  <span className="flex items-center space-x-8">
                    <img src={mission.image} alt="" />
                    <p className="text-[30px]  md:text-[30px]">
                      {mission.title}
                    </p>
                  </span>
                </h5>
                <p className="font-normal">{mission.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Missions;
