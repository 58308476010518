import { useState, useEffect, useRef } from 'react';
import 'intersection-observer'; // Polyfill for IntersectionObserver

function Counter() {
  const [isVisible, setIsVisible] = useState(false);
  const [counters, setCounters] = useState([
    {
      label: 'Années',
      value: 0,
      info: 'd’expérience',
      target: new Date().getFullYear() - 1977,
    },
    { label: 'Salariés', value: 0, info: 'actifs', target: 40 },
    { label: 'Références', value: 0, info: 'en ligne', target: 200 },
    {
      label: 'Véhicules',
      value: 0,
      info: 'traités en ' + (new Date().getFullYear() - 1),
      target: 2,
    },
  ]);

  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.9 }, // Adjust threshold to trigger animation earlier
    );

    observer.observe(sectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    let animationFrameId;

    const updateCounters = () => {
      setCounters((prevCounters) =>
        prevCounters.map((counter) => {
          const increment = getRandomIncrement();
          const newValue = Math.min(counter.value + increment, counter.target); // Ensure the value doesn't exceed the target
          return { ...counter, value: newValue };
        }),
      );

      animationFrameId = requestAnimationFrame(updateCounters);
    };

    if (isVisible) {
      animationFrameId = requestAnimationFrame(updateCounters);
    }

    return () => cancelAnimationFrame(animationFrameId);
  }, [isVisible]);

  const getRandomIncrement = () => Math.floor(Math.random() * 4) + 1;

  return (
    <div className="wrapper py-[22px]" ref={sectionRef}>
      <div className="content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[22px] 1400:px-0 px-[16px]">
        <h3 className="font-clash py-5 md:py-0 text-[20px] sm:text-[30px] leading-[1] 900:text-[60px] 900:leading-[64px] font-[600] text-dark">
          Stop pieces Auto en chiffres
        </h3>
        <div className="grid grid-cols-12 text-center gap-2">
          {counters.map((counter, index) => (
            <div
              key={index}
              className="col-span-12 md:col-span-6 lg:col-span-3"
            >
              <div className="border-2 border-gray rounded-xl py-4 px-2">
                <h3
                  className={`font-clash text-[50px] sm:text-[50px] leading-[1] 900:text-[60px] 900:leading-[64px] font-[600] text-orange counter-value ${
                    index === counters.length - 1 ||
                    index === counters.length - 2
                      ? 'thousand'
                      : ''
                  }`}
                >
                  {counter.value.toLocaleString()}
                  {index === counters.length - 1 ||
                  index === counters.length - 2
                    ? 'k'
                    : ''}
                </h3>
                <p className="font-clash text-[26px] mt-2 sm:text-[26px] leading-[1] 900:text-[45px] 900:leading-[64px] font-[500] text-dark">
                  {counter.label}
                </p>
                <p className="font-clash text-[26px] mt-2 whitespace-nowrap sm:text-[26px] leading-[1] 900:text-[35px]  font-[300] text-dark">
                  {' '}
                  {counter.info}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Counter;
