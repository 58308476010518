import { useState } from 'react';
import { FaArrowRightLong } from 'react-icons/fa6';
import EpaveModal from '../../Modals/EpaveModal';

const ProductDetailsLastSection = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="px-[16px] w-full max-w-[1920px] mb-[30px]">
      <EpaveModal openModal={openModal} setOpenModal={setOpenModal} />
      <div className="w-full grid lg:grid-cols-2 bg-orange 1100:gap-[100px] gap-[20px] lg:gap-[50px] 1500:gap-[130px] rounded-[12px] px-[16px] md:px-[38px] py-[16px] md:py-[35px]">
        <div className="flex items-center">
          <div className="relative">
            <img
              src="/epave-vehicule.png"
              alt="Reprise d'épave de véhicule"
              title="Service de reprise d'épave gratuit"
              className="w-full 1100:max-h-[506px]"
            />
            <img
              src="/logo-blanc.png"
              alt="Logo Stop Pièces Auto en blanc"
              title="Logo Stop Pièces Auto"
              className="max-w-[80px] sm:max-w-[150px] 1100:max-w-[200px] 1500:max-w-[270px] absolute top-[10px] right-[10px] lg:right-[-70px] 1100:right-[-110px] 1500:right-[-150px] lg:top-[-20px]"
            />
          </div>
        </div>
        <div className="text-white flex items-center">
          <div className="flex flex-col gap-[8px] md:gap-[16px] xl:gap-[24px] lg:mt-[50px]">
            <p className=" sm:text-[18px] xl:text-[22px] leading-[30px] opacity-60">
              Votre expert automobile
            </p>
            <h1 className="font-[600] font-clash text-[26px] sm:text-[35px] xl:text-[40px] leading-[30px] sm:leading-[35px] xl:leading-[36px]">
              Faites reprendre gratuitement votre épave dans notre centre auto !
            </h1>
            <div>
              <button
                onClick={() => setOpenModal(true)}
                className="bg-dark hover:opacity-80 text-white px-[24px] py-[12px] sm:py-[16px] center gap-[12px] max-h-[56px] rounded-[8px] mt-[8px] md:mt-[24px]"
              >
                Faire reprendre mon épave <FaArrowRightLong />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsLastSection;
