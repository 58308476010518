import PasswordInput from '../Form/PasswordInput';
import { useEffect, useState } from 'react';
import { register } from '../../utils/auth';
import LittleWhiteLoader from '../Loader/LittleWhiteLoader';
import FormError from '../Form/FormError';
import { translateError } from '../../utils/translatting';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import FormInput from '../CheckoutForm/FormInput';
import Switch from '../CheckoutForm/Switch';

export default function RegisterForm({
  onSuccess = () => {
    window.location.reload();
  },
  closeModal = () => {},
  className = '',
}) {
  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  const [iAmPro, setIAmPro] = useState(false);

  const getRegisterFieldsValidation = (forPro = false) => {
    const toReturn = {
      firstName: Yup.string()
        .max(255, 'Maximum 255 caractères')
        .required('Prénom requis'),
      lastName: Yup.string()
        .max(255, 'Maximum 255 caractères')
        .required('Nom requis'),
      email: Yup.string()
        .max(255, 'Maximum 255 caractères')
        .email('Email non valide')
        .required('Email requis'),
      password: Yup.string()
        .min(8, 'Le mot de passe doit faire minimum 8 caractères')
        .max(255, 'Maximum 255 caractères')
        .required('Mot de passe requis'),
      confirmPassword: Yup.string()
        .required('Confirmation de mot de passe requis')
        .oneOf(
          [Yup.ref('password')],
          'La confirmation de mot de passe est incorrecte',
        ),
    };
    if (forPro) {
      return {
        ...toReturn,
        company: Yup.string().required("Nom de l'entreprise requis"),
        siret: Yup.string().required('SIRET requis'),
        taxNumber: Yup.string().required('Numéro de TVA requis'),
      };
    }
    return toReturn;
  };

  const [registerValidationSchema, setRegisterValidationSchema] = useState(
    Yup.object(getRegisterFieldsValidation(iAmPro)),
  );

  useEffect(() => {
    setRegisterValidationSchema(
      Yup.object(getRegisterFieldsValidation(iAmPro)),
    );
  }, [iAmPro]);

  const handleRegisterSubmit = (values) => {
    setRegisterLoading(true);
    let payload = {
      email: values.email?.trim().length > 0 ? values.email.trim() : null,
      password:
        values.password?.trim().length > 0 ? values.password.trim() : null,
      password_confirmation:
        values.confirmPassword?.trim().length > 0
          ? values.confirmPassword.trim()
          : null,
      first_name:
        values.firstName?.trim().length > 0 ? values.firstName.trim() : null,
      last_name:
        values.lastName?.trim().length > 0 ? values.lastName.trim() : null,
    };
    if (iAmPro) {
      payload = {
        ...payload,
        siret: values.siret?.trim().length > 0 ? values.siret.trim() : null,
        company_name:
          values.company?.trim().length > 0 ? values.company.trim() : null,
        vat_no:
          values.taxNumber?.trim().length > 0 ? values.taxNumber.trim() : null,
      };
    }
    register(payload)
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        setRegisterError(translateError(e));
      })
      .finally(() => {
        setRegisterLoading(false);
      });
  };

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        company: '',
        siret: '',
        taxNumber: '',
      }}
      validationSchema={registerValidationSchema}
      onSubmit={handleRegisterSubmit}
      enableReinitialize={true}
      validateOnChange={true}
    >
      {({ isValid }) => (
        <Form className={className}>
          <div className="flex flex-col gap-4 mb-4">
            <div className="grid-cols-1 sm:grid-cols-2 grid gap-4">
              <FormInput name="firstName" placeholder="Prénom" />
              <FormInput name="lastName" placeholder="Nom" />
            </div>
            <PasswordInput
              name="password"
              className="outline-none h-full w-full"
              placeholder="Mot de passe"
              forFormik
            />
            <PasswordInput
              name="confirmPassword"
              className="outline-none h-full w-full"
              placeholder="Confirmation de mot de passe"
              forFormik
            />
            <FormInput name="email" placeholder="E-mail" />
            {iAmPro && (
              <>
                <FormInput name="company" placeholder="Raison sociale" />
                <div className="grid-cols-1 sm:grid-cols-2 grid gap-4">
                  <FormInput name="siret" placeholder="SIRET" />
                  <FormInput name="taxNumber" placeholder="Numéro de TVA" />
                </div>
              </>
            )}
          </div>
          <Switch
            checked={iAmPro}
            onChange={setIAmPro}
            text="Je suis un professionnel"
          />
          {/* <div className="mt-4 mb-4 flex items-center gap-2">
            <input
              defaultChecked
              type="checkbox"
              id="newsletter"
              className="w-5 h-5 accent-orange"
            />
            <label htmlFor="newsletter" className="font-jost">
              Recevoir notre newsletter
            </label>
          </div> */}
          <div className="mt-1 mb-4 flex items-center gap-2">
            <p className="font-jost">
              En créant un compte vous acceptez les{' '}
              <Link
                to="/cgv-particuliers"
                className="text-orange"
                onClick={closeModal}
              >
                conditions générales
              </Link>{' '}
              et la{' '}
              <Link
                to="/cgv-particuliers"
                className="text-orange"
                onClick={closeModal}
              >
                politique de confidentialité
              </Link>
            </p>
          </div>
          {registerError && <FormError className="mb-2" text={registerError} />}
          <button
            type="submit"
            className={`px-[30px] border
                duration-300 rounded-[8px] py-[16px]
                w-full text-white inline-flex justify-center gap-3 ${
                  !isValid
                    ? 'bg-zinc-500'
                    : 'bg-orange hover:text-orange border-orange hover:bg-transparent'
                }`}
            disabled={registerLoading || !isValid}
          >
            Créer un compte
            {registerLoading && <LittleWhiteLoader className="w-5 h-5" />}
          </button>
        </Form>
      )}
    </Formik>
  );
}
