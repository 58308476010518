export default function Mentions() {
  return (
    <div className="my-12 w-[90%] md:w-[700px] lg:w-[1000px] mx-auto text-justify">
      <h1 className="text-center text-3xl font-bold my-12">Mentions légales</h1>
      <p className="my-5">
        Le site e-commerce est édité par TAFANI AUTOS, spécialisée dans la
        vente, l'achat de véhicules neufs et d'occasion, de pièces détachées, de
        pneumatiques et d'accessoires automobiles.
      </p>
      <p className="my-5">
        <strong>Propriétaire :</strong> TAFANI AUTOS
      </p>
      <p className="my-5">
        <strong>Forme juridique :</strong> SAS
      </p>
      <p className="my-5">
        <strong>Adresse du Siège Social :</strong> 7 RD 386 – Les Sept Chemins –
        69390 VOURLES
      </p>
      <p className="my-5">
        <strong>Mail :</strong>{' '}
        <a
          className="underline text-orange"
          href="mailto:contact@tafaniautos.com"
        >
          contact@tafaniautos.com
        </a>
      </p>
      <p className="my-5">
        <strong>SIREN :</strong> 894 748 490 RCS de Lyon
      </p>
      <p className="my-5">
        <strong>N° TVA intracommunautaire :</strong> FR 81 894 748 490
      </p>
      <p className="my-5">
        <strong>
          Numéro d'identifiant unique emballage auprès de l'ADEME :
        </strong>
        FR329795_01SEQX
      </p>
      <p className="my-5">
        <strong>Directeur de publication :</strong> Monsieur TAFANI Stéphane
      </p>
      <p className="my-5">
        <strong>Hébergeur :</strong> OVH – 2 rue Kellermann – 59100 Roubaix –
        France
      </p>
      <p className="my-5">
        Ce site a été réalisé et est maintenu par l'agence digitale CookieLab.fr
        –{' '}
        <a className="underline text-orange" href="mailto:contact@cookielab.fr">
          contact@cookielab.fr
        </a>
      </p>
      <h2 className="text-2xl font-bold my-5">Propriété intellectuelle</h2>
      <p className="my-5">
        Tous les contenus du site www.tafaniautos.com (textes, images, logos,
        graphismes, etc.) sont protégés par les lois relatives à la propriété
        intellectuelle et sont la propriété exclusive de TAFANI AUTOS, sauf
        mentions contraires. Toute reproduction ou représentation, totale ou
        partielle, de ces éléments, sans autorisation préalable, est interdite.
      </p>

      <h2 className="text-2xl font-bold my-5">Responsabilité</h2>
      <p className="my-5">
        TAFANI AUTOS met tout en œuvre pour offrir des informations et des
        outils de qualité sur ce site, mais ne saurait être tenue responsable de
        toute erreur ou omission concernant ces informations, ni des
        conséquences de leur utilisation.
      </p>

      <h2 className="text-2xl font-bold my-5">
        Collecte de données personnelles
      </h2>
      <p className="my-5">
        Conformément à la loi informatique et libertés, les données personnelles
        collectées sur ce site sont destinées uniquement à la gestion des
        commandes, à la communication et à l'amélioration des services proposés.
        Vous disposez d'un droit d'accès, de rectification et de suppression de
        vos données personnelles en contactant le service client à l'adresse :{' '}
        <a
          className="underline text-orange"
          href="mailto:contact@tafaniautos.com"
        >
          contact@tafaniautos.com
        </a>
      </p>

      <h2 className="text-2xl font-bold my-5">Conditions générales de vente</h2>
      <p className="my-5">
        Les conditions générales de vente (CGV) régissent l'ensemble des ventes
        réalisées sur ce site et sont accessibles à tout moment via un lien
        spécifique.
      </p>
    </div>
  );
}
