import secureLocalStorage from 'react-secure-storage';
import { get, post } from '../components/Api/Api';
// import { logout as reduxLogout } from '../store/Reducers/auth';

export const authStatus = {
  init: 'init',
  loading: 'loading',
  idle: 'idle',
  failed: 'failed',
};

export async function login(email, password) {
  const cartId = secureLocalStorage.getItem('cart_id');
  const header = cartId
    ? { 'cart-id': cartId, 'from-checkout': location.pathname === '/checkout' }
    : {};
  const { token } = await post('login', { email, password }, header).catch(
    (err) => {
      if (err.code === 401) {
        throw new Error('Identifiants incorrects');
      }
      throw new Error("Quelque chose s'est mal passé");
    },
  );
  if (!token) {
    throw new Error("Quelque chose s'est mal passé");
  }
  await secureLocalStorage.setItem('token', token);
  const user = await get('users/me');

  return { token, user };
}

export async function register(payload) {
  if (
    !payload.email ||
    !payload.password ||
    !payload.password_confirmation ||
    !payload.first_name ||
    !payload.last_name
  ) {
    throw new Error('Veuillez remplir tous les champs');
  }
  const cartId = secureLocalStorage.getItem('cart_id');
  const header = cartId
    ? { 'cart-id': cartId, 'from-checkout': location.pathname === '/checkout' }
    : {};
  const { token } = await post('register', payload, header).catch((err) => {
    throw new Error(err.message);
  });
  if (!token) {
    throw new Error('Veuillez vérifier vos informations');
  }
  await secureLocalStorage.setItem('token', token);
  const user = await get('users/me');

  return { token, user };
}

export function logout() {
  // reduxLogout();
  secureLocalStorage.removeItem('cart_id');
  return secureLocalStorage.removeItem('token');
}
