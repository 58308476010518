
import { MdArrowForwardIos } from "react-icons/md";
import Blogs from "../../components/Blogs/Blogs";
import RestexInformes from "../../components/restez-informes/RestexInformes";
import { Link } from "react-router-dom";


const Blog = () => {

    return (
        <>
            <div className="wrapper flex flex-col gap-[8px] mb-[40px]">
                <div className="content flex gap-[12px] md:gap-[24px] items-center w-full py-[16px] 1400:px-0 px-[16px]">
                    <p className="text-dark cursor-pointer transition">
                        <Link to="/">Accueil</Link>
                    </p>
                    <MdArrowForwardIos className="text-[#9EA5A7] text-[12px]" />
                    <p className="text-dark cursor-pointer transition">
                        Blog           
                    </p>

                </div>
                <div className="content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[50px] 1400:px-0 px-[16px]">
                    <div className="flex-col text-center gap-[12px] lg:gap-[48px]  md:flex">
                        <h1 className="font-clash  text-center text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
                            Notre blog
                        </h1>
                        <div className="flex justify-center mt-2">
                            <img src="/pictos/Vector.png" className="w-20 h-10" alt="" />
                        </div>
                    </div>

                </div>
                <div className="content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[22px] 1400:px-0 px-[16px]">
                    <Blogs />
                </div>

            </div>
            <RestexInformes/>
        </>
    )
}

export default Blog





