import { useState } from 'react';

function Général() {
  const [openIndex1, setOpenIndex1] = useState(null);
  const [openIndex2, setOpenIndex2] = useState(null);
  const [openIndex3, setOpenIndex3] = useState(null);
  const [openIndex4, setOpenIndex4] = useState(null);

  const [showMore1, setShowMore1] = useState(false);
  const [showMore2, setShowMore2] = useState(false);
  const [showMore3, setShowMore3] = useState(false);
  const [showMore4, setShowMore4] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  const Général = [
    {
      question: 'Quels types de pièces d’occasion proposez-vous ?',
      answer:
        'Nous proposons des pièces mécaniques, électroniques, de carrosserie et des accessoires pour tous types de véhicules, avec plus de 200 000 références disponibles.',
    },
    {
      question:
        'Comment puis-je être sûr que les pièces sont compatibles avec mon véhicule ?',
      answer:
        'Nous vous invitons à vérifier attentivement la référence indiquée sur la fiche produit. Si vous avez un doute, cliquez sur l\'onglet "Effectuer une demande" ou contactez-nous par email à contact@tafaniautos.com. Nos équipes sont là pour vous aider.',
    },
    {
      question: 'Vos pièces sont-elles garanties ?',
      answer:
        'Oui, toutes nos pièces sont garanties de 6 à 12 mois et passent des contrôles qualité rigoureux avant leur mise en vente.',
    },
    {
      question: 'Livrez-vous dans toute la France ?',
      answer:
        "Oui, nous livrons partout en France métropolitaine, dans les DOM-TOM, dans l'Union Européenne, et à l’étranger. Vous avez plusieurs options : livraison à domicile, en point relais ou retrait en boutique, avec des délais rapides, généralement sous 48 heures.",
    },
    {
      question: 'Quelle est votre politique de retour ?',
      answer:
        "Vous pouvez retourner une pièce sous certaines conditions. Consultez notre politique de retour disponible sur le site ou contactez notre service client pour plus d'informations.",
    },
    {
      question: 'Quels sont les délais de traitement des commandes ?',
      answer:
        'Les commandes sont généralement traitées sous 24 à 48 heures, sauf indication contraire sur le site.',
    },
    {
      question: 'Puis-je visiter un magasin physique pour voir les pièces ?',
      answer:
        'Oui, vous pouvez visiter notre magasin physique situé à Vourles pour voir les pièces disponibles.',
    },
  ];

  const Commande = [
    {
      question: 'Le paiement est-il sécurisé ?',
      answer:
        'Tous vos paiements sont sécurisés grâce à nos partenaires de confiance et aux protocoles de cryptage les plus récents.',
    },
    {
      question:
        'Comment sont calculés les frais de livraison pour plusieurs produits ?',
      answer:
        'Au-delà de deux produits commandés, nous réalisons une estimation manuelle des frais de livraison afin d’optimiser le prix pour vous. Vous serez informé(e) dès que l’estimation est validée.',
    },
    {
      question: 'Comment suivre mon colis ?',
      answer:
        "Une fois votre commande expédiée, vous recevrez un email contenant un numéro de suivi. Ce suivi s'effectue directement sur le site du transporteur sélectionné, vous permettant de consulter en temps réel l'état d'acheminement de votre colis.",
    },
    {
      question: 'Que faire si je ne reçois pas ma commande ?',
      answer:
        'Si votre colis n’arrive pas dans les délais annoncés, contactez notre service client par mail : contact@tafaniautos.com pour vérifier l’état de votre commande et résoudre le problème rapidement.',
    },
    {
      question: 'Quels transporteurs utilisez-vous ?',
      answer:
        "Nous collaborons avec plusieurs transporteurs fiables, comme Colissimo, Chronopost et d'autres selon votre localisation.",
    },
    {
      question: 'Puis-je modifier une commande une fois validée ?',
      answer:
        "Vous pouvez modifier votre commande tant qu'elle n'a pas été expédiée. Contactez rapidement notre service client pour effectuer les modifications nécessaires.",
    },
    {
      question: 'Livrez-vous en point relais ?',
      answer:
        'Oui, nous proposons une livraison en point relais pour plus de flexibilité. Les options disponibles vous seront proposées lors de la commande.',
    },
  ];

  const Compte = [
    {
      question: 'Comment créer mon compte ?',
      answer:
        'La création d’un compte se fait automatiquement lors de votre première commande. Une fois votre commande effectuée, vous recevrez vos identifiants (email et mot de passe) par email.',
    },
    {
      question: 'Comment accéder à mon profil ?',
      answer:
        'Pour accéder à votre profil, cliquez sur l’onglet "Mon compte" en haut à droite de la page. Entrez votre adresse email et votre mot de passe pour vous connecter.',
    },
    {
      question: 'J’ai perdu mon mot de passe, que faire ?',
      answer:
        'Pas de panique ! Cliquez sur "Mot de passe oublié ?" sur la page de connexion. Entrez l’adresse email associée à votre compte, et vous recevrez un lien pour réinitialiser votre mot de passe.',
    },
    {
      question: 'Où trouver l’historique de mes factures ?',
      answer:
        'Le fait de posséder un compte vous permet de retrouver l’ensemble de vos factures, l’historique de vos commandes, ainsi que d’autres informations utiles directement dans votre espace client.',
    },
    {
      question: 'Comment modifier mes informations personnelles ?',
      answer:
        'Pour modifier vos informations personnelles, connectez-vous à votre compte et rendez-vous dans la section "Mon profil".',
    },
    {
      question: 'Puis-je supprimer mon compte ?',
      answer:
        'Oui, vous pouvez demander la suppression de votre compte en contactant notre service client.',
    },
  ];

  const voitures = [
    {
      question: 'Quels types de voitures proposez-vous ?',
      answer:
        "Nous proposons des véhicules d'occasion variés, adaptés à tous les besoins : voitures familiales, citadines, utilitaires, et bien plus.",
    },
    {
      question: 'Quelles sont les garanties associées à une voiture ?',
      answer:
        "Les véhicules vendus bénéficient de garanties variables selon le modèle. Consultez les détails directement sur la fiche produit ou contactez-nous pour plus d'informations.",
    },
    {
      question:
        'Puis-je demander des informations détaillées sur un véhicule ?',
      answer:
        'Oui, vous pouvez demander des informations détaillées sur un véhicule en utilisant notre formulaire de contact ou en en cliquant sur l\'onglet "Effectuer une demande de pièce".',
    },
    {
      question: "Proposez-vous un service d'immatriculation ?",
      answer:
        "Oui, nous proposons un service d'accompagnement pour les démarches d'immatriculation de votre nouveau véhicule.",
    },
    {
      question:
        "Quels sont les modes de paiement acceptés pour l'achat d'un véhicule ?",
      answer:
        'Nous acceptons plusieurs modes de paiement, notamment les cartes bancaires, virements et solutions de financement. Contactez-nous pour les options spécifiques.',
    },
  ];

  const toggleQuestion1 = (index) => {
    setOpenIndex1(index === openIndex1 ? null : index); // Toggle the index
  };

  const toggleQuestion2 = (index) => {
    setOpenIndex2(index === openIndex2 ? null : index); // Toggle the index
  };

  const toggleQuestion3 = (index) => {
    setOpenIndex3(index === openIndex3 ? null : index); // Toggle the index
  };

  const toggleQuestion4 = (index) => {
    setOpenIndex4(index === openIndex4 ? null : index); // Toggle the index
  };

  const toggleShowMore1 = () => {
    setShowMore1(!showMore1);
  };

  const toggleShowMore2 = () => {
    setShowMore2(!showMore2);
  };

  const toggleShowMore3 = () => {
    setShowMore3(!showMore3);
  };

  const toggleShowMore4 = () => {
    setShowMore4(!showMore4);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filterQuestions = (questions) => {
    return questions.filter(
      (item) =>
        item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.answer.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  };

  return (
    <>
      <div className="flex justify-center">
        <div className="w-[1020px]">
          <div className="mb-6 relative">
            <label
              htmlFor="large-input"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Large input
            </label>
            <div className="relative">
              <input
                type="text"
                id="large-input"
                className="block w-full p-4  border-2 border-gray rounded-lg bg-gray-50 text-base"
                placeholder="Posez vos questions"
                onChange={handleSearch}
              />
              <svg
                className=" w-6 h-6 absolute right-3 top-1/2 transform -translate-y-1/2 text-[#0D1F22]"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-12 font-clash gap-4">
        <div className="col-span-12 lg:col-span-6 ">
          <div className="block w-full min-h-[280px] p-6 bg-[#EEF3F9] border-2 border-gray rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold">Général</h5>
            <ul className="text-lg  space-y-4 font-medium">
              {filterQuestions(
                Général.slice(0, showMore1 ? Général.length : 4),
              ).map((item, index) => (
                <div key={index}>
                  <span className="flex items-center space-x-2" key={index}>
                    <img src="/pictos/ar.png" alt="" />
                    <li
                      className="cursor-pointer underline"
                      onClick={() => toggleQuestion1(index)}
                    >
                      {item.question}
                    </li>
                  </span>
                  <div className="ml-5">
                    {openIndex1 === index && (
                      <div className="dropdown ">
                        <p>{item.answer}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </ul>
            {!showMore1 && (
              <div className="flex justify-end mt-auto">
                {' '}
                {/* Added mt-auto to push the span to the bottom */}
                <span
                  className="flex items-center space-x-4 cursor-pointer"
                  onClick={toggleShowMore1}
                >
                  <p>Voir plus</p>
                  <img src="/pictos/bar.png" alt="" />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="col-span-12 lg:col-span-6 ">
          <div className="block w-full min-h-[280px] p-6 bg-[#EEF3F9] border-2 border-gray rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold">Commande & Livraison</h5>
            <ul className="text-lg  space-y-4 font-medium">
              {filterQuestions(
                Commande.slice(0, showMore2 ? Commande.length : 4),
              ).map((item, index) => (
                <div key={index}>
                  <span className="flex items-center space-x-2" key={index}>
                    <img src="/pictos/ar.png" alt="" />
                    <li
                      className="cursor-pointer underline"
                      onClick={() => toggleQuestion2(index)}
                    >
                      {item.question}
                    </li>
                  </span>
                  <div className="ml-5">
                    {openIndex2 === index && (
                      <div className="dropdown ">
                        <p>{item.answer}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </ul>
            {!showMore2 && (
              <div className="flex justify-end mt-auto">
                {' '}
                {/* Added mt-auto to push the span to the bottom */}
                <span
                  className="flex items-center space-x-4 cursor-pointer"
                  onClick={toggleShowMore2}
                >
                  <p>Voir plus</p>
                  <img src="/pictos/bar.png" alt="" />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="col-span-12 lg:col-span-6 ">
          <div className="block w-full min-h-[280px] p-6 bg-[#EEF3F9] border-2 border-gray rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold">Compte</h5>
            <ul className="text-lg  space-y-4 font-medium">
              {filterQuestions(
                Compte.slice(0, showMore3 ? Compte.length : 4),
              ).map((item, index) => (
                <div key={index}>
                  <span className="flex items-center space-x-2" key={index}>
                    <img src="/pictos/ar.png" alt="" />
                    <li
                      className="cursor-pointer underline"
                      onClick={() => toggleQuestion3(index)}
                    >
                      {item.question}
                    </li>
                  </span>
                  <div className="ml-5">
                    {openIndex3 === index && (
                      <div className="dropdown ">
                        <p>{item.answer}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </ul>
            {!showMore3 && (
              <div className="flex justify-end mt-auto">
                {' '}
                {/* Added mt-auto to push the span to the bottom */}
                <span
                  className="flex items-center space-x-4 cursor-pointer"
                  onClick={toggleShowMore3}
                >
                  <p>Voir plus</p>
                  <img src="/pictos/bar.png" alt="" />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="col-span-12 lg:col-span-6 ">
          <div className="block w-full min-h-[280px] p-6 bg-[#EEF3F9] border-2 border-gray rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold">Les voitures</h5>
            <ul className="text-lg  space-y-4 font-medium">
              {filterQuestions(
                voitures.slice(0, showMore4 ? voitures.length : 4),
              ).map((item, index) => (
                <div key={index}>
                  <span className="flex items-center space-x-2" key={index}>
                    <img src="/pictos/ar.png" alt="" />
                    <li
                      className="cursor-pointer underline"
                      onClick={() => toggleQuestion4(index)}
                    >
                      {item.question}
                    </li>
                  </span>
                  <div className="ml-5">
                    {openIndex4 === index && (
                      <div className="dropdown ">
                        <p>{item.answer}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </ul>
            {!showMore4 && (
              <div className="flex justify-end mt-auto">
                {' '}
                {/* Added mt-auto to push the span to the bottom */}
                <span
                  className="flex items-center space-x-4 cursor-pointer"
                  onClick={toggleShowMore4}
                >
                  <p>Voir plus</p>
                  <img src="/pictos/bar.png" alt="" />
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Général;
