export const UNTRACKED_ROUTES = [];

export const DYNAMIC_TITLE_ROUTES = [
  '/vehicles/',
  '/pieces/',
  '/jantes/',
  '/pneus/',
  '/orders/',
];

export const pageTitles = {
  '/catalogue': { title: 'Catalogue - Stop Pièces Auto', option: {} },
  '/reset-password/:token': {
    title: 'Réinitialisation de mot de passe - Stop Pièces Auto',
    option: {},
  },
  '/forgot-password': {
    title: 'Mot de passe oublié - Stop Pièces Auto',
    option: {},
  },
  '/vehicules': { title: 'Véhicules - Stop Pièces Auto', option: {} },
  '/vehicules-accidentes': {
    title: 'Véhicules accidentés - Stop Pièces Auto',
    option: {},
  },
  '/pieces-d-occasion': {
    title: "Pièces d'occasion - Stop Pièces Auto",
    option: {},
  },
  '/jantes-d-occasion': {
    title: "Jantes d'occasion - Stop Pièces Auto",
    option: {},
  },
  '/pneus-d-occasion': {
    title: "Pneus d'occasion - Stop Pièces Auto",
    option: {},
  },
  '/cgv-pros': { title: 'CGV Professionnels - Stop Pièces Auto', option: {} },
  '/cgv-particuliers': {
    title: 'CGV Particuliers - Stop Pièces Auto',
    option: {},
  },
  '/mentions-legales': {
    title: 'Mentions légales - Stop Pièces Auto',
    option: {},
  },
  '/propos': { title: 'A propos - Stop Pièces Auto', option: {} },
  '/blog': { title: 'Blog - Stop Pièces Auto', option: {} },
  '/wishlist': { title: 'Wishlist - Stop Pièces Auto', option: {} },
  '/rse': { title: 'RSE - Stop Pièces Auto', option: {} },
  '/aide': { title: 'Aide - Stop Pièces Auto', option: {} },
  '/checkout': { title: 'Checkout - Stop Pièces Auto', option: {} },
  '/recrutement': { title: 'Recrutement - Stop Pièces Auto', option: {} },
  '/profile/profile-details': {
    title: 'Profil - Stop Pièces Auto',
    option: {},
  },
  '/profile/profile-info': {
    title: 'Informations personnelles - Stop Pièces Auto',
    option: {},
  },
  '/profile/addresses': { title: 'Adresses - Stop Pièces Auto', option: {} },
  '/profile/orders': { title: 'Commandes - Stop Pièces Auto', option: {} },
  '/': { title: 'Stop Pièces Auto - Votre spécialiste automobile', option: {} },
};

export const defaultTitle = 'Stop Pièces Auto - Votre spécialiste automobile';
