const NosEngagements = () => {
    return (
        <>
            <div className="content flex flex-col gap-[40px] lg:gap-[80px] 1400:px-0 px-[16px] lg:py-[40px]">
                <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
                    Nos engagements
                </h1>
                <div className="flex flex-wrap items-center justify-center gap-[12px] sm:gap-[20px] lg:gap-[35px]  xl:gap-[48px]">
                    <div className="w-full 1300:max-w-[603px] px-[10px] gap-[5px] py-[12px] sm:py-[24px] flex-col sm:flex-row flex h-[193px items-center border-[1px] border-[#8080804f] rounded-[12px]">
                        {/* left  */}
                        <div>
                            <img
                                src="/eng1.png"
                                alt=""
                                className="sm:min-w-[105px] max-w-[80px] sm:max-w-[109px]"
                            />
                        </div>
                        {/* Right */}
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex flex-col gap-[4px]">
                                <h3 className="font-clash  font-[600] text-center sm:text-start text-[25px] sm:text-[36px] leading-[32px] text-dark">
                                    Centre VHU agréé
                                </h3>
                                <p className="font-clash font-[300] text-[17px] text-center sm:text-start sm:text-[20px] leading-[18px] text-dark">
                                    N° d’agrément : PR6900054D
                                </p>
                            </div>
                            <p className="text-[14px] font-[700] text-dark text-center sm:text-start leading-[19.6px] opacity-[.8]">
                                Les centres VHU agréés ont {"l'obligation"} de
                                réaliser la dépollution complète du véhicule
                                avant de transmettre les carcasses de véhicule à
                                des broyeurs agréés, qui assurent leurs
                                broyages, puis séparent les matières restantes
                                pour les recycler.
                            </p>
                        </div>
                    </div>
                    <div className="w-full 1300:max-w-[603px] px-[10px] sm:px-[30px] gap-[5px] sm:gap-[30px] py-[12px] sm:py-[28px] flex-col sm:flex-row sm:h-[193px] flex items-center border-[1px] border-[#8080804f] rounded-[12px]">
                        {/* left  */}
                        <div>
                            <img
                                src="/eng2.png"
                                alt=""
                                className="sm:min-w-[80px] max-w-[60px] sm:max-w-[90px]"
                            />
                        </div>
                        {/* Right */}
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex flex-col gap-[4px]">
                                <h3 className="font-clash  font-[600] text-center sm:text-start text-[25px] sm:text-[36px] leading-[32px] text-dark">
                                    Qualicert SGS
                                </h3>
                            </div>
                            <p className="text-[14px] font-[700] text-dark text-center sm:text-start leading-[19.6px] opacity-[.8]">
                                La certification Qualicert permet d’attester que
                                les engagements de services rendus aux clients
                                sont conformes à un référentiel, validé en
                                concertation avec les professionnels, les
                                utilisateurs et les pouvoirs publics.
                            </p>
                        </div>
                    </div>
                    <div className="w-full 1300:max-w-[603px] px-[10px] sm:px-[20px] gap-[5px] sm:gap-[30px] py-[12px] sm:py-[28px] flex-col sm:flex-row sm:h-[183px] flex items-center border-[1px] border-[#8080804f] rounded-[12px]">
                        {/* left  */}
                        <div>
                            <img
                                src="/eng3.png"
                                alt=""
                                className="min-w-[80px] max-w-[80px]"
                            />
                        </div>
                        {/* Right */}
                        <div className="flex flex-col gap-[10px]">
                            <div className="flex flex-col gap-[4px]">
                                <h3 className="font-clash  font-[600] text-center sm:text-start text-[25px] sm:text-[36px] leading-[32px] text-dark">
                                    Made in France
                                </h3>
                            </div>
                            <p className="text-[14px] font-[700] text-dark text-center sm:text-start leading-[19.6px] opacity-[.8]">
                                100% des pièces automobiles provenant de chez
                                stop pièces auto sont produites en France.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="content  flex-col gap-[25px] sm:gap-[40px] py-[10px] sm:py-[50px] 1400:px-0 px-[16px] hidden">
                <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
                    personnages importants
                </h1>

                <div className="w-full grid sm:grid-cols-4 gap-[12px] sm:gap-[15px] xl:gap-[24px]">
                    <div className="border-[1px] sm:col-span-2 1100:col-span-1 border-[#0d1f2229] flex flex-col gap-[12px] sm:gap-[15px] xl:gap-[24px] rounded-[13px] p-[10px] sm:p-[20px] xl:p-[30px]">
                        <div className="flex sm:flex-col items-center sm:items-start gap-[10px] sm:gap-[15px] xl:gap-[24px]">
                            <img src="/pictos/star.png" alt="" className="max-w-[56px] max-h-[56px]" />
                            <h2 className="font-clash font-[600] text-dark text-[24px] sm:text-[36px] leading-[21px] sm:leading-[32px]">
                                40 ans
                            </h2>
                        </div>
                        <p className=" leading-[21px] sm:leading-[22.4px] text-dark opacity-[.8]">
                            Inventaire étendu : Avec plus de 40 ans d’expérience dans l’industrie, nous avons constitué un vaste inventaire de pièces de machines d’occasion.
                        </p>
                    </div>
                    <div className="border-[1px] sm:col-span-2 1100:col-span-1 border-[#0d1f2229] flex flex-col gap-[12px] sm:gap-[15px] xl:gap-[24px] rounded-[13px]  p-[10px] sm:p-[20px] xl:p-[30px]">
                        <div className="flex sm:flex-col items-center sm:items-start gap-[10px] sm:gap-[15px] xl:gap-[24px]">
                            <img src="/pictos/link.png" alt="" className="max-w-[56px] :max-h-[56px]" />
                            <h2 className="font-clash font-[600] text-dark text-[24px] sm:text-[36px] leading-[21px] sm:leading-[32px]">
                                + de 200 000 références
                            </h2>
                        </div>
                        <p className=" leading-[21px] sm:leading-[22.4px] text-dark opacity-[.8]">
                            Notre vaste sélection garantit que vous pouvez trouver exactement ce dont vous avez besoin pour votre machine, quelle que soit la rareté ou la spécificité de la pièce.
                        </p>
                    </div>
                    <div className="border-[1px] sm:col-span-4 1100:col-span-2 border-[#0d1f2229] rounded-[13px]  p-[10px] sm:p-[20px] xl:p-[30px] relative overflow-hidden">
                        <div className="flex flex-col gap-[12px] sm:gap-[15px] xl:gap-[24px] 100:max-w-[440px]">
                            <div className="flex sm:flex-col items-center sm:items-start gap-[10px] sm:gap-[15px] xl:gap-[24px]">
                                <img src="/pictos/lock.png" alt="" className="max-w-[56px] max-h-[56px]" />
                                <h2 className="font-clash font-[600] text-dark text-[24px] sm:text-[36px] leading-[21px] sm:leading-[32px]">
                                    Site 100% sécurisé
                                </h2>
                            </div>
                            <p className=" leading-[21px] sm:leading-[22.4px] text-dark opacity-[.8]">
                                Assurance {"qualité :"} soyez assuré que nos pièces de machines {"d'occasion"} sont soumises à des processus de tests et {"d'inspection"} rigoureux pour garantir leur qualité.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
};

export default NosEngagements;
