function getMonthName(month) {
  const months = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];
  return months[month];
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}
export function dateToDisplay(date) {
  let d = new Date(date);
  return `${d.getDate() >= 10 ? d.getDate() : '0' + d.getDate()}/${
    d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : '0' + (d.getMonth() + 1)
  }/${d.getFullYear()}`;
}
export function longDateToDisplay(date) {
  let d = new Date(date);
  return `${d.getDate()} ${getMonthName(d.getMonth())} ${d.getFullYear()}`;
}
export function numberToDisplay(num) {
  return new Intl.NumberFormat('fr-FR').format(num);
}
