import { useState } from 'react';
import { fetchWithHeaders, get } from '../Api/Api';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import FormTextArea from './FormTextArea';
import * as Yup from 'yup';
import 'yup-phone-lite';
import Switch from './Switch';
import { SHIPPABLE_COUNTRIES } from '../../helpers/shopHelper';

export const disabledCountry = {
  label: 'Sélectionnez un pays',
  value: '',
  disabled: true,
};

export function getCountriesOptions() {
  return fetchWithHeaders(
    process.env.VITE_API_URL +
      'shop/countries?iso2=' +
      SHIPPABLE_COUNTRIES.join(','),
  ).then((data) => {
    return [
      disabledCountry,
      ...data.map((country) => ({
        label: country.native,
        value: country.id,
        iso2: country.iso2,
      })),
    ];
  });
}

export function formatAddressData(data, forShipping = true, forPro = true) {
  const prefix = forShipping ? 'ship_' : 'bill_';
  const toReturn = {
    first_name: data[prefix + 'firstName']?.trim().length
      ? data[prefix + 'firstName'].trim()
      : null,
    last_name: data[prefix + 'lastName']?.trim().length
      ? data[prefix + 'lastName'].trim()
      : null,
    line_one: data[prefix + 'address']?.trim().length
      ? data[prefix + 'address'].trim()
      : null,
    line_two: data[prefix + 'addressLine2']?.trim().length
      ? data[prefix + 'addressLine2'].trim()
      : null,
    city: data[prefix + 'city']?.trim().length
      ? data[prefix + 'city'].trim()
      : null,
    postcode: data[prefix + 'postalCode']?.trim().length
      ? data[prefix + 'postalCode'].trim()
      : null,
    country_id: data[prefix + 'country'] ? data[prefix + 'country'] : null,
    contact_phone: data[prefix + 'phone']?.trim().length
      ? data[prefix + 'phone'].trim()
      : null,
    delivery_instructions:
      forShipping && data.deliveryInstructions?.trim().length
        ? data.deliveryInstructions.trim()
        : null,
  };
  if (forPro) {
    return {
      ...toReturn,
      company_name: data[prefix + 'company']?.trim().length
        ? data[prefix + 'company'].trim()
        : null,
      vat_no: data[prefix + 'taxNumber']?.trim().length
        ? data[prefix + 'taxNumber'].trim()
        : null,
      siret: data[prefix + 'siret']?.trim().length
        ? data[prefix + 'siret'].trim()
        : null,
    };
  }
  return toReturn;
}

export function formatAddressDataFromDBToForm(data, forShipping = true) {
  const prefix = forShipping ? 'ship_' : 'bill_';
  return {
    [prefix + 'firstName']: data.first_name?.trim().length
      ? data.first_name.trim()
      : '',
    [prefix + 'lastName']: data.last_name?.trim().length
      ? data.last_name.trim()
      : '',
    [prefix + 'address']: data.line_one?.trim().length
      ? data.line_one.trim()
      : '',
    [prefix + 'addressLine2']: data.line_two?.trim().length
      ? data.line_two.trim()
      : '',
    [prefix + 'city']: data.city?.trim().length ? data.city.trim() : '',
    [prefix + 'postalCode']: data.postcode?.trim().length
      ? data.postcode.trim()
      : '',
    [prefix + 'country']: data.country_id ? data.country_id : '',
    [prefix + 'phone']: data.contact_phone?.trim().length
      ? data.contact_phone.trim()
      : '',
    [prefix + 'deliveryInstructions']: forShipping
      ? data.delivery_instructions?.trim().length
        ? data.delivery_instructions.trim()
        : ''
      : undefined,
    [prefix + 'company']: data.company_name?.trim().length
      ? data.company_name.trim()
      : '',
    [prefix + 'taxNumber']: data.meta?.vat_no?.trim().length
      ? data.meta.vat_no.trim()
      : '',
    [prefix + 'siret']: data.meta?.siret?.trim().length
      ? data.meta.siret.trim()
      : '',
  };
}

export function getAddressData(data, forShipping = true) {
  const prefix = forShipping ? 'ship_' : 'bill_';
  const toReturn = {
    [prefix + 'firstName']: data[prefix + 'firstName'],
    [prefix + 'lastName']: data[prefix + 'lastName'],
    [prefix + 'address']: data[prefix + 'address'],
    [prefix + 'addressLine2']: data[prefix + 'addressLine2'],
    [prefix + 'company']: data[prefix + 'company'],
    [prefix + 'taxNumber']: data[prefix + 'taxNumber'],
    [prefix + 'siret']: data[prefix + 'siret'],
    [prefix + 'city']: data[prefix + 'city'],
    [prefix + 'postalCode']: data[prefix + 'postalCode'],
    [prefix + 'country']: data[prefix + 'country'],
    [prefix + 'phone']: data[prefix + 'phone'],
  };
  if (!forShipping) {
    return toReturn;
  }
  return {
    ...toReturn,
    deliveryInstructions: data.deliveryInstructions,
  };
}

export function getInitialAddressData(
  defaultCountryValue = '',
  forShipping = true,
  forPro = false,
  customer = null,
  cartAddresses = null,
) {
  const prefix = forShipping ? 'ship_' : 'bill_';
  const cartAddress = cartAddresses
    ? forShipping
      ? cartAddresses.shipping_address
      : cartAddresses.billing_address
    : null;
  let initialValues = {
    [prefix + 'firstName']:
      cartAddress?.first_name || customer?.first_name || '',
    [prefix + 'lastName']: cartAddress?.last_name || customer?.last_name || '',
    [prefix + 'address']: cartAddress?.line_one || '',
    [prefix + 'addressLine2']: cartAddress?.line_two || '',
    [prefix + 'postalCode']: cartAddress?.postcode || '',
    [prefix + 'city']: cartAddress?.city || '',
    [prefix + 'country']: cartAddress?.country_id || defaultCountryValue,
    [prefix + 'phone']: cartAddress?.contact_phone || '',
  };
  if (forShipping) {
    initialValues = {
      ...initialValues,
      deliveryInstructions: '',
    };
  }
  if (forPro) {
    initialValues = {
      ...initialValues,
      [prefix + 'company']: customer?.company_name || '',
      [prefix + 'taxNumber']: customer?.vat_no || '',
      [prefix + 'siret']: customer?.meta?.siret || '',
    };
  }
  return initialValues;
}

export function getAdditionalFieldsSchema(
  countries,
  forShipping = true,
  forPro = false,
) {
  const prefix = forShipping ? 'ship_' : 'bill_';
  let toReturn = {
    [prefix + 'firstName']: Yup.string()
      .max(255, 'Maximum 255 caractères')
      .required('Prénom requis'),
    [prefix + 'lastName']: Yup.string()
      .max(255, 'Maximum 255 caractères')
      .required('Nom requis'),
    [prefix + 'address']: Yup.string()
      .max(255, 'Maximum 255 caractères')
      .required('Addresse requise'),
    [prefix + 'postalCode']: Yup.string()
      .max(255, 'Maximum 255 caractères')
      .required('Code postal requis'),
    [prefix + 'city']: Yup.string()
      .max(255, 'Maximum 255 caractères')
      .required('Ville requise'),
    [prefix + 'country']: Yup.number()
      .positive()
      .integer()
      .required('Pays requis')
      .oneOf(
        countries.map((country) => country.value),
        'Pays requis',
      ),
    [prefix + 'phone']: Yup.string()
      .phone(SHIPPABLE_COUNTRIES, 'Numéro de téléphone invalide')
      // .when(prefix + 'country', {
      //   is: (value) =>
      //     value == countries.find((country) => country.iso2 == 'FR').value,
      //   then: (schema) => schema.phone('FR', 'Numéro de téléphone invalide'),
      //   otherwise: (schema) =>
      //     schema.when(prefix + 'country', {
      //       is: (value) =>
      //         value == countries.find((country) => country.iso2 == 'BE').value,
      //       then: (schema) =>
      //         schema.phone('BE', 'Numéro de téléphone invalide'),
      //       otherwise: (schema) =>
      //         schema.when(prefix + 'country', {
      //           is: (value) =>
      //             value ==
      //             countries.find((country) => country.iso2 == 'CH').value,
      //           then: (schema) =>
      //             schema.phone('CH', 'Numéro de téléphone invalide'),
      //           otherwise: (schema) => schema,
      //         }),
      //     }),
      // })
      .required('Numéro de téléphone requis'),
  };
  if (forShipping) {
    toReturn = {
      ...toReturn,
      deliveryInstructions: Yup.string().max(255, 'Maximum 255 caractères'),
    };
  }
  if (forPro) {
    toReturn = {
      ...toReturn,
      [prefix + 'company']: Yup.string().required("Nom de l'entreprise requis"),
      [prefix + 'taxNumber']: Yup.string().required('Numéro de TVA requis'),
      [prefix + 'siret']: Yup.string().required('Numéro de SIRET requis'),
    };
  }
  return toReturn;
}

export default function AddressForm({
  countries,
  forProState = useState(false),
  forShipping = false,
  showForPro = !forShipping,
  children: additionalInputs = undefined,
}) {
  const [forPro, setForPro] = forProState;
  const prefix = forShipping ? 'ship_' : 'bill_';
  return (
    <>
      <div className="grid-cols-2 smm:grid-cols-1 grid gap-4 mb-2">
        <FormInput name={`${prefix}firstName`} placeholder="Prénom" />
        <FormInput name={`${prefix}lastName`} placeholder="Nom" />
        <FormInput name={`${prefix}address`} placeholder="Adresse" />
        <FormInput
          name={`${prefix}addressLine2`}
          placeholder="Complément d'adresse (optionnel)"
        />
        <FormInput name={`${prefix}postalCode`} placeholder="Code postal" />
        <FormInput name={`${prefix}city`} placeholder="Ville" />
        <FormSelect
          name={`${prefix}country`}
          placeholder="Pays"
          options={countries}
        />
        {forPro && (
          <>
            <FormInput name={`${prefix}company`} placeholder="Société" />
            <FormInput
              name={`${prefix}taxNumber`}
              placeholder="Numéro de TVA"
            />
            <FormInput name={`${prefix}siret`} placeholder="SIRET" />
          </>
        )}
        <FormInput name={`${prefix}phone`} placeholder="Téléphone" />
        {forShipping && (
          <FormTextArea
            name="deliveryInstructions"
            placeholder="Instructions de livraison (optionnel)"
          />
        )}
        {additionalInputs && additionalInputs}
      </div>
      {showForPro && (
        <Switch
          checked={forPro}
          onChange={setForPro}
          text="Je suis un professionnel"
        />
      )}
    </>
  );
}
