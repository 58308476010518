import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
} from '@headlessui/react';
import { VscChromeClose } from 'react-icons/vsc';
import LittleWhiteLoader from '../components/Loader/LittleWhiteLoader';

export default function ConfirmModal({
  showModal,
  setShowModal,
  title,
  onConfirm,
  onCancel = () => {},
  text,
  confirmText = 'Confirmer',
  confirmLoading = false,
  errorMessage = null,
  confirmButtonClassName = '',
}) {
  return (
    <Dialog
      open={showModal}
      as="div"
      className="relative z-30 focus:outline-none"
      onClose={() => {
        onCancel();
        setShowModal(false);
      }}
    >
      <Transition
        appear
        show={showModal}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 z-10 w-screen h-full overflow-y-auto bg-[#0D1F2280]/50 smm:bg-white">
          <div className="flex min-h-full items-center justify-center p-4">
            <button
              className="text-xl hidden text-black z-40 absolute top-4 smm:rounded-xl smm:bg-gray smm:w-12 smm:flex justify-center items-center smm:h-12 right-4"
              onClick={() => {
                onCancel();
                setShowModal(false);
              }}
            >
              <VscChromeClose />
            </button>
            <DialogPanel className="max-w-[980px] rounded-xl bg-white text-black p-6 backdrop-blur-2xl">
              <button
                className="text-xl  text-black smm:hidden z-40 absolute top-4 smm:rounded-xl smm:bg-gray flex justify-center items-center smm:h-12 right-4"
                onClick={() => {
                  onCancel();
                  setShowModal(false);
                }}
              >
                <VscChromeClose />
              </button>
              <DialogTitle as="h3" className="text-[22px] font-semibold">
                {title}
              </DialogTitle>
              <p className="text-[16px] mt-4">{text}</p>
              {errorMessage && <p className="text-red-500">{errorMessage}</p>}
              <div className="flex justify-end gap-4 mt-6">
                <Button
                  onClick={() => {
                    onCancel();
                    setShowModal(false);
                  }}
                  className="bg-slate-100 rounded px-3 py-2"
                >
                  Annuler
                </Button>
                <Button
                  onClick={onConfirm}
                  disabled={confirmLoading}
                  className={`inline-flex gap-2 items-center bg-orange rounded px-3 py-2 text-white ${confirmButtonClassName}`}
                >
                  {confirmText}{' '}
                  {confirmLoading && <LittleWhiteLoader className="h-5 w-5" />}
                </Button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Transition>
    </Dialog>
  );
}
