const PlusDe47 = () => {
  return (
    <div className="wrapper px-[12px] py-[30px]">
      <div className="wrapper bg-blue rounded-[12px]  max-w-[1920px]">
        <div className="w-full grid 1100:grid-cols-2 px-[12px] lg:px-[30px] 1500:px-[72px] gap-[24px] sm:gap-[50px] py-[12px] lg:py-[50px] 1500:py-[80px]">
          <div className="flex flex-col items-start justify-center">
            <div className="flex flex-col gap-[24px] sm:gap-[30px] 1500:gap-[41px] 1500:max-w-[660px]">
              <h1 className="font-clash font-[600] text-[40px] mt-[12px] lg:mt-0 sm:text-[60px] 1500:text-[80px] text-[white] leading-[32px] sm:leading-[50px] 1500:leading-[64px]">
                Plus de {new Date().getFullYear() - 1977} ans <br />
                d'expertise et de passion
              </h1>
              <p className="sm:text-[20px] leading-[21px] sm:leading-[26px] text-white font-[300] font-jost">
                Depuis 1977, Stop Pièces Auto s'impose comme un acteur clé du
                recyclage automobile. Avec près d'un demi-siècle d'expérience,
                nous avons construit une entreprise fondée sur des valeurs
                fortes qui guident chaque aspect de notre activité.
              </p>
              <div className="grid 1400:grid-cols-3 w-full gap-[10px] 1500:gap-[24px]">
                {/* 1 */}
                <div className="mb-2">
                  <div className="flex 1400:flex-col items-center 1400:items-start gap-[12px] sm:gap-[16px]">
                    <img
                      src="/pictos/picto-excellence.png"
                      alt="Icône représentant l'excellence"
                      title="Notre engagement envers l'excellence"
                      className="max-w-[56px] max-h-[56px]"
                    />
                    <p className="font-[600] sm:text-[20px] leading-[1.1] 1500:leading-[26px] text-[white]">
                      Engagement envers l'excellence <br />
                    </p>
                  </div>
                  <span className="font-[400] text-[16px] text-[white]">
                    Offrir des produits fiables et de qualité à nos clients est
                    notre priorité depuis le premier jour.
                  </span>
                </div>
                {/* 2 */}
                <div className="mb-2">
                  <div className="flex 1400:flex-col items-center 1400:items-start gap-[12px] sm:gap-[16px]">
                    <img
                      src="/pictos/picto-environnement.png"
                      alt="Icône représentant l'innovation environnementale"
                      title="Notre engagement pour l'environnement"
                      className="max-w-[56px] max-h-[56px]"
                    />
                    <p className="font-[600] sm:text-[20px] leading-[1.1] 1500:leading-[26px] text-[white]">
                      Innovation au service de l'environnement
                    </p>
                  </div>
                  <span className="font-[400] text-[16px] text-[white]">
                    Nous innovons sans cesse pour intégrer des pratiques
                    durables et minimiser notre impact écologique.
                  </span>
                </div>
                {/* 3 */}
                <div className="mb-2">
                  <div className="flex 1400:flex-col items-center 1400:items-start gap-[12px] sm:gap-[16px]">
                    <img
                      src="/pictos/picto-confiance.png"
                      alt="Icône représentant la confiance"
                      title="Notre engagement de proximité et confiance"
                      className="max-w-[56px] max-h-[56px]"
                    />
                    <p className="font-[600] sm:text-[20px] leading-[1.1] 1500:leading-[26px] text-[white]">
                      Proximité et confiance
                    </p>
                  </div>
                  <span className="font-[400] text-[16px] text-[white]">
                    Nous avons toujours placé nos clients et partenaires au cœur
                    de notre démarche, bâtissant des relations solides et
                    durables.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex 1100:justify-end items-center">
            <div className=" relative">
              <img
                src="/image-equipe-stop-pieces-auto.png"
                alt="Photo de l'entreprise Stop Pièces Auto"
                title="Notre entreprise Stop Pièces Auto"
                className="w-full 1100:max-w-[500px] 1500:max-w-[620px] 1100:max-h-[506px]"
              />
              <img
                src="/logo-blanc.png"
                alt="Logo Stop Pièces Auto en blanc"
                title="Logo Stop Pièces Auto"
                className="max-w-[113px] sm:max-w-[150px] 1100:max-w-[200px] 1500:max-w-[270px] absolute left-[10px] sm:left-[30px] 1100:left-[-100px] 1500:left-[-135px] top-[10px] sm:top-[30px] 1100:top-[-26px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlusDe47;
