import { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa6';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const FaqSection = () => {
  return (
    <div className="content flex gap-[24px] sm:gap-[50px] flex-col lg:flex-row xl:gap-[140px] pt-[40px] 900:pt-[60px] lg:pt-[120px] pb-[20px] lg:pb-[61px] px-[16px] 1400:px-0">
      {/* left  */}
      <div className="min-w-[253px] flex flex-col lg:max-w-[253px]">
        <div className="w-full flex flex-col gap-[24px] lg:gap-[48px]">
          <div className="flex flex-col gap-[12px] lg:gap-[24px]">
            <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
              FAQ
            </h1>
            <p className="text-[20px] leading-[28px] text-dark">
              Vous avez {"d'autres"} questions?
            </p>
          </div>
          <div>
            <Link
              to={'/aide'}
              className="px-[30px] rounded-[8px] py-[16px] sm:w-auto w-full bg-orange text-white"
            >
              Contactez-nous
            </Link>
          </div>
        </div>
      </div>
      {/* right  */}
      <div className="flex flex-col flex-1 gap-[10px] sm:gap-[40px] lg:gap-[64px]">
        {/* Général */}
        <div className="flex flex-col gap-[10px] sm:gap-[24px] w-full">
          {/* heading  */}
          <h2 className="sm:text-[20px] sm:leading-[24px] font-[600] opacity-[.6] text-dark">
            Général
          </h2>
          {/* FAQs  */}
          <div className="flex flex-col w-full">
            <FAQ
              question="Quels types de pièces d’occasion proposez-vous ?"
              borderClasses="border-y-[1px] border-[#00000037]"
              answer="Nous proposons des pièces mécaniques, électroniques, de carrosserie et des accessoires pour tous types de véhicules, avec plus de 200 000 références disponibles."
            />
            <FAQ
              question="Comment puis-je être sûr que les pièces sont compatibles avec mon véhicule ?"
              borderClasses="border-b-[1px] border-[#00000037]"
              answer='Nous vous invitons à vérifier attentivement la référence indiquée sur la fiche produit. Si vous avez un doute, cliquez sur l&apos;onglet "Effectuer une demande" ou contactez-nous par email à contact@tafaniautos.com. Nos équipes sont là pour vous aider.'
            />
            {/* <FAQ
              question="Vos pièces sont-elles garanties ?"
              borderClasses="border-b-[1px] border-[#00000037]"
              answer="Oui, toutes nos pièces sont garanties de 6 à 12 mois et passent des contrôles qualité rigoureux avant leur mise en vente."
            />  */}
            <FAQ
              question="Livrez-vous dans toute la France ?"
              borderClasses="border-b-[1px] border-[#00000037]"
              answer="Oui, nous livrons partout en France métropolitaine, dans les DOM-TOM, dans l'Union Européenne, et à l’étranger. Vous avez plusieurs options : livraison à domicile, en point relais ou retrait en boutique, avec des délais rapides, généralement sous 48 heures."
            />
          </div>
        </div>
        {/* Commande  */}
        <div className="flex flex-col gap-[10px] sm:gap-[24px] w-full">
          {/* heading  */}
          <h2 className="sm:text-[20px] sm:leading-[24px] font-[600] opacity-[.6] text-dark">
            Commande & livraison
          </h2>
          {/* FAQs  */}
          <div className="flex flex-col w-full">
            <FAQ
              question="Le paiement est-il sécurisé ?"
              borderClasses="border-y-[1px] border-[#00000037]"
              answer="Tous vos paiements sont sécurisés grâce à nos partenaires de confiance et aux protocoles de cryptage les plus récents."
            />
            <FAQ
              question="Comment sont calculés les frais de livraison pour plusieurs produits ?"
              borderClasses="border-b-[1px] border-[#00000037]"
              answer="Au-delà de deux produits commandés, nous réalisons une estimation manuelle des frais de livraison afin d’optimiser le prix pour vous. Vous serez informé(e) dès que l’estimation est validée."
            />
            <FAQ
              question="Comment suivre mon colis ?"
              borderClasses="border-b-[1px] border-[#00000037]"
              answer="Une fois votre commande expédiée, vous recevrez un email contenant un numéro de suivi. Ce suivi s'effectue directement sur le site du transporteur sélectionné, vous permettant de consulter en temps réel l'état d'acheminement de votre colis."
            />
            <FAQ
              question="Que faire si je ne reçois pas ma commande ?"
              borderClasses="border-b-[1px] border-[#00000037]"
              answer="Si votre colis n’arrive pas dans les délais annoncés, contactez notre service client par mail : contact@tafaniautos.com pour vérifier l’état de votre commande et résoudre le problème rapidement."
            />
          </div>
        </div>
        {/* Compte  */}
        <div className="flex flex-col gap-[10px] sm:gap-[24px] w-full">
          {/* heading  */}
          <h2 className="sm:text-[20px] sm:leading-[24px] font-[600] opacity-[.6] text-dark">
            Compte
          </h2>
          {/* FAQs  */}
          <div className="flex flex-col w-full">
            <FAQ
              question="Comment créer mon compte ?"
              borderClasses="border-y-[1px] border-[#00000037]"
              answer="Pour le moment, la création d’un compte se fait automatiquement lors de votre première commande. Une fois votre commande effectuée, vous recevrez vos identifiants (email et mot de passe) par email. Vous pourrez ensuite utiliser ces informations pour accéder à votre espace client."
            />
            <FAQ
              question="Comment accéder à mon profil ?"
              borderClasses="border-b-[1px] border-[#00000037]"
              answer='Pour accéder à votre profil, cliquez sur l’onglet "Mon compte" en haut à droite de la page. Entrez votre adresse email et votre mot de passe pour vous connecter. Une fois connecté(e), vous aurez accès à vos informations personnelles, vos commandes, et vos préférences.'
            />
            <FAQ
              question="J’ai perdu mon mot de passe ?"
              borderClasses="border-b-[1px] border-[#00000037]"
              answer='Pas de panique ! Cliquez sur "Mot de passe oublié" sur la page de connexion. Entrez l’adresse email associée à votre compte, et vous recevrez un lien pour réinitialiser votre mot de passe. Suivez les instructions dans l’email pour créer un nouveau mot de passe.'
            />
            {/* <FAQ
              question="Où trouver l’historique de mes factures ?"
              borderClasses="border-b-[1px] border-[#00000037]"
              answer='Le fait de posséder un compte vous permet de retrouver l’ensemble de vos factures, l’historique de vos commandes, ainsi que d’autres informations utiles directement dans votre espace client. Connectez-vous à votre compte et rendez-vous dans la section "Historique et détails des commandes" pour accéder à vos factures.'
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqSection;

const FAQ = ({ question = '', answer = '', borderClasses = '' }) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`flex flex-col w-full py-[10px] lg:py-[16px] ${borderClasses}`}
    >
      {/* question  */}
      <div
        onClick={() => setActive((prev) => !prev)}
        className="  flex items-center justify-between cursor-pointer select-none"
      >
        <h3 className="font-[600] sm:text-[18px] lg:text-[24px] leading-[19px] sm:leading-[28px] text-dark">
          {question}
        </h3>
        <div className="min-w-[40px] flex justify-end">
          {active ? <FaMinus /> : <FaPlus />}
        </div>
      </div>
      {/* answer  */}
      <div
        style={{
          maxHeight: active ? 'none' : '0px',
          transition: '1s ease all',
        }}
        className="w-full overflow-hidden"
      >
        <div className="py-[10px] lg:py-[20px] lg:text-[18px] leading-[22px] lg:leading-[25px] text-dark 1400:max-w-[90%] select-none">
          {answer}
        </div>
      </div>
    </div>
  );
};

FAQ.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string,
  borderClasses: PropTypes.string,
};
