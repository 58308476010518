const filterMarques = () => (
    <div className="flex md:flex-col gap-[12px] md:gap-[24px]">
        <div className="flex flex-1 flex-col gap-[8px]">
            <p className="font-[600] leading-[20px] text-dark hidden md:flex">
                Marque
            </p>
            <div className="flex-1 relative center">
                <select className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]">
                    <option value="">Toutes</option>
                    <option value="Marque">Marque</option>
                </select>
                <img
                    src="/chevron-down.svg"
                    alt=""
                    className="absolute right-[20px] w-full max-w-[24px] pointer-events-none"
                />
            </div>
        </div>
    </div>
);

const filterModeles = () => (
    <div className="flex flex-col gap-[8px]">
        <p className="font-[600] leading-[20px] text-dark hidden md:flex">
            Modèle
        </p>
        <div className="flex-1 relative center">
            <select className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]">
                <option value="">Tous</option>
                <option value="Modèle">Modèle</option>
            </select>
            <img
                src="/chevron-down.svg"
                alt=""
                className="absolute right-[20px] w-full max-w-[24px] pointer-events-none"
            />
        </div>
    </div>
);

const filterEnergie = () => (
    <div className="flex md:flex-col gap-[12px] md:gap-[24px]">
        <div className="flex flex-1 flex-col gap-[8px]">
            <p className="font-[600] leading-[20px] text-dark hidden md:flex">
                Énergie
            </p>
            <select className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]">
                <option value="">Toutes</option>
                <option value="Diesel">Diesel</option>
                <option value="Essence">Essence</option>
            </select>
            <img
                src="/chevron-down.svg"
                alt=""
                className="absolute right-[20px] w-full max-w-[24px] pointer-events-none"
            />
        </div>
    </div>
);
const filterProcedure = () => (
    <div className="flex flex-col gap-[8px]">
        <p className="font-[600] leading-[20px] text-dark hidden md:flex">
            Procédure
        </p>
        <div className="flex-1 relative center">
            <select className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]">
                <option value="">Toutes</option>
                <option value="part">Vente aux particuliers</option>
                <option value="pro">
                    Vente aux professionnels ou à l'export
                </option>
                <option value="pieces">Pour pièces</option>
            </select>
            <img
                src="/chevron-down.svg"
                alt=""
                className="absolute right-[20px] w-full max-w-[24px] pointer-events-none"
            />
        </div>
    </div>
);
const filterPrix = () => (
    <div className="flex flex-col gap-[8px]">
        <p className="font-[600] leading-[20px] text-dark">Prix</p>
        <div className="flex-1 relative flex gap-[12px] items-center justify-between center">
            <input
                type="number"
                placeholder="à partir de €"
                step="50"
                className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]"
            />
            <input
                type="number"
                placeholder="jusqu'à €"
                step="50"
                className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]"
            />
        </div>
    </div>
);
const filterAnnee = () => (
    <div className="flex flex-col gap-[8px]">
        <p className="font-[600] leading-[20px] text-dark">
            Année de mise en circulation
        </p>
        <div className="flex-1 relative flex gap-[12px] items-center justify-between center">
            <input
                type="number"
                placeholder="Min"
                className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]"
            />
            <input
                type="number"
                placeholder="Max"
                className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]"
            />
        </div>
    </div>
);
const filterDimensions = () => (
    <div className="flex flex-col gap-[8px]">
        <p className="font-[600] leading-[20px] text-dark">Dimensions</p>
        <div className="flex-1 relative flex gap-[12px] items-center justify-between center">
            <input
                type="number"
                placeholder="Largeur"
                step="50"
                className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]"
            />
            <input
                type="number"
                placeholder="Hauteur"
                step="50"
                className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]"
            />
            <input
                type="number"
                placeholder="Diamètre"
                step="50"
                className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]"
            />
        </div>
    </div>
);

const filterTypePiece = () => (
    <div className="flex flex-col gap-[8px]">
        <p className="font-[600] leading-[20px] text-dark">Type de pièce</p>
        <div className="flex-1 relative center">
            <select className="px-[12px] 900:px-[24px] py-[15px] 900:py-[16px] hide-select-arrow w-full border-[1px] border-[#00000058] outline-none rounded-[8px] text-[#00000088]">
                <option value="">Toutes</option>
                <option value="Moteur">Moteur</option>
                <option value="Boîte de vitesse">Boîte de vitesse</option>
                <option value="Carrosserie">Carrosserie</option>
                <option value="Pièces électriques">Pièces électriques</option>
            </select>
            <img
                src="/chevron-down.svg"
                alt=""
                className="absolute right-[20px] w-full max-w-[24px] pointer-events-none"
            />
        </div>
    </div>
);

export {
    filterMarques,
    filterModeles,
    filterEnergie,
    filterProcedure,
    filterPrix,
    filterAnnee,
    filterDimensions,
    filterTypePiece,
};
