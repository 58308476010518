import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineMail } from 'react-icons/md';
import { useState } from 'react';
import EpaveModal from '../../Modals/EpaveModal';

const Footer = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="wrapper px-[12px] pb-[16px]">
      <div className="wrapper bg-dark rounded-[12px] max-w-[1920px] center relative">
        {/* for PC  */}
        <div className="w-full px-[16px] sm:px-[20px] flex-col lg:px-[30px] flex 1500:px-[72px] gap-[20px] sm:gap-[20px] pt-[24px] z-[2] lg:pt-[50px] 1500:pt-[60px] pb-[24px] sm:pb-[32px]">
          <div className="w-full flex flex-col gap-[20px] 1400:gap-[0px] 1400:flex-row  flex-wrap">
            {/* first Col  */}
            <div className="w-full max-w-[250px] flex flex-col gap-[24px] sm:gap-[48px]">
              <img
                src="/footer-logo.png"
                alt=""
                className="max-w-[125px] sm:max-w-[137px]"
              />
              <div>
                <p className="text-[14px] leading-[19px] text-[#ffffff91]">
                  7, RD 386 - Les 7 chemins 69390 <br /> Vourles (Brignais-Sud)
                </p>
                <p className="text-[14px] leading-[19px] text-[#ffffff91] mt-[16px]">
                  Horaires boutique
                </p>
                <p className="text-[14px] leading-[19px] text-[#ffffff91] ">
                  Lundi - Jeudi : 8h30-12h00, 14h00-18h15
                </p>
                <p className="text-[14px] leading-[19px] text-[#ffffff91] ">
                  Vendredi : 8h30-12h00, 14h00-18h30
                </p>
                <p className="text-[14px] leading-[19px] flex gap-[7px] items-center text-[#ffffff91] mt-[3px]">
                  <MdOutlineMail className="text-[26px]" />{' '}
                  <a
                    href="mailto:contact@tafaniautos.com"
                    className="hover:text-[#f60] hover:underline"
                  >
                    contact@tafaniautos.com
                  </a>
                </p>
              </div>
            </div>
            {/* Second Col  */}
            <div className="flex w-full flex-col 1400:flex-row max-w-[880px] gap-[20px] 1400:gap-[60px] 1400:ml-[150px]">
              <div className="flex flex-col gap-[12px] 1400:gap-[27px]">
                <h1 className="text-[20px] leading-[28px] font-[500] text-[white]">
                  Catalogue
                </h1>
                <div className="space-y-[7px] 1400:space-y-[16px]">
                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link
                      to={'/pieces-d-occasion'}
                      onClick={(e) => {
                        e.preventDefault();
                        // navigate('pieces-d-occasion');
                        window.location.replace('pieces-d-occasion');
                      }}
                      className="flex"
                    >
                      {' '}
                      Pièces d'occasion
                    </Link>
                  </p>

                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link
                      to={'/jantes-d-occasion'}
                      onClick={(e) => {
                        e.preventDefault();
                        // navigate('jantes-d-occasion');
                        window.location.replace('jantes-d-occasion');
                      }}
                      className="flex"
                    >
                      {' '}
                      Jantes d'occasion
                    </Link>
                  </p>

                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link
                      to={'/pneus-d-occasion'}
                      onClick={(e) => {
                        e.preventDefault();
                        // navigate('pneus-d-occasion');
                        window.location.replace('pneus-d-occasion');
                      }}
                      className="flex"
                    >
                      {' '}
                      Pneus d'occasion
                    </Link>
                  </p>
                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link
                      to={'/vehicules-d-occasion'}
                      onClick={(e) => {
                        e.preventDefault();
                        // navigate('vehicules-d-occasion');
                        window.location.replace('vehicules-d-occasion');
                      }}
                      className="flex"
                    >
                      {' '}
                      Voitures d’occasion
                    </Link>
                  </p>
                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link
                      to={'/vehicules-accidentes'}
                      onClick={(e) => {
                        e.preventDefault();
                        // navigate('vehicules-accidentes');
                        window.location.replace('vehicules-accidentes');
                      }}
                      className="flex"
                    >
                      Voitures accidentées
                    </Link>
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-[12px] 1400:gap-[27px]">
                <h1 className="text-[20px] leading-[28px] font-[500] text-[white]">
                  Informations
                </h1>
                <div className="space-y-[7px] 1400:space-y-[16px]">
                  {/*<p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link
                      to={'/blog'}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('blog');
                      }}
                      className="flex"
                    >
                      {' '}
                      Blog
                    </Link>
                  </p>*/}
                  {/* <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link
                      to={'/recrutement'}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('recrutement');
                      }}
                      className="hflex"
                    >
                      Recrutement
                    </Link>
                  </p> */}
                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link
                      to={'/rse'}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate('rse');
                      }}
                      className="flex"
                    >
                      RSE
                    </Link>
                  </p>
                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <EpaveModal
                      openModal={openModal}
                      setOpenModal={setOpenModal}
                    />
                    <span
                      onClick={() => {
                        setOpenModal(true);
                      }}
                      className="flex cursor-pointer"
                    >
                      Reprise d'épave
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-[12px] 1400:gap-[27px]">
                <h1 className="text-[20px] leading-[28px] font-[500] text-[white]">
                  Besoin d’aide
                </h1>
                <div className="space-y-[7px] 1400:space-y-[16px]">
                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link to={'/aide'} className="flex">
                      {' '}
                      Aide & Contact
                    </Link>
                  </p>
                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link to={'/cgv-particuliers'} className="flex">
                      Conditions générales de vente - Particulier
                    </Link>
                  </p>
                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link to={'/cgv-pros'} className="flex">
                      Conditions générales de vente - Professionnel
                    </Link>
                  </p>
                  <p className="text-[16px] leading-[22px] text-[#ffffff91] ">
                    <Link to={'/mentions-legales'} className="flex">
                      Mentions légales
                    </Link>
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-[12px] 1400:gap-[27px]">
                <h1 className="text-[20px] leading-[28px] font-[500] text-[white]">
                  Certifications
                </h1>
                <div className="space-y-[7px] 1400:space-y-[16px]">
                  <div className="flex sm:gap-[12px]">
                    <div className="flex flex-col gap-[10px]">
                      <img
                        src="/centre_vhu.png"
                        alt=""
                        className="max-w-[120px] sm:max-w-[134px] max-h-[120px] sm:max-h-[130px]"
                      />
                      <p className="text-[10px] leading-[22px] text-[#ffffff91] max-w-[120px] sm:max-w-[134px] max-h-[120px] sm:max-h-[130px] center">
                        N° d’agrément : PR6900054D
                      </p>
                    </div>
                    <div className="flex flex-col gap-[10px]">
                      <img
                        src="/qualicert.png"
                        alt=""
                        className="max-w-[120px] sm:max-w-[130px] center"
                      />
                      <p className="text-[10px] leading-[22px] text-[#ffffff91] max-w-[120px] sm:max-w-[134px] max-h-[120px] sm:max-h-[130px] center">
                        <Link
                          to={'https://www.qualicert.fr/'}
                          target="_blank"
                          className="flex text-[#f60] underline hover:text-[#f60]/90 hover:no-underline transition-colors"
                        >
                          https://www.qualicert.fr/
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full sm:items-center sm:justify-between flex flex-col gap-[12px] sm:flex-row">
            <div className="flex gap-[20px]">
              <img
                src="/pictos/Instagram.png"
                alt=""
                className="max-w-[20px] max-h-[20px] cursor-pointer"
              />
              <img
                src="/pictos/Facebook.png"
                alt=""
                className="max-w-[20px] max-h-[20px] cursor-pointer"
              />
              {/*<img src="/pictos/TwitterX.png" alt="" className="max-w-[20px] max-h-[20px] cursor-pointer" />*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
