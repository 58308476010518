export const productTypesForBrandsRequest = {
  vehicleUsed: 'vehicles_used',
  vehicleAccidented: 'vehicles_accidented',
  tyre: 'tyres',
  rim: 'rims',
  part: 'parts',
};

export const getProductLinkFromProduct = (product) => {
  const routeByType = getRouteByType(product.subjectType);

  const { refSource, slug } = product;

  //uppercase slug
  if (slug && typeof slug === 'string') {
    return `/${routeByType}/${refSource}-${slug.toUpperCase()}`;
  }
  return `/${routeByType}/${refSource}`;
};

export const getRouteByType = (type) => {
  const correspondance = {
    vehicle: 'vehicules',
    tyre: 'pneus',
    rim: 'jantes',
    part: 'pieces',
  };

  return correspondance[type];
};

export const getFailoverImage = (type, product) => {
  switch (type) {
    case 'tyre':
    case 'tyres':
    case 'pneus':
      if (product.partCode == 3801) {
        return 'failover-double-tyres.png';
      }
      return 'failover-tyres.png';
    case 'jantes':
    case 'rims':
    case 'rim':
      return 'failover-rims.png';
    case 'pieces':
    case 'part':
    case 'parts':
      return 'failover-parts.png';
    default:
      return 'failover.png';
  }
};

export function translateFilterLabelToFrench(value) {
  switch (value) {
    case 'GO':
      return 'Diesel';
    case 'FE':
      return 'Superéthanol';
    case 'ES':
      return 'Essence';
    case 'EL':
      return 'Electrique';
    case 'EH':
      return 'Hybride Essence';
    case 'EG':
      return 'Bicarburation essence-GPL';
    case 'used':
      return 'Occasion';
    case 'accidented':
      return 'Accidenté';
    case 'vehicle':
      return 'Véhicules';
    case 'rim':
      return 'Jantes';
    case 'part':
      return 'Pièces détachées';
    case 'tyre':
      return 'Pneus';
    case '1':
      return 'Oui';
    default:
      return value;
  }
}

export function translateCatalogueLabelToFrench(label) {
  // Implement your translation logic here
  // Return the translated label
  switch (label) {
    case 'partCode':
      return 'Code de la pièce';
    case 'partLabel':
      return 'Article';
    case 'manufacturer':
      return 'Fabricant';
    case 'designation':
      return 'Désignation';
    case 'criteria':
      return 'Critères';
    case 'width':
      return 'Largeur';
    case 'height':
      return 'Hauteur';
    case 'diameter':
      return 'Diamètre';
    case 'speedLoadIndex':
      return 'Indice de vitesse et de charge';
    case 'season':
      return 'Saison';
    case 'status':
      return 'Etat';
    case 'disassemblyDate':
      return 'Date de démontage';
    case 'gamme':
      return 'Gamme';
    case 'model':
      return 'Modèle';
    case 'globalModel':
      return 'Modèle';
    case 'globalType':
      return "Type d'article";
    case 'brand':
      return 'Marque';
    case 'familyModel':
      return 'Famille de modèle';
    case 'finish':
      return 'Finition';
    case 'dateFirstCirculation':
      return 'Date de première circulation';
    case 'vehicleColor':
      return 'Couleur du véhicule';
    case 'manufacturerReferences':
      return 'Référence constructeur';
    case 'serialReferences':
      return 'Référence série';
    case 'oeReference':
      return 'Référence OE';
    case 'numberOfHoles':
      return 'Nombre de trous';
    case 'deportET':
      return 'Déport de jante (ET)';
    case 'type':
      return 'Type';
    case 'refSource':
      return 'Source de référence';
    case 'finition':
      return 'Finition';
    case 'typeCG':
      return 'Type de CG';
    case 'energy':
      return 'Motorisation';
    case 'dateImmat':
      return "Date d'immatriculation";
    case 'colorReference':
      return 'Référence de couleur';
    case 'comments':
      return 'Commentaires';
    case 'mileage':
      return 'Kilométrage';
    case 'damage':
      return 'Dommages';
    case 'price':
      return 'Prix';
    case 'availability':
      return 'Disponibilité';
    case 'manufacturerType':
      return 'Type de fabricant';
    case 'referencesSerialNumber':
      return 'Autres références';
    case 'manufacturerReference':
      return 'Référence constructeur';
    case 'transmission':
      return 'Transmission';
    case 'gears':
      return 'Vitesses';
    case 'gearboxType':
      return 'Type de boîte de vitesses';
    case 'engineType':
      return 'Type de moteur';
    case 'equipmentReference':
      return 'Référence équipementier';
    case 'cylinderCapacity':
      return 'Cylindrée';
    case 'powerHp':
      return 'Puissance (ch)';
    case 'is2Wheels':
      return '2 roues';
    default:
      return label;
  }
}
