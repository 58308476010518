import { Link } from 'react-router-dom';
import { GoChevronRight } from 'react-icons/go';
const BreadBrumbs = (props) => {
  return (
    <div className=" flex flex-wrap gap-2 items-center">
      <Link className="text-dark" to="/">
        Accueil
      </Link>
      <span className="text-[#9EA5A7]">
        <GoChevronRight />
      </span>
      {props.link1path ? (
        <Link
          className={`${props.link2 === '' ? 'text-[#9EA5A7]' : 'text-dark'}`}
          to={props.link1path}
        >
          {props.link1}
        </Link>
      ) : (
        <span className="text-[#9EA5A7]">{props.link1}</span>
      )}
      {props?.link2 ? (
        <span className="text-[#9EA5A7]">
          <GoChevronRight />
        </span>
      ) : (
        ''
      )}
      <span className="text-[#9EA5A7]">{props.link2}</span>
    </div>
  );
};

export default BreadBrumbs;
