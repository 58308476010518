import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import {
  pageTitles,
  DYNAMIC_TITLE_ROUTES,
  UNTRACKED_ROUTES,
  defaultTitle,
} from '../helpers/pageConfig';
export default function GoogleAnalytics() {
  const { pathname } = useLocation();
  const previousPath = useRef(null);
  const previousTitle = useRef(null);
  const isMounted = useRef(false);

  useEffect(() => {
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.VITE_GOOGLE_ANALYTICS_ID
    ) {
      ReactGA.initialize(process.env.VITE_GOOGLE_ANALYTICS_ID);
      isMounted.current = true;
      return () => {
        isMounted.current = false;
      };
    }
  }, []);

  useEffect(() => {
    if (
      !isMounted.current ||
      previousPath.current === pathname ||
      UNTRACKED_ROUTES.includes(pathname) ||
      !process.env.VITE_GOOGLE_ANALYTICS_ID ||
      process.env.NODE_ENV !== 'production'
    ) {
      return;
    }

    const trackPage = () => {
      let mutationObserverTimeout;
      let trackingAttempts = 0;
      const MAX_ATTEMPTS = 3;

      if (isMounted.current) {
        const mutationObserver = new MutationObserver((mutations) => {
          if (previousTitle.current === document.title) {
            return;
          }
          if (trackingAttempts >= MAX_ATTEMPTS) {
            mutationObserver.disconnect();
            return;
          }

          trackingAttempts++;
          clearTimeout(mutationObserverTimeout);

          ReactGA.send({
            hitType: 'pageview',
            page: pathname,
            title: document.title,
          });

          mutationObserver.disconnect();
        });

        if (mutationObserverTimeout) {
          clearTimeout(mutationObserverTimeout);
        }

        const titleElement = document.querySelector('title');
        if (titleElement) {
          if (
            DYNAMIC_TITLE_ROUTES.some((route) => pathname.startsWith(route))
          ) {
            mutationObserver.observe(titleElement, {
              childList: true,
            });
          } else {
            mutationObserverTimeout = setTimeout(() => {
              mutationObserver.disconnect();
              if (isMounted.current) {
                const title =
                  pathname === '/'
                    ? defaultTitle
                    : pageTitles[
                        Object.keys(pageTitles).find((key) =>
                          pathname.startsWith(key),
                        )
                      ]?.title || document.title;
                const options =
                  pathname === '/'
                    ? {} // home options
                    : pageTitles[
                        Object.keys(pageTitles).find((key) =>
                          pathname.startsWith(key),
                        )
                      ]?.option || {};

                ReactGA.send({
                  hitType: 'pageview',
                  page: pathname,
                  title: title,
                  ...options,
                });
              }
            }, 300);
          }
        }
      }
    };

    requestAnimationFrame(trackPage);
    previousPath.current = pathname;
    previousTitle.current = document.title;

    return () => {
      previousPath.current = null;
      previousTitle.current = null;
    };
  }, [pathname]);

  return null;
}
