export default function Tag({
  text,
  color,
  iconPath = null,
  as = 'div',
  className = '',
  ...props
}) {
  const As = as;
  const colors = {
    primary: {
      container: 'bg-primary',
      text: 'text-white',
    },
    'primary-outline': {
      container: 'bg-white border-[2px] border-orange',
      text: 'text-white',
    },
    secondary: {
      container: 'bg-secondary',
      text: 'text-primary',
    },
  };
  return (
    <As
      className={`inline-flex gap-[5px] xl:gap-[8px] px-[5px] xl:px-[8px] items-center h-[32px] rounded-[15px] ${colors[color].container} ${className}`}
      {...props}
    >
      <p className={`font-[700] text-[14px] text-white ${colors[color].text}`}>
        {text}
      </p>
      {iconPath && (
        <img src={iconPath} alt="" className="h-[24px] text-white" />
      )}
    </As>
  );
}
