import CGVTemplate from '../../components/CGV/CGVTemplate';

export default function CGVPro() {
  return (
    <CGVTemplate title="Conditions générales de vente des biens à des professionnels">
      <p className="my-5">
        Les présentes conditions générales de vente s’appliquent à toutes
        commandes passées sur le site internet www.stopieces-auto.fr géré par
        l‘Entreprise Tafani Autos, Entreprise en nom propre, ayant son siège
        social 7 RD 386 – Les Sept Chemins à Vourles (69390), immatriculée au
        RCS de LYON sous le numéro 894 748 490. Le vendeur a pour activité la
        vente de pièces automobiles d’occasion et neuves.
      </p>
      <p className="my-5">
        Tafani Autos présente également sur son site, des véhicules d’occasion
        et des véhicules accidentés destinés aux professionnels, sans
        possibilité toutefois de les commander en ligne. Les présentes
        conditions générales de vente s’appliquent aux ventes en lignes conclues
        entre le vendeur, Tafani Autos et un acheteur professionnel.
      </p>
      <h2 className="text-2xl font-bold my-5">Article 1 – Intégralité</h2>
      <p className="my-5">
        1.1 Les présentes conditions générales expriment l'intégralité des
        obligations des parties. En ce sens, l'acheteur est réputé les accepter
        sans réserve.
      </p>
      <p className="my-5">
        1.2 Le vendeur et l'acheteur conviennent que les présentes conditions
        générales régissent exclusivement leur relation, quel que soit les
        clauses pouvant figurer sur les documents du client et notamment ses
        conditions générales d'achats. Le vendeur se réserve le droit de
        modifier ponctuellement ses conditions générales. Elles seront
        applicables dès leur mise en ligne.
      </p>
      <p className="my-5">
        1.3 Si une condition de vente venait à faire défaut, elle serait
        considérée être régie par les usages en vigueur dans le secteur de la
        vente à distance dont les sociétés ont siège en France.
      </p>
      <h2 className="text-2xl font-bold my-5">Article 2 – Objet</h2>
      <p className="my-5">
        2.1 Les présentes conditions générales ont pour objet de définir les
        droits et obligations des parties dans le cadre de la vente en ligne des
        biens proposés par le vendeur à l'acheteur.
      </p>
      <p className="my-5">
        2.2 Les présentes conditions ne concernent que les achats effectués par
        les acheteurs situés en France et livrés exclusivement en France
        métropolitaine. Pour toute livraison hors de France métropolitaine ou à
        des acheteurs situés hors de France, il convient de le signaler pour
        obtenir un devis spécifique.
      </p>
      <h2 className="text-2xl font-bold my-5">Article 3 – La commande</h2>
      <p className="my-5">
        3.1 L'acheteur passe sa commande en ligne, à partir du catalogue en
        ligne et au moyen du formulaire qui figure sur le site.
      </p>
      <p className="my-5">
        3.2 Pour que la commande soit validée, l'acheteur devra accepter, en
        cliquant à l'endroit indiqué, les présentes conditions générales. Toutes
        clauses restrictives de la part de l'acheteur, non expressément
        acceptées par le vendeur, ne nous sont pas opposables. La validation de
        la commande vaut donc acceptation sans restriction ni réserve des
        présentes conditions générales de vente.
      </p>
      <p className="my-5">
        3.3 Afin de réaliser la Commande, le Client devra obligatoirement suivre
        les étapes suivantes :
      </p>
      <ul className="list-disc ml-8 mb-2">
        <li>Suivre les instructions du Site ;</li>
        <li>
          Pour les clients titulaire d'un Compte Client, suivre les instructions
          nécessaires à l'ouverture de leur compte client ;
        </li>
        <li>
          Remplir le formulaire de commande. En cas d'inactivité prolongée lors
          de la connexion, il est possible que la sélection des Biens choisis
          par le client avant cette inactivité ne soit plus garantie. Le client
          est alors invité à reprendre sa sélection de biens depuis le début ;
        </li>
        <li>
          Vérifier les éléments de la Commande et le cas échéant, identifier et
          corriger les erreurs ;
        </li>
        <li>
          Valider la Commande, le Prix Total ainsi que le Prix Tout Compris (la
          « Validation de la Commande ») ;
        </li>
        <li>
          Suivre les instructions du serveur de paiement en ligne pour payer le
          Prix Tout Compris (comprenant le prix de la marchandise, le transport
          et la TVA).
        </li>
      </ul>
      <p className="my-5">
        Pour les clients titulaires d'un Compte Client, le paiement du Prix Tout
        Compris sera réalisé conformément aux modalités convenues lors de
        l'ouverture du compte et une facture leur sera adressée.
      </p>
      <p className="my-5">
        Pour les autres clients, le paiement se fait exclusivement par carte
        bancaire ou par virement.
      </p>
      <p className="my-5">3.4 Confirmation de la commande :</p>
      <p className="my-5">
        Le client reçoit par voie électronique et sans délai un accusé réception
        valant confirmation de la commande (la « Confirmation de la Commande »).
      </p>
      <p className="my-5">
        La Livraison aura lieu à l'adresse de livraison indiquée par le client
        lors de la commande.
      </p>
      <p className="my-5">
        3.5 Toute commande vaut acceptation des prix et descriptions des
        produits disponibles à la vente.
      </p>
      <p className="my-5">
        3.6 Dans certains cas, notamment défaut de paiement, adresse erronée ou
        autre problème sur le compte de l'acheteur, le vendeur se réserve le
        droit de bloquer la commande de l'acheteur jusqu'à la résolution du
        problème.
      </p>
      <p className="my-5">
        3.7 En cas d'indisponibilité d'un produit commandé, l'acheteur en sera
        informé par courrier électronique.
      </p>
      <p className="my-5">
        3.8 L'annulation de la commande de ce produit et son éventuel
        remboursement seront alors effectués, le reste de la commande demeurant
        ferme et définitif.
      </p>
      <p className="my-5">
        3.9 Pour toute question relative au suivi d'une commande, l'acheteur
        doit contacter le vendeur aux numéros de téléphone suivant : 04 72 31 32
        3 2 ou 04 72 31 32 30, du lundi au vendredi de 8 H 45 à 12 H et de 14 H
        à 18 H 15, (coût d'un appel local) et transmettre son numéro de
        commande, ou par mail :{' '}
        <a
          className="underline text-orange"
          href="mailto:commandes@tafaniautos.com"
        >
          commandes@tafaniautos.com
        </a>
      </p>
      <h2 className="text-2xl font-bold my-5">
        Article 4 – Signature électronique
      </h2>
      <p className="my-5">
        4.1 La fourniture en ligne des coordonnées bancaires de l'acheteur et la
        validation finale de la commande vaudront preuve de l'accord de
        l'acheteur conformément aux dispositions de la loi du 13 mars 2000 et
        vaudront :
      </p>
      <ul className="list-disc ml-8 mb-2">
        <li>exigibilité des sommes dues au titre du bon de commande ;</li>
        <li>
          signature et acception expresse de toutes les opérations effectuées.
        </li>
      </ul>
      <p className="my-5">
        4.2 En cas d'utilisation frauduleuse des coordonnées bancaires,
        l'acheteur est invité, dès le constat de cette utilisation, à contacter
        le vendeur en appelant aux numéros de téléphone suivant : 04 72 31 32 3
        2 ou 04 72 31 32 30, du lundi au vendredi de 8 H 45 à 12 H et de 14 H à
        18 H 15, (coût d'un appel local) et transmettre son numéro de commande,
        ou par mail :{' '}
        <a
          className="underline text-orange"
          href="mailto:commandes@tafaniautos.com"
        >
          commandes@tafaniautos.com
        </a>
      </p>
      <h2 className="text-2xl font-bold my-5">
        Article 5 – Preuve de la transaction
      </h2>
      <p className="my-5">
        Les registres informatisés, conservés dans les systèmes informatiques du
        vendeur dans des conditions raisonnables de sécurité, seront considérés
        comme les preuves des communications, des commandes et des paiements
        intervenus entre les parties. L'archivage des mails, des bons de
        commande et des factures est effectué sur un support fiable et durable
        pouvant être produit à titre de preuve.
      </p>
      <h2 className="text-2xl font-bold my-5">
        Article 6 – Informations sur les produits
      </h2>
      <p className="my-5">
        6.1 Les produits régis par les présentes conditions générales sont ceux
        qui figurent sur le site internet du vendeur et qui sont proposés à la
        vente par le vendeur. Ils sont proposés dans la limite des stocks
        disponibles.
      </p>
      <p className="my-5">
        6.2 Les produits sont décrits et présentés avec la plus grande
        exactitude possible. Toutefois si des erreurs ou omissions ont pu se
        produire quant à cette présentation, la responsabilité du vendeur ne
        pourrait être engagée.
      </p>
      <p className="my-5">
        6.3 Pour certains produits le vendeur est contraint d'utiliser des
        marques déposées par les fabricants. Ces dernières ne le sont qu'à titre
        de référence nécessaire pour qualifier la destination du produit. En
        aucun cas, cette utilisation peut entraîner une confusion sur leur
        origine. Il s'agit uniquement pour le vendeur de désigner le véhicule
        sur lequel ces pièces s'adaptent, conformément à l'article L-713-6B du
        code de la propriété intellectuelle. Cette utilisation est parfaitement
        admise.
      </p>
      <p className="my-5">
        6.4 Sauf mention particulière, les pièces détachées neuves vendues sont
        des pièces neuves génériques, adaptables (au sens retenu par le
        constructeur). Toute référence au véhicule, à la marque et/ou au modèle
        attestent de la faisabilité technique de la pose de ladite sur le
        véhicule.
      </p>
      <p className="my-5">
        6.5 Certains équipements spécifiques et protections tubulaires (pare
        chocs, pare buffle…) ainsi que certains feux, optique ou rétroviseurs ne
        sont pas encore homologués Europe et sont en conséquences réservés à un
        usage en dehors des voies publiques ou à l'export.
      </p>
      <h2 className="text-2xl font-bold my-5">
        Article 7 – Prix des biens et conditions de validité
      </h2>
      <p className="my-5">
        7.1 Les prix des biens vendus sur le site internet sont indiqués par
        article et référence et sont exprimés en Euros HT. Ils ne tiennent pas
        compte de la TVA et des frais de livraison facturés en supplément. Le
        prix TTC est indiqué au niveau du panier du client.
      </p>
      <p className="my-5">
        Les prix TTC tiennent compte de la TVA applicable au jour de la commande
        et tout changement du taux de TVA applicable, sera automatiquement
        répercuté sur le prix des produits de la boutique en ligne. Le paiement
        de la totalité du prix doit être réalisé lors de la commande. A aucun
        moment, les sommes versées ne pourront être considérées comme des arrhes
        ou des acomptes.
      </p>
      <p className="my-5">
        7.2 Au moment de la validation de la commande, le prix à payer s'entend
        du Prix Tout Compris (comprenant le prix des marchandises, les frais de
        transport, la TVA, remises déduites). Les frais de télécommunication
        inhérents à l'accès au site restent à la charge exclusive du client.
      </p>
      <p className="my-5">
        7.3 Les tarifs sont basés sur les conditions du jour et suivant le stock
        disponible. Le vendeur se réserve le droit de modifier ses prix à tout
        moment mais s'engage à appliquer les tarifs en vigueur indiqués au
        moment de la commande, sous réserve de disponibilité à cette date.
      </p>
      <p className="my-5">
        7.4 Si une ou plusieurs taxes ou contributions, notamment
        environnementales, venaient à être créées ou modifiées, en hausse comme
        en baisse, ce changement pourra être répercuté sur le prix de vente des
        produits.
      </p>
      <h2 className="text-2xl font-bold my-5">Article 8 – Paiement</h2>
      <p className="my-5">
        8.1 Le paiement du Prix Tout Compris en cas de paiement par carte
        bancaire:
      </p>
      <p className="my-5">
        Le paiement doit être joint à la commande, en totalité, au jour de la
        passation de la commande par l'acheteur. Le paiement du Prix Tout
        Compris se fait uniquement par carte bancaire.
      </p>
      <p className="my-5">
        Les cartes bancaires acceptées sont celles des réseaux Carte Bleue,
        Visa, Eurocard/Mastercard et celles précisées par le fournisseur sur le
        site internet rubrique « modes de paiement ».
      </p>
      <p className="my-5">
        La transaction est immédiatement débitée sur la carte du client après
        vérification des données de celle-ci, à réception de l'autorisation de
        débit de la part de la société émettrice de la carte bancaire utilisée
        par le client.
      </p>
      <p className="my-5">
        Conformément à l'article L. 132-2 du Code monétaire et financier,
        l'engagement de payer donné au moyen d'une carte de paiement est
        irrévocable. En communiquant les informations relatives à sa carte
        bancaire, le Client autorise le Vendeur à débiter sa carte bancaire du
        montant correspondant au Prix Tout Compris.
      </p>
      <p className="my-5">
        À cette fin, le Client confirme qu'il est le titulaire de la carte
        bancaire à débiter et que le nom figurant sur la carte bancaire est
        effectivement le sien. Le Client communique les seize chiffres et la
        date d'expiration de sa carte bleue ainsi que le cas échéant, les
        numéros du cryptogramme visuel. Dans le cas où le débit du Prix Tout
        Compris serait impossible, la Vente en Ligne serait immédiatement
        résolue de plein droit et la Commande serait annulée.
      </p>
      <p className="my-5">
        8.2 Le paiement du Prix Tout Compris en cas d'ouverture par l'acheteur
        d'un Compte Client :
      </p>
      <p className="my-5">
        8.2.1 Lorsque l'acheteur a ouvert un Compte Client, le Prix Tout Compris
        est payable selon les modalités convenues lors de l'ouverture du compte.
        Une facture sera adressée à l'acheteur avec un délai de règlement fixé à
        30 jours, fin de mois, date de facture.
      </p>
      <p className="my-5">
        Les paiements effectués par l'acheteur ne seront considérés comme
        définitifs qu'après encaissement effectif des sommes dues par le
        vendeur.
      </p>
      <p className="my-5">
        8.2.2 En cas de non-paiement de nos factures au plus tard dans les 30
        jours à compter de la livraison et sans mise en demeure préalable, une
        pénalité sera due par le client de 10% du montant de la créance impayée,
        sans toutefois être inférieure au montant qui résulterait de
        l'application d'un taux égal à une fois et demie le taux de l'intérêt
        légal, en vigueur à la date considérée.
      </p>
      <p className="my-5">
        De convention expresse et sauf report sollicité à temps et accordé par
        nous, le défaut de paiement de l'une de nos factures à échéance fixée
        entraînera l'exigibilité immédiate de toutes les sommes restant dues,
        quel que soit le mode de règlement prévu.
      </p>
      <p className="my-5">
        8.3 Le vendeur se réserve le droit de refuser d'effectuer une livraison
        ou d'honorer une commande émanant d'un acheteur qui n'aurait pas réglé
        totalement ou partiellement une commande précédente ou avec lequel un
        litige de paiement serait en cours d'administration.
      </p>
      <p className="my-5">
        8.4 Les paiements électroniques effectués dans le cadre des transactions
        réalisées sur le site internet du vendeur sont sécurisés par
        l'utilisation du procédé de cryptage TLS. Le vendeur utilise pour
        lesdits paiements électroniques sécurisés la solution MERCANET fournie
        par la BANQUE BNP PARIBAS.
      </p>
      <h2 className="text-2xl font-bold my-5">
        Article 9 – Disponibilité des produits
      </h2>
      <p className="my-5">
        9.1 Sauf en cas de force majeure ou lors des périodes de fermeture
        clairement annoncées sur la page d'accueil du site, les délais
        d'expédition seront, dans la limite des stocks disponibles, ceux
        indiqués sur le site internet au moment de la commande.
      </p>
      <p className="my-5">
        9.2 Retards de livraison liés à l'indisponibilité d'un produit :
      </p>
      <p className="my-5">
        La plupart des articles proposés sur le site sont en stock. Toutefois,
        certains d'entre eux peuvent nécessiter un délai de livraison pour
        répondre soit à une rupture de stock, un défaut de livraison du
        fournisseur… Tafani Autos ne peut être tenue pour responsable quelle que
        soit la durée du délai de livraison et aucune indemnité de retard ne
        pourra être exigée.
      </p>
      <p className="my-5">
        En cas de retard de livraison lié à l'indisponibilité du produit
        commandé, la commande n'est pas annulée. Le vendeur informe le client
        par courrier électronique que la livraison interviendra avec du retard.
        Le client pourra alors décider d'annuler la commande et enverra par
        courrier électronique à l'adresse suivante{' '}
        <a
          className="underline text-orange"
          href="mailto:commandes@tafaniautos.com"
        >
          commandes@tafaniautos.com
        </a>{' '}
        un avis d'annulation de la commande.
      </p>
      <p className="my-5">
        Dans le cas où la commande n'a pas encore été expédiée lors de la
        réception par le vendeur de l'avis d'annulation du client, la livraison
        est bloquée et le client est remboursé des sommes éventuellement
        débitées dans un délai de quinze jours suivant la réception de l'avis
        d'annulation.
      </p>
      <h2 className="text-2xl font-bold my-5">
        Article 10 – Modalités de livraison
      </h2>
      <p className="my-5">10.1 Mode de livraison</p>
      <p className="my-5">
        Les produits acquis par l'acheteur sont livrés en France métropolitaine.
        Le client choisit l'un des modes de livraison proposés sur le site à
        l'occasion de la réalisation de la Commande. Les produits commandés
        peuvent également être retirés au magasin.
      </p>
      <p className="my-5">10.2 Adresse de livraison</p>
      <p className="my-5">
        Le client choisit une adresse de livraison nécessairement située en
        France métropolitaine, sous peine de refus de la commande. Le client est
        seul responsable d'un défaut de livraison dû à un manque d'indication
        lors de la commande.
      </p>
      <p className="my-5">10.3 Montant des Frais de Livraison</p>
      <p className="my-5">
        Le montant des Frais de Livraison dépend de la nature du produit
        commandé et du mode de livraison choisi par le client. En tout état de
        cause, le montant des frais de livraison est indiqué au client avant la
        validation de la commande.
      </p>
      <p className="my-5">10.4 Délais de livraison</p>
      <p className="my-5">
        Les Délais de Livraison sont disponibles sur le Site et peuvent varier
        en fonction de la disponibilité des Biens ayant fait l'objet de la
        Commande. Les Délais de Livraison s'entendent en jours ouvrés et
        correspondent aux délais moyens de préparation et d'acheminement de la
        Commande en France métropolitaine. Les Délais de Livraison courent à
        compter de la date de Confirmation de la Commande par le Vendeur. Les
        délais de livraison sont donnés à titre indicatif et les retards, même
        en l'absence de cas de force majeure, ne peuvent entraîner ni
        dommages-intérêts à la charge du vendeur, ni annulation de commandes.
      </p>
      <p className="my-5">
        Les livraisons sont assurées par un transporteur indépendant choisit par
        le vendeur.
      </p>
      <p className="my-5">
        Les livraisons sont donc soumises aux conditions contractuelles de
        chaque transporteur.
      </p>
      <p className="my-5">
        La livraison est réputée effectuée dès la remise des pièces commandées
        par le vendeur au transporteur dès lors qu'il a remis les marchandises
        vendues au transporteur qui les a acceptées sans réserves. L'acheteur
        reconnaît donc que c'est au transporteur qu'il appartient d'effectuer la
        livraison et ne dispose d'aucun recours en garantie contre le vendeur.
        Toutes les marchandises sont transportées aux risques et périls du
        destinataire. Le délai d'acheminement ou la perte éventuelle est sous la
        seule responsabilité du transporteur.
      </p>
      <p className="my-5">10.5 Suivi de la livraison</p>
      <p className="my-5">
        Le client peut suivre l'état d'avancement du traitement de la commande
        dans l'espace réservé à cet effet sur le site.
      </p>
      <p className="my-5">
        Les produits sont livrés à l'adresse indiquée par l'acheteur sur le bon
        de commande, l'acheteur devra veiller à son exactitude. Tout colis
        renvoyé au vendeur à cause d'une adresse de livraison erronée ou
        incomplète sera réexpédié aux frais de l'acheteur. L'acheteur peut, à sa
        demande, obtenir l'envoi d'une facture à l'adresse de facturation et non
        à l'adresse de livraison, en validant l'option prévue à cet effet sur le
        bon de commande.
      </p>
      <p className="my-5">
        10.6 Transfert de propriété – transfert des risques
      </p>
      <p className="my-5">
        Nous nous réservons la propriété des marchandises jusqu'à complet
        paiement, conformément aux dispositions des lois du 12 MAI 1980 et du 25
        JANVIER, et ce quelle que soit la date de livraison desdits produits.
      </p>
      <p className="my-5">
        Le transfert des risques de perte et de détérioration des produits sera
        en revanche réalisé dès acceptation de la commande par le vendeur,
        matérialisant l'accord des parties sur la chose et sur le prix et ce
        quelle que soit la date du paiement et de la livraison. Les produits
        voyagent donc aux risques et périls de l'acheteur qui pourra, en cas
        d'avaries, formuler toutes réclamations auprès du transporteur dans les
        conditions légales et réglementaires en vigueur.
      </p>
      <p className="my-5">10.7 Vérification de la commande à son arrivée</p>
      <p className="my-5">
        Le client est tenu de vérifier l'état de l'emballage ainsi que les biens
        lors de la livraison.
      </p>
      <p className="my-5">
        Les marchandises voyagent toujours aux risques et périls du
        destinataire, même en cas d'expéditions franco de port, de contre
        remboursement ou de retour.
      </p>
      <p className="my-5">
        Toute réclamation, pour être valable, devant, en tout état de cause,
        être formulée dans les délais légaux au transporteur.
      </p>
      <p className="my-5">
        Si au moment de la livraison, l'emballage d'origine est abîmé, déchiré,
        ouvert, l'acheteur doit alors vérifier l'état des articles.
      </p>
      <p className="my-5">
        Les éléments de carrosserie tels que les portes, capots, ailes, hayons,
        phares, feux, doivent impérativement être ouverts et contrôlés en
        présence du livreur. S'ils ont été endommagés, l'acheteur doit
        impérativement refuser le colis et noter une réserve sur le bordereau de
        transport (colis refusé car ouvert ou contenu endommagé).
      </p>
      <p className="my-5">
        L'acheteur doit indiquer sur le bon de transport et sous forme de
        réserves manuscrites accompagnées de sa signature toute anomalie
        concernant la livraison (avarie, produit manquant par rapport au bon de
        transport, colis endommagé, produits cassés...). Cette vérification est
        considérée comme effectuée dès lors que l'acheteur, ou une personne
        autorisée par lui, a signé le bon de transport.
      </p>
      <p className="my-5">
        L'acheteur devra confirmer par courrier recommandé ses réserves au
        transporteur au plus tard dans les trois jours ouvrables suivant la
        réception du ou des articles et transmettre une copie de ce courrier par
        fax ou simple courrier au vendeur à l'adresse indiquée dans les mentions
        légales du site.
      </p>
      <p className="my-5">
        Si les produits nécessitent d'être renvoyés au vendeur, ils doivent
        faire l'objet d'une demande de retour auprès du vendeur dans les 15
        jours suivant la livraison et dans les conditions énoncées ci-dessous
        (articles 11.2 à 11.5 des présentes conditions générales de vente).
        Toute réclamation formulée hors de ce délai ne pourra être acceptée. Le
        retour du produit ne pourra être accepté que pour les produits dans leur
        état d'origine, complet, intact et en parfait état de revente
        (emballage, accessoires, notice…).
      </p>
      <h2 className="text-2xl font-bold my-5">
        Article 11 – Erreurs de livraison
      </h2>
      <p className="my-5">
        11.1 L'acheteur devra formuler auprès du vendeur le jour même de la
        livraison ou au plus tard dans les 15 jours suivant la livraison, toute
        réclamation d'erreur de livraison et/ou de non-conformité des produits
        en nature ou en qualité par rapport aux indications figurant sur le bon
        de commande. Toute réclamation formulée au-delà de ce délai sera
        rejetée.
      </p>
      <p className="my-5">
        11.2 La réclamation pourra être faite, au choix de l'acheteur :
      </p>
      <p className="my-5">Par téléphone au 04 72 31 32 32 ou 04 72 31 32 30</p>
      <p className="my-5">
        Ou par mail :{' '}
        <a
          className="underline text-orange"
          href="mailto:commandes@tafaniautos.com"
        >
          commandes@tafaniautos.com
        </a>
      </p>
      <p className="my-5">
        11.3 Toute réclamation non effectuée dans les règles définies ci-dessus
        et dans les délais impartis ne pourra être prise en compte et dégagera
        le vendeur de toute responsabilité vis-à-vis de l'acheteur.
      </p>
      <p className="my-5">
        11.4 A réception de la réclamation, le vendeur attribuera un numéro de
        retour du ou des produit(s) concerné(s) et le communiquera par e-mail à
        l'acheteur. L'échange et le retour d'un produit ne peut avoir lieu
        qu'après l'attribution du numéro de retour.
      </p>
      <p className="my-5">
        L'acheteur utilisera pour le retour des marchandises, l'étiquette de
        retour que lui fera parvenir le vendeur soit par voie postal ou par
        mail, et indiquera sur cette étiquette de retour, le numéro de retour
        attribué par le vendeur.
      </p>
      <p className="my-5">
        NE JAMAIS RENVOYER EN RETOUR UN PRODUIT SANS AVOIR OBTENU DE NOTRE
        SERVICE UN NUMERO DE RETOUR
      </p>
      <p className="my-5">
        11.5 Le produit devra être retourné au vendeur entier, non démonté,
        intact, en parfait état de revente et dans son emballage d'origine
        (complet, intact, en parfait état de revente avec ses accessoires,
        notice…) en Colissimo Recommandé, à l'adresse suivante : STOP PIECES
        AUTO – 7 RD 386 – Les Sept Chemins 69390 VOURLES.
      </p>
      <p className="my-5">
        11.6 Le vendeur remplacera dans les plus brefs délais et à ses frais,
        les produits livrés dont les vices apparents ou les défauts de
        conformité auront été dûment prouvés par l'acheteur.
      </p>
      <h2 className="text-2xl font-bold my-5">
        Article 12 – Procédure de retour
      </h2>
      <p className="my-5">
        Tout retour de marchandises devra faire l'objet d'une demande préalable
        sous 15 jours et d'une acceptation expresse du vendeur. Passé ce délai,
        aucune réclamation ne sera prise en compte.
      </p>
      <p className="my-5">
        Les frais de transport sont en tout état de cause, à la charge de notre
        client.
      </p>

      <h2 className="text-2xl font-bold my-5">
        Article 13 – Garantie des produits
      </h2>
      <p className="my-5">
        Conformément aux textes légaux en vigueur, le vendeur garantit
        l'acheteur contre toutes les conséquences de non-conformité et des vices
        cachés de la chose vendue.
      </p>
      <p className="my-5">
        Toute garantie est exclue en cas de mauvaise utilisation, négligence ou
        défaut d'entretien de la part de l'acheteur comme en cas d'usure normale
        du bien, d'accident ou de force majeure, et la garantie du vendeur est
        limitée au remplacement ou au remboursement des produits non conformes
        ou affectés d'un vice.
      </p>

      <h2 className="text-2xl font-bold my-5">
        Article 14 – Droit de rétractation
      </h2>
      <p className="my-5">
        L'acheteur étant un professionnel achetant dans le cadre et pour les
        besoins de sa profession, il n'y a pas lieu d'appliquer le droit de
        rétractation prévu par le code de la consommation.
      </p>

      <h2 className="text-2xl font-bold my-5">Article 15 – Réclamations</h2>
      <p className="my-5">
        Le client doit adresser ses éventuelles réclamations auprès de STOP
        PIECES AUTO – 7 RD 386 – Les Sept Chemins 69390 VOURLES, Email :{' '}
        <a
          className="underline text-orange"
          href="mailto:commandes@tafaniautos.com"
        >
          commandes@tafaniautos.com
        </a>
        , soit par voie postale ou électronique en rappelant le numéro de
        commande et la date de la commande. Seules les réclamations relatives à
        la vente en ligne des biens seront prises en compte.
      </p>

      <h2 className="text-2xl font-bold my-5">Article 16 – Force majeure</h2>
      <p className="my-5">
        16.1 Toutes circonstances indépendantes de la volonté des parties,
        empêchant l'exécution dans des conditions normales de leurs obligations,
        sont considérées comme des causes d'exonération des obligations des
        parties et entraînent leur suspension.
      </p>
      <p className="my-5">
        16.2 La partie qui invoque les circonstances visées ci-dessus doit
        avertir immédiatement l'autre partie de leur survenance, ainsi que de
        leur disparition.
      </p>
      <p className="my-5">
        16.3 Seront considérés comme cas de force majeure tous faits ou
        circonstances irrésistibles, extérieurs aux parties, imprévisibles,
        inévitables, indépendants de la volonté des parties et qui ne pourront
        être empêchés par ces dernières, malgré tous les efforts raisonnablement
        possibles. De façon expresse, sont considérés comme cas de force majeure
        ou cas fortuits, outre ceux habituellement retenus par la jurisprudence
        des cours et des tribunaux français : le blocage des moyens de
        transports ou d'approvisionnements, tremblements de terre, incendies,
        tempêtes, inondations, foudre, l'arrêt des réseaux de télécommunication
        ou difficultés propres aux réseaux de télécommunication externes aux
        clients.
      </p>
      <p className="my-5">
        16.4 Les parties se rapprocheront pour examiner l'incidence de
        l'événement et convenir des conditions dans lesquelles l'exécution du
        contrat sera poursuivie. Si le cas de force majeur a une durée
        supérieure à trois mois, les présentes conditions générales pourront
        être résiliées par la partie lésée.
      </p>

      <h2 className="text-2xl font-bold my-5">
        Article 17 – Nullité d'une Clause du Contrat
      </h2>
      <p className="my-5">
        Si l'une quelconque des dispositions du présent Contrat était annulée,
        cette nullité n'entraînerait pas la nullité des autres dispositions du
        Contrat qui demeureront en vigueur entre les Parties.
      </p>

      <h2 className="text-2xl font-bold my-5">Article 18 – Non-renonciation</h2>
      <p className="my-5">
        Le fait pour l'une des parties de ne pas se prévaloir d'un manquement
        par l'autre partie à l'une quelconque des obligations visées dans les
        présentes conditions générales ne saurait être interprété pour l'avenir
        comme une renonciation à l'obligation en cause.
      </p>

      <h2 className="text-2xl font-bold my-5">
        Article 19 – Droits de propriété intellectuelle
      </h2>
      <p className="my-5">
        L'ensemble des marques figuratives ou non et plus généralement toutes
        les autres marques, illustrations, images et logotypes figurant sur les
        biens, leurs accessoires et leurs emballages, qu'ils soient déposés ou
        non, sont et demeureront la propriété exclusive du vendeur. Toute
        reproduction totale ou partielle, modification ou utilisation de ces
        marques, illustrations, images et logotypes, pour quelque motif et sur
        quelque support que ce soit, sans accord exprès et préalable du vendeur,
        est strictement interdite. Il en est de même de toute combinaison ou
        conjonction avec toute autre marque, symbole, logotype et plus
        généralement tout signe distinctif destiné à former un logo composite.
        Il en est de même pour tout droit d'auteur, dessin, modèle et brevet qui
        sont la propriété du vendeur
      </p>

      <h2 className="text-2xl font-bold my-5">
        Article 20 – Confidentialité des données
      </h2>
      <p className="my-5">
        Les informations demandées au client sont nécessaires au traitement de
        la commande.
      </p>
      <p className="my-5">
        Dans l'hypothèse où le client consent à communiquer des données
        individuelles à caractère personnel, il dispose d'un droit individuel
        d'accès, de retrait et de rectification de ces données dans les
        conditions prévues par la loi n° 78-17 du 6 janvier 1978 relative à
        l'informatique, aux fichiers et aux libertés. Le client doit adresser,
        par voie postale uniquement, toute demande écrite à STOP PIECES AUTO – 7
        RD 386 – Les Sept Chemins 69390 VOURLES.
      </p>
      <p className="my-5">
        À l'occasion de la création de son compte client sur le site, le client
        aura la possibilité de choisir s'il souhaite recevoir des offres du
        vendeur et de ses partenaires.
      </p>

      <h2 className="text-2xl font-bold my-5">Article 21 – Titre</h2>
      <p className="my-5">
        En cas de difficulté d'interprétation entre l'un quelconque des titres
        figurant en tête des clauses, et l'une quelconque des clauses, les
        titres seront déclarés inexistants.
      </p>

      <h2 className="text-2xl font-bold my-5">Article 22 – Loi applicable</h2>
      <p className="my-5">
        Les présentes conditions générales sont régies par la loi française.
      </p>

      <h2 className="text-2xl font-bold my-5">
        Article 23 – Attribution de juridiction
      </h2>
      <p className="my-5">
        En cas de contestation relative à l'exécution du contrat de vente ou au
        paiement du prix ainsi qu'en cas d'interprétation ou d'exécution des
        clauses et conditions précitées, le tribunal de commerce d'Aubenas sera
        seul compétent quelques soient le lieu de livraison, le mode de
        règlement accepté même en cas d'appel de garantie ou de pluralité des
        défendeurs. Aucune dérogation et aucune novation ne sera faite à cette
        clause attributive de juridiction.
      </p>
    </CGVTemplate>
  );
}
