function Meilleur() {
  return (
    <div className="grid grid-cols-12 gap-6 py-8 md:py-0">
      <div className="col-span-12 lg:col-span-7">
        <h2 className="font-clash text-[30px] sm:text-[40px] leading-[1] 900:text-[60px] 900:leading-[64px] font-[600] text-dark ">
          Un avenir construit autour de pratiques responsables
        </h2>
        <img
          src="/image-rse.png"
          alt="Illustration de notre démarche RSE"
          title="Notre engagement RSE dans le secteur automobile"
          className="mt-4"
        />
        <p className="text-[18px] mt-2 md:text-[25px] mt-4">
          La démarche RSE est un engagement évolutif, pensé pour répondre aux
          besoins du secteur automobile tout en s'adaptant aux attentes
          environnementales. Elle se traduit par des actions concrètes visant à
          réduire les déchets automobiles grâce au recyclage et à la
          valorisation des pièces, à encourager des solutions innovantes et
          durables limitant l'impact environnemental, et à soutenir une économie
          circulaire. En sensibilisant clients et collaborateurs aux enjeux
          écologiques, cette démarche contribue à intégrer des pratiques
          responsables dans chaque aspect de l'activité.
        </p>
        <img
          src="/image-recyclage.png"
          alt="Illustration du recyclage automobile"
          title="Notre approche du recyclage automobile"
          className="mt-4"
        />
      </div>

      <div className="col-span-12 lg:col-span-5">
        <div className="block w-full p-6  border-2 border-gray rounded-lg shadow ">
          <div className="flex items-center space-x-4">
            <img
              src="/pictos/picto-france.png"
              alt="Icône Made in France"
              title="Notre engagement Made in France"
            />
            <h5 className="mb-2 text-2xl font-bold tracking-tight ">
              Made in France
            </h5>
          </div>
          <p className="font-normal text-lg mt-5">
            Nous privilégions les partenariats avec des fournisseurs locaux et
            reconnus pour leur expertise, favorisant ainsi une économie
            responsable et de proximité. Ce choix garantit des produits de
            qualité, une traçabilité renforcée et un soutien à des pratiques
            durables qui bénéficient à tous. En optant pour nos solutions, vous
            encouragez un savoir-faire qui allie excellence et respect des
            ressources.
          </p>
        </div>
        <div className="block w-full p-6 mt-10  border-2 border-gray rounded-lg shadow ">
          <div className="flex items-center space-x-4">
            <img
              src="/pictos/picto-ethique.png"
              alt="Icône représentant l'éthique"
              title="Notre engagement éthique"
            />
            <h5 className="mb-2 text-2xl font-bold tracking-tight ">Éthique</h5>
          </div>
          <p className="font-normal text-lg mt-5">
            La transparence et l'éthique sont les piliers de toutes nos actions.
            Nous croyons en une communication ouverte et honnête avec nos
            clients. Notre site web est conçu pour offrir des informations
            claires sur nos produits, nos partenaires et nos pratiques
            commerciales. Cet engagement éthique se reflète également dans nos
            relations avec nos collaborateurs, nos fournisseurs et nos clients,
            en favorisant des pratiques justes et responsables.
          </p>
        </div>
        <div className="block w-full p-6 mt-10  border-2 border-gray rounded-lg shadow ">
          <div className="flex items-center space-x-4">
            <img
              src="/pictos/picto-agrements.png"
              alt="Icône des agréments"
              title="Nos certifications et agréments"
            />
            <h5 className="mb-2 text-2xl font-bold tracking-tight ">
              Agréments
            </h5>
          </div>
          <p className="font-normal text-lg mt-5">
            Stop Pièces Auto s'efforce constamment d'atteindre les normes les
            plus exigeantes en matière de qualité et de conformité. Nous sommes
            fiers de détenir des certifications et agréments qui témoignent de
            notre engagement envers l'excellence, comme le statut de centre VHU
            agréé et la certification qualité SGS. Ces distinctions englobent
            des normes strictes de qualité, des pratiques environnementales
            responsables et des critères éthiques rigoureux.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Meilleur;
