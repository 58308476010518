import { ErrorMessage, Field } from 'formik';

const FormInput = ({ placeholder, name, size = 16, className = '' }) => {
  return (
    <div className="w-full">
      <Field
        className={`border border-[#CBCDCD] rounded-lg h-14 w-full outline-none px-4 block text-[${size}px] ${className}`}
        placeholder={placeholder}
        name={name}
      />
      <ErrorMessage name={name}>
        {(error) => {
          return <small className="text-red-400">{error}</small>;
        }}
      </ErrorMessage>
    </div>
  );
};

export default FormInput;
