import { MdArrowBackIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const BackButton = ({ className = '', backTo = '' }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    if (backTo) {
      window.location.replace(backTo);
    } else {
      // Vérifier si la page précédente est du même domaine
      const previousPage = document.referrer;
      const currentDomain = window.location.origin;

      if (previousPage.startsWith(currentDomain)) {
        window.history.back();
      } else {
        navigate('/catalogue');
      }
    }
  };

  return (
    <div
      className={`inline-flex gap-x-[6px] items-center py-[10px] px-[16px] bg-gray rounded cursor-pointer ${className}`}
      onClick={handleBack}
    >
      <MdArrowBackIos className="text-[#9EA5A7] text-[12px]" />
      <p className="text-dark transition">Retour à la liste</p>
    </div>
  );
};

export default BackButton;
