import { useEffect, useState } from 'react';
import { Car, Truck } from '../../Utils/Icons/Icons';
import * as Yup from 'yup';
import { GoPencil } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { immatCheck } from '../../store/Reducers/CheckoutSlice';
import { IoCheckmark } from 'react-icons/io5';
import Amount from '../../components/commons/Amount/Amount';
import LittleLoader from '../../components/Loader/LittleLoader';
import { get, patch, post } from '../../components/Api/Api';
import { translateError } from '../../utils/translatting';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';
import { useCart } from '../../contexts/CartContext';
import { Form, Formik } from 'formik';
import FormError from '../../components/Form/FormError';
import FormInput from '../../components/CheckoutForm/FormInput';
const Immat = () => {
  const dispatch = useDispatch();
  const isInformationFilled = useSelector(
    (state) => state.CheckoutSlice.isInformationFilled,
  );
  const {
    setCartImmat,
    cartImmat,
    cartLoading,
    setCartShippingOption,
    getHeaders,
  } = useCart();

  const [editForm, setEditForm] = useState(true);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [immat, setImmat] = useState('');
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {
    dispatch(immatCheck(false));
  }, []);

  useEffect(() => {
    dispatch(immatCheck(!editForm));
  }, [editForm]);

  useEffect(() => {
    if (cartImmat) {
      setImmat(cartImmat);
    }
  }, [cartImmat]);

  const handleSubmit = (values) => {
    setSubmitLoading(true);
    const headers = getHeaders();
    patch('shop/cart/immat', { immat: values.immat }, headers)
      .then((data) => {
        setCartImmat(data.immat);
        setImmat(data.immat);
        setSubmitError(null);
        setEditForm(false);
        setShowEditIcon(true);
        dispatch(immatCheck(true));
      })
      .catch((err) => {
        setSubmitError(
          translateError(
            err,
            "Une erreur est survenue lors de l'enregistrement de la plaque d`immatriculation",
          ),
        );
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    immat: Yup.string()
      .required('Veuillez entrer une plaque d`immatriculation')
      .matches(
        /^([a-hA-Hj-nJ-Np-tP-Tv-zV-Z]{2})\s*-*(\d{3})\s*-*([a-hA-Hj-nJ-Np-tP-Tv-zV-Z]{2})$|^(\d{1,4})\s*-*([a-zA-Z]{1,3})\s*-*(\d{2,3})$/,
        "La plaque d`immatriculation n'est pas au bon format",
      ),
  });

  return (
    <div
      className={`bg-gray duration-300 w-full p-8 ${
        editForm && isInformationFilled ? 'smm:h-max' : 'h-24'
      } overflow-hidden rounded-xl`}
    >
      <div className="flex justify-between items-center">
        <div className="grid grid-flow-col items-center gap-2">
          <div
            className={`${
              showEditIcon ? 'bg-orange' : 'bg-black'
            } w-9 h-9 grid place-items-center text-white rounded-full`}
          >
            {showEditIcon ? <IoCheckmark /> : 2}
          </div>
          <h3 className="font-jost text-[22px] smm:text-xl font-semibold">
            Plaque d'immatriculation
          </h3>
        </div>
        {showEditIcon && (
          <button
            className="text-[#CBCDCD] text-2xl"
            onClick={() => setEditForm(!editForm)}
          >
            <GoPencil />
          </button>
        )}
      </div>
      <div className="mt-7">
        <p className="mb-5">
          Cette information servira à des fins de vérification concernant la
          compatibilité des pièces commandées.
        </p>
        <Formik
          initialValues={{ immat }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({ isValid }) => (
            <Form>
              <FormInput
                placeholder="XX-XXX-XX"
                name="immat"
                size={35}
                className="w-[270px] text-center"
              />
              {submitError && <FormError text={submitError} />}
              <div className="sm:w-full sm:flex justify-end">
                <button
                  type="submit"
                  className={`${
                    !isValid
                      ? 'bg-zinc-500'
                      : 'border-orange hover:bg-transparent duration-300 hover:text-orange bg-orange'
                  } px-[30px] mt-4 border rounded-[8px] py-[16px] sm:w-auto w-full text-white inline-flex justify-center gap-3`}
                  disabled={!isValid || submitLoading}
                >
                  Continuer
                  {submitLoading && <LittleWhiteLoader className="w-5 h-5" />}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Immat;
