import { useState } from "react";

function Blogs() {

    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 5;

    const blogData = [
        {
            image: '/123.png',
            category: 'PIÈCES AUTOMOBILE',
            title: 'Quels sont les avantages du recyclage automobile',
            content: 'Aujourd\'hui, plongeons-nous dans le monde du recyclage automobile et explorons les nombreux avantages qu\'il offre. En tant que passionnés de l\'industrie automobile, chez Stoppièces Auto, nous croyons en la responsabilité environnementale et souhaitons partager avec vous l\'importance du recyclage automobile.'
        },
        {
            image: '/123.png',
            category: 'CONSEILS',
            title: 'Qu’est-ce que la loi Montagne 2 ?',
            content: 'En France, la réglementation Montagne stipule l\'obligation d être équipé de pneus hiver ou de pneus toutes saisons, ou encore de détenir des chaînes/chaussettes neige dans 48 départements pendant la saison hivernale, du 1er novembre au 31 mars.'
        },
        {
            image: '/123.png',
            category: 'TUTORIEL',
            title: 'Comment choisir ses pièces détachées avec Stop Pièces Auto ?',
            content: 'Lorsque vient le moment de remplacer une pièce défectueuse de votre véhicule, la question cruciale se pose : comment choisir les bonnes pièces détachées ? En tant que recycleur automobile , nous comprenons l\'importance de cette décision et souhaitons vous guider à travers ce processus.'
        },
        {
            image: '/123.png',
            category: 'ENTRETIEN',
            title: 'Comment entretenir l\'intérieur de votre voiture',
            content: 'Explorez nos astuces et recommandations pour entretenir l\'intérieur de votre voiture. Des conseils pratiques, des produits recommandés et des méthodes efficaces pour garder votre habitacle impeccable et confortable, garantissant ainsi une expérience de conduite agréable à tout moment.'
        },
        {
            image: '/123.png',
            category: 'PIÈCES AUTOMOBILE',
            title: 'Tout ce que vous devez savoir sur les pneus : guide complet',
            content: 'Plongez dans notre guide complet sur les pneus automobiles. Découvrez tout ce qu il faut savoir sur les différents types de pneus, leur entretien, comment choisir les meilleurs pour votre véhicule, et bien plus encore. Un incontournable pour rouler en toute sécurité et optimiser les performances de votre voiture.'
        },
        {
            image: '/123.png',
            category: 'PIÈCES AUTOMOBILE',
            title: 'Quels sont les avantages du recyclage automobile',
            content: 'Aujourd\'hui, plongeons-nous dans le monde du recyclage automobile et explorons les nombreux avantages qu\'il offre. En tant que passionnés de l\'industrie automobile, chez Stoppièces Auto, nous croyons en la responsabilité environnementale et souhaitons partager avec vous l\'importance du recyclage automobile.'
        },
        {
            image: '/123.png',
            category: 'PIÈCES AUTOMOBILE',
            title: 'Quels sont les avantages du recyclage automobile',
            content: 'Aujourd\'hui, plongeons-nous dans le monde du recyclage automobile et explorons les nombreux avantages qu\'il offre. En tant que passionnés de l\'industrie automobile, chez Stoppièces Auto, nous croyons en la responsabilité environnementale et souhaitons partager avec vous l\'importance du recyclage automobile.'
        },
        {
            image: '/123.png',
            category: 'PIÈCES AUTOMOBILE',
            title: 'Quels sont les avantages du recyclage automobile',
            content: 'Aujourd\'hui, plongeons-nous dans le monde du recyclage automobile et explorons les nombreux avantages qu\'il offre. En tant que passionnés de l\'industrie automobile, chez Stoppièces Auto, nous croyons en la responsabilité environnementale et souhaitons partager avec vous l\'importance du recyclage automobile.'
        },
        {
            image: '/123.png',
            category: 'PIÈCES AUTOMOBILE',
            title: 'Quels sont les avantages du recyclage automobile',
            content: 'Aujourd\'hui, plongeons-nous dans le monde du recyclage automobile et explorons les nombreux avantages qu\'il offre. En tant que passionnés de l\'industrie automobile, chez Stoppièces Auto, nous croyons en la responsabilité environnementale et souhaitons partager avec vous l\'importance du recyclage automobile.'
        },
        {
            image: '/123.png',
            category: 'PIÈCES AUTOMOBILE',
            title: 'Quels sont les avantages du recyclage automobile',
            content: 'Aujourd\'hui, plongeons-nous dans le monde du recyclage automobile et explorons les nombreux avantages qu\'il offre. En tant que passionnés de l\'industrie automobile, chez Stoppièces Auto, nous croyons en la responsabilité environnementale et souhaitons partager avec vous l\'importance du recyclage automobile.'
        },
        {
            image: '/123.png',
            category: 'PIÈCES AUTOMOBILE',
            title: 'Quels sont les avantages du recyclage automobile',
            content: 'Aujourd\'hui, plongeons-nous dans le monde du recyclage automobile et explorons les nombreux avantages qu\'il offre. En tant que passionnés de l\'industrie automobile, chez Stoppièces Auto, nous croyons en la responsabilité environnementale et souhaitons partager avec vous l\'importance du recyclage automobile.'
        },
        {
            image: '/123.png',
            category: 'PIÈCES AUTOMOBILE',
            title: 'Quels sont les avantages du recyclage automobile',
            content: 'Aujourd\'hui, plongeons-nous dans le monde du recyclage automobile et explorons les nombreux avantages qu\'il offre. En tant que passionnés de l\'industrie automobile, chez Stoppièces Auto, nous croyons en la responsabilité environnementale et souhaitons partager avec vous l\'importance du recyclage automobile.'
        },

    ];

    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogData.slice(indexOfFirstBlog, indexOfLastBlog);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <>
            <div className="grid grid-cols-12 gap-4">
                <div className=" col-span-12 lg:col-span-8">
                    <div>
                        {currentBlogs.map((blog, index) => (
                            <div key={index} className="flex flex-col items-center px-4 mt-5 py-4 border-2 border-gray rounded-lg shadow md:flex-row">
                                <img className="max-w-xs" src={blog.image} alt="" />
                                <div className="flex flex-col justify-between px-0 mt-5 md:mt-0 md:px-4 leading-normal">
                                    <div className="flex">
                                        <div className="bg-blue rounded-full px-2 py-1 text-white font-bold inline-block">
                                            {blog.category}
                                        </div>
                                    </div>
                                    <h5 className="font-clash mt-5 mb-2 text-2xl font-bold tracking-tight">
                                        {blog.title}
                                    </h5>
                                    <hr className="w-72 border-solid border-t-2 border-gray" />
                                    <p className="mb-3 w-full lg:max-w-[380px] font-normal mt-2  ">
                                        {blog.content}
                                    </p>
                                </div>
                            </div>
                        ))}
                        <ul className="flex justify-center mt-5">
                            {Array.from({ length: Math.ceil(blogData.length / blogsPerPage) }, (_, i) => (
                                <li key={i}>
                                    <button
                                        onClick={() => paginate(i + 1)}
                                        className={`px-4 py-2 mx-1 font-bold bg-white text-black border-2 border-gray rounded-lg ${currentPage === i + 1 ? 'bg-[#FF5600] text-white border-[#FF5600]' : ''}`}
                                        style={{
                                            backgroundColor: currentPage === i + 1 ? '#FF5600' : 'transparent',
                                            color: currentPage === i + 1 ? '#FFFFFF' : '#000000',
                                            borderColor: currentPage === i + 1 ? '#FF5600' : '#CCCCCC'
                                        }}
                                    >
                                        {i + 1}
                                    </button>
                                </li>
                            ))}
                            <li>
                                <button
                                    onClick={() => currentPage < Math.ceil(blogData.length / blogsPerPage) && paginate(currentPage + 1)}
                                    disabled={currentPage >= Math.ceil(blogData.length / blogsPerPage)}
                                    className={`px-4 py-2 mx-1 font-bold bg-white text-black border-2 border-gray rounded-lg ${currentPage >= Math.ceil(blogData.length / blogsPerPage) ? 'opacity-50 cursor-not-allowed' : ''}`}
                                    style={{
                                        borderColor: '#CCCCCC'
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor" class="w-4 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                                    </svg>

                                </button>
                            </li>
                        </ul>

                    </div>
                </div>
                <div className=" col-span-12 lg:col-span-4 mt-5">
                    <div className="border-2 border-gray rounded-lg shadow px-4 py-4">
                        <h5 className="font-clash mt-5    text-2xl font-bold tracking-tight ">
                            Rechercher
                        </h5>
                        <div className="mb-6 relative">
                            <label
                                htmlFor="large-input"
                                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                                Large input
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    id="large-input"
                                    className="block w-full p-4  border-2 border-gray rounded-lg bg-gray-50 text-base"
                                    placeholder="Exemple...."
                                />
                                <svg className=" w-6 h-6 absolute right-3 top-1/2 transform -translate-y-1/2 text-[#0D1F22]" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
                                </svg>

                            </div>
                        </div>
                        <h3 className="font-clash mt-5    text-2xl font-bold tracking-tight ">
                            Articles récents
                        </h3>


                        <div

                            className="flex mt-4  items-center px-2 space-x-2 py-2 border-2 border-gray rounded-lg shadow w-full"
                        >
                            <img
                                className=" "
                                src="/Group35.png"
                                alt=""
                            />
                            <div className="flex flex-col justify-between px-0  md:px-4 ">
                                <div className="flex">
                                    <div className="bg-blue whitespace-nowrap rounded-full text-[10px]  md:text-xs px-2 py-1 text-white font-bold inline-block">
                                        ENTRETIEN
                                    </div>
                                </div>


                                <h5 className="font-clash mt-0 md:mt-4 w-full mb-2 text-xs md:text-sm font-bold tracking-tight ">
                                    Les bases de l’entretien préventif de votre véhicule
                                </h5>
                                <hr className="w-32 border-solid border-t-2 border-gray" />
                                <p className="text-xs text-[#5B6162] mt-2">Il y a 16 heures</p>
                            </div>
                        </div>
                        <div

                            className="flex mt-4  items-center px-2 space-x-2 py-2 border-2 border-gray rounded-lg shadow w-full"
                        >
                            <img
                                className=" "
                                src="/aa3.png"
                                alt=""
                            />
                            <div className="flex flex-col justify-between px-0  md:px-4 ">
                                <div className="flex">
                                    <div className="bg-blue whitespace-nowrap rounded-full text-[10px]  md:text-xs px-2 py-1 text-white font-bold inline-block">
                                        TUTORIEL
                                    </div>
                                </div>


                                <h5 className="font-clash mt-0 md:mt-4 w-full mb-2 text-xs md:text-sm font-bold tracking-tight ">
                                    Comment changer les plaquettes de frein de votre voiture
                                </h5>
                                <hr className="w-32 border-solid border-t-2 border-gray" />
                                <p className="text-xs text-[#5B6162] mt-2">Il y a 16 heures</p>
                            </div>
                        </div>
                        <div

                            className="flex mt-4  items-center px-2 space-x-2 py-2 border-2 border-gray rounded-lg shadow w-full"
                        >
                            <img
                                className=" "
                                src="/aa4.png"
                                alt=""
                            />
                            <div className="flex flex-col justify-between px-0  md:px-4 ">
                                <div className="flex">
                                    <div className="bg-blue whitespace-nowrap rounded-full text-[10px]  md:text-xs px-2 py-1 text-white font-bold inline-block">
                                        PIÈCES AUTOMOBILE
                                    </div>
                                </div>


                                <h5 className="font-clash mt-0 md:mt-4 w-full mb-2 text-xs md:text-sm font-bold tracking-tight ">
                                    Tout ce que vous devez savoir sur les pneus _ guide complet
                                </h5>
                                <hr className="w-32 border-solid border-t-2 border-gray" />
                                <p className="text-xs text-[#5B6162] mt-2">Il y a 16 heures</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}

export default Blogs
