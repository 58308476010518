import { Switch as ReactSwitch } from '@headlessui/react';
import { useEffect } from 'react';

const Switch = ({ checked, onChange, text, className = '' }) => {
  return (
    <div className={`${className} flex flex-row h-14 items-center`}>
      <ReactSwitch
        checked={checked}
        onChange={onChange}
        className="group relative flex h-7 w-14 cursor-pointer rounded-full bg-[#CBCDCD] p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-orange"
      >
        <span
          aria-hidden="true"
          className="pointer-events-none inline-block w-5 h-5 translate-x-0 rounded-full bg-white ring-0 shadow-lg transition duration-200 ease-in-out group-data-[checked]:translate-x-7"
        />
      </ReactSwitch>
      <span className="ml-2">{text}</span>
    </div>
  );
};

export default Switch;
