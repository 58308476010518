import { Form, Formik } from 'formik';
import FormError from '../../components/Form/FormError';
import { useState } from 'react';
import * as Yup from 'yup';
import { post } from '../../components/Api/Api';
import { toast } from 'react-toastify';
import { translateError } from '../../utils/translatting';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';
import FormInput from '../../components/CheckoutForm/FormInput';
import { Link } from 'react-router-dom';

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().email('Email non valide').required('Email requis'),
  });

  const handleSubmit = (values) => {
    setLoading(true);
    const payload = {
      email: values.email?.trim().length > 0 ? values.email.trim() : null,
    };

    post('/forgot-password', payload)
      .then(() => {
        toast.success('Mot de passe réinitialisé avec succès');
        setSuccess(true);
      })
      .catch((e) => {
        setError(translateError(e));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="px-2 mx-auto my-10 max-w-md">
      <h1 className="text-2xl font-bold mb-10 text-center">
        Réinitialisation de mot de passe
      </h1>
      {success && (
        <div className="flex flex-col items-center justify-center py-6 px-4 bg-green-200 rounded-lg mb-10 mx-auto md:w-full">
          <p className="text-center text-md font-bold">
            Un email de réinitialisation de mot de passe a été envoyé à votre
            adresse email.
          </p>
          <p className="text-center text-md">
            Pensez à bien vérifier vos courriers indésirables ou spam.
          </p>
          <Link
            to="/"
            className="mt-4 px-[30px] border
                duration-300 rounded-[8px] py-[8px] text-white inline-flex justify-center gap-3 bg-orange hover:text-orange border-orange hover:bg-transparent"
          >
            Retour à l'accueil
          </Link>
        </div>
      )}
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validateOnChange={true}
      >
        {({ isValid }) => (
          <Form>
            <div className="flex flex-col gap-4 mb-4">
              <FormInput name="email" placeholder="Email" />
            </div>
            {error && <FormError className="mb-2" text={error} />}
            <button
              type="submit"
              className={`px-[30px] border
                duration-300 rounded-[8px] py-[16px]
                w-full text-white inline-flex justify-center gap-3 ${
                  !isValid
                    ? 'bg-zinc-500'
                    : 'bg-orange hover:text-orange border-orange hover:bg-transparent'
                }`}
              disabled={loading || !isValid}
            >
              Envoyer
              {loading && <LittleWhiteLoader className="w-5 h-5" />}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
