import { Button } from '@headlessui/react';
import * as Yup from 'yup';
import 'yup-phone-lite';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import FormInput from '../CheckoutForm/FormInput';
import LittleWhiteLoader from '../Loader/LittleWhiteLoader';
import { toast } from 'react-toastify';
import FormTextArea from '../CheckoutForm/FormTextArea';
import { post } from '../Api/Api';
import { translateError } from '../../utils/translatting';
import { useSelector } from 'react-redux';
import { SHIPPABLE_COUNTRIES } from '../../helpers/shopHelper';

export default function ContactForm({ submitted = () => {}, productId }) {
  const [errorMessage, setErrorMessage] = useState(null);

  const { isAuthenticated } = useSelector((state) => state.auth);

  const [validationSchema, setValidationSchema] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      setValidationSchema(
        Yup.object().shape({
          phone: Yup.string().phone(
            SHIPPABLE_COUNTRIES,
            'Numéro de téléphone invalide',
          ),
          message: Yup.string().required('Message obligatoire'),
        }),
      );
    } else {
      setValidationSchema(
        Yup.object().shape({
          firstName: Yup.string().required('Prénom obligatoire'),
          lastName: Yup.string().required('Nom obligatoire'),
          phone: Yup.string().phone(
            SHIPPABLE_COUNTRIES,
            'Numéro de téléphone invalide',
          ),
          email: Yup.string()
            .email('Email invalide')
            .required('Email obligatoire'),
          message: Yup.string().required('Message obligatoire'),
        }),
      );
    }
  }, [isAuthenticated]);

  const handleSubmit = (values, onSubmitProps) => {
    post('/product_contact', { ...values, productId: productId })
      .then((res) => {
        onSubmitProps.resetForm();
        setErrorMessage(null);
        toast.success('Message envoyé avec succès');
        submitted();
      })
      .catch((err) => {
        setErrorMessage(translateError(err, 'Une erreur est survenue'));
      })
      .finally(() => {
        onSubmitProps.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={
        !isAuthenticated
          ? {
              firstName: '',
              lastName: '',
              phone: '',
              email: '',
              message: '',
            }
          : {
              phone: '',
              message: '',
            }
      }
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ errors, isSubmitting, isValid, dirty }) => (
        <Form className="space-y-4 mt-5">
          {!isAuthenticated && (
            <div className="grid-cols-2 smm:grid-cols-1 grid gap-4 mb-2">
              <FormInput name="firstName" placeholder="Prénom*" />
              <FormInput name="lastName" placeholder="Nom*" />
            </div>
          )}
          <FormInput name="phone" placeholder="Téléphone" />
          {!isAuthenticated && <FormInput name="email" placeholder="Email*" />}
          <FormTextArea name="message" placeholder="Message*" height="100px" />
          {errorMessage && <p className="text-red-500">{errorMessage}</p>}
          <Button
            type="submit"
            className={`inline-flex justify-center items-center gap-2 rounded-lg text-sm font-semibold shadow-inner px-24 py-4 mt-8 smm:w-full ${
              isValid && dirty && !isSubmitting
                ? 'bg-orange text-white cursor-pointer'
                : 'bg-[#f8f9fd] text-[#858f90] cursor-not-allowed'
            }`}
            disabled={!isValid || isSubmitting || !dirty}
          >
            {isSubmitting ? (
              <LittleWhiteLoader className="w-5 h-5" />
            ) : (
              'Envoyer'
            )}
          </Button>
        </Form>
      )}
    </Formik>
  );
}
