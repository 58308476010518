import { useEffect } from 'react';
import Catalogue from '../../components/catalogue/Catalogue';
import FaqSection from '../../components/faq-section/FaqSection';
import HeroSection from '../../components/hero/HeroSection';
import LesMarques from '../../components/les-marques/LesMarques';
import LesRetoursDeNosClients from '../../components/les-retours-de-nos-clients/LesRetoursDeNosClients';
import NosDernierArticles from '../../components/nos-derniers-articles/NosDernierArticles';
import NosDerniersVehicules from '../../components/nos-derniers-vehicules/NosDerniersVehicules';
import NosEngagements from '../../components/nos-engagements/NosEngagements';
import PlusDe47 from '../../components/plus-de-47-section/PlusDe47';
import RestexInformes from '../../components/restez-informes/RestexInformes';
import VotreRecycleur from '../../components/votre-recycleur/VotreRecycleur';
import { toast } from 'react-toastify';
import NosDernieresPieces from '../../components/nos-dernieres-pieces/NosDernieresPieces';

export default function Home() {
  useEffect(() => {
    if (location.search.includes('orderSuccess')) {
      toast.success('Commande passée avec succès');
    }
    if (location.search.includes('emailVerified')) {
      toast.success('Email vérifié avec succès');
    }
  }, []);
  return (
    <div className="wrapper">
      <HeroSection />
      <Catalogue />
      <VotreRecycleur />
      <NosDernieresPieces />
      <NosDerniersVehicules />
      <NosEngagements />
      <LesMarques />
      <PlusDe47 />
      {/* <NosDernierArticles /> */}
      <LesRetoursDeNosClients />
      <FaqSection />
      <RestexInformes />
    </div>
  );
}
