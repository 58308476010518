import { ErrorMessage, Field } from 'formik';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';

export default function PasswordInput({
  name,
  required = false,
  label = undefined,
  placeholder = undefined,
  onChange = undefined,
  forFormik = false,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div {...props}>
      {label && (
        <label className="block text-md mb-1 font-medium">{label}</label>
      )}
      <div className="relative">
        {forFormik ? (
          <Field
            type={showPassword ? 'text' : 'password'}
            className="border border-[#CBCDCD] rounded-lg h-14 w-full outline-none pl-4"
            placeholder={placeholder}
            name={name}
          />
        ) : (
          <input
            type={showPassword ? 'text' : 'password'}
            id={name}
            className="bg-gray-50 border border-[#CBCDCD] text-sm rounded-lg block w-full p-3 pr-8 h-14"
            placeholder={placeholder}
            required={required}
            onChange={onChange}
          />
        )}
        <button
          type="button"
          className="absolute top-1/2 right-2 transform -translate-y-1/2"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? (
            <FiEye className="h-5 w-5 text-dark" />
          ) : (
            <FiEyeOff className="h-5 w-5 text-dark" />
          )}
        </button>
      </div>
      {forFormik && (
        <ErrorMessage name={name} component="small" className="text-red-400" />
      )}
    </div>
  );
}
