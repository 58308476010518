import PropTypes from 'prop-types';
// import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { useRef, useState } from 'react';
import { LiaAngleRightSolid, LiaAngleLeftSolid } from 'react-icons/lia';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const NosDernierArticles = () => {
  const sliderRef = useRef(null);
  const [index, setIndex] = useState(0);

  const PrevArrow = () => {
    if (sliderRef.current) {
      const index = sliderRef.current.splide.Components.Controller.getPrev();
      setIndex(index);
      sliderRef.current.splide.Components.Controller.go(index);
    }
  };

  const NextArrow = () => {
    if (sliderRef.current) {
      const index = sliderRef.current.splide.Components.Controller.getNext();
      setIndex(index);
      sliderRef.current.splide.Components.Controller.go(index);
    }
  };
  return (
    <div className="content flex flex-col gap-[24px] 900:gap-[60px] xl:gap-[60px] py-[30px] sm:py-[50px] 1400:px-0 px-[16px]">
      <div className="flex items-center justify-between">
        <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
          Nos derniers articles
        </h1>
        <div className="center gap-[24px] hidden lg:flex px-[30px]">
          <button onClick={PrevArrow}>
            <LiaAngleLeftSolid
              className={`text-[35px]  ${index === 0 && 'text-[#00000068]'}  ${
                index === -1 && 'text-[#00000068]'
              }`}
            />
          </button>
          <button onClick={NextArrow}>
            <LiaAngleRightSolid className="text-[35px]" />
          </button>
        </div>
      </div>

      <div className="flex flex-wrap justify-center items-center gap-[12px] sm:gap-[20px] 900:gap-[55px]">
        <Splide
          ref={sliderRef}
          className="w-full px-[5px]" // Add a custom class to Splide for styling
          options={{
            width: '100%',
            perPage: 3, // Show only 1 image at a time by default
            perMove: 1, // Move 1 image at a time
            drag: false,
            classes: '', // Custom class for image slider styling
            pagination: false,
            arrows: false, // Set arrows to navigate through the images
            type: 'false', // Set the type to 'slide' for image slider
            gap: 23, // No gap between images
            padding: 0, // No padding
            autoWidth: false, // Set autoWidth to false to display full-width images
            breakpoints: {
              768: {
                perPage: 1, // For screens of width 768px and above (mobile devices), show 1 slide
                arrows: false, // Hide arrows on smaller screens if needed
                drag: true,
                autoWidth: true,
                gap: 10,
              },
              1024: {
                perPage: 2,
                drag: true, // For screens of width 1024px and above (PC), show maximum 3 slides
              },
            },
          }}
        >
          <SplideSlide>
            <Article
              src="/article1.png"
              category="ENTRETIEN"
              title="Les bases de l’entretien préventif de votre véhicule"
              info="Il y a 16 heures"
            />
          </SplideSlide>
          <SplideSlide>
            <Article
              src="/article2.png"
              category="TUTORIEL"
              title="Comment changer les plaquettes de frein de votre voiture"
              info="Il y a 2 jours"
            />
          </SplideSlide>
          <SplideSlide>
            <Article
              src="/article3.png"
              category="PIÈCES AUTOMOBILE"
              title="Tout ce que vous devez savoir sur les pneus : guide complet"
              info="Il y a 5 jours"
            />
          </SplideSlide>
          <SplideSlide>
            <Article
              src="/article3.png"
              category="PIÈCES AUTOMOBILE"
              title="Tout ce que vous devez savoir sur les pneus : guide complet"
              info="Il y a 5 jours"
            />
          </SplideSlide>
          <SplideSlide>
            <Article
              src="/article3.png"
              category="PIÈCES AUTOMOBILE"
              title="Tout ce que vous devez savoir sur les pneus : guide complet"
              info="Il y a 5 jours"
            />
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
};

export default NosDernierArticles;

const Article = ({ src = '', category = '', title = '', info = '' }) => {
  return (
    <div className="max-w-[286px] sm:max-w-none sm:w-full flex flex-col gap-[20px] xl:gap-[24px]">
      <img src={src} alt="" />
      <div className="flex flex-col  items-start gap-[14px] xl:gap-[17px]">
        <button className="font-[600] xl:text-[18px] leading-[1] font-clash px-[13px] py-[9px] rounded-[12px] bg-[#007FFF] text-white">
          {category}
        </button>
        <h1 className="font-clash text-[21px] xl:text-[25px] leading-[22px] text-dark font-[600]">
          {title}
        </h1>
        <div className="bg-[#00000057] h-[1px] w-full max-w-[206px]"></div>
        <p className="text-[#5B6162] text-[15px] leading-[21px]">{info}</p>
      </div>
    </div>
  );
};

Article.propTypes = {
  src: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
};
