export function translateError(
  error,
  defaultMessage = "Quelque chose s'est mal passé",
) {
  const errors = {
    'Identifiants incorrects': 'Identifiants incorrects',
    'The email has already been taken': 'L’adresse email est déjà utilisée',
    'The password confirmation does not match':
      'Les mots de passe ne correspondent pas',
    'First name, last name, and email (for guest) are required':
      'Le prénom, le nom, et l’email (pour les invités) sont requis',
    'The password must be at least 8 characters':
      'Le mot de passe doit contenir au moins 8 caractères',
    'Veuillez remplir tous les champs': 'Veuillez remplir tous les champs',
    'Cannot destructure property': 'Veuillez remplir tous les champs',
    'You have no cart': 'Vous n’avez pas de panier',
    'Customer not found': 'Client introuvable',
    'You are not a guest':
      'Veuillez modifier les informations de votre compte directement',
    'Shipping option identifier is required':
      'L’option de livraison est requise',
    'Shipping option not found':
      'Méthode de livraison introuvable, veuillez réessayer',
    'Cart line not found': 'Article introuvable',
    'The cart has no billing address':
      'Le panier n’a pas d’adresse de facturation',
    'Old password is incorrect':
      'L’ancien mot de passe est incorrect, veuillez réessayer',
    'Invalid immat': 'La plaque d’immatriculation est invalide',
    'Stock notification already exists':
      'Vous avez déjà demandé à être notifié lorsque ce produit sera de nouveau disponible',
    'Stock notification not found':
      'Vous n’avez pas demandé à être notifié pour ce produit',
    'Product not found': 'Produit introuvable',
    'passwords.token':
      'Votre lien de réinitialisation de mot de passe est invalide. Il a peut-être expiré.',
    'Something went wrong': defaultMessage,
  };
  let translattedMessage = null;
  Object.keys(errors).forEach((e) => {
    if (error.message.includes(e)) {
      return (translattedMessage = errors[e]);
    }
  });
  return translattedMessage ?? defaultMessage;
}
