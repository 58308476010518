import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { get } from '../../components/Api/Api';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { OrderItem } from '../Profile/ItemHistory';
import { Helmet } from 'react-helmet-async';

export default function Order() {
  const { orderRef } = useParams();
  const [order, setOrder] = useState(null);
  const [orderLoading, setOrderLoading] = useState(true);
  const [orderValidated, setOrderValidated] = useState(false);
  const [orderEstimationRequested, setOrderEstimationRequested] =
    useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search.includes('error')) {
      switch (location.search.split('error=')[1]) {
        case 'order_not_found':
          toast.error('Commande introuvable');
          break;
        case 'payment_failed':
          toast.error('Le paiement a échoué');
          break;
        case 'payment_canceled':
          toast.error('Le paiement a été annulé');
          break;
        default:
          toast.error('Une erreur est survenue');
          break;
      }
    } else if (location.search.includes('success')) {
      switch (location.search.split('success=')[1]) {
        case 'order_validated':
          toast.success('Commande validée');
          setOrderValidated(true);
          break;
        case 'order_estimation_requested':
          toast.success("Demande d'estimation de livraison envoyée");
          setOrderEstimationRequested(true);
          break;
      }
    }
  }, []);

  useEffect(() => {
    get(`shop/orders/${orderRef}`)
      .then((res) => {
        setOrder(res);
      })
      .catch((err) => {
        if (err.code === 404) {
          toast.error('Commande introuvable');
        } else if (err.code === 403) {
          toast.error("Vous n'avez pas accès à cette commande");
        } else {
          toast.error(
            'Une erreur est survenue lors de la récupération de la commande',
          );
        }
        navigate('/');
      })
      .finally(() => {
        setOrderLoading(false);
      });
  }, [orderRef]);

  return orderLoading ? (
    <Loader />
  ) : (
    <div className="mx-2 md:w-5/6 md:mx-auto my-10">
      {order?.reference && (
        <Helmet>
          <title>Commande n°{order?.reference} - Stop Pièces Auto</title>
        </Helmet>
      )}
      {orderValidated && (
        <div className="flex flex-col items-center justify-center py-10 px-4 md:px-[200px] bg-green-200 rounded-lg mb-10 mx-auto md:w-fit">
          <p className="text-center text-2xl font-bold">Commande validée !</p>
          <p className="text-center text-lg">Merci pour votre confiance !</p>
        </div>
      )}
      {orderEstimationRequested && (
        <div className="flex flex-col items-center justify-center py-10 md:px-[200px] bg-green-200 rounded-lg mb-10 mx-auto md:w-fit">
          <p className="text-center text-2xl font-bold">
            Demande d'estimation de livraison envoyée !
          </p>
          <p className="text-center text-lg">
            Nous vous contacterons dans les plus brefs délais.
          </p>
        </div>
      )}
      <OrderItem order={order} loadLinesSeparately={false} initShowLines />
    </div>
  );
}
