import { useEffect, useState } from 'react';
import { Car, Truck } from '../../Utils/Icons/Icons';
import { GoPencil } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { deliveryCheck } from '../../store/Reducers/CheckoutSlice';
import { IoCheckmark } from 'react-icons/io5';
import Amount from '../../components/commons/Amount/Amount';
import LittleLoader from '../../components/Loader/LittleLoader';
import { get, post } from '../../components/Api/Api';
import { translateError } from '../../utils/translatting';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';
import { useCart } from '../../contexts/CartContext';
import { useNavigate } from 'react-router-dom';
import { SHIPPING_OPTION_BASDEL } from '../../helpers/shopHelper';
const Methods = () => {
  const dispatch = useDispatch();
  const { isImmatFilled, isAddressFilled } = useSelector(
    (state) => state.CheckoutSlice,
  );
  const {
    cartShippingOption,
    cartAddresses,
    cartLoading,
    setCartShippingOption,
    getHeaders,
    cartItems,
  } = useCart();

  const navigate = useNavigate();

  const [selectedShipping, setSelectedShipping] = useState('');
  const [editForm, setEditForm] = useState(true);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [shippingOptions, setShippingOptions] = useState(null);
  const [shippingOptionsLoading, setShippingOptionsLoading] = useState(true);
  const [shippingOptionsError, setShippingOptionsError] = useState(null);
  const [selectShippingError, setSelectShippingError] = useState(null);
  const [selectShippingLoading, setSelectShippingLoading] = useState(false);
  const [estimateLoading, setEstimateLoading] = useState(false);
  const [estimateError, setEstimateError] = useState(null);
  const [estimateSuccess, setEstimateSuccess] = useState(false);

  useEffect(() => {
    dispatch(deliveryCheck(false));
  }, []);

  useEffect(() => {
    dispatch(deliveryCheck(!editForm));
  }, [editForm]);

  useEffect(() => {
    if (isImmatFilled && isAddressFilled) {
      setShippingOptionsLoading(true);
      const headers = getHeaders();
      get('shop/cart/shipping_options', headers)
        .then((data) => {
          setShippingOptions(data);
          setShippingOptionsError(null);
        })
        .catch((err) => {
          setShippingOptionsError(
            translateError(
              err,
              'Une erreur est survenue lors du chargement des méthodes de livraison',
            ),
          );
        })
        .finally(() => {
          setShippingOptionsLoading(false);
        });
    }
  }, [cartAddresses]);

  useEffect(() => {
    setSelectedShipping(cartShippingOption?.identifier);
    setCartShippingOption(cartShippingOption);
  }, [cartShippingOption]);

  const handleSubmit = () => {
    setSelectShippingLoading(true);
    const headers = getHeaders();
    post(
      'shop/cart/shipping_options/choose',
      { identifier: selectedShipping },
      headers,
    )
      .then((data) => {
        setSelectShippingError(null);
        setEditForm(false);
        setShowEditIcon(true);
        dispatch(deliveryCheck(true));
      })
      .catch((err) => {
        setSelectShippingError(
          translateError(
            err,
            'Une erreur est survenue lors du choix de la méthode de livraison',
          ),
        );
      })
      .finally(() => {
        setSelectShippingLoading(false);
      });
  };

  const handleEstimate = () => {
    setEstimateLoading(true);
    const headers = getHeaders();
    post('shop/cart/shipping_options/estimate', {}, headers)
      .then((order) => {
        setEstimateError(null);
        setEstimateSuccess(true);
        setTimeout(() => {
          navigate(
            `/orders/${order.reference}?success=order_estimation_requested`,
          );
        }, 5000);
      })
      .catch((err) => {
        setEstimateError(
          translateError(
            err,
            'Une erreur est survenue lors de la demande de devis',
          ),
        );
        setEstimateSuccess(false);
      })
      .finally(() => {
        setEstimateLoading(false);
      });
  };

  return (
    <div
      className={`bg-gray duration-300 w-full p-8 ${
        editForm && isImmatFilled && isAddressFilled
          ? 'min-h-[360px] smm:h-max'
          : 'h-24'
      } overflow-hidden rounded-xl`}
    >
      <div className="flex justify-between items-center">
        <div className="grid grid-flow-col items-center gap-2">
          <div
            className={`${
              showEditIcon ? 'bg-orange' : 'bg-black'
            } w-9 h-9 grid place-items-center text-white rounded-full`}
          >
            {showEditIcon ? <IoCheckmark /> : 4}
          </div>
          <h3 className="font-jost text-[22px] smm:text-xl font-semibold">
            Méthodes de livraison
          </h3>
        </div>
        {showEditIcon && (
          <button
            className="text-[#CBCDCD] text-2xl"
            onClick={() => setEditForm(!editForm)}
          >
            <GoPencil />
          </button>
        )}
      </div>
      <div className="mt-7">
        <div>
          {shippingOptionsError ? (
            <p className="text-red-600">{shippingOptionsError}</p>
          ) : (
            <>
              {shippingOptionsLoading || cartLoading ? (
                <LittleLoader />
              ) : (
                <>
                  <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8 my-3">
                    {shippingOptions.map((option) => (
                      <div
                        key={option.identifier}
                        className={`bg-white flex flex-col gap-3 items-start border-2 w-full cursor-pointer rounded-xl p-3 ${
                          selectedShipping === option.identifier
                            ? ' border-orange'
                            : ' border-[#CBCDCD]'
                        }`}
                        onClick={() => {
                          setSelectedShipping(option.identifier);
                          setCartShippingOption(option);
                        }}
                      >
                        <div className="flex justify-between w-full items-center">
                          <div
                            className={`${
                              selectedShipping === option.identifier
                                ? 'bg-orange  border-orange'
                                : 'bg-transparent border-[#CBCDCD]    '
                            } w-5 h-5 rounded-full grid place-items-center border`}
                          >
                            <div className="w-2 h-2 rounded-full bg-white"></div>
                          </div>
                          {option.collect ? <Car /> : <Truck />}
                        </div>
                        <div className="w-full">
                          <p className="text-base">{option.name}</p>
                          <div className="grid grid-flow-col gap-2 items-center justify-between w-full">
                            <p className="text-sm  text-[#566365]">
                              {option.description}
                            </p>
                            <h4 className="font-semibold text-dark">
                              {(cartItems.length > 1 ||
                                cartAddresses?.shipping_address?.country
                                  .iso2 !== 'FR') &&
                              !option.collect ? (
                                'Sur devis'
                              ) : option.price.value === 0 ? (
                                'Gratuit'
                              ) : (
                                <Amount value={option.price.value / 100} />
                              )}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {(cartItems.length > 1 ||
                    cartAddresses?.shipping_address?.country.iso2 !== 'FR') &&
                    !cartShippingOption?.collect &&
                    shippingOptions?.find(
                      (ship) => ship.identifier === SHIPPING_OPTION_BASDEL,
                    ) && (
                      <div className="mt-4">
                        <div className="flex flex-col sm:flex-row justify-between items-center">
                          <p className="text-sm mb-2 sm:mb-0 text-center mx-5">
                            Afin d'obtenir un cout de livraison adapté, vous
                            devez demander un devis avec les frais de livraison.
                          </p>

                          <button
                            className="bg-orange text-white px-[30px] py-[16px] rounded-md flex items-center gap-2 w-full sm:w-auto justify-center text-nowrap"
                            disabled={estimateLoading}
                            onClick={handleEstimate}
                          >
                            Demander un devis{' '}
                            {estimateLoading && (
                              <LittleWhiteLoader className="w-5 h-5" />
                            )}
                          </button>
                        </div>
                        {estimateSuccess &&
                          !estimateLoading &&
                          !estimateError && (
                            <>
                              <p className="text-green-600 mt-2 text-center">
                                Demande de devis envoyée avec succès.
                              </p>
                              <p className="mt-1 text-center">
                                Vous allez être redirigé sur le récapitulatif de
                                votre commande dans quelques secondes...
                              </p>
                            </>
                          )}
                        {estimateError &&
                          !estimateLoading &&
                          !estimateSuccess && (
                            <p className="text-red-600 mt-2 text-center">
                              {estimateError}
                            </p>
                          )}
                      </div>
                    )}
                </>
              )}
            </>
          )}
          {selectShippingError && (
            <p className="text-red-600">{selectShippingError}</p>
          )}
          {((cartItems.length <= 1 &&
            cartAddresses?.shipping_address?.country.iso2 === 'FR') ||
            cartShippingOption?.collect) && (
            <div className="mt-4 sm:w-full sm:flex justify-end">
              <button
                onClick={handleSubmit}
                className={`${
                  !selectedShipping
                    ? 'bg-zinc-500'
                    : 'border-orange hover:bg-transparent duration-300 hover:text-orange  bg-orange'
                } px-[30px] border rounded-[8px] py-[16px] sm:w-auto w-full text-white inline-flex justify-center gap-3`}
                disabled={!selectedShipping || selectShippingLoading}
              >
                Continuer
                {selectShippingLoading && (
                  <LittleWhiteLoader className="w-5 h-5" />
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Methods;
