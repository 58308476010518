/**
 * Valide une plaque d'immatriculation française (ancien et nouveau format)
 * @param {string} plaque - La plaque d'immatriculation à valider
 * @returns {boolean} - True si la plaque est valide, false sinon
 */
export function validerPlaqueImmatriculation(plaque) {
  // Supprimer les espaces et les tirets
  const plaqueNettoyee = plaque.replace(/[-\s]/g, '').toUpperCase();

  // Regex pour le nouveau format (AA-123-AA)
  const regexNouveauFormat = /^([A-HJ-NP-TV-Z]{2})(\d{3})([A-HJ-NP-TV-Z]{2})$/;

  // Regex pour l'ancien format (123 ABC 75 ou 1234 AB 75)
  const regexAncienFormat = /^(\d{1,4})([A-Z]{1,3})(\d{2,3})$/;

  // Vérifier si la plaque correspond à l'un des formats
  return (
    regexNouveauFormat.test(plaqueNettoyee) ||
    regexAncienFormat.test(plaqueNettoyee)
  );
}

/**
 * Formate une plaque d'immatriculation en ajoutant des tirets (nouveau format) ou des espaces (ancien format)
 * @param {string} plaque - La plaque d'immatriculation à formater
 * @returns {string} - La plaque formatée ou la plaque originale si le format n'est pas reconnu
 */
export function formaterPlaqueImmatriculation(plaque) {
  const plaqueNettoyee = plaque.replace(/[-\s]/g, '').toUpperCase();

  if (/^([A-HJ-NP-TV-Z]{2})(\d{3})([A-HJ-NP-TV-Z]{2})$/.test(plaqueNettoyee)) {
    // Nouveau format
    return `${plaqueNettoyee.slice(0, 2)}-${plaqueNettoyee.slice(
      2,
      5,
    )}-${plaqueNettoyee.slice(5)}`;
  } else if (/^(\d{1,4})([A-Z]{1,3})(\d{2,3})$/.test(plaqueNettoyee)) {
    // Ancien format
    const match = plaqueNettoyee.match(/^(\d{1,4})([A-Z]{1,3})(\d{2,3})$/);
    return `${match[1]} ${match[2]} ${match[3]}`;
  }

  return plaque;
}
