import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ProfileLayout from './ProfileLayout';
import FormInput from '../../components/CheckoutForm/FormInput';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import { GoCheck, GoPencil } from 'react-icons/go';
import ProfileInfoModal from '../../Modals/ProfileInfoModal';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { authStatus } from '../../utils/auth';
import Loader from '../../components/Loader/Loader';
import { patch } from '../../components/Api/Api';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/Reducers/auth';
import PasswordModal from '../../Modals/PasswordModal';
const ProfileInfo = () => {
  const {
    user,
    status: userStatus,
    isAuthenticated,
  } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  // Validation schema
  const infoValidationSchema = Yup.object({
    first_name: Yup.string().required('Ce champ est requis'),
    last_name: Yup.string().required('Ce champ est requis'),
    company_name: Yup.string(),
    vat_no: Yup.string(),
    siret: Yup.string(),
  });

  const emailValidationSchema = Yup.object({
    email: Yup.string().email('Email invalide').required('Ce champ est requis'),
    password: Yup.string().required('Ce champ est requis'),
  });

  const handleSubmit = (values, onSubmitProps) => {
    const payload = Object.keys(values).reduce((reducer, key) => {
      if (values[key] && values[key] !== '') reducer[key] = values[key];
      return reducer;
    }, {});
    patch('/users/me', payload).then((res) => {
      dispatch(setUser(res));
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({
        values: values,
      });
    });
  };
  return (
    <ProfileLayout>
      <div className="w-full">
        <div className="hidden slg:flex flex-col mb-4">
          <Link to={'/profile/profile-info'} className="flex items-center">
            <IoIosArrowRoundBack />
            Retour
          </Link>
          <h4 className="text-[32px] font-semibold font-jost">
            Informations personnelles
          </h4>
        </div>

        {userStatus === authStatus.loading || !isAuthenticated ? (
          <Loader />
        ) : (
          <>
            <Formik
              initialValues={{
                first_name: user.first_name || '',
                last_name: user.last_name || '',
                company_name: user.company_name || '',
                vat_no: user.vat_no || '',
                siret: user.meta?.siret || '',
              }}
              validationSchema={infoValidationSchema}
              onSubmit={handleSubmit}
            >
              {({ isValid, dirty, isSubmitting }) => (
                <Form>
                  <div className="bg-gray p-6 rounded-xl w-full">
                    <h3 className="text-[22px] font-jost font-semibold mb-6">
                      Informations personnelles
                    </h3>
                    <div className="grid grid-cols-2 smm:grid-cols-1 gap-5">
                      <FormInput name="first_name" placeholder="Prénom" />
                      <FormInput name="last_name" placeholder="Nom" />
                      <FormInput
                        name="company_name"
                        placeholder="Société (Optionnel)"
                      />
                      <FormInput
                        name="vat_no"
                        placeholder="Numéro de TVA (Optionnel)"
                      />
                      <FormInput name="siret" placeholder="SIRET (Optionnel)" />
                    </div>
                    <div className="flex items-center gap-3 mt-8">
                      <button
                        type="submit"
                        className={`px-[30px] outline-none font-semibold duration-300 rounded-[8px] py-[16px] sm:w-auto w-full inline-flex justify-center items-center gap-2  ${
                          isValid && dirty
                            ? 'bg-orange text-white cursor-pointer'
                            : 'bg-[#f8f9fd] text-[#858f90] cursor-not-allowed'
                        }`}
                        disabled={!(isValid && dirty)}
                      >
                        Enregistrer les Modifications
                        {isSubmitting && (
                          <LittleWhiteLoader className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="bg-gray p-6 pb-8 mt-5 rounded-xl">
              <h3 className="text-[22px] mb-5 font-jost font-semibold  mb-6">
                Compte
              </h3>
              {/* <div className="grid smm:grid-cols-1 grid-cols-2 gap-5">
                <Formik
                  initialValues={{
                    email: user.email,
                    password: '',
                  }}
                  validationSchema={emailValidationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isValid, dirty, isSubmitting }) => (
                    <Form>
                      <FormInput name="email" placeholder="Email" />
                      <div className="w-full flex flex-col">
                        <div className="border flex items-center pr-4 bg-white border-[#CBCDCD] rounded-lg h-14 w-full outline-none pl-4">
                          <Field
                            type={show ? 'text' : 'password'}
                            name="password"
                            className="outline-none h-full w-full"
                            placeholder="Mot de passe"
                            id="password"
                          />
                          <button
                            type="button"
                            className="text-[#abb3b9]"
                            onClick={() => setShow(!show)}
                          >
                            {!show ? <FiEye /> : <FiEyeOff />}
                          </button>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="small"
                          className="text-red-400"
                        />
                      </div>

                      <button
                        type="submit"
                        className={`px-[30px] outline-none font-semibold duration-300 rounded-[8px] py-[16px] sm:w-auto w-full inline-flex justify-center items-center gap-2  ${
                          isValid && dirty
                            ? 'bg-orange text-white cursor-pointer'
                            : 'bg-[#f8f9fd] text-[#858f90] cursor-not-allowed'
                        }`}
                        disabled={!(isValid && dirty)}
                      >
                        Enregistrer les Modifications
                        {isSubmitting && (
                          <LittleWhiteLoader className="w-5 h-5" />
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div> */}
              <div
                role="button"
                className="text-dark cursor-pointer flex items-center gap-2"
                onClick={() => setShowPasswordModal(!showPasswordModal)}
              >
                <span className="text-xl text-lightGrey">
                  <GoPencil />
                </span>
                Modifier le mot de passe
              </div>
            </div>
          </>
        )}
      </div>
      <PasswordModal
        showModal={showPasswordModal}
        setShowModal={setShowPasswordModal}
      />
    </ProfileLayout>
  );
};

export default ProfileInfo;
