import 'instantsearch.css/themes/satellite.css';
import { Link, useSearchParams } from 'react-router-dom';
import { MdArrowForwardIos } from 'react-icons/md';
import RestexInformes from '../../components/restez-informes/RestexInformes';
import { useEffect, useMemo, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import {
  Configure,
  Hits,
  InstantSearch,
  CurrentRefinements,
  Pagination,
  RangeInput,
  SortBy,
  ClearRefinements,
  Menu,
  SearchBox,
} from 'react-instantsearch';

import './style.css';
import { useWishList } from '../../contexts/WishListContext';
import {
  translateCatalogueLabelToFrench,
  translateFilterLabelToFrench,
} from '../../utils/productHelper';
import {
  Panel,
  StatePagination,
  MyRefinementList,
  Product,
  instantSearchOnStateChange,
  TyreFilters,
  RimFilters,
  VehicleFilters,
  PartFilters,
} from '../../components/products/ProductList';
import { history } from 'instantsearch.js/es/lib/routers';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

const hitsPerPage = 30;

const getProductType = (globalType) => {
  switch (globalType) {
    case 'Véhicules':
      return 'vehicules';
    case 'Pneus':
      return 'pneus';
    case 'Jantes':
      return 'jantes';
    case 'Pièces détachées':
      return 'pieces';
  }
};

const getCodeType = (globalType) => {
  switch (globalType) {
    case 'Véhicules':
      return 'vehicles';
    case 'Pneus':
      return 'tyres';
    case 'Jantes':
      return 'rims';
    case 'Pièces détachées':
      return 'parts';
    default:
      return null;
  }
};

const Catalogue = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { searchClient } = useMemo(
    () =>
      instantMeiliSearch(
        process.env.VITE_MEILISEARCH_HOST_URL,
        process.env.VITE_MEILISEARCH_API_KEY,
        {
          finitePagination: true,
        },
      ),
    [],
  );
  const { isItemInWishList } = useWishList();
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [codeType, setCodeType] = useState(null);
  const [previousGlobalType, setPreviousGlobalType] = useState(null);
  const [sortConfig, setSortConfig] = useState([
    {
      label: 'Par défaut',
      value: 'products_index',
    },
    {
      label: 'Prix croissant',
      value: 'products_index:price:asc',
    },
    {
      label: 'Prix décroissant',
      value: 'products_index:price:desc',
    },
    {
      label: 'Kilométrage croissant',
      value: 'products_index:mileage:asc',
    },
    {
      label: 'Kilométrage décroissant',
      value: 'products_index:mileage:desc',
    },
    {
      label: "Par date d'ajout",
      value: 'products_index:created_at:desc',
    },
  ]);

  useEffect(() => {
    const brandFilter = searchParams.get('refinementList[brand][0]');
    if (brandFilter) {
      setCurrentFilters({ brand: brandFilter });
    }
  }, []);

  useEffect(() => {
    setCodeType(getCodeType(currentFilters.globalType));
    if (!codeType || codeType === 'vehicles' || codeType === 'parts') {
      const newSortConfig = sortConfig.filter(
        (e) =>
          e.label !== 'Kilométrage croissant' &&
          e.label !== 'Kilométrage décroissant',
      );
      newSortConfig.splice(3, 0, {
        label: 'Kilométrage croissant',
        value: 'products_index:mileage:asc',
      });
      newSortConfig.splice(4, 0, {
        label: 'Kilométrage décroissant',
        value: 'products_index:mileage:desc',
      });
      setSortConfig(newSortConfig);
    } else {
      setSortConfig(
        sortConfig.filter(
          (e) =>
            e.label !== 'Kilométrage croissant' &&
            e.label !== 'Kilométrage décroissant',
        ),
      );
    }
  }, [currentFilters]);

  const routing = {
    router: history(),
    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState['products_index'];
        return {
          q: indexUiState.query,
          refinementList: indexUiState.refinementList,
          menu: indexUiState.menu,
          page: indexUiState.page,
          sortBy: indexUiState.sortBy,
        };
      },
      routeToState(routeState) {
        setCurrentFilters({
          ...routeState?.refinementList,
          ...routeState?.menu,
        });
        return {
          ['products_index']: {
            query: routeState.q,
            menu: routeState.menu,
            refinementList: routeState.refinementList,
            page: routeState.page,
            sortBy: routeState.sortBy,
          },
        };
      },
    },
  };

  return (
    <>
      <div className="wrapper flex flex-col gap-[8px] mb-[40px]">
        <div className="content flex gap-[12px] md:gap-[24px] items-center w-full py-[16px] 1400:px-0 px-[16px]">
          <p className="text-dark cursor-pointer transition">
            <Link to="/">Accueil</Link>
          </p>
          <MdArrowForwardIos className="text-[#9EA5A7] text-[12px]" />
          <p className="text-[#9EA5A7] cursor-pointer transition">
            <Link to="/catalogue">Catalogue</Link>
          </p>
        </div>
        <div className="search-result-cards content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[50px] 1400:px-0 px-[16px]">
          <InstantSearch
            searchClient={searchClient}
            indexName={'products_index'}
            routing={routing}
            onStateChange={({ uiState, setUiState }) => {
              const index = Object.keys(uiState)[0];

              // set globalType to tyre when manufacturer is selected
              if (
                uiState[index]?.refinementList &&
                uiState[index]?.refinementList.manufacturer
              ) {
                uiState[index].menu = {
                  globalType: 'Pneus',
                };
                uiState[index].refinementList.manufacturer =
                  uiState[index]?.refinementList.manufacturer;
                setPreviousGlobalType('Pneus');
              } else {
                // delete all filters when change globalType for products page
                if (
                  uiState[index]?.menu &&
                  previousGlobalType !== uiState[index]?.menu?.globalType
                ) {
                  delete uiState[index].refinementList;
                  delete uiState[index].sortBy;
                  uiState[index].menu = {
                    globalType: uiState[index].menu.globalType,
                  };
                }
                setPreviousGlobalType(
                  uiState[index]?.menu && uiState[index]?.menu?.globalType
                    ? uiState[index]?.menu?.globalType
                    : null,
                );
              }

              return instantSearchOnStateChange(
                { uiState, setUiState },
                setCurrentFilters,
                [currentPage, setCurrentPage],
                codeType,
              );
            }}
          >
            <Loader instantSearch />
            <Configure hitsPerPage={hitsPerPage}></Configure>

            <div className="flex-col gap-[12px] lg:gap-[48px] hidden md:flex">
              <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[34px] font-[600] text-dark">
                Notre catalogue
              </h1>
            </div>
            <div className="w-full flex flex-col md:flex-row gap-[70px] md:gap-[20px] lg:gap-[48px]">
              {/* Filters  */}
              <div className="w-full bg-gray p-[12px] rounded-[8px] md:p-0 md:bg-transparent md:max-w-[250px] 900:max-w-[300px] flex flex-col gap-[12px] 900:gap-[24px]">
                <div>
                  {currentFilters && Object.keys(currentFilters).length > 0 && (
                    <ClearRefinements
                      translations={{
                        resetButtonText: 'Réinitialiser les filtres',
                      }}
                    />
                  )}
                  <SearchBox placeholder="Rechercher" className="mb-6" />
                  <Panel header="Type d'article">
                    <Menu attribute="globalType" />
                  </Panel>
                  <div hidden={codeType}>
                    <Panel header="Marque">
                      <MyRefinementList
                        attribute="brand"
                        operator="and"
                        searchable={true}
                        searchablePlaceholder="Marque"
                        showMore={true}
                      />
                    </Panel>
                    <Panel
                      header="Modèle"
                      hidden={!currentFilters || !currentFilters.brand}
                    >
                      <MyRefinementList
                        attribute="globalModel"
                        searchable={true}
                        operator="and"
                        searchablePlaceholder="Modèle"
                        showMore={true}
                      />
                    </Panel>
                    <Panel header="Prix">
                      <RangeInput
                        attribute="price"
                        precision={0}
                        translations={{
                          submitButtonText: 'Valider',
                          separatorElementText: ' à ',
                        }}
                      />
                    </Panel>
                    <Panel header="Motorisation">
                      <MyRefinementList
                        attribute="energy"
                        searchable={false}
                        operator="or"
                        searchablePlaceholder="Motorisation"
                        showMore={true}
                        showMoreLimit={100000}
                        limit={10}
                        // transformItems={(items) => (
                        //     items.map((item) => ({
                        //         ...item,
                        //         label: translateFilterLabelToFrench(item.label)
                        //     }))
                        // )}
                      />
                    </Panel>
                  </div>
                  <VehicleFilters
                    currentFilters={currentFilters}
                    hidden={codeType !== 'vehicles'}
                  />
                  <PartFilters
                    currentFilters={currentFilters}
                    hidden={codeType !== 'parts'}
                  />
                  <RimFilters
                    currentFilters={currentFilters}
                    hidden={codeType !== 'rims'}
                  />
                  <TyreFilters
                    currentFilters={currentFilters}
                    hidden={codeType !== 'tyres'}
                  />
                </div>
              </div>
              {/* Products  */}
              <div className="w-full flex flex-col gap-[15px]">
                <div className="flex flex-col gap-[12px] lg:gap-[48px] md:hidden">
                  <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
                    Nos produits
                  </h1>
                </div>
                {/* pRODUCTS tOP bAR  */}
                <div className="flex w-full items-center justify-between">
                  <div className="flex gap-[24px] items-center"></div>
                  <div className="flex items-center gap-[24px]">
                    <div className="flex gap-[8px] font-[400] text-blue items-center">
                      <img
                        src="/menu-blue.png"
                        alt=""
                        className="max-w-[16px] max-h-[16px]"
                      />
                      Tri
                      <SortBy items={sortConfig}></SortBy>
                    </div>
                    <div className="w-[1px] h-[24px] bg-[#9ea5a7c4]" />
                    <div className="text-[#9EA5A7] hover:text-dark cursor-pointer transition">
                      <StatePagination hitsPerPage={hitsPerPage} />
                    </div>
                  </div>
                </div>
                <CurrentRefinements
                  transformItems={(items) => {
                    items.forEach((item) => {
                      item.label = translateCatalogueLabelToFrench(item.label);
                      item.refinements.forEach((refinement) => {
                        refinement.label = translateFilterLabelToFrench(
                          refinement.label,
                        );
                      });
                    });
                    return items;
                  }}
                />
                <Hits
                  classNames={{
                    root: 'w-full flex-1 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-x-[12px] 1200:gap-x-[24px] gap-y-[24px] 1200:gap-y-[48px]',
                    list: 'relative contents',
                  }}
                  hitComponent={({ hit }) => (
                    <Product
                      key={'product-' + hit.id}
                      title={hit.title}
                      price={hit.price}
                      liked={isItemInWishList(hit)}
                      src={hit.mainImage}
                      id={Number(hit.refSource)}
                      type={getProductType(hit.globalType)}
                      product={hit}
                    />
                  )}
                />
                <Pagination className="Pagination" />
              </div>
            </div>
          </InstantSearch>
        </div>
      </div>
      <RestexInformes />
    </>
  );
};

export default Catalogue;
