import PasswordInput from '../Form/PasswordInput';
import { useState } from 'react';
import { login } from '../../utils/auth';
import LittleWhiteLoader from '../Loader/LittleWhiteLoader';
import FormError from '../Form/FormError';
import { translateError } from '../../utils/translatting';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FormInput from '../CheckoutForm/FormInput';
import { Link } from 'react-router-dom';

export default function LoginForm({
  onSuccess = () => {
    window.location.reload();
  },
  closeModal = () => {},
  className = '',
}) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = (values) => {
    setLoading(true);
    login(values.email, values.password)
      .then(() => {
        onSuccess();
      })
      .catch((error) => {
        setError(translateError(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const validationSchema = Yup.object({
    email: Yup.string().email('Email non valide').required('Email requis'),
    password: Yup.string().required('Mot de passe requis'),
  });

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validateOnChange={true}
    >
      {({ isValid }) => (
        <Form className={className}>
          <div className="flex flex-col gap-4 mb-4">
            <FormInput name="email" placeholder="E-mail" />
            <PasswordInput
              name="password"
              className="outline-none h-full w-full"
              placeholder="Mot de passe"
              forFormik
            />
          </div>
          {error && <FormError className="mb-2" text={error} />}

          <Link
            to="/forgot-password"
            className="text-center text-sm text-orange underline hover:opacity-[.8] duration-200 flex my-4"
            onClick={closeModal}
          >
            Mot de passe oublié ?
          </Link>
          <button
            type="submit"
            className={`px-[30px] border
                duration-300 rounded-[8px] py-[16px]
                w-full text-white inline-flex justify-center gap-3 ${
                  !isValid
                    ? 'bg-zinc-500'
                    : 'bg-orange hover:text-orange border-orange hover:bg-transparent'
                }`}
            disabled={loading || !isValid}
          >
            Se connecter
            {loading && <LittleWhiteLoader className="w-5 h-5" />}
          </button>
        </Form>
      )}
    </Formik>
  );
}
