import { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { del, get, post } from '../components/Api/Api';
import { useSelector } from 'react-redux';
import secureLocalStorage from 'react-secure-storage';
import { authStatus } from '../utils/auth';
import { getFailoverImage } from '../utils/productHelper';
import Modal from '../Modals/Modal';
import { Link } from 'react-router-dom';
import LittleWhiteLoader from '../components/Loader/LittleWhiteLoader';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartId, setCartId] = useState(secureLocalStorage.getItem('cart_id'));
  const [cartCustomer, setCartCustomer] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [cartLoading, setCartLoading] = useState(true);
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [nbCartItems, setNbCartItems] = useState(0);
  const [totalProductCost, setTotalProductCost] = useState(0);
  const [totalCartCost, setTotalCartCost] = useState(0);
  const [cartAddresses, setCartAddresses] = useState(null);
  const [cartImmat, setCartImmat] = useState(null);
  const [cartShippingOption, setCartShippingOption] = useState(null);
  const [isCartModalSuccessOpen, setIsCartModalSuccessOpen] = useState(false);
  const [isCartModalWarningOpen, setIsCartModalWarningOpen] = useState(false);
  const [certifyProfessional, setCertifyProfessional] = useState(false);
  const [cartItemId, setCartItemId] = useState(null);

  const { isAuthenticated, status: authenticationStatus } = useSelector(
    (state) => {
      return state.auth;
    },
  );

  const getItemFailover = (item) => {
    let type;
    switch (item.purchasable.productable_type) {
      case 'App\\Models\\Tyre':
        type = 'pneus';
        break;
      case 'App\\Models\\Rim':
        type = 'jantes';
        break;
      case 'App\\Models\\Part':
        type = 'pieces';
        break;
      default:
        type = null;
        break;
    }

    return getFailoverImage(type, item.purchasable);
  };

  const getHeaders = (authCheck = true) => {
    return (authCheck && !isAuthenticated) ||
      (!authCheck && secureLocalStorage.getItem('cart_id'))
      ? { 'cart-id': secureLocalStorage.getItem('cart_id') }
      : {};
  };

  const reloadCart = () => {
    setCartLoading(true);
    if (authenticationStatus !== authStatus.loading) {
      setCartId(secureLocalStorage.getItem('cart_id'));
      if (!isAuthenticated && !cartId) {
        setCartLoading(false);
        setCartItems([]);
        setCartCustomer(null);
      } else {
        const headers = getHeaders(false);
        get('shop/cart', headers)
          .then((data) => {
            setCartAddresses({
              shipping_address: data.shipping_address ?? null,
              billing_address: data.billing_address ?? null,
            });
            setCartImmat(data.meta?.immat ?? null);
            setCartId(data.id);
            setCartItems(data.lines);
            setCartCustomer(data.customer ?? null);
            setCartShippingOption(data.shipping_option ?? null);
          })
          .catch((err) => {
            if (err.code === 404) {
              setCartId(null);
              setCartItems([]);
              setCartCustomer(null);
              secureLocalStorage.removeItem('cart_id');
            }
          })
          .finally(() => {
            setCartLoading(false);

            if (isAuthenticated) {
              secureLocalStorage.removeItem('cart_id');
              setCartId(null);
            }
          });
      }
    }
  };

  useEffect(() => {
    if (authenticationStatus != authStatus.loading) {
      reloadCart();
    } else {
      setCartLoading(true);
    }
  }, [isAuthenticated, authenticationStatus]);

  useEffect(() => {
    setNbCartItems(cartItems.length);
    setTotalProductCost(
      cartItems.reduce(
        (acc, item) => acc + parseFloat(item.purchasable.price),
        0,
      ),
    );
  }, [cartItems]);

  useEffect(() => {
    setNbCartItems(cartItems.length);
    setTotalCartCost(
      totalProductCost +
        ((cartItems.length > 1 ||
          cartAddresses?.shipping_address?.country.iso2 !== 'FR') &&
        !cartShippingOption?.collect
          ? 0
          : (cartShippingOption?.price?.value ?? 0) / 100),
    );
  }, [totalProductCost, cartShippingOption]);

  const isItemInCart = (itemId) => {
    return cartItems.some((itemCart) => itemCart.id === itemId);
  };

  const confirmAddToCart = () => {
    if (!certifyProfessional) {
      toast.error('Vous devez certifier que vous êtes un professionnel');
      return;
    }
    addToCart(cartItemId, false);
  };

  useEffect(() => {
    setCertifyProfessional(false);
  }, [isCartModalWarningOpen]);

  const addToCart = (itemId, showWarning = false) => {
    if (showWarning) {
      setCartItemId(itemId);
      setIsCartModalWarningOpen(true);
      return;
    }

    setAddToCartLoading(true);
    if (isItemInCart(itemId)) {
      toast.error('Le produit est déjà présent dans le panier');
      setAddToCartLoading(false);
      setIsCartModalWarningOpen(false);
      setCertifyProfessional(false);
    } else {
      const headers = getHeaders(false);
      setCartLoading(true);

      post(
        'shop/add_to_cart',
        {
          product_id: itemId,
          quantity: 1,
        },
        headers,
      )
        .then((data) => {
          setCartItems(data.lines);
          secureLocalStorage.setItem('cart_id', data.id);
          setCartId(data.id);
          setCartShippingOption(data.shipping_option ?? null);
          setIsCartModalSuccessOpen(true);
        })
        .catch((err) => {
          if (err.message === 'Product already on cart') {
            toast.error('Le produit est déjà présent dans le panier');
          } else if (
            err.message === 'You cannot add more than the available stock.'
          ) {
            toast.error("Le produit n'est plus disponible");
          } else {
            toast.error("Une erreur s'est produite lors de l'ajout au panier");
          }
        })
        .finally(() => {
          setCartLoading(false);
          setAddToCartLoading(false);
          setIsCartModalWarningOpen(false);
          setCertifyProfessional(false);
        });
    }
  };

  const removeFromCart = (itemId) => {
    const headers = cartId ? { 'cart-id': cartId } : {};

    setCartLoading(true);

    del(`shop/cart_lines/${itemId}`, headers)
      .then((data) => {
        setCartItems(data.lines);
        setCartShippingOption(data.shipping_option ?? null);
      })
      .catch((err) => {
        toast.error(
          "Une erreur s'est produite lors de la suppression du produit",
        );
      })
      .finally(() => {
        setCartLoading(false);
      });
  };

  const setShippingCost = (amount) => {
    setTotalCartCost(totalProductCost + amount);
  };

  const openCart = () => setIsCartOpen(true);
  const closeCart = () => setIsCartOpen(false);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        cartId,
        addToCart,
        removeFromCart,
        isCartOpen,
        openCart,
        closeCart,
        totalProductCost,
        setCartShippingOption,
        totalCartCost,
        nbCartItems,
        isItemInCart,
        cartLoading,
        addToCartLoading,
        cartCustomer,
        reloadCart,
        cartAddresses,
        cartShippingOption,
        getHeaders,
        getItemFailover,
        cartImmat,
        setCartImmat,
        setCartCustomer,
        setCartAddresses,
      }}
    >
      <>
        <Modal
          showModal={isCartModalSuccessOpen}
          setShowModal={setIsCartModalSuccessOpen}
          className="py-12"
        >
          <div>
            <p className="text-2xl text-center font-semibold">
              Le produit a bien été ajouté au panier !
            </p>
            <div className="flex flex-col sm:flex-row justify-center mt-12 gap-4 sm:gap-8">
              <button
                onClick={() => setIsCartModalSuccessOpen(false)}
                className="text-orange border border-orange px-[50px] py-[16px] rounded-md text-center hover:bg-orange hover:text-white duration-300"
              >
                Continuer mes achats
              </button>
              <Link
                to="/checkout"
                onClick={() => setIsCartModalSuccessOpen(false)}
                className="bg-orange text-white px-[50px] py-[16px] rounded-md text-center"
              >
                Finaliser la commande
              </Link>
            </div>
          </div>
        </Modal>
        <Modal
          showModal={isCartModalWarningOpen}
          setShowModal={setIsCartModalWarningOpen}
          className="py-12"
        >
          <div>
            <p className="text-2xl text-center font-semibold mb-8">
              ⚠️ Attention : Vente réservée aux professionnels
            </p>
            <p className="text-lg text-center font-medium mb-14">
              Ce produit est strictement réservé aux professionnels autorisés à
              l’acheter.
            </p>
            <div className="flex justify-center items-start">
              <input
                type="checkbox"
                id="professional-sale"
                className="mr-2 h-[24px]"
                onChange={(e) => setCertifyProfessional(e.target.checked)}
              />
              <label
                htmlFor="professional-sale"
                className="font-semibold text-md"
              >
                Je certifie être un professionnel habilité à acheter cet
                article.
              </label>
            </div>
            <div className="flex flex-col sm:flex-row justify-center mt-8 gap-4 sm:gap-8">
              <button
                onClick={() => setIsCartModalWarningOpen(false)}
                className="text-orange border border-orange px-[50px] py-[16px] rounded-md text-center hover:bg-orange hover:text-white duration-300"
              >
                Retour
              </button>
              <button
                onClick={() => confirmAddToCart()}
                className={`bg-orange text-white px-[50px] py-[16px] rounded-md text-center flex items-center justify-center gap-2 ${
                  !certifyProfessional ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={!certifyProfessional || addToCartLoading}
              >
                Confirmer et ajouter au panier{' '}
                {addToCartLoading && <LittleWhiteLoader className="w-5 h-5" />}
              </button>
            </div>
          </div>
        </Modal>
        {children}
      </>
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
