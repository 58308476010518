import 'instantsearch.css/themes/satellite.css';
import { Link, useLocation } from 'react-router-dom';
import { MdArrowForwardIos } from 'react-icons/md';
import RestexInformes from '../../components/restez-informes/RestexInformes';
import { useEffect, useMemo, useState } from 'react';
import Loader from '../../components/Loader/Loader';
import {
  filterMarques,
  filterModeles,
  filterEnergie,
  filterProcedure,
  filterPrix,
  filterAnnee,
  filterDimensions,
  filterTypePiece,
} from './Filters';
import {
  Configure,
  Hits,
  InstantSearch,
  Menu,
  CurrentRefinements,
  Pagination,
  RangeInput,
  ToggleRefinement,
  useInstantSearch,
  SortBy,
  ClearRefinements,
} from 'react-instantsearch';

import './style.css';
import { useWishList } from '../../contexts/WishListContext';
import TyreFilter from '../../components/commons/TyreFilter/TyreFilter';
import {
  translateCatalogueLabelToFrench,
  translateFilterLabelToFrench,
} from '../../utils/productHelper';
import {
  Panel,
  Product,
  StatePagination,
  MyRefinementList,
  instantSearchOnStateChange,
  TyreFilters,
  RimFilters,
  VehicleFilters,
  PartFilters,
} from '../../components/products/ProductList';
import { history } from 'instantsearch.js/es/lib/routers';
import { simple } from 'instantsearch.js/es/lib/stateMappings';
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';

const hitsPerPage = 30;

const config = {
  products: {
    title: 'Notre catalogue',
    url: 'catalogue',
  },
  vehicules: {
    title: 'Nos véhicules',
    url: 'vehicules',
    filters: [
      { name: 'Marque', value: '', comp: filterMarques },
      { name: 'Modèle', value: '', comp: filterModeles },
      { name: 'Énergie', value: '', comp: filterEnergie },
      { name: 'Procédure', value: '', comp: filterProcedure },
      { name: 'Prix', value: '', comp: filterPrix },
      {
        name: 'Année de mise en circulation',
        value: '',
        comp: filterAnnee,
      },
    ],
    sortBy: [
      {
        label: 'Par défaut',
        value: 'vehicles_index',
      },
      {
        label: 'Prix croissant',
        value: 'vehicles_index:price:asc',
      },
      {
        label: 'Prix décroissant',
        value: 'vehicles_index:price:desc',
      },
      {
        label: 'Kilométrage croissant',
        value: 'vehicles_index:mileage:asc',
      },
      {
        label: 'Kilométrage décroissant',
        value: 'vehicles_index:mileage:desc',
      },
      {
        label: "Par date d'ajout",
        value: 'vehicles_index:created_at:desc',
      },
    ],
  },
  pneus: {
    title: "Nos pneus d'occasion",
    url: 'pneus-d-occasion',
    filters: [
      { name: 'Marque', value: '', comp: filterMarques },
      { name: 'Modèle', value: '', comp: filterModeles },
      { name: 'Dimension', value: '', comp: filterDimensions },
      { name: 'Prix', value: '', comp: filterPrix },
    ],
    sortBy: [
      {
        label: 'Par défaut',
        value: 'tyres_index',
      },
      {
        label: 'Prix croissant',
        value: 'tyres_index:price:asc',
      },
      {
        label: 'Prix décroissant',
        value: 'tyres_index:price:desc',
      },
      {
        label: "Par date d'ajout",
        value: 'tyres_index:created_at:desc',
      },
    ],
  },
  jantes: {
    title: "Nos jantes d'occasion",
    url: 'jantes-d-occasion',
    filters: [
      { name: 'Marque', value: '', comp: filterMarques },
      { name: 'Modèle', value: '', comp: filterModeles },
      { name: 'Dimension', value: '', comp: filterDimensions },
      { name: 'Prix', value: '', comp: filterPrix },
    ],
    sortBy: [
      {
        label: 'Par défaut',
        value: 'rims_index',
      },
      {
        label: 'Prix croissant',
        value: 'rims_index:price:asc',
      },
      {
        label: 'Prix décroissant',
        value: 'rims_index:price:desc',
      },
      {
        label: "Par date d'ajout",
        value: 'rims_index:created_at:desc',
      },
    ],
  },
  pieces: {
    title: "Nos pièces d'occasion",
    url: 'pieces-d-occasion',
    filters: [
      { name: 'Marque', value: '', comp: filterMarques },
      { name: 'Modèle', value: '', comp: filterModeles },
      { name: 'Type de pièce', value: '', comp: filterTypePiece },
      { name: 'Prix', value: '', comp: filterPrix },
    ],
    sortBy: [
      {
        label: 'Par défaut',
        value: 'parts_index',
      },
      {
        label: 'Prix croissant',
        value: 'parts_index:price:asc',
      },
      {
        label: 'Prix décroissant',
        value: 'parts_index:price:desc',
      },
      {
        label: 'Kilométrage croissant',
        value: 'parts_index:mileage:asc',
      },
      {
        label: 'Kilométrage décroissant',
        value: 'parts_index:mileage:desc',
      },
      {
        label: "Par date d'ajout",
        value: 'parts_index:created_at:desc',
      },
    ],
  },
};
const Products = () => {
  const { searchClient } = useMemo(
    () =>
      instantMeiliSearch(
        process.env.VITE_MEILISEARCH_HOST_URL,
        process.env.VITE_MEILISEARCH_API_KEY,
        {
          finitePagination: true,
        },
      ),
    [],
  );
  const [currentFilters, setCurrentFilters] = useState({});
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);

  const EmptyQueryBoundary = ({ children, fallback }) => {
    const { indexUiState } = useInstantSearch();

    if (!indexUiState.query || indexUiState.query.length < 1) {
      return fallback;
    }

    return children;
  };

  let initialFilters = {};
  let initialProductType = 'vehicules';

  const { isItemInWishList } = useWishList();

  var defaultCarsType = 0;
  if (window.location.pathname === '/vehicules-d-occasion') {
    initialProductType = 'vehicules';
    defaultCarsType = 2;
  }

  if (window.location.pathname === '/vehicules-accidentes') {
    initialProductType = 'vehicules';
    defaultCarsType = 1;
  }

  const regex = /\/(.*)-d-occasion/;
  const match = window.location.pathname.match(regex);
  if (match) {
    initialProductType = match[1];
  }

  const correspondance = {
    vehicules: 'vehicles',
    pneus: 'tyres',
    jantes: 'rims',
    pieces: 'parts',
  };

  const [codeType, setCodeType] = useState(correspondance[initialProductType]);

  const [activeCars, setActiveCars] = useState(0);

  // Fonction pour obtenir le type de véhicule en fonction de l'URL
  const getVehicleType = () => {
    if (location.pathname === '/vehicules-accidentes') {
      return 'Accidenté';
    } else if (location.pathname === '/vehicules-d-occasion') {
      return 'Occasion';
    }
    return '';
  };
  let initialVehicleType;

  useEffect(() => {
    if (location.pathname === '/vehicules') {
      setActiveCars(0);
    } else if (location.pathname === '/vehicules-accidentes') {
      setActiveCars(1);
    } else if (location.pathname === '/vehicules-d-occasion') {
      setActiveCars(2);
    }
  }, [location]);
  initialVehicleType = getVehicleType();

  const productData = config[initialProductType];
  const isVehicle = initialProductType === 'vehicules';

  let FilterComponent;
  switch (codeType) {
    case 'tyres':
      FilterComponent = TyreFilters;
      break;
    case 'rims':
      FilterComponent = RimFilters;
      break;
    case 'vehicles':
      FilterComponent = VehicleFilters;
      break;
    case 'parts':
      FilterComponent = PartFilters;
      break;
    default:
      FilterComponent = null;
      break;
  }

  const routing = {
    router: history(),
    stateMapping: {
      stateToRoute(uiState) {
        const indexUiState = uiState[`${codeType}_index`];
        return {
          q: indexUiState.query,
          refinementList: indexUiState.refinementList,
          menu: indexUiState.menu,
          page: indexUiState.page,
          sortBy: indexUiState.sortBy,
        };
      },
      routeToState(routeState) {
        setCurrentFilters({
          ...routeState?.refinementList,
          ...routeState?.menu,
        });
        return {
          [`${codeType}_index`]: {
            query: routeState.q,
            menu: routeState.menu,
            refinementList: routeState.refinementList,
            page: routeState.page,
            sortBy: routeState.sortBy,
          },
        };
      },
    },
  };

  return (
    <>
      <div className="wrapper flex flex-col gap-[8px] mb-[40px]">
        <div className="content flex gap-[12px] md:gap-[24px] items-center w-full py-[16px] 1400:px-0 px-[16px]">
          <p className="text-dark cursor-pointer transition">
            <Link to="/">Accueil</Link>
          </p>
          <MdArrowForwardIos className="text-[#9EA5A7] text-[12px]" />
          <p className="text-[#9EA5A7] cursor-pointer transition">
            <Link to={'/' + productData.url}>{productData.title}</Link>
          </p>
        </div>
        <div className="search-result-cards content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[50px] 1400:px-0 px-[16px]">
          <InstantSearch
            searchClient={searchClient}
            indexName={`${codeType}_index`}
            routing={routing}
            onStateChange={(uiState) =>
              instantSearchOnStateChange(
                uiState,
                setCurrentFilters,
                [currentPage, setCurrentPage],
                codeType,
              )
            }
          >
            <Loader instantSearch />
            <Configure
              hitsPerPage={hitsPerPage}
              facetsRefinements={
                initialVehicleType
                  ? { type: [`${initialVehicleType}`] }
                  : undefined
              }
            ></Configure>
            {/* handle active cars */}

            <div className="flex-col gap-[12px] lg:gap-[48px] hidden md:flex">
              <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[34px] font-[600] text-dark">
                {productData.title}
              </h1>
              {isVehicle && (
                <div className="flex gap-[8px] md:gap-[14px] flex-wrap">
                  <Link
                    onClick={() => window.location.replace('/vehicules')}
                    to="/vehicules"
                    className={`px-[16px] md:px-[24px] py-[6px] text-[14px] md:text-[16px] rounded-[32px] ${
                      window.location.pathname === '/vehicules'
                        ? 'bg-dark text-[white]'
                        : 'text-dark border-[1px] border-dark'
                    }`}
                  >
                    Tous
                  </Link>
                  <Link
                    to={encodeURI('/vehicules-d-occasion')}
                    onClick={() =>
                      window.location.replace('/vehicules-d-occasion')
                    }
                    className={`px-[16px] md:px-[24px] py-[6px] text-[14px] md:text-[16px] rounded-[32px] ${
                      window.location.pathname === '/vehicules-d-occasion'
                        ? 'bg-dark text-[white]'
                        : 'text-dark border-[1px] border-dark'
                    }`}
                  >
                    Véhicules d’occasion
                  </Link>
                  <Link
                    to={encodeURI('/vehicules-accidentes')}
                    onClick={() =>
                      window.location.replace('/vehicules-accidentes')
                    }
                    className={`px-[16px] md:px-[24px] py-[6px] text-[14px] md:text-[16px] rounded-[32px] ${
                      window.location.pathname === '/vehicules-accidentes'
                        ? 'bg-dark text-[white]'
                        : 'text-dark border-[1px] border-dark'
                    }`}
                  >
                    Véhicules accidentés
                  </Link>
                </div>
              )}
            </div>

            <div className="flex flex-col gap-[12px] lg:gap-[48px] mb-8 md:hidden">
              <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
                {productData.title}
              </h1>
              {isVehicle && (
                <div className="flex gap-[8px] md:gap-[14px] flex-wrap">
                  <Link
                    onClick={() => window.location.replace('/vehicules')}
                    to="/vehicules"
                    className={`px-[16px] md:px-[24px] py-[6px] text-[14px] md:text-[16px] rounded-[32px] ${
                      window.location.pathname === '/vehicules'
                        ? 'bg-dark text-[white]'
                        : 'text-dark border-[1px] border-dark'
                    }`}
                  >
                    Tous
                  </Link>
                  <Link
                    to={encodeURI('/vehicules-d-occasion')}
                    onClick={() =>
                      window.location.replace('/vehicules-d-occasion')
                    }
                    className={`px-[16px] md:px-[24px] py-[6px] text-[14px] md:text-[16px] rounded-[32px] ${
                      window.location.pathname === '/vehicules-d-occasion'
                        ? 'bg-dark text-[white]'
                        : 'text-dark border-[1px] border-dark'
                    }`}
                  >
                    Véhicules d’occasion
                  </Link>
                  <Link
                    to={encodeURI('/vehicules-accidentes')}
                    onClick={() =>
                      window.location.replace('/vehicules-accidentes')
                    }
                    className={`px-[16px] md:px-[24px] py-[6px] text-[14px] md:text-[16px] rounded-[32px] ${
                      window.location.pathname === '/vehicules-accidentes'
                        ? 'bg-dark text-[white]'
                        : 'text-dark border-[1px] border-dark'
                    }`}
                  >
                    Véhicules accidentés
                  </Link>
                </div>
              )}
            </div>

            <div className="w-full flex flex-col md:flex-row gap-[70px] md:gap-[20px] lg:gap-[48px]">
              {/* Filters  */}
              <div className="w-full bg-gray p-[12px] rounded-[8px] md:p-0 md:bg-transparent md:max-w-[250px] 900:max-w-[300px] flex flex-col gap-[12px] 900:gap-[24px]">
                <div>
                  {currentFilters && Object.keys(currentFilters).length > 0 && (
                    <ClearRefinements
                      translations={{
                        resetButtonText: 'Réinitialiser les filtres',
                      }}
                    />
                  )}
                  {FilterComponent && (
                    <FilterComponent currentFilters={currentFilters} />
                  )}
                </div>
              </div>
              {/* Products  */}
              <div className="w-full flex flex-col gap-[15px]">
                {/* pRODUCTS tOP bAR  */}
                <div className="flex w-full items-center justify-between">
                  <div className="flex gap-[24px] items-center">
                    {/*<p className="flex gap-[8px] font-[600] text-blue items-center cursor-pointer">
                                    Défaut <img src="/menu-blue.png" alt="" className="max-w-[16px] max-h-[16px]" />
                                </p>
                                <div className="flex gap-[24px] hidden 1100:flex">
                                    <p className="text-[#9EA5A7] hover:text-dark cursor-pointer transition">
                                        Prix croissant
                                    </p>
                                    <p className="text-[#9EA5A7] hover:text-dark cursor-pointer transition">
                                        Prix décroissant
                                    </p>
                                    <p className="text-[#9EA5A7] hover:text-dark cursor-pointer transition">
                                        Par date d’ajout
                                    </p>
                                    </div>*/}
                  </div>
                  <div className="flex items-center gap-[24px]">
                    <div className="flex gap-[8px] font-[400] text-blue items-center cursor-pointer">
                      <img
                        src="/menu-blue.png"
                        alt=""
                        className="max-w-[16px] max-h-[16px]"
                      />
                      Tri
                      <SortBy items={productData.sortBy}></SortBy>
                    </div>
                    <div className="w-[1px] h-[24px] bg-[#9ea5a7c4]" />
                    <div className="text-[#9EA5A7] hover:text-dark cursor-pointer transition">
                      <StatePagination hitsPerPage={hitsPerPage} />
                    </div>
                  </div>
                </div>
                <CurrentRefinements
                  transformItems={(items) => {
                    if (window.location.pathname !== '/vehicules') {
                      items = items.filter((item) => item.attribute !== 'type');
                    }
                    items.forEach((item) => {
                      item.label = translateCatalogueLabelToFrench(item.label);
                      item.refinements.forEach((refinement) => {
                        refinement.label = translateFilterLabelToFrench(
                          refinement.label,
                        );
                      });
                    });
                    return items;
                  }}
                />

                <Hits
                  classNames={{
                    root: 'w-full flex-1 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-x-[12px] 1200:gap-x-[24px] gap-y-[24px] 1200:gap-y-[48px]',
                    list: 'relative contents',
                    /*item: ' h-[30px] align-middle cursor-pointer	 leading-[30px]'*/
                    /*absolute top-full pt-[6px] left-[0px] w-[100vw] xl:w-[90vw] z-[50] max-w-[70vw] xl:max-w-[1065px] max-h-[360px] overflow-y-auto  */
                  }}
                  hitComponent={({ hit, sendEvent }) => (
                    <Product
                      key={'product-' + hit.id}
                      title={hit.title}
                      price={hit.price}
                      liked={isItemInWishList(hit)}
                      src={hit.mainImage}
                      id={Number(hit.refSource)}
                      type={initialProductType}
                      product={hit}
                    />
                  )}
                />
                <Pagination className="Pagination" />
                {/*products.map((product)=> (
                                <Product 
                                    key={'product-'+ product.id}
                                    title={product.title}
                                    price={product.price}
                                    liked={Math.random() < 0.2}
                                    src={product.mainImage}
                                    id={product.refSource}
                                    type={initialProductType}
                                    product={product}
                                />
                            ))*/}
                {/*<ReactPaginate
                            containerClassName={"list-style-none flex justify-end"}
                            breakLabel="..."
                            nextLabel="Suivant"
                            onPageChange={handlePageClick}
                            breakClassName={"relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300 dark:text-neutral-400 cursor-none"}
                            pageRangeDisplayed={6}
                            pageCount={totalPages}
                            activeLinkClassName={"relative block rounded bg-transparent px-3 py-1.5 text-neutral-600 text-[#bbb] bg-[#f4f4f4] hover:none dark:hover:none dark:hover:none"}
                            pageLinkClassName={"relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-600 hover:bg-neutral-100 dark:hover:bg-neutral-700 dark:hover:text-white text-orange"}
                            previousLabel="Précédent"
                            previousClassName={" relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300 dark:text-neutral-400"}
                            nextClassName={"relative block rounded bg-transparent px-3 py-1.5 text-sm text-neutral-500 transition-all duration-300 dark:text-neutral-400"}
                            renderOnZeroPageCount={null}
                        />*/}
              </div>
            </div>
          </InstantSearch>
        </div>
      </div>
      <RestexInformes />
    </>
  );
};

export default Products;
