import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { GoPencil } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { addressCheck } from '../../store/Reducers/CheckoutSlice';
import { IoCheckmark } from 'react-icons/io5';
import { get, post } from '../../components/Api/Api';
import AddressForm, {
  disabledCountry,
  formatAddressData,
  getAdditionalFieldsSchema,
  getAddressData,
  getCountriesOptions,
  getInitialAddressData,
} from '../../components/CheckoutForm/AddressForm';
import AddressCard from '../../components/CheckoutForm/AddressCard';
import UseDifferentAddressToggle from '../../components/CheckoutForm/UseDifferentAddressToggle';
import { authStatus } from '../../utils/auth';
import LittleLoader from '../../components/Loader/LittleLoader';
import AddressModal from '../../Modals/AddressModal';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';
import { useCart } from '../../contexts/CartContext';
import { translateError } from '../../utils/translatting';
import FormError from '../../components/Form/FormError';
import secureLocalStorage from 'react-secure-storage';

const Address = () => {
  const dispatch = useDispatch();

  const isImmatFilled = useSelector(
    (state) => state.CheckoutSlice.isImmatFilled,
  );

  const { isAuthenticated, status, user } = useSelector((state) => {
    return state.auth;
  });

  const {
    cartCustomer,
    cartLoading,
    cartAddresses,
    setCartAddresses,
    getHeaders,
    setCartShippingOption,
  } = useCart();

  const [showAddModal, setShowAddModal] = useState(false);
  const [addAddressLoading, setAddAddressLoading] = useState(false);
  const [useDifferentAsBilling, setUseDifferentAsBilling] = useState(false);
  const [showShippingAddressForm, setShowShippingAddressForm] = useState(false);
  const [showBillingAddressForm, setShowBillingAddressForm] = useState(false);
  const [shippingAddressForPro, setShippingAddressForPro] = useState(false);
  const [billingAddressForPro, setBillingAddressForPro] = useState(false);
  const [editForm, setEditForm] = useState(true);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [addressOptions, setAddressOptions] = useState([]);
  const [addressLoading, setAddressLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(
    addressOptions.length ? addressOptions[0].id : null,
  );
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(
    addressOptions.length ? addressOptions[0].id : null,
  );
  const [shippingAddressValues, setShippingAddressValues] = useState(
    getInitialAddressData('', true, true, null, cartAddresses),
  );
  const [billingAddressValues, setBillingAddressValues] = useState(
    getInitialAddressData('', false, true, null, cartAddresses),
  );
  const [countries, setCountries] = useState([disabledCountry]);

  useEffect(() => {
    dispatch(addressCheck(false));
    getCountriesOptions().then((data) => {
      setCountries(data);
      const frCountryId = data.find(
        (country) => country.label === 'France',
      )?.value;
      setShippingAddressValues(
        getInitialAddressData(frCountryId, true, true, null, cartAddresses),
      );
      setBillingAddressValues(
        getInitialAddressData(frCountryId, false, true, null, cartAddresses),
      );
      setAdditionalFieldsSchema(
        Yup.object().shape(getAdditionalFieldsSchema(countries, true, false)),
      );
    });
  }, []);

  useEffect(() => {
    dispatch(addressCheck(!editForm));
  }, [editForm]);

  const loadAddresses = () => {
    get('shop/addresses').then((data) => {
      if (!data.length) {
        setShowShippingAddressForm(true);
        setShowBillingAddressForm(false);
      } else {
        setShowShippingAddressForm(false);
        setShowBillingAddressForm(false);
      }
      setAddressOptions(data);
      const defaultShippingAddress = data.find(
        (address) => address.shipping_default,
      );
      const defaultBillingAddress = data.find(
        (address) => address.billing_default,
      );
      setSelectedShippingAddress(defaultShippingAddress?.id);
      setSelectedBillingAddress(defaultBillingAddress?.id);
      setAddressLoading(false);
    });
  };

  useEffect(() => {
    if (status !== authStatus.loading) {
      if (isAuthenticated) {
        loadAddresses();
      } else {
        setShowShippingAddressForm(true);
        setShowBillingAddressForm(false);
        setAddressLoading(false);
      }
    } else {
      setAddressLoading(true);
    }
  }, [isAuthenticated, status]);

  useEffect(() => {
    if (cartCustomer || user) {
      const customer = cartCustomer || user;
      const isPro =
        !!customer.company_name && !!customer.vat_no && !!customer.meta?.siret;
      setShippingAddressForPro(isPro);

      const frCountryId = countries.find(
        (country) => country.label === 'France',
      )?.value;
      setShippingAddressValues(
        getInitialAddressData(frCountryId, true, true, customer, cartAddresses),
      );
    }
  }, [cartCustomer, user, cartAddresses]);

  useEffect(() => {
    if (!showShippingAddressForm && !showBillingAddressForm) {
      if (!useDifferentAsBilling) {
        return setIsValid(selectedShippingAddress !== null);
      } else {
        return setIsValid(
          selectedShippingAddress !== null && selectedBillingAddress !== null,
        );
      }
    } else if (!showShippingAddressForm) {
      return setIsValid(selectedShippingAddress !== null);
    } else if (!showBillingAddressForm && showShippingAddressForm) {
      return setIsValid(true);
    } else if (!showBillingAddressForm) {
      return setIsValid(selectedBillingAddress !== null);
    } else {
      return setIsValid(true);
    }
  }, [
    showShippingAddressForm,
    showBillingAddressForm,
    useDifferentAsBilling,
    selectedShippingAddress,
    selectedBillingAddress,
  ]);

  // Validation schema for additional fields only when a new address is being added
  const [additionalFieldsSchema, setAdditionalFieldsSchema] = useState({});

  useEffect(() => {
    if (countries.length) {
      const newFieldsSchema = useDifferentAsBilling
        ? {
            ...getAdditionalFieldsSchema(countries, true, false),
            ...getAdditionalFieldsSchema(
              countries,
              false,
              billingAddressForPro,
            ),
          }
        : getAdditionalFieldsSchema(countries, true, shippingAddressForPro);

      setAdditionalFieldsSchema(Yup.object().shape(newFieldsSchema));
    }
  }, [
    useDifferentAsBilling,
    countries,
    shippingAddressForPro,
    billingAddressForPro,
  ]);

  const handleShippingAddressSelect = (id) => {
    setSelectedShippingAddress(id);
    setShowShippingAddressForm(false);
    if (!useDifferentAsBilling) {
      setShowBillingAddressForm(false);
    }
  };

  const handleBillingAddressSelect = (id) => {
    setSelectedBillingAddress(id);
    setShowBillingAddressForm(false);
  };

  const handleUseDifferentBillingAddress = (checked) => {
    setUseDifferentAsBilling(checked);
    if (checked) {
      setShowBillingAddressForm(showShippingAddressForm);
      setShippingAddressForPro(false);
      setBillingAddressForPro(shippingAddressForPro);
    } else {
      setShowBillingAddressForm(false);
      setShippingAddressForPro(billingAddressForPro);
      setBillingAddressForPro(false);
    }
  };

  const handleAddNewAddress = () => {
    // setShowShippingAddressForm(
    //   !(showShippingAddressForm || showBillingAddressForm),
    // );
    // setShowBillingAddressForm(
    //   !(showShippingAddressForm || showBillingAddressForm),
    // );
    setShowAddModal(true);
  };

  const handleAddAddressSubmit = async (values) => {
    setAddAddressLoading(true);
    const payload = formatAddressData(values, true, values.forPro);
    const address = await post('shop/addresses', payload);
    setAddressOptions([...addressOptions, address]);
    setSelectedShippingAddress(address.id);
    setSelectedBillingAddress(address.id);
    setAddAddressLoading(false);
    setShowAddModal(false);
  };

  const handleSubmit = async (values) => {
    setSubmitLoading(true);
    let shipPayload = null;
    let billPayload = null;
    if (selectedShippingAddress && !showShippingAddressForm) {
      shipPayload = {
        address_id: selectedShippingAddress,
      };
    } else {
      shipPayload = formatAddressData(values, true, shippingAddressForPro);
    }
    if (selectedBillingAddress && !showBillingAddressForm) {
      billPayload = {
        address_id: selectedBillingAddress,
      };
    } else {
      billPayload = formatAddressData(
        values,
        !useDifferentAsBilling,
        billingAddressForPro,
      );
    }
    const headers = getHeaders();
    try {
      let cart;
      if (useDifferentAsBilling) {
        await post('shop/set_billing_address', billPayload, headers);
        cart = await post('shop/set_shipping_address', shipPayload, headers);
      } else {
        cart = await post('shop/set_address', shipPayload, headers);
      }
      setCartAddresses({
        billing_address: cart.billing_address,
        shipping_address: cart.shipping_address,
      });
      setCartShippingOption(cart.shipping_option ?? null);
    } catch (e) {
      setSubmitLoading(false);
      setSubmitError(translateError(e));
    }
    setSubmitLoading(false);
    if (isAuthenticated) {
      loadAddresses();
    } else {
      setShippingAddressValues(getAddressData(values, true));
      setBillingAddressValues(getAddressData(values, false));
    }
    setShowEditIcon(true);
    setEditForm(false);
    dispatch(addressCheck(true));
  };

  return (
    <div
      className={`bg-gray duration-300 w-full p-8 ${
        editForm && isImmatFilled ? 'min-h-[460px]' : 'h-24'
      } overflow-hidden rounded-xl`}
    >
      <div className="flex justify-between items-center">
        <div className="grid grid-flow-col items-center gap-2">
          <div
            className={`${
              showEditIcon ? 'bg-orange' : 'bg-black'
            } w-9 h-9 grid place-items-center text-white rounded-full`}
          >
            {showEditIcon ? <IoCheckmark /> : 3}
          </div>
          <h3 className="font-jost text-[22px] smm:text-xl font-semibold">
            Adresses
          </h3>
        </div>
        {showEditIcon && (
          <button
            className="text-[#CBCDCD] text-2xl"
            onClick={() => setEditForm(!editForm)}
          >
            <GoPencil />
          </button>
        )}
      </div>
      <div className="mt-8">
        <p className="max-w-[502px]">
          L'adresse sélectionnée sera utilisée à la fois comme adresse de
          livraison et (sauf contre-indiquation) comme adresse personnelle (pour
          la facturation).
        </p>
        {editForm &&
          (addressLoading || cartLoading ? (
            <div className="flex justify-center">
              <LittleLoader className="h-10 w-10 mt-10" />
            </div>
          ) : (
            <div>
              <div>
                {addressOptions.length > 0 && (
                  <>
                    <h3 className="font-jost text-[18px] smm:text-xl font-semibold mt-5">
                      Adresse de livraison
                    </h3>
                    <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8 my-3">
                      {addressOptions.map((option) => (
                        <AddressCard
                          key={option.id}
                          address={option}
                          selected={selectedShippingAddress === option.id}
                          onClick={() => handleShippingAddressSelect(option.id)}
                        />
                      ))}
                    </div>
                    <UseDifferentAddressToggle
                      onChange={handleUseDifferentBillingAddress}
                      checked={useDifferentAsBilling}
                    />
                    {useDifferentAsBilling && (
                      <>
                        <h3 className="font-jost text-[18px] smm:text-xl font-semibold">
                          Adresse de facturation
                        </h3>
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-8 my-3">
                          {addressOptions.map((option) => (
                            <AddressCard
                              key={option.id}
                              address={option}
                              selected={selectedBillingAddress === option.id}
                              onClick={() =>
                                handleBillingAddressSelect(option.id)
                              }
                            />
                          ))}
                        </div>
                      </>
                    )}
                    <button
                      type="button"
                      className="mb-4"
                      onClick={handleAddNewAddress}
                    >
                      + Ajouter une adresse
                    </button>
                    <AddressModal
                      countries={countries}
                      showModal={showAddModal}
                      setShowModal={setShowAddModal}
                      addressData={getInitialAddressData(
                        countries.find((country) => country.label === 'France')
                          ?.value,
                        true,
                        false,
                        null,
                        null,
                      )}
                      onSubmit={handleAddAddressSubmit}
                      submitLoading={addAddressLoading}
                      modalTitle="Ajouter une adresse"
                    />
                  </>
                )}
              </div>
              <Formik
                initialValues={{
                  ...shippingAddressValues,
                  ...billingAddressValues,
                }} // Load saved values
                validationSchema={
                  showShippingAddressForm || showBillingAddressForm
                    ? additionalFieldsSchema
                    : null
                } // Conditionally apply validation
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                <Form>
                  {showShippingAddressForm && (
                    <>
                      <h3 className="font-jost text-[18px] smm:text-xl font-semibold mb-5 mt-5">
                        Adresse de livraison
                      </h3>
                      <AddressForm
                        forShipping
                        countries={countries}
                        forProState={[
                          shippingAddressForPro,
                          setShippingAddressForPro,
                        ]}
                        showForPro={!useDifferentAsBilling}
                      />
                    </>
                  )}

                  {!addressOptions.length && (
                    <UseDifferentAddressToggle
                      onChange={handleUseDifferentBillingAddress}
                      checked={useDifferentAsBilling}
                    />
                  )}

                  {showBillingAddressForm && useDifferentAsBilling && (
                    <>
                      <h3 className="font-jost text-[18px] smm:text-xl font-semibold mb-5">
                        Adresse de facturation
                      </h3>
                      <AddressForm
                        countries={countries}
                        forProState={[
                          billingAddressForPro,
                          setBillingAddressForPro,
                        ]}
                        showForPro
                      />
                    </>
                  )}

                  {submitError && <FormError text={submitError} />}

                  <div className="sm:w-full sm:flex justify-end">
                    <button
                      type="submit"
                      className={`${
                        !isValid
                          ? 'bg-zinc-500'
                          : 'bg-orange hover:bg-transparent duration-300 hover:text-orange'
                      } px-[30px] border rounded-[8px] py-[16px] sm:w-auto w-full text-white inline-flex justify-center gap-3`}
                      disabled={!isValid || submitLoading}
                    >
                      Continuer
                      {submitLoading && (
                        <LittleWhiteLoader className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Address;
