import ProfileLayout from './ProfileLayout';
import { IoChevronDownOutline } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { get, post } from '../../components/Api/Api';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import { dateToDisplay, longDateToDisplay } from '../../utils/formatting';
import LittleLoader from '../../components/Loader/LittleLoader';
import {
  ORDER_STATUS_AWAITING_PAYMENT,
  ORDER_STATUS_DISPATCHED,
  ORDER_STATUS_ESTIMATE,
  ORDER_STATUS_PAYMENT_RECEIVED,
  SHIPPING_OPTION_BASDEL,
} from '../../helpers/shopHelper';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';
import { translateError } from '../../utils/translatting';

export const OrderItem = ({
  order,
  loadLinesSeparately = false,
  initShowLines = false,
  loadLinesOnMount = false,
}) => {
  const [showOrderLines, setShowOrderLines] = useState(initShowLines);
  const [showShipDetails, setShowShipDetails] = useState(false);
  const [lines, setLines] = useState([]);
  const [linesLoading, setLinesLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [payError, setPayError] = useState(null);

  useEffect(() => {
    if (loadLinesSeparately && loadLinesOnMount) {
      loadLines();
    } else if (loadLinesOnMount) {
      setLines(order.lines);
      setLinesLoading(false);
    }
  }, [order.reference, loadLinesSeparately, loadLinesOnMount]);

  useEffect(() => {
    if (showOrderLines && !loadLinesOnMount && !linesLoading && !lines.length) {
      loadLines();
    }
  }, [showOrderLines]);

  const loadLines = () => {
    setLinesLoading(true);
    get(`shop/orders/${order.reference}/lines`)
      .then((res) => {
        setLines(res);
      })
      .catch((err) => {
        toast.error(
          'Une erreur est survenue lors de la récupération des lignes de commande',
        );
      })
      .finally(() => {
        setLinesLoading(false);
      });
  };

  const handlePay = () => {
    setPayLoading(true);
    post(`shop/orders/${order.reference}/pay`, {})
      .then((response) => {
        window.location.href = response.redirectUrl;
      })
      .catch((err) => {
        setPayError(
          translateError(
            err,
            'Une erreur est survenue lors du paiement de la commande',
          ),
        );
        setPayLoading(false);
      })
      .finally(() => {});
  };

  return (
    <div className="bg-gray w-full p-6 rounded-xl">
      <div className="grid grid-flow-col justify-between gap-5">
        <h4 className="text-[22px]  font-semibold font-clash ">
          Commande {order.reference}, {longDateToDisplay(order.created_at)}
        </h4>
        <div className="grid grid-flow-col smm:grid-flow-row smm:place-items-center items-center gap-2">
          {order.status === ORDER_STATUS_DISPATCHED ? (
            <span className="rounded-full py-1 px-5 flex justify-center bg-[#00912E] text-white items-center">
              Terminée
            </span>
          ) : order.status === ORDER_STATUS_PAYMENT_RECEIVED ? (
            <span className="rounded-full py-1 px-5 flex justify-center items-center border border-dark">
              En cours
            </span>
          ) : order.status === ORDER_STATUS_ESTIMATE ? (
            <span className="rounded-full py-1 px-5 flex justify-center bg-slate-500 text-white items-center text-sm">
              Estimation de livraison en cours
            </span>
          ) : order.status === ORDER_STATUS_AWAITING_PAYMENT ? (
            <span className="rounded-full py-1 px-5 flex justify-center bg-orange text-white items-center">
              En attente de paiement
            </span>
          ) : (
            <span className="rounded-full py-1 px-5 flex justify-center bg-[#CB0003] text-white items-center   ">
              Annulée
            </span>
          )}
          <h4 className="text-[22px] smm:text-lg font-semibold font-clash ">
            {order.status === ORDER_STATUS_ESTIMATE
              ? order.sub_total.value / 100
              : order.total.value / 100}{' '}
            €
          </h4>
        </div>
      </div>
      <div className=" mt-3 ">
        <div
          className={`${
            showOrderLines
              ? `h-[${
                  linesLoading
                    ? 24 + 20
                    : (lines.length ?? 0) * (24 + 12) + 24 + 9
                }px] smm:h-max`
              : 'h-5'
          } overflow-hidden duration-200`}
        >
          <div
            className="flex text-orange items-center gap-2 cursor-pointer"
            onClick={() => setShowOrderLines(!showOrderLines)}
          >
            Détails de la commande
            <span
              className={`${
                showOrderLines ? 'rotate-180' : 'rotate-0'
              } duration-300`}
            >
              <IoChevronDownOutline />
            </span>
          </div>
          {linesLoading ? (
            <LittleLoader className="w-5 h-5" />
          ) : (
            <>
              {lines
                .filter((l) => l.type !== 'shipping')
                .map((line) => (
                  <div
                    className="grid grid-flow-col items-center justify-between gap-2 mt-3"
                    key={line.id}
                  >
                    <div className="grid grid-flow-col gap-2 items-center">
                      {/* <img
                        src="/failover.png"
                        className="object-cover w-20 slg:w-20 slg:object-cover h-14 rounded-xl"
                        alt=""
                      /> */}
                      <h6 className="text-sm smm:text-xs smm:w-24">
                        <span className="font-medium">{line.description}</span>{' '}
                        x{line.quantity}
                      </h6>
                    </div>
                    <h6 className="font-medium">
                      {line.total.value / 100} € TTC
                    </h6>
                  </div>
                ))}
              <div className="h-[1px] bg-slate-300 my-2"></div>
              {lines
                .filter((l) => l.type === 'shipping')
                .map((line) => (
                  <div
                    className="grid grid-flow-col items-center justify-between gap-2 mt-3"
                    key={line.id}
                  >
                    <div className="grid grid-flow-col gap-2 items-center">
                      {/* <img
                        src="/failover.png"
                        className="object-cover w-20 slg:w-20 slg:object-cover h-14 rounded-xl"
                        alt=""
                      /> */}
                      <h6 className="text-sm smm:text-xs smm:w-24">
                        <span className="font-medium">{line.description}</span>{' '}
                        x{line.quantity}
                      </h6>
                    </div>
                    <h6 className="font-medium">
                      {order.status === ORDER_STATUS_ESTIMATE
                        ? 'Non défini'
                        : line.total.value / 100 + ' € TTC'}
                    </h6>
                  </div>
                ))}
            </>
          )}
        </div>

        <div className="h-[1px] bg-slate-400 my-5"></div>
        {order.meta?.immat && (
          <p className="my-5">Plaque d'immatriculation : {order.meta.immat}</p>
        )}
        {/* {order.notes && <p className="my-5">Notes : {order.notes}</p>} */}
        {/* <div className="h-[1px] bg-slate-400 my-5"></div>
        <div>
          <h4 className="font-semibold font-jost">Suivi de la commande</h4>
          <div
            className={`relative my-4 space-y-3 ${
              showShipDetails ? 'h-44' : 'h-0'
            } overflow-hidden duration-200`}
          >
            <div className="h-16 absolute top-5 left-[7px] bg-[#cbcdcc] w-[2px]"></div>
            <div className="h-20 absolute bottom-3 left-[7px] bg-orange w-[2px]"></div>
            <div className="relative after:absolute after:top-0 after:left-0 after:bg-[#cbcdcc] after:h-4 after:w-4 after:rounded-full pl-10">
              <h5>20 mars 2024</h5>
              <span className="text-lightGrey font-light">
                La commande a été livrée
              </span>
            </div>
            <div className="relative after:absolute after:top-3 after:left-0 after:bg-orange after:h-4 after:w-4 after:rounded-full pl-10">
              <h5>20 mars 2024</h5>
              <span className="text-lightGrey font-light">
                La commande a été livrée
              </span>
            </div>
            <div className="relative after:absolute after:bottom-2 after:left-0 after:bg-orange after:h-4 after:w-4 after:rounded-full pl-10">
              <h5>20 mars 2024</h5>
              <span className="text-lightGrey font-light">
                La commande a été livrée
              </span>
            </div>
          </div>

          <div
            className="flex text-orange items-center gap-2 cursor-pointer"
            onClick={() => setShowShipDetails(!showShipDetails)}
          >
            Enrouler
            <span
              className={`${
                showShipDetails ? 'rotate-0' : 'rotate-180'
              } duration-300`}
            >
              <IoChevronDownOutline />
            </span>
          </div>
        </div>
        <div className="h-[1px] bg-slate-400 my-5"></div> */}
        <div className="space-y-3">
          {order.shipping_address?.shipping_option ===
            SHIPPING_OPTION_BASDEL && (
            <div className="flex gap-2 items-center">
              <div className="text-blue text-xl">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      d="M8.24984 10.0833C8.24984 10.8127 8.53957 11.5121 9.05529 12.0279C9.57102 12.5436 10.2705 12.8333 10.9998 12.8333C11.7292 12.8333 12.4287 12.5436 12.9444 12.0279C13.4601 11.5121 13.7498 10.8127 13.7498 10.0833C13.7498 9.35397 13.4601 8.65449 12.9444 8.13877C12.4287 7.62304 11.7292 7.33331 10.9998 7.33331C10.2705 7.33331 9.57102 7.62304 9.05529 8.13877C8.53957 8.65449 8.24984 9.35397 8.24984 10.0833Z"
                      stroke="#007FFF"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.1854 15.2689L12.296 19.1583C11.9522 19.5017 11.4862 19.6946 11.0003 19.6946C10.5144 19.6946 10.0484 19.5017 9.70459 19.1583L5.81426 15.2689C4.78871 14.2433 4.09032 12.9366 3.80739 11.5141C3.52446 10.0916 3.66971 8.61711 4.22476 7.27715C4.77981 5.93718 5.71975 4.79189 6.9257 3.98611C8.13165 3.18033 9.54946 2.75024 10.9998 2.75024C12.4502 2.75024 13.868 3.18033 15.074 3.98611C16.2799 4.79189 17.2199 5.93718 17.7749 7.27715C18.33 8.61711 18.4752 10.0916 18.1923 11.5141C17.9094 12.9366 17.211 14.2433 16.1854 15.2689Z"
                      stroke="#007FFF"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
              {order.shipping_address.first_name}{' '}
              {order.shipping_address.last_name} -{' '}
              {order.shipping_address.line_one},{' '}
              {order.shipping_address.postcode} {order.shipping_address.city}
            </div>
          )}
          <div className="flex gap-2 items-center">
            <div className="text-blue text-xl">
              <svg
                width="22"
                height="22"
                viewBox="0 0 456 456"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M415.008 1.59a9.95 9.95 0 0 0-5.223-1.485H111.996c-25.601 0-46.43 20.625-46.43 45.903l-1.75 256.441H10c-5.523 0-10 4.477-10 10v46.2c.035 33.925 27.527 61.417 61.453 61.456.688 0 1.379-.07 2.055-.21h248.457c1.48.105 2.973.175 4.476.175 34.454-.062 62.352-28 62.364-62.453V207.711h67.406c5.523 0 10-4.477 10-10V49.266c-.121-23.871-17.602-44.102-41.203-47.676zM20 358.652v-36.199h234.078v35.168a62.242 62.242 0 0 0 16.512 42.274H60.086c-.473.003-.941.039-1.406.109-21.754-1.48-38.653-19.547-38.68-41.352zM358.805 49.266v308.351a42.284 42.284 0 0 1-12.356 29.801 41.871 41.871 0 0 1-28.648 12.625 9.958 9.958 0 0 0-1.637-.145h-3.516c-21.78-2.144-38.425-20.386-38.57-42.273v-45.176c0-5.52-4.476-10-10-10H83.816l1.75-256.37c.145-14.462 11.965-26.083 26.43-25.974h256.742a47.735 47.735 0 0 0-9.933 29.16zM436.21 187.71h-57.406V49.266c.261-15.668 13.035-28.227 28.703-28.227s28.441 12.559 28.703 28.227zm0 0"
                    stroke="#007FFF"
                    strokeWidth="15"
                  />
                  <path
                    d="M128.387 108.441h94.71c5.524 0 10-4.476 10-10 0-5.523-4.476-10-10-10h-94.71c-5.524 0-10 4.477-10 10 0 5.524 4.476 10 10 10zM305.059 159.941H128.387c-5.524 0-10 4.477-10 10 0 5.52 4.476 10 10 10h176.672c5.523 0 10-4.48 10-10 0-5.523-4.477-10-10-10zM305.059 231.438H128.387c-5.524 0-10 4.48-10 10 0 5.523 4.476 10 10 10h176.672c5.523 0 10-4.477 10-10 0-5.52-4.477-10-10-10zm0 0"
                    stroke="#007FFF"
                    strokeWidth="15"
                  />
                </g>
              </svg>
            </div>
            {order.billing_address.first_name} {order.billing_address.last_name}{' '}
            - {order.billing_address.line_one}, {order.billing_address.postcode}{' '}
            {order.billing_address.city}
          </div>
          <div className="flex gap-2 items-center">
            <div className="text-blue text-xl">
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.5">
                  <path
                    d="M3.6665 6.875V15.125L10.9998 19.25V11M3.6665 6.875L10.9998 2.75L18.3332 6.875M3.6665 6.875L10.9998 11M18.3332 6.875V11M18.3332 6.875L10.9998 11M13.7498 16.5H20.1665M20.1665 16.5L17.4165 13.75M20.1665 16.5L17.4165 19.25"
                    stroke="#007FFF"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
            </div>
            {order.shipping_address?.shipping_option === SHIPPING_OPTION_BASDEL
              ? 'Transporteur choisi par Stop Pieces Auto'
              : 'Retrait en magasin'}
          </div>
          {order.status === ORDER_STATUS_AWAITING_PAYMENT && (
            <>
              <button
                className="bg-orange text-white rounded py-3 px-5 flex gap-2 items-center"
                onClick={handlePay}
              >
                Payer la commande{' '}
                {payLoading && <LittleWhiteLoader className="w-5 h-5" />}
              </button>
              {payError && (
                <p className="text-red-600 text-sm mt-1">{payError}</p>
              )}
            </>
          )}
          {order.placed_at && (
            <div className="flex gap-2 items-center">
              <div className="text-blue text-xl">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      d="M15.5832 7.33341V4.58341C15.5832 4.3403 15.4866 4.10714 15.3147 3.93523C15.1428 3.76333 14.9096 3.66675 14.6665 3.66675H5.49984C5.01361 3.66675 4.54729 3.8599 4.20347 4.20372C3.85966 4.54754 3.6665 5.01385 3.6665 5.50008M3.6665 5.50008C3.6665 5.98631 3.85966 6.45263 4.20347 6.79644C4.54729 7.14026 5.01361 7.33341 5.49984 7.33341H16.4998C16.743 7.33341 16.9761 7.42999 17.148 7.6019C17.3199 7.77381 17.4165 8.00697 17.4165 8.25008V11.0001M3.6665 5.50008V16.5001C3.6665 16.9863 3.85966 17.4526 4.20347 17.7964C4.54729 18.1403 5.01361 18.3334 5.49984 18.3334H16.4998C16.743 18.3334 16.9761 18.2368 17.148 18.0649C17.3199 17.893 17.4165 17.6599 17.4165 17.4167V14.6667M18.3332 11.0001V14.6667H14.6665C14.1803 14.6667 13.714 14.4736 13.3701 14.1298C13.0263 13.786 12.8332 13.3196 12.8332 12.8334C12.8332 12.3472 13.0263 11.8809 13.3701 11.5371C13.714 11.1932 14.1803 11.0001 14.6665 11.0001H18.3332Z"
                      stroke="#007FFF"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
              Payé le {dateToDisplay(order.placed_at)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ItemHistory = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (location.search.includes('success')) {
      toast.success('Commande passée avec succès');
    }

    get('shop/orders')
      .then((res) => {
        setOrders(res);
      })
      .catch((err) => {
        toast.error(
          'Une erreur est survenue lors de la récupération des commandes',
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <ProfileLayout>
      {loading && <Loader />}
      <div className="w-full flex flex-col gap-y-5">
        <div className="hidden slg:flex flex-col mb-4 ">
          <Link to={'/profile/profile-info'} className="flex items-center">
            <IoIosArrowRoundBack />
            Retour
          </Link>
          <h4 className="text-[32px] font-semibold font-jost">Mes commandes</h4>
        </div>
        {orders.length === 0 && (
          <div className="bg-gray p-6 rounded-xl w-full">
            <p className="text-center">
              Vous n'avez pas encore passé de commande
            </p>
          </div>
        )}
        {orders.map((order) => (
          <OrderItem key={order.id} order={order} loadLinesSeparately />
        ))}
      </div>
    </ProfileLayout>
  );
};

export default ItemHistory;
