import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchWithHeaders } from '../Api/Api';
import { Product } from '../products/ProductList';
const NosDernieresPieces = () => {
  const [randomParts, setRandomParts] = useState([]);
  useEffect(() => {
    fetchWithHeaders(process.env.VITE_API_URL + 'parts/selection/8').then(
      (result) => {
        setRandomParts(result);
      },
    );
  }, []);

  return (
    <div className="content flex flex-col gap-[24px] sm:gap-[30px] lg:gap-[48px] py-[50px] 1400:px-0 px-[16px]">
      <div className="flex flex-col gap-[12px] lg:gap-[48px]">
        <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
          Nos dernières pièces
        </h1>
      </div>
      <div className="w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-[12px] 1200:gap-x-[24px] gap-y-[24px] 1200:gap-y-[48px]">
        {randomParts &&
          randomParts.map((part) => (
            <Product
              key={'part-' + part.id}
              title={part.title}
              price={part.price}
              liked={Math.random() < 0}
              src={part.mainImage}
              id={part.id}
              product={part}
              type="pieces"
            />
          ))}
      </div>
      <div className="w-full center">
        <Link
          to={'/pieces-d-occasion'}
          className="bg-gray px-[24px] center py-[16px] font-[600] text-dark rounded-[8px] w-full sm:w-auto"
        >
          Voir plus
        </Link>
      </div>
    </div>
  );
};

export default NosDernieresPieces;
