import { MdArrowForwardIos } from 'react-icons/md';

import RestexInformes from '../../components/restez-informes/RestexInformes';
import Meilleur from '../../components/Meilleur/Meilleur';
import { Link } from 'react-router-dom';

const Rse = () => {
  return (
    <>
      <div className="wrapper flex flex-col gap-[8px] mb-[40px]">
        <div className="content flex gap-[12px] md:gap-[24px] items-center w-full py-[16px] 1400:px-0 px-[16px]">
          <p className="text-dark cursor-pointer transition">
            <Link to="/">Accueil</Link>
          </p>
          <MdArrowForwardIos className="text-[#9EA5A7] text-[12px]" />
          <p className="text-dark cursor-pointer transition">RSE</p>
        </div>
        <div className="content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[22px] 1400:px-0 px-[16px]">
          <div className="flex-col gap-[12px] lg:gap-[48px] hidden md:flex">
            <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
              Notre démarche RSE
            </h1>
            <div className="flex  border-b-2 border-blue w-96 "></div>
          </div>
          <h2 className="font-clash text-[25px] sm:text-[40px] leading-[1] 900:text-[60px] 900:leading-[64px] font-[600] text-dark">
            Un engagement sociétal et environnemental fort
          </h2>
          <p className="text-[18px] mt-2 md:text-[25px]">
            La Responsabilité Sociétale des Entreprises (RSE) est au cœur de
            chaque décision et initiative. L’objectif est de réduire l’impact
            environnemental tout en ayant une contribution sociale positive.
            Recycler plus de 98% de la masse des véhicules hors d’usage (VHU)
            fait partie intégrante des actions menées pour valoriser les pièces
            détachées, prolonger leur cycle de vie et limiter les déchets
            automobiles. Des démarches rigoureuses sont également mises en place
            pour respecter les normes les plus élevées en matière de qualité,
            sécurité et développement durable, comme en témoignent les
            certifications obtenues.
          </p>

          <div className="border-[1px] sm:col-span-4 1100:col-span-2 border-[#0d1f2229] bg-[#7EBC89] bg-opacity-30 rounded-[13px]  p-[10px] sm:p-[20px] xl:p-[30px] relative overflow-hidden">
            <div className="flex flex-col  gap-[12px] sm:gap-[15px] xl:gap-[24px] 100:max-w-[440px]">
              <div className="flex justify-center sm:flex-col items-center sm:items-start gap-[10px] sm:gap-[15px] xl:gap-[24px] min-h-[224px]">
                <img
                  src="/recycle.png"
                  alt=""
                  className="absolute h-full max-w-[337px] top-0 right-[-40px] sm:right-[14%]   "
                />

                <h2 className="font-clash font-[600] text-dark md:text-[50px] text-[36px]  leading-[42px]">
                  <span className=" sm:flex">
                    Plus de 98% de la masse VHU <br /> est recyclée
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[22px] 1400:px-0 px-[16px]">
          <Meilleur />
        </div>
      </div>

      <RestexInformes />
    </>
  );
};

export default Rse;
