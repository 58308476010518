import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { authStatus } from '../utils/auth';

export default function AuthMiddleware() {
  const { isAuthenticated, status } = useSelector((state) => state.auth);
  // const location = useLocation();

  if (
    !isAuthenticated &&
    status !== authStatus.loading &&
    status !== authStatus.init
  ) {
    return (
      <Navigate
        to={{ pathname: '/' }}
        // to={{ pathname: "/", state: { from: location } }}
        replace
      />
    );
  }

  return <Outlet />;
}
