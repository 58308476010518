import ProfileLayout from './ProfileLayout';
import React, { useEffect, useState } from 'react';
import { GoPencil, GoCheck, GoTrash } from 'react-icons/go';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { Link } from 'react-router-dom';
import AddressModal from '../../Modals/AddressModal';
import {
  disabledCountry,
  formatAddressData,
  formatAddressDataFromDBToForm,
  getCountriesOptions,
  getInitialAddressData,
} from '../../components/CheckoutForm/AddressForm';
import { del, get, patch, post } from '../../components/Api/Api';
import Loader from '../../components/Loader/Loader';
import { translateError } from '../../utils/translatting';
import ConfirmModal from '../../Modals/ConfirmModal';

const Card = ({ title, children, onEdit, onDelete }) => (
  <div className="bg-gray p-6 rounded-xl w-[484px] min-h-[208px] flex flex-col justify-between">
    <div className="flex justify-between items-center mb-4">
      <h2 className="font-jost font-semibold text-[22px]">{title}</h2>
      <div className="flex gap-10">
        {onEdit && (
          <button
            className="text-lightGrey text-2xl hover:text-gray-600"
            onClick={onEdit}
          >
            <GoPencil />
          </button>
        )}
        {onDelete && (
          <button
            className="text-lightGrey text-2xl hover:text-gray-600"
            onClick={onDelete}
          >
            <GoTrash />
          </button>
        )}
      </div>
    </div>
    {children}
  </div>
);

const Address = () => {
  const [showModal, setShowModal] = useState(false);
  const [editSubmitLoading, setEditSubmitLoading] = useState(false);
  const [addressData, setAddressData] = useState(null);
  const [addressToEditId, setAddressToEditId] = useState(null);
  const [editAddressError, setEditAddressError] = useState(null);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [addSubmitLoading, setAddSubmitLoading] = useState(false);
  const [newAddressData, setNewAddressData] = useState(null);
  const [addAddressError, setAddAddressError] = useState(null);
  const [countries, setCountries] = useState([disabledCountry]);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSubmitLoading, setDeleteSubmitLoading] = useState(false);
  const [deleteAddressError, setDeleteAddressError] = useState(null);
  const [addressToDelete, setAddressToDelete] = useState(null);

  const reloadAddresses = () => {
    setLoading(true);
    get('shop/addresses')
      .then((res) => {
        setAddresses(res);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setAddresses([]);
      });
  };

  useEffect(() => {
    reloadAddresses();
    getCountriesOptions().then((data) => {
      setCountries(data);
    });
  }, []);

  const handleEditClick = (data) => {
    setAddressData(formatAddressDataFromDBToForm(data));
    setAddressToEditId(data.id);
    setShowModal(true);
  };

  const handleAddClick = () => {
    const frCountryId = countries.find(
      (country) => country.label === 'France',
    )?.value;
    setNewAddressData(getInitialAddressData(frCountryId, true));
    setShowModalAdd(true);
  };

  const handleEditAddressSubmit = (data) => {
    setEditSubmitLoading(true);
    const formattedData = formatAddressData(data, true, data.forPro);
    if (!data.forPro) {
      formattedData.company_name = null;
      formattedData.vat_no = null;
      formattedData.siret = null;
    }
    patch('shop/addresses/' + addressToEditId, formattedData)
      .then((res) => {
        setAddresses((addr) =>
          addr.map((address) =>
            address.id === addressToEditId ? res : address,
          ),
        );
        setEditSubmitLoading(false);
        setAddressToEditId(null);
        setAddressData(null);
        setShowModal(false);
      })
      .catch((err) => {
        setEditAddressError(
          translateError(
            err,
            "Une erreur est survenue lors de la modification de l'adresse",
          ),
        );
      })
      .finally(() => {
        setEditSubmitLoading(false);
      });
  };

  const handleAddAddressSubmit = (data) => {
    setAddSubmitLoading(true);
    const formattedData = formatAddressData(data, true, data.forPro);
    if (!data.forPro) {
      formattedData.company_name = null;
      formattedData.vat_no = null;
      formattedData.siret = null;
    }
    post('shop/addresses', formattedData)
      .then((res) => {
        setAddresses((addr) => [...addr, res]);
        setAddSubmitLoading(false);
        setAddressData(null);
        setShowModalAdd(false);
      })
      .catch((err) => {
        setAddAddressError(
          translateError(
            err,
            "Une erreur est survenue lors de la modification de l'adresse",
          ),
        );
      })
      .finally(() => {
        setAddSubmitLoading(false);
      });
  };

  const handleDeleteConfirm = () => {
    setDeleteSubmitLoading(true);
    del('shop/addresses/' + addressToDelete.id)
      .then(() => {
        setAddresses((addr) =>
          addr.filter((address) => address.id !== addressToDelete.id),
        );
        setShowDeleteModal(false);
      })
      .catch((err) => {
        setDeleteAddressError(
          translateError(
            err,
            "Une erreur est survenue lors de la suppression de l'adresse",
          ),
        );
      })
      .finally(() => {
        setDeleteSubmitLoading(false);
      });
  };

  return (
    <ProfileLayout>
      <div>
        <div className="hidden slg:flex flex-col mb-4">
          <Link to={'/profile/profile-info'} className="flex items-center">
            <IoIosArrowRoundBack />
            Address
          </Link>
          <h4 className="text-[32px] font-semibold font-jost">Mes commandes</h4>
        </div>
        <div className="flex gap-4 flex-wrap w-full">
          {addresses.length === 0 && (
            <div className="bg-gray p-6 rounded-xl w-full">
              <p className="text-center">
                Vous n'avez pas encore enregistré d'adresse
              </p>
            </div>
          )}
          {loading ? (
            <Loader />
          ) : (
            addresses.map((address, index) => (
              <Card
                key={index}
                title={`Adresse ${index + 1}`}
                onEdit={() => handleEditClick(address)}
                onDelete={() => {
                  setAddressToDelete(address);
                  setShowDeleteModal(true);
                }}
              >
                <div className="space-y-2 mb-7">
                  <p>
                    {address.first_name} {address.last_name}
                  </p>
                  <p>
                    {address.state} {address.city} {address.country.name}
                  </p>
                  <p>
                    {address.line_one}
                    {address.line_two ? ' - ' + address.line_two : ''}
                    {address.line_three
                      ? ' - ' + address.line_three
                      : ''} - {address.postcode} {address.city}
                  </p>
                  <p>{address.contact_phone}</p>
                </div>
              </Card>
            ))
          )}
        </div>
        <div>
          <button type="button" onClick={handleAddClick} className="mb-4 mt-4">
            + Ajouter une adresse
          </button>
        </div>
      </div>
      {addressData && (
        <AddressModal
          countries={countries}
          showModal={showModal}
          setShowModal={setShowModal}
          addressData={addressData}
          onSubmit={handleEditAddressSubmit}
          submitLoading={editSubmitLoading}
          errorMessage={editAddressError}
        />
      )}
      {newAddressData && (
        <AddressModal
          countries={countries}
          showModal={showModalAdd}
          setShowModal={setShowModalAdd}
          addressData={newAddressData}
          onSubmit={handleAddAddressSubmit}
          submitLoading={addSubmitLoading}
          errorMessage={addAddressError}
        />
      )}
      <ConfirmModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        onConfirm={handleDeleteConfirm}
        onCancel={() => setAddressToDelete(null)}
        title="Supprimer l'adresse"
        text={`Êtes-vous sûr de vouloir supprimer l'addresse au nom de ${addressToDelete?.first_name} ${addressToDelete?.last_name} ?`}
        confirmText="Supprimer"
        confirmLoading={deleteSubmitLoading}
        errorMessage={deleteAddressError}
        confirmButtonClassName="bg-red-500 text-white"
      />
    </ProfileLayout>
  );
};

export default Address;
