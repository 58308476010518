import { Link } from 'react-router-dom';
import { useWishList } from '../../contexts/WishListContext';
import {
  getFailoverImage,
  getProductLinkFromProduct,
} from '../../utils/productHelper';
import PropTypes from 'prop-types';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import {
  Menu,
  RangeInput,
  RefinementList,
  ToggleRefinement,
  usePagination,
} from 'react-instantsearch';
import { useEffect, useRef, useState } from 'react';
import Amount from '../Amount/Amount';
import TyreFilter from '../commons/TyreFilter/TyreFilter';
import Tag from '../Tag';
import { numberToDisplay } from '../../utils/formatting';

export const Product = ({
  title = 'Title',
  price = 0,
  liked = false,
  src = '',
  id = '',
  type,
  product,
}) => {
  let failover = 'failover.png';
  if (typeof src !== 'string') src = '';
  const { addToWishList, isItemInWishList, removeFromWishList } = useWishList();

  const handleWishList = (product, event) => {
    event.preventDefault();
    if (isItemInWishList(product)) {
      removeFromWishList(product);
    } else {
      addToWishList(product);
    }
  };
  const productLink = getProductLinkFromProduct(product);

  const isVehicle = type === 'vehicules';
  const forParts = product.procedure === 'PIECES';
  const isMecaPart = type === 'pieces' && product.carmeca === 'M';

  failover = getFailoverImage(type, product);

  return (
    <Link
      to={productLink}
      className="w-full flex flex-col gap-[16px] cursor-pointer relative"
    >
      {isVehicle && forParts && (
        <Tag.Container>
          <Tag.ForParts />
        </Tag.Container>
      )}
      <img
        src={
          src.substring(
            0,
            src.indexOf('?lock') != -1 ? src.indexOf('?lock') : src.length,
          ) +
          '?lock=' +
          id
        }
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = failover;
        }}
        alt=""
        className="w-full h-[200px] sm:h-[250px] aspect-square object-contain"
      />
      <div className="flex flex-col w-full gap-[4px]">
        <div className="w-full flex items-start justify-between">
          <div className="font-[600] text-[14px] sm:text-[16px] 1200:text-[20px] 1200:leading-[28px] text-dark">
            <p>{title}</p>
          </div>
          <div onClick={(event) => handleWishList(product, event)}>
            {isItemInWishList(product) ? (
              <img
                src="/pictos/liked.png"
                alt=""
                className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] cursor-pointer"
              />
            ) : (
              <img
                src="/pictos/unliked.png"
                alt=""
                className="max-w-[20px] sm:max-w-[21px] max-h-[18.6px] opacity-[.6] cursor-pointer"
              />
            )}
          </div>
        </div>
        <div className="font-[600] text-[14px] sm:text-[16px] 1200:text-[20px] 1200:leading-[24px] text-blue flex justify-between">
          {!!price && Number(price) && (
            <span>
              <Amount value={Number(price)} precision={0} />
            </span>
          )}
          {isMecaPart && product.mileage > 5 && (
            <span className="font-[600] text-[14px] sm:text-[16px] 1200:text-[18px] 1200:leading-[22px] text-dark">
              {numberToDisplay(product.mileage)} km
            </span>
          )}
        </div>
      </div>
    </Link>
  );
};

Product.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  price: PropTypes.number || PropTypes.string,
  liked: PropTypes.bool,
  id: PropTypes.number,
  product: PropTypes.object,
  type: PropTypes.string,
};

export const Panel = ({
  children,
  header = null,
  hidden = false,
  footer = null,
}) => {
  return (
    <div hidden={hidden}>
      {header && (
        <h1 className="font-clash text-[15px] sm:text-[20px] my-3 leading-[1] font-[600] text-orange">
          {header}
        </h1>
      )}
      <div className="ais-Panel-body">{children}</div>
      {footer && <div className="ais-Panel-footer">{footer}</div>}
    </div>
  );
};

export const StatePagination = ({ hitsPerPage }) => {
  const { currentRefinement, nbPages, nbHits } = usePagination();
  return (
    <div>
      <span>
        {Math.min((currentRefinement + 1) * hitsPerPage, nbHits)} sur{' '}
        {nbHits === 1000 ? '1000+' : nbHits}
      </span>
    </div>
  );
};

export const RefinementListDropDown = ({
  attribute,
  placeholder,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center justify-between px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
      >
        {placeholder}
        <ChevronDownIcon
          className="w-5 h-5 ml-2 -mr-1 text-gray-400"
          aria-hidden="true"
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
          <div className="py-1">
            <RefinementList
              attribute={attribute}
              {...props}
              classNames={{
                root: 'max-h-60 overflow-auto',
                list: 'space-y-1',
                item: 'flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900',
                label: 'flex items-center w-full',
                checkbox:
                  'mr-2 h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded',
                labelText: 'flex-grow',
                count: 'ml-auto text-xs text-gray-500',
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const MyRefinementList = (props) => {
  return (
    <RefinementList
      {...props}
      translations={{
        showMoreButtonText({ isShowingMore }) {
          return isShowingMore ? 'Voir moins' : 'Voir plus';
        },
      }}
      // sortBy={(a, b) => {
      //     if (a.name < b.name) return -1;
      //     if (a.name > b.name) return 1;
      //     return 0;
      // }}
      showMoreLimit={999999999}
      className="my-refinement-list"
    />
  );
};

export function instantSearchOnStateChange(
  { uiState, setUiState },
  setCurrentFilters,
  [currentPage, setCurrentPage],
  codeType = undefined,
) {
  const index = Object.keys(uiState)[0];

  // delete model filter when gamme filter is empty for vehicles and parts
  if (
    codeType &&
    (codeType === 'vehicles' || codeType === 'parts') &&
    !uiState[index]?.refinementList?.gamme &&
    uiState[index]?.refinementList?.model
  ) {
    delete uiState[index].refinementList.model;
  }

  setCurrentFilters({
    ...uiState[index]?.refinementList,
    ...uiState[index]?.menu,
  });
  if (
    uiState[index]?.page !== currentPage ||
    (!uiState[index]?.page && currentPage === 1)
  ) {
    window.scrollTo(0, 0);
    setCurrentPage(uiState[index]?.page ?? undefined);
  }

  setUiState(uiState);
}

export const TyreFilters = ({ currentFilters, hidden = false }) => {
  return (
    <div hidden={hidden}>
      <TyreFilter
        widthValue={currentFilters?.width ?? undefined}
        heightValue={currentFilters?.height ?? undefined}
        diameterValue={currentFilters?.diameter ?? undefined}
        loadspeedValue={currentFilters?.speedLoadIndex ?? undefined}
      />
      <Panel header="Article">
        <MyRefinementList
          attribute="partLabel"
          searchable={false}
          searchablePlaceholder="Article"
          showMore={false}
          operator="and"
        />
      </Panel>

      <Panel header="Marque">
        <MyRefinementList
          attribute="brand"
          searchable={true}
          searchablePlaceholder="Marque"
          showMore={true}
          operator="and"
        />
      </Panel>
      <Panel header="Prix">
        <RangeInput
          attribute="price"
          precision={0}
          translations={{
            submitButtonText: 'Valider',
            separatorElementText: ' à ',
          }}
        />
      </Panel>
      <Panel header="Saison">
        <MyRefinementList attribute="season" showMore={true} />
      </Panel>
      <Panel header="Etat">
        <MyRefinementList attribute="status" />
      </Panel>
    </div>
  );
};

export const RimFilters = ({ currentFilters, hidden = false }) => {
  return (
    <div hidden={hidden}>
      <Panel header="Article">
        <MyRefinementList
          attribute="partLabel"
          searchable={true}
          searchablePlaceholder="Article"
          showMore={true}
          operator="and"
        />
      </Panel>
      <Panel header="Marque">
        <MyRefinementList
          attribute="brand"
          operator="and"
          searchable={true}
          searchablePlaceholder="Marque"
          showMore={true}
        />
      </Panel>
      <Panel header="Modèle">
        <MyRefinementList
          attribute="familyModel"
          searchable={true}
          searchablePlaceholder="Modèle"
          showMore={true}
          operator="and"
        />
      </Panel>
      <Panel header="Diamètre">
        <MyRefinementList attribute="diameter" operator="or" showMore={true} />
      </Panel>
      <Panel header="Largeur">
        <MyRefinementList attribute="width" showMore={true} operator="or" />
      </Panel>
      <Panel header="Nombre de trous">
        <MyRefinementList
          attribute="numberOfHoles"
          searchable={false}
          operator="and"
          searchablePlaceholder="Nombre de trous"
          showMore={false}
        />
      </Panel>
      <Panel header="Prix">
        <RangeInput
          attribute="price"
          precision={0}
          translations={{
            submitButtonText: 'Valider',
            separatorElementText: ' à ',
          }}
        />
      </Panel>
      <Panel header="Déport de jante (ET)">
        <MyRefinementList attribute="deportET" showMore={true} operator="and" />
      </Panel>
    </div>
  );
};

export const VehicleFilters = ({ currentFilters, hidden = false }) => {
  return (
    <div hidden={hidden}>
      {window.location.pathname === '/vehicules' && (
        <Panel header="Etat">
          <Menu attribute="type" />
        </Panel>
      )}
      <Panel header="Marque">
        <MyRefinementList
          attribute="brand"
          operator="and"
          searchable={true}
          searchablePlaceholder="Marque"
          showMore={true}
        />
      </Panel>
      <Panel header="Gamme">
        <MyRefinementList
          attribute="gamme"
          searchable={true}
          searchablePlaceholder="Gamme"
          showMore={true}
        />
      </Panel>
      {currentFilters && currentFilters.gamme && (
        <Panel header="Modèle">
          <MyRefinementList
            attribute="model"
            searchable={true}
            searchablePlaceholder="Modèle"
            showMore={true}
            operator="and"
          />
        </Panel>
      )}
      <Panel header="Type">
        <ToggleRefinement attribute="is2Wheels" label="2 roues" on="1" />
      </Panel>
      <Panel header="Transmission">
        <MyRefinementList
          attribute="transmission"
          searchablePlaceholder="Transmission"
        />
      </Panel>
      <Panel header="Procédure">
        <MyRefinementList attribute="procedure" showMore={true} />
      </Panel>
      <Panel header="Voiture sans permis">
        <ToggleRefinement
          attribute="withoutLicense"
          label="Voiture sans permis"
          on="1"
        />
      </Panel>
      <Panel header="Année">
        <MyRefinementList attribute="year" searchable={true} showMore={true} />
      </Panel>
      <Panel header="Motorisation">
        <MyRefinementList
          attribute="energy"
          searchable={false}
          operator="or"
          searchablePlaceholder="Motorisation"
          showMore={true}
          showMoreLimit={100000}
          limit={10}
          // transformItems={(items) => (
          //     items.map((item) => ({
          //         ...item,
          //         label: translateFilterLabelToFrench(item.label)
          //     }))
          // )}
        />
      </Panel>
    </div>
  );
};

export const PartFilters = ({ currentFilters, hidden = false }) => {
  return (
    <div hidden={hidden}>
      <Panel header="Article">
        <MyRefinementList
          attribute="partLabel"
          searchable={true}
          searchablePlaceholder="Article"
          showMore={true}
          operator="and"
        />
      </Panel>
      <Panel header="Marque">
        <MyRefinementList
          attribute="brand"
          operator="and"
          searchable={true}
          searchablePlaceholder="Marque"
          showMore={true}
        />
      </Panel>
      <Panel header="Gamme">
        <MyRefinementList
          attribute="gamme"
          searchable={true}
          searchablePlaceholder="Gamme"
          showMore={true}
        />
      </Panel>
      {currentFilters && currentFilters.gamme && (
        <Panel header="Modèle">
          <MyRefinementList
            attribute="model"
            searchable={true}
            searchablePlaceholder="Modèle"
            showMore={true}
            operator="and"
          />
        </Panel>
      )}
      <Panel header="Motorisation">
        <MyRefinementList
          attribute="energy"
          searchable={false}
          operator="or"
          searchablePlaceholder="Motorisation"
          showMore={true}
          showMoreLimit={100000}
          limit={10}
          // transformItems={(items) => (
          //     items.map((item) => ({
          //         ...item,
          //         label: translateFilterLabelToFrench(item.label)
          //     }))
          // )}
        />
      </Panel>
      <Panel header="Prix">
        <RangeInput
          attribute="price"
          precision={0}
          translations={{
            submitButtonText: 'Valider',
            separatorElementText: ' à ',
          }}
        />
      </Panel>
    </div>
  );
};
