import PropTypes from "prop-types"
// import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { useRef, useState } from "react";
import { LiaAngleRightSolid, LiaAngleLeftSolid } from "react-icons/lia";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FaStar } from "react-icons/fa";

const LesRetoursDeNosClients = () => {
    const sliderRef = useRef(null);
    const [index, setIndex] = useState(0)

    const PrevArrow = () => {
        if (sliderRef.current) {
            const index = sliderRef.current.splide.Components.Controller.getPrev();
            setIndex(index)
            sliderRef.current.splide.Components.Controller.go(index);
        }
    };

    const NextArrow = () => {
        if (sliderRef.current) {
            const index = sliderRef.current.splide.Components.Controller.getNext();
            setIndex(index)
            sliderRef.current.splide.Components.Controller.go(index);
        }
    };
    return (
        <div className="content flex flex-col gap-[24px] 900:gap-[60px] xl:gap-[60px] py-[30px] sm:py-[50px] 1400:px-0 px-[16px]">
            <div className="flex items-center justify-between">
                <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
                Les retours de nos clients
                </h1>
                <div className="center gap-[24px] hidden lg:flex px-[30px]">
                    <button onClick={PrevArrow}>
                        <LiaAngleLeftSolid className={`text-[35px]  ${index === 0 && "text-[#00000068]"}  ${index === -1 && "text-[#00000068]"}`} />
                    </button>
                    <button onClick={NextArrow}>
                        <LiaAngleRightSolid className="text-[35px]" />
                    </button>
                </div>
            </div>

            <div className="flex flex-wrap justify-center items-center gap-[12px] sm:gap-[20px] 900:gap-[55px]">
                <Splide
                    ref={sliderRef}
                    className="w-full px-[5px]" // Add a custom class to Splide for styling
                    options={{
                        width: "100%",
                        perPage: 3, // Show only 1 image at a time by default
                        perMove: 1, // Move 1 image at a time
                        drag: false,
                        classes: "", // Custom class for image slider styling
                        pagination: false,
                        arrows: false, // Set arrows to navigate through the images
                        type: "false", // Set the type to 'slide' for image slider
                        gap: 23, // No gap between images
                        padding: 0, // No padding
                        autoWidth: false, // Set autoWidth to false to display full-width images
                        breakpoints: {
                            768: {
                                perPage: 1, // For screens of width 768px and above (mobile devices), show 1 slide
                                autoWidth: true,
                                gap:10
                            },
                            1024: {
                                perPage: 2,
                                drag: true // For screens of width 1024px and above (PC), show maximum 3 slides
                            },
                        },
                    }}
                >
                    <SplideSlide>
                        <Review
                            review="Stop Pieces Auto va vraiment au-delà des attentes pour assurer la satisfaction de ses clients, et je les recommande vivement à toute personne ayant besoin de pièces automobiles."
                            src="/r1.png"
                            name="Valentin B."
                        />
                    </SplideSlide>
                    <SplideSlide>
                        <Review
                            review="Produit parfaitement conforme à ce qui a ete proposé au téléphone. Expedition dans les temps et emballage parfaitement réalisé.Je ne peux que recommander chaudement ce fournisseur fiable."
                            src="/r2.png"
                            name="Eric D."
                        />
                    </SplideSlide>
                    <SplideSlide>
                        <Review
                            review="A la recherche de jantes, j'ai été accueilli avec le sourire, conseillé  et ai trouvé ce que je cherchais au meilleur prix. Serge est un top-vendeur !"
                            src="/r3.png"
                            name="Ours B."
                        />
                    </SplideSlide>
                    <SplideSlide>
                        <Review
                            review="Identifications des pièces et devis super rapides. Solutions trouvées pour réduire les couts. Pièces mises à dispo en une semaine. Très pro. Super service apporté par Cindy."
                            src="/r2.png"
                            name="Christophe J."
                        />
                    </SplideSlide>
                    <SplideSlide>
                        <Review
                            review="La qualité de leurs pièces'est excellente et je n'ai jamais eu de problèmes de compatibilité ou de durabilité."
                            src="/r2.png"
                            name="Norris Cotuand"
                        />
                    </SplideSlide>
                </Splide>
            </div>
        </div>
    )
}


export default LesRetoursDeNosClients


const Review = ({ review = "", src = "", name = "" }) => {
    return (
        <div className="max-w-[286px] sm:max-w-none bg-gray rounded-[8px] min-h-[265px] sm:min-h-[260px] flex flex-col justify-between p-[30px]">
            <div className="flex text-orange gap-[4px]">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
            </div>
            <p className="text-dark leading-[22px]">
                {review}
            </p>
            <div className="flex gap-[16px] items-center">
                <img src={src} alt="" className="max-w-[40px] max-h-[40px] min-w-[40px]" />
                <p className="font-[600] leading-[22px] text-dark">{name}</p>
            </div>
        </div>
    )
}

Review.propTypes = {
    review: PropTypes.string,
    src: PropTypes.string,
    name: PropTypes.string
}
