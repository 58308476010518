import React, { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';

const WishListContext = createContext();

export const WishListProvider = ({ children }) => {
  const [wishListItems, setWishListItems] = useState(() => {
    const savedWishListItems = localStorage.getItem('wishListItems');
    return savedWishListItems ? JSON.parse(savedWishListItems) : [];
  });
  const [isWishListOpen, setIsWishListOpen] = useState(false);
  const [nbWishListItems, setNbWishListItems] = useState(0);

  const isItemInWishList = (itemCheck) => {
    return wishListItems.some((item) => item.id === itemCheck.id);
  };

  useEffect(() => {
    localStorage.setItem('wishListItems', JSON.stringify(wishListItems));
  }, [wishListItems]);

  useEffect(() => {
    setNbWishListItems(wishListItems.length);
  }, [wishListItems]);

  const addToWishList = (item) => {
    const itemInWishList = wishListItems.find(
      (itemWishList) => itemWishList.id === item.id,
    );
    if (!itemInWishList) {
      setWishListItems((prevItems) => [...prevItems, item]);
    }
  };

  const removeFromWishList = (itemCheck) => {
    setWishListItems((prevItems) =>
      prevItems.filter((item) => item.id !== itemCheck.id),
    );
  };

  const openWishList = () => setIsWishListOpen(true);
  const closeWishList = () => setIsWishListOpen(false);

  return (
    <WishListContext.Provider
      value={{
        wishListItems,
        addToWishList,
        removeFromWishList,
        isWishListOpen,
        openWishList,
        closeWishList,
        nbWishListItems,
        isItemInWishList,
      }}
    >
      {children}
    </WishListContext.Provider>
  );
};

export const useWishList = () => useContext(WishListContext);
