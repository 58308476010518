import {
  Transition,
  Button,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { useEffect, useState } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import PasswordInput from '../components/Form/PasswordInput';
import LittleWhiteLoader from '../components/Loader/LittleWhiteLoader';
import { post } from '../components/Api/Api';
import { translateError } from '../utils/translatting';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required('Ancien mot de passe requis'),
  password: Yup.string()
    .required('Nouveau mot de passe requis')
    .min(8, 'Le mot de passe doit faire minimum 8 caractères'),
  password_confirmation: Yup.string()
    .oneOf(
      [Yup.ref('password'), null],
      'Les mots de passe doivent correspondre',
    )
    .required('Confirmation du mot de passe requise'),
});

export default function PasswordModal({ showModal, setShowModal }) {
  const [submitError, setSubmitError] = useState(null);

  const onSubmit = (values, onSubmitProps) => {
    post('/update_password', values)
      .then((res) => {
        onSubmitProps.setSubmitting(false);
        onSubmitProps.resetForm();
        setShowModal(false);
        toast.success('Mot de passe modifié avec succès');
      })
      .catch((err) => {
        onSubmitProps.setSubmitting(false);
        setSubmitError(
          translateError(
            err,
            'Une erreur est survenue lors de la modification du mot de passe',
          ),
        );
      });
  };

  useEffect(() => {
    setSubmitError(null);
  }, [showModal]);

  return (
    <>
      <Dialog
        open={showModal}
        as="div"
        className="relative z-30 focus:outline-none"
        onClose={() => setShowModal(false)}
      >
        <Transition
          appear
          show={showModal}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop className="fixed inset-0 bg-black/30 smm:bg-white" />
        </Transition>

        <div className="fixed inset-0 z-10 w-screen h-full overflow-y-auto">
          <div className="flex min-h-full items-center  justify-center p-4">
            <button
              className="text-xl hidden text-black absolute top-4 smm:rounded-xl smm:bg-gray smm:w-12 smm:flex justify-center items-center smm:h-12 right-4"
              onClick={() => setShowModal(false)}
            >
              <VscChromeClose />
            </button>
            <Transition
              appear
              show={showModal}
              enter="transition-transform duration-300"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition-transform duration-300"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <DialogPanel className="w-full max-w-md smm:h-full rounded-xl bg-white text-black p-6 backdrop-blur-2xl">
                <button
                  className="text-xl text-black absolute top-4 smm:rounded-xl smm:bg-gray smm:w-12 smm:hidden block  smm:h-12 right-4"
                  onClick={() => setShowModal(false)}
                >
                  <VscChromeClose />
                </button>
                <DialogTitle
                  as="h3"
                  className="text-[22px] font-semibold font-jost"
                >
                  Modifier le mot de passe
                </DialogTitle>
                <Formik
                  initialValues={{
                    old_password: '',
                    password: '',
                    password_confirmation: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({ errors, isSubmitting, isValid, dirty }) => (
                    <Form>
                      <div className="mt-5 space-y-5">
                        <PasswordInput
                          name="old_password"
                          className="outline-none h-full w-full"
                          placeholder="Ancien mot de passe"
                          forFormik
                        />
                        <PasswordInput
                          name="password"
                          className="outline-none h-full w-full"
                          placeholder="Nouveau mot de passe"
                          forFormik
                        />
                        <PasswordInput
                          name="password_confirmation"
                          className="outline-none h-full w-full"
                          placeholder="Confirmation du nouveau mot de passe"
                          forFormik
                        />
                      </div>
                      {submitError && (
                        <small className="text-red-400">{submitError}</small>
                      )}
                      <div className="mt-8 flex gap-4">
                        <button
                          type="button"
                          className="bg-gray h-12 w-full rounded-lg"
                          onClick={() => setShowModal(false)}
                        >
                          Annuler
                        </button>
                        <Button
                          type="submit"
                          className={`inline-flex justify-center items-center gap-2 rounded-lg h-12 text-sm/6 font-semibold shadow-inner shadow-white/10 w-full ${
                            isValid && dirty
                              ? 'bg-orange text-white cursor-pointer'
                              : 'bg-[#f8f9fd] text-[#858f90] cursor-not-allowed'
                          }`}
                        >
                          Enregistrer
                          {isSubmitting && (
                            <LittleWhiteLoader className="w-5 h-5" />
                          )}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </DialogPanel>
            </Transition>
          </div>
        </div>
      </Dialog>
    </>
  );
}
