import { createSlice } from '@reduxjs/toolkit';

const CheckoutSlice = createSlice({
  name: 'CheckoutSlice',
  initialState: {
    isInformationFilled: false,
    isImmatFilled: false,
    isAddressFilled: false,
    isDeliveryMethodSelected: false,
  },
  reducers: {
    informationCheck: (state, action) => {
      state.isInformationFilled = action.payload;
    },
    immatCheck: (state, action) => {
      state.isImmatFilled = action.payload;
    },
    addressCheck: (state, action) => {
      state.isAddressFilled = action.payload;
    },
    deliveryCheck: (state, action) => {
      state.isDeliveryMethodSelected = action.payload;
    },
  },
});

export const { informationCheck, immatCheck, addressCheck, deliveryCheck } =
  CheckoutSlice.actions;

export default CheckoutSlice.reducer;
