import secureLocalStorage from 'react-secure-storage';

const ngrokSkipBrowserWarning = false;

export async function fetchWithHeaders(url, options = {}) {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...(ngrokSkipBrowserWarning
        ? {
            'ngrok-skip-browser-warning': '69420',
          }
        : {}),
      ...options.headers,
    },
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function fetchAsUser(url, options = {}, token = null) {
  const tkn = token ?? secureLocalStorage.getItem('token');
  const userHeaders = tkn ? { Authorization: `Bearer ${tkn}` } : {};
  const response = await fetch(url, {
    ...options,
    headers: {
      ...(ngrokSkipBrowserWarning
        ? {
            'ngrok-skip-browser-warning': '69420',
          }
        : {}),
      ...userHeaders,
      ...options.headers,
    },
  });

  if (response.ok && response.status === 204) {
    return null;
  }

  const result = response.body ? await response.json() : {};

  if (!response.ok) {
    const responseError = {
      type: 'Error',
      message: result.message || "Quelque chose s'est mal passé",
      data: result.data || '',
      code: response.status || '',
    };

    let error = new Error();
    error = { ...error, ...responseError };
    throw error;
  }

  return result;
}

const API_URL = process.env.VITE_API_URL;
let apiHeaders = {
  Accept: '*/*',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': API_URL,
};

export async function post(url, data, headers = {}, token = null) {
  const apiUrlWithouSlash =
    API_URL.slice(-1) === '/' ? API_URL.slice(0, -1) : API_URL;
  const path =
    apiUrlWithouSlash + (url.substring(0, 1) === '/' ? url : `/${url}`);

  return fetchAsUser(
    path,
    {
      method: 'POST',
      headers: {
        ...apiHeaders,
        ...headers,
      },
      body: JSON.stringify(data),
    },
    token,
  );
}

export async function postFormData(url, data, headers = {}, token = null) {
  const apiUrlWithouSlash =
    API_URL.slice(-1) === '/' ? API_URL.slice(0, -1) : API_URL;
  const path =
    apiUrlWithouSlash + (url.substring(0, 1) === '/' ? url : `/${url}`);

  return fetchAsUser(
    path,
    {
      method: 'POST',
      headers: {
        // ...apiHeaders,
        ...headers,
        // 'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': API_URL,
      },
      body: data,
    },
    token,
  );
}

export function get(url, headers = {}, token = null) {
  const apiUrlWithouSlash =
    API_URL.slice(-1) === '/' ? API_URL.slice(0, -1) : API_URL;
  const path =
    apiUrlWithouSlash + (url.substring(0, 1) === '/' ? url : `/${url}`);

  return fetchAsUser(
    path,
    {
      method: 'GET',
      headers: {
        ...apiHeaders,
        ...headers,
      },
    },
    token,
  );
}

export function patch(url, data, headers = {}, token = null) {
  const apiUrlWithouSlash =
    API_URL.slice(-1) === '/' ? API_URL.slice(0, -1) : API_URL;
  const path =
    apiUrlWithouSlash + (url.substring(0, 1) === '/' ? url : `/${url}`);

  return fetchAsUser(
    path,
    {
      method: 'PATCH',
      headers: {
        ...apiHeaders,
        ...headers,
      },
      body: JSON.stringify(data),
    },
    token,
  );
}

export function del(url, headers = {}, token = null) {
  const apiUrlWithouSlash =
    API_URL.slice(-1) === '/' ? API_URL.slice(0, -1) : API_URL;
  const path =
    apiUrlWithouSlash + (url.substring(0, 1) === '/' ? url : `/${url}`);

  return fetchAsUser(
    path,
    {
      method: 'DELETE',
      headers: {
        ...apiHeaders,
        ...headers,
      },
    },
    token,
  );
}
