import Modal from './Modal';

export default function EpaveModal({ openModal, setOpenModal }) {
  return (
    <Modal
      showModal={openModal}
      setShowModal={setOpenModal}
      modalTitle="Conditions de prise en charge des véhicules terrestres hors d'usage"
    >
      <div>
        <h4 className="uppercase font-bold mb-6">
          Documents obligatoires à fournir pour céder un véhicule :
        </h4>
        <ul className="list-disc list-inside mb-6">
          <li className="uppercase font-bold mb-1">Original carte grise</li>
          <p className="mb-3">
            <span className="underline">En cas de perte ou vol</span> : fiche
            d’identification détaillée du véhicule (sur le site :{' '}
            <a
              href="https://immatriculation.ants.gouv.fr"
              target="_blank"
              className="underline"
            >
              immatriculation.ants.gouv.fr
            </a>
            ) et une déclaration de perte ou de vol (la déclaration de vol doit
            obligatoirement être validée par une gendarmerie ou un commissariat
            de police).
          </p>
          <li className="mb-1">
            <span className="font-bold">
              <span className="uppercase">
                Certificat de situation administrative détaillée
              </span>{' '}
              de moins de 15 jours
            </span>{' '}
            sur un des sites suivant :
          </li>
          <ul className="list-inside ml-[16px] mb-3">
            <li className="mb-1">
              -{' '}
              <a
                href="https://histovec.interieur.gouv.fr/histovec/proprietaire"
                target="_blank"
                className="underline ml-[16px]"
              >
                histovec.interieur.gouv.fr
              </a>
            </li>
          </ul>
          <li className="font-bold mb-1">
            <span className="uppercase">2 certificats de cession</span>{' '}
            (acheteur, vendeur)
          </li>
          <ul className="list-inside ml-[16px] mb-3">
            <li className="mb-1">
              - <span className="ml-[16px]">oui</span>
            </li>
          </ul>
          <li className="mb-1">
            <span className="uppercase font-bold">Pièce d’identité</span> du ou
            des titulaires de la carte grise
          </li>
        </ul>
        <p className="font-bold uppercase underline mb-6">
          Tous les documents doivent être au nom du titulaire de la carte grise
        </p>
        <p className="font-bold mb-6">
          Tél :{' '}
          <a href="tel:0472318327" className="underline">
            04 72 31 83 27
          </a>
        </p>
      </div>
    </Modal>
  );
}
