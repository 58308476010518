import { useState } from 'react';
import { Link } from 'react-router-dom';
import { GoPencil } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { IoCheckmark } from 'react-icons/io5';
import LittleWhiteLoader from '../../components/Loader/LittleWhiteLoader';
import { post } from '../../components/Api/Api';
import { translateError } from '../../utils/translatting';
import { useCart } from '../../contexts/CartContext';
const PaymentMethods = () => {
  const [selectedPayment, setSelectedPayment] = useState(1);
  const [editForm, setEditForm] = useState(true);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [terms, setTerms] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const isValid = () => {
    return selectedPayment && terms;
  };

  const dispatch = useDispatch();
  const isDeliveryMethodSelected = useSelector(
    (state) => state.CheckoutSlice.isDeliveryMethodSelected,
  );
  const { getHeaders } = useCart();

  const handlePaymentSelect = (id) => {
    setSelectedPayment(id);
  };

  const handleSubmit = () => {
    if (!isValid()) return;
    setSubmitLoading(true);

    const headers = getHeaders();

    post('shop/process_to_order', { payment_method: selectedPayment }, headers)
      .then((response) => {
        window.location.href = response.redirectUrl;
      })
      .catch((e) => {
        setSubmitLoading(false);
        setSubmitError(
          translateError(
            e,
            "Une erreur est survenue lors de l'initialisation du paiement",
          ),
        );
      });

    // setEditForm(false);
    // setShowEditIcon(true);
  };

  return (
    <div
      className={`bg-gray duration-300 w-full p-8 ${
        editForm && isDeliveryMethodSelected
          ? 'min-h-[360px] smm:h-max'
          : 'h-24'
      } overflow-hidden rounded-xl`}
    >
      <div className="flex justify-between items-center">
        <div className="grid grid-flow-col items-center gap-2">
          <div
            className={`${
              showEditIcon ? 'bg-orange' : 'bg-black'
            } w-9 h-9 grid place-items-center text-white rounded-full`}
          >
            {showEditIcon ? <IoCheckmark /> : 5}
          </div>
          <h3 className="font-jost text-[22px] smm:text-xl font-semibold">
            Modes de paiement
          </h3>
        </div>
        {showEditIcon && (
          <button
            className="text-[#CBCDCD] text-2xl"
            onClick={() => setEditForm(!editForm)}
          >
            <GoPencil />
          </button>
        )}
      </div>
      <div className="mt-7">
        <div>
          <div className="flex gap-8 my-3 flex-col 1200:flex-row">
            <div
              className={`bg-white grid grid-flow-row 500:grid-flow-col  justify-between min-h-[60px] gap-4 items-center border-2 w-full cursor-pointer rounded-xl p-3 ${
                selectedPayment === 1 ? ' border-orange' : ' border-[#CBCDCD]'
              }`}
              onClick={() => handlePaymentSelect(1)}
            >
              <div className="grid grid-flow-col gap-2 w-full items-center">
                <div
                  className={`${
                    selectedPayment === 1
                      ? 'bg-orange  border-orange'
                      : 'bg-transparent border-[#CBCDCD]    '
                  } w-5 h-5 rounded-full grid place-items-center border`}
                >
                  <div className="w-2 h-2 rounded-full bg-white"></div>
                </div>
                <p className="text-sm break-words smm:pr-5">
                  Payer par carte bancaire
                </p>
              </div>
              <div className="flex  justify-end">
                <img src="/icons/card1.svg" alt="" />
                <img src="/icons/card2.svg" alt="" />
                <img src="/icons/card3.svg" alt="" />
                <img src="/icons/card4.svg" alt="" />
              </div>
            </div>
            {/* <div
              className={`bg-white grid grid-flow-row 500:grid-flow-col justify-between min-h-[60px] gap-3 items-center border-2 w-full cursor-pointer rounded-xl p-3 ${
                selectedPayment === 2 ? " border-orange" : " border-[#CBCDCD]"
              }`}
              onClick={() => handlePaymentSelect(2)}
            >
              <div className="grid grid-flow-col gap-2 w-full items-center">
                <div
                  className={`${
                    selectedPayment === 2
                      ? "bg-orange  border-orange"
                      : "bg-transparent border-[#CBCDCD]    "
                  } w-5 h-5 rounded-full grid place-items-center border`}
                >
                  <div className="w-2 h-2 rounded-full bg-white"></div>
                </div>
                <p className="text-sm">Payer avec PayPal</p>
              </div>
              <div className="flex justify-end">
                <img src="/icons/card5.svg" alt="" />
              </div>
            </div> */}
          </div>
          <div className="mt-4 mb-4 grid grid-flow-col  items-start place-items-start grid-cols-[22px_1fr] gap-2">
            <input
              checked={terms}
              type="checkbox"
              id="terms"
              className="w-5 h-5 accent-orange"
              onChange={(e) => setTerms(e.target.checked)}
            />
            <label htmlFor="terms" className="font-jost">
              J'ai lu les
              <Link to="/cgv-particuliers" className="mx-2 text-orange">
                conditions générales de vente
              </Link>
              et j'y adhère sans réserve
            </label>
          </div>
          {submitError && <p className="text-red-600 text-sm">{submitError}</p>}
          <div className="sm:w-full sm:flex justify-end">
            <button
              onClick={handleSubmit}
              className={`${
                !isValid()
                  ? 'bg-zinc-500'
                  : 'border-orange hover:bg-transparent duration-300 hover:text-orange bg-orange'
              } px-[30px] border mt-4 rounded-[8px] py-[16px] sm:w-auto w-full text-white inline-flex justify-center gap-3`}
              disabled={!isValid() || submitLoading}
            >
              Valider et payer la commande
              {submitLoading && <LittleWhiteLoader className="w-5 h-5" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
