import { FaArrowRightLong } from 'react-icons/fa6';
import { Link, useNavigate } from 'react-router-dom';

const Catalogue = () => {
  const navigate = useNavigate();

  return (
    <div className="content flex flex-col gap-[15px] sm:gap-[30px] pb-[50px] 1400:px-0 px-[16px] pt-10 md:pt-5">
      <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
        Catalogue
      </h1>
      <div className="w-full flex flex-col gap-[10px] sm:gap-[20px] g:gap-[50px]">
        <Link
          className="bg-gray group rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] w-full  flex flex-col gap-[45px] sm:gap-[120px] 900:gap-[170px] justify-between cursor-pointer relative max-h-[430px] 1200:h-[420px] lg:hidden"
          to="/pieces-d-occasion"
        >
          <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
            Pièces <br /> {"d'occasion"}
          </h1>
          <div>
            <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
              <FaArrowRightLong />
            </button>
          </div>
          <img
            src="/stopieces-auto-pieces-occasion-tafani-autos.png"
            alt="Pièces d'occasion automobile"
            title="Découvrez notre catalogue de pièces d'occasion"
            className="absolute right-0 bottom-0 max-w-[60%] max-h-[60%] sm:max-h-[100%]"
          />
        </Link>
        <div className="w-full grid grid-cols-2 gap-[10px] sm:gap-[20px] g:gap-[50px] lg:hidden">
          <Link
            to="/vehicules-d-occasion"
            className="bg-gray rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] w-full  flex flex-col gap-[45px] sm:gap-[120px] 900:gap-[170px] group cursor-pointer justify-between relative max-h-[430px] 1200:h-[420px] "
          >
            <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
              Véhicules <br />
              d'occasion
            </h1>
            <div>
              <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
                <FaArrowRightLong />
              </button>
            </div>
            <img
              src="/vehicules-occasion.png"
              alt="Véhicules d'occasion à vendre"
              title="Explorez notre sélection de véhicules d'occasion"
              className="absolute right-0 bottom-0 max-w-[60%] max-h-[60%] sm:max-h-[100%]"
            />
          </Link>
          <Link
            to="/vehicules-accidentes"
            className="bg-gray rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] flex flex-col justify-between gap-[45px] sm:gap-[120px] group 900:gap-[170px] cursor-pointer relative max-h-[430px] 1200:h-[420px] "
          >
            <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
              Véhicules <br />
              accidentés
            </h1>
            <div>
              <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
                <FaArrowRightLong />
              </button>
            </div>
            <img
              src="/vehicules-accidentes.png"
              alt="Véhicules accidentés disponibles"
              title="Consultez nos véhicules accidentés disponibles"
              className="absolute right-0 bottom-0 max-w-[65%] max-h-[60%] sm:max-h-[100%]"
            />
          </Link>
          <Link
            className="bg-gray group rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] w-full  flex flex-col gap-[45px] sm:gap-[120px] 900:gap-[170px] justify-between cursor-pointer relative max-h-[430px] 1200:h-[420px] "
            to="/jantes-d-occasion"
          >
            <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
              Jantes <br /> {"d'occasion"}
            </h1>
            <div>
              <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
                <FaArrowRightLong />
              </button>
            </div>
            <img
              src="/jantes-occasion.png"
              alt="Jantes d'occasion pour voiture"
              title="Découvrez notre gamme de jantes d'occasion"
              className="absolute right-0 bottom-0 max-w-[60%] max-h-[60%] sm:max-h-[100%]"
            />
          </Link>
          <Link
            className="bg-gray group rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] w-full  flex flex-col gap-[45px] sm:gap-[120px] 900:gap-[170px] justify-between cursor-pointer relative max-h-[430px] 1200:h-[420px] "
            to="/pneus-d-occasion"
          >
            <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
              Pneus <br /> {"d'occasion"}
            </h1>
            <div>
              <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
                <FaArrowRightLong />
              </button>
            </div>
            <img
              src="/pneus-occasion.png"
              alt="Pneus d'occasion toutes marques"
              title="Explorez notre sélection de pneus d'occasion"
              className="absolute right-0 bottom-0 max-w-[60%] max-h-[60%] sm:max-h-[100%]"
            />
          </Link>
        </div>
      </div>

      <div className="w-full hidden lg:flex gap-[30px]">
        <Link
          to="/pieces-d-occasion"
          className="bg-gray rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] w-full  flex flex-col gap-[45px] sm:gap-[120px] 900:gap-[170px] group cursor-pointer justify-between relative max-h-[430px] 1200:h-[420px] "
        >
          <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
            Pièces <br />
            d'occasion
          </h1>
          <div>
            <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
              <FaArrowRightLong />
            </button>
          </div>
          <img
            src="/stopieces-auto-pieces-occasion-tafani-autos.png"
            alt="Pièces d'occasion automobile"
            title="Découvrez notre catalogue de pièces d'occasion"
            className="absolute right-0 bottom-0 max-w-[60%] max-h-[60%] sm:max-h-[100%]"
          />
        </Link>
      </div>
      <div className="w-full hidden lg:flex gap-[30px]">
        <Link
          to="/vehicules-d-occasion"
          className="bg-gray rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] w-full  flex flex-col gap-[45px] sm:gap-[120px] 900:gap-[170px] group cursor-pointer justify-between relative max-h-[430px] 1200:h-[420px] "
        >
          <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
            Véhicules <br />
            d'occasion
          </h1>
          <div>
            <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
              <FaArrowRightLong />
            </button>
          </div>
          <img
            src="/vehicules-occasion.png"
            alt="Véhicules d'occasion à vendre"
            title="Explorez notre sélection de véhicules d'occasion"
            className="absolute right-0 bottom-0 max-w-[60%] max-h-[60%] sm:max-h-[100%]"
          />
        </Link>
        <Link
          to="/vehicules-accidentes"
          className="bg-gray rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] w-full  flex flex-col gap-[45px] sm:gap-[120px] 900:gap-[170px] group cursor-pointer justify-between relative max-h-[430px] 1200:h-[420px] "
        >
          <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
            Véhicules <br />
            accidentés
          </h1>
          <div>
            <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
              <FaArrowRightLong />
            </button>
          </div>
          <img
            src="/vehicules-accidentes.png"
            alt="Véhicules accidentés disponibles"
            title="Consultez nos véhicules accidentés disponibles"
            className="absolute right-0 bottom-0 max-w-[65%] max-h-[60%] sm:max-h-[100%]"
          />
        </Link>
      </div>
      <div className="w-full hidden lg:flex gap-[30px]">
        <Link
          to="/jantes-d-occasion"
          className="bg-gray rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] w-full  flex flex-col gap-[45px] sm:gap-[120px] 900:gap-[170px] group cursor-pointer justify-between relative max-h-[430px] 1200:h-[420px] "
        >
          <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
            Jantes <br />
            d'occasion
          </h1>
          <div>
            <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
              <FaArrowRightLong />
            </button>
          </div>
          <img
            src="/jantes-occasion.png"
            alt="Jantes d'occasion pour voiture"
            title="Découvrez notre gamme de jantes d'occasion"
            className="absolute right-0 bottom-0 max-w-[60%] max-h-[60%] sm:max-h-[100%]"
          />
        </Link>

        <Link
          to="/pneus-d-occasion"
          className="bg-gray rounded-[12px] p-[10px] sm:p-[20px] md:p-[30px] w-full  flex flex-col gap-[45px] sm:gap-[120px] 900:gap-[170px] group cursor-pointer justify-between relative max-h-[430px] 1200:h-[420px] "
        >
          <h1 className="font-clash font-[600] text-[23px] leading-[21px] sm:text-[38px] sm:leading-[33px] 900:text-[48px] 900:leading-[43px] text-dark group-hover:text-orange">
            Pneus <br />
            d'occasion
          </h1>
          <div>
            <button className="w-[32px] sm:w-auto h-[32px] sm:h-auto sm:px-[24px] sm:py-[16px] bg-white group-hover:bg-orange center rounded-[8px]">
              <FaArrowRightLong />
            </button>
          </div>
          <img
            src="/pneus-occasion.png"
            alt="Pneus d'occasion toutes marques"
            title="Explorez notre sélection de pneus d'occasion"
            className="absolute right-0 bottom-0 max-w-[60%] max-h-[60%] sm:max-h-[100%]"
          />
        </Link>
      </div>
    </div>
  );
};

export default Catalogue;
