import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { fetchWithHeaders } from '../Api/Api';
import { Product } from '../products/ProductList';
const NosDerniers = () => {
  const [randomCars, setRandomCars] = useState([]);
  useEffect(() => {
    fetchWithHeaders(process.env.VITE_API_URL + 'vehicles/selection/8').then(
      (result) => {
        setRandomCars(result);
      },
    );
  }, []);

  return (
    <div className="content flex flex-col gap-[24px] sm:gap-[30px] lg:gap-[48px] py-[50px] 1400:px-0 px-[16px]">
      <div className="flex flex-col gap-[12px] lg:gap-[48px]">
        <h1 className="font-clash text-[40px] sm:text-[50px] leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
          Nos derniers véhicules
        </h1>
        <div className="flex gap-[8px] md:gap-[14px] flex-wrap">
          <Link
            to={'/vehicules-accidentes'}
            className="bg-dark px-[16px] sm:px-[24px] py-[6px] text-[white] rounded-[32px]"
          >
            Véhicules accidentés
          </Link>
          <Link
            to={'/vehicules-d-occasion'}
            className="bg-white px-[16px] sm:px-[24px] py-[5px] text-dark border-[1px] border-dark rounded-[32px]"
          >
            Véhicules d’occasions
          </Link>
        </div>
      </div>
      <div className="w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-[12px] 1200:gap-x-[24px] gap-y-[24px] 1200:gap-y-[48px]">
        {randomCars &&
          randomCars.map((car) => (
            <Product
              key={'car-' + car.id}
              title={
                car.brand +
                ' ' +
                car.gamme +
                ' ' +
                car.model +
                (car.year ? ', ' + car.year : '')
              }
              price={car.price}
              liked={Math.random() < 0}
              src={car.mainImage}
              id={car.id}
              product={car}
              type="vehicules"
            />
          ))}
      </div>
      <div className="w-full center">
        <Link
          to={'/vehicules'}
          className="bg-gray px-[24px] center py-[16px] font-[600] text-dark rounded-[8px] w-full sm:w-auto"
        >
          Voir plus
        </Link>
      </div>
    </div>
  );
};

export default NosDerniers;
