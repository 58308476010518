import { useEffect } from 'react';

const ErrorFallback = ({ error, resetError }) => {
  useEffect(() => {
    // Redirection automatique après 5 secondes
    const timer = setTimeout(() => {
      window.location.href = '/';
      resetError();
    }, 10000);

    return () => clearTimeout(timer);
  }, [resetError]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full">
        <div className="text-center">
          <div className="mb-4">
            <svg
              className="mx-auto h-16 w-16 text-[#FF5600]"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>

          <h2 className="text-2xl font-semibold text-gray-900 mb-4">
            Oups ! Quelque chose s'est mal passé
          </h2>

          <p className="text-gray-600 mb-6">
            Nos équipes sont averties et nous corrigeons le problème rapidement
            !
          </p>

          <p className="text-gray-600 mb-6">
            En attendant, vous serez redirigé vers la page d'accueil dans
            quelques secondes.
          </p>

          <div className="space-x-4">
            <button
              onClick={() => {
                window.location.href = '/';
                resetError();
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-[#007FFF] hover:bg-[#007FFF] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Retour à l'accueil
            </button>
            <button
              onClick={resetError}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Réessayer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
