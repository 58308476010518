import { useEffect, useState } from 'react';
import { CartProvider } from './contexts/CartContext';
import CartSwiper from './components/commons/Cart/CartSwiper';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home/Home';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import Products from './pages/products-page/Products';
import Catalogue from './pages/products-page/Catalogue';
import ProductDetails from './pages/single-product-page/ProductDetails';
import Propos from './pages/propos/propos';
import Blog from './pages/Blog/Blog';
import Recrutement from './pages/Recrutement/Recrutement';
import Rse from './pages/Rse/Rse';
import Aide from './pages/Aide/Aide';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { WishListProvider } from './contexts/WishListContext';
import WishList from './pages/WishList/WishList';
import ProfileDetails from './pages/Profile/ProfileDetails';
import ItemHistory from './pages/Profile/ItemHistory';
import Checkout from './pages/checkout/Checkout';
import ProfileInfo from './pages/Profile/ProfileInfo';
import Address from './pages/Profile/Address';
import { Provider, useDispatch } from 'react-redux';
import { initAuthState } from './store/Reducers/auth';
import { store } from './store/store';
import AuthMiddleware from './middleware/Auth';
import Order from './pages/Order/Order';
import CGVPro from './pages/CGV/CGVPro';
import CGVParticulier from './pages/CGV/CGVParticulier';
import Mentions from './pages/Mentions/Mentions';
import ScrollToTop from './Utils/ScrollToTop';
import ResetPassword from './pages/ForgotPassword/ResetPassword';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import GoogleAnalytics from './Utils/GoogleAnalytics';
import NotFound from './pages/NotFound/NotFound';
import MicrosoftClarity from './Utils/MicrosoftClarity';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
  defaultTitle,
  DYNAMIC_TITLE_ROUTES,
  pageTitles,
} from './helpers/pageConfig';

function App() {
  const dispatch = useDispatch();
  const types = ['vehicules', 'pneus', 'jantes', 'pieces'];
  const path = useLocation();
  const [title, setTitle] = useState(defaultTitle);

  useEffect(() => {
    const pathname = path.pathname;
    if (!DYNAMIC_TITLE_ROUTES.some((route) => pathname.startsWith(route))) {
      setTitle(
        pageTitles[
          Object.keys(pageTitles).find((key) =>
            pathname === '/' ? null : pathname.startsWith(key),
          )
        ]?.title || defaultTitle,
      );
    }
  }, [path]);

  useEffect(() => {
    dispatch(initAuthState());
  }, []);

  let routes = [];
  for (let type of types) {
    //create routes
    routes.push(<Route path={`/${type}-d-occasion`} element={<Products />} />);
    routes.push(<Route path={`/${type}/:id`} element={<ProductDetails />} />);
  }

  return (
    <Provider store={store}>
      <CartProvider>
        <WishListProvider>
          <HelmetProvider>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            {path.pathname !== '/checkout' && <Header />}
            <ScrollToTop />
            <GoogleAnalytics />
            <MicrosoftClarity />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/vehicules" element={<Products />} />
              <Route path="/catalogue" element={<Catalogue />} />
              <Route path="/vehicules-accidentes" element={<Products />} />
              {types.map((type) => (
                <Route
                  path={`${type}-d-occasion`}
                  element={<Products />}
                  key={`${type}-route`}
                />
              ))}
              {types.map((type) => (
                <Route
                  key={`${type}-route`}
                  path={`/${type}/:idOrSlug`}
                  element={<ProductDetails />}
                />
              ))}
              <Route path="/cgv-pros" element={<CGVPro />} />
              <Route path="/cgv-particuliers" element={<CGVParticulier />} />
              <Route path="/mentions-legales" element={<Mentions />} />
              <Route path="/propos" element={<Propos />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/wishlist" element={<WishList />} />
              {/* <Route path="/recrutement" element={<Recrutement />} /> */}
              <Route path="/rse" element={<Rse />} />
              <Route path="/aide" element={<Aide />} />

              <Route path="/checkout" element={<Checkout />} />
              <Route path="/orders/:orderRef" element={<Order />} />
              <Route element={<AuthMiddleware />}>
                {/* <Route path="/profile" element={<ProfileDetails />}></Route> */}
                <Route
                  path="/profile/profile-details"
                  element={<ProfileDetails />}
                />
                <Route path="/profile/profile-info" element={<ProfileInfo />} />
                <Route path="/profile/addresses" element={<Address />} />
                <Route path="/profile/orders" element={<ItemHistory />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
            <ToastContainer />
            <CartSwiper />
          </HelmetProvider>
        </WishListProvider>
      </CartProvider>
    </Provider>
  );
}

export default App;
