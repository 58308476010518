import { ErrorMessage, Field } from 'formik';

const FormSelect = ({ placeholder, name, options }) => {
  return (
    <div className="w-full ">
      <Field
        className="border border-[#CBCDCD] rounded-lg h-14 w-full outline-none pl-4 bg-white"
        placeholder={placeholder}
        name={name}
        component="select"
      >
        {options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name}>
        {(error) => {
          return <small className="text-red-400">{error}</small>;
        }}
      </ErrorMessage>
    </div>
  );
};

export default FormSelect;
