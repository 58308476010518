import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const BRANDS_MAP = {
  p1: 'renault',
  p2: 'peugeot',
  'p3-1': 'citroen',
  p4: 'kia',
  p5: 'mazda',
  p6: 'mercedes',
  p7: 'chevrolet',
  p8: 'subaru',
  p10: 'bmw',
  p11: 'ford',
  p12: 'nissan',
  p13: 'volkswagen',
  p14: 'mitsubishi',
  p15: 'suzuki',
  p16: 'toyota',
  p18: 'hyundai',
  p19: 'fiat',
  p21: 'audi',
};

const LesMarques = () => {
  return (
    <div className="content flex flex-col 900:gap-[70px] xl:gap-[100px] py-[30px] sm:py-[50px] 1400:px-0 px-[16px]">
      <h1 className="font-clash text-[40px] sm:text-[50px] hidden 900:flex leading-[1] 900:text-[80px] 900:leading-[64px] font-[600] text-dark">
        Nos pièces par marque de voiture
      </h1>

      <div className="flex flex-wrap justify-center items-center gap-[12px] sm:gap-[20px] 900:gap-[55px]">
        <Company src={'/companies/p1.png'} />
        <Company src={'/companies/p2.png'} />
        <Company src={'/companies/p3-1.png'} />
        <Company src={'/companies/p13.png'} />
        <Company src={'/companies/p11.png'} />
        <Company src={'/companies/p19.png'} />
        <Company src={'/companies/p10.png'} />
        <Company src={'/companies/p4.png'} />
        <Company src={'/companies/p5.png'} />
        <Company src={'/companies/p6.png'} />
        <Company src={'/companies/p7.png'} />
        <Company src={'/companies/p8.png'} />
        <Company src={'/companies/p12.png'} />
        <Company src={'/companies/p14.png'} />
        <Company src={'/companies/p15.png'} />
        <Company src={'/companies/p16.png'} />
        <Company src={'/companies/p18.png'} />
        <Company src={'/companies/p21.png'} />
      </div>
    </div>
  );
};

export default LesMarques;

const Company = ({ src }) => {
  const brand = BRANDS_MAP[src.split('/').pop().split('.')[0]];

  return (
    <Link
      to={`/catalogue?refinementList%5Bbrand%5D%5B0%5D=${brand.toUpperCase()}`}
      className="center"
    >
      <img
        src={src}
        className="max-w-[52px] sm:max-w-[70px] lg:max-w-[80px] xl:max-w-[90px] h-auto"
        alt={brand}
      />
    </Link>
  );
};

Company.propTypes = {
  src: PropTypes.string,
};
