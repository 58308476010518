import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SwitchSelector from '../SwitchSelector';

export default function CGVTemplate({
  children,
  title = 'Conditions générales de vente',
}) {
  const { pathname } = useLocation();
  const isPro = pathname === '/cgv-pros';
  return (
    <div className="my-12 w-[90%] md:w-[700px] lg:w-[1000px] mx-auto text-justify">
      <SwitchSelector
        className="my-7"
        choice1={
          <Link
            to="/cgv-pros"
            className={`px-6 py-3 rounded-full z-10 ${
              isPro ? 'text-white bg-orange' : 'text-black'
            } transition`}
          >
            Professionnel
          </Link>
        }
        choice2={
          <Link
            to="/cgv-particuliers"
            className={`px-6 py-3 rounded-full z-10 ${
              isPro ? 'text-black' : 'text-white bg-orange'
            } transition`}
          >
            Particulier
          </Link>
        }
        choice1Value={1}
        choice2Value={2}
        choice={isPro ? 1 : 2}
      >
        {/* <div
          className={`absolute text-transparent px-6 top-0 h-full bg-orange rounded-full z-0 transition-all duration-300 ${
            isPro ? 'left-0' : 'right-0'
          }`}
        >
          {isPro ? 'Professionnel' : 'Particulier'}
        </div> */}
      </SwitchSelector>
      <h1 className="text-center text-3xl font-bold my-12">{title}</h1>
      {children}
    </div>
  );
}
