import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import secureLocalStorage from 'react-secure-storage';
import { get } from '../../components/Api/Api';
import { authStatus } from '../../utils/auth';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    user: null,
    isAuthenticated: false,
    status: authStatus.init,
    error: null,
  },
  reducers: {
    setAuthState: (state, action) => {
      state = {
        token: action.payload?.token,
        user: action.payload?.user,
        isAuthenticated: !!action.payload?.token,
        status: authStatus.idle,
        error: null,
      };
      return state;
    },
    setUser: (state, action) => {
      state = {
        ...state,
        user: action.payload,
      };
      return state;
    },
    logout: (state, action) => {
      state = {
        token: null,
        user: null,
        isAuthenticated: false,
        status: authStatus.idle,
        error: null,
      };
      return state;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(initAuthState.pending, (state, action) => {
        state = {
          token: null,
          user: null,
          isAuthenticated: false,
          status: authStatus.loading,
          error: null,
        };
        return state;
      })
      .addCase(initAuthState.fulfilled, (state, action) => {
        state = {
          ...action.payload,
          status: authStatus.idle,
          error: null,
        };
        return state;
      })
      .addCase(initAuthState.rejected, (state, action) => {
        state = {
          token: null,
          user: null,
          isAuthenticated: false,
          status: authStatus.failed,
          error: action.payload,
        };
        return state;
      });
  },
});

export const initAuthState = createAsyncThunk('auth/initAuthState', () => {
  const token = secureLocalStorage.getItem('token');
  if (!token) {
    return {
      token: null,
      user: null,
      isAuthenticated: false,
    };
  }
  return get('users/me', {}, token)
    .then((res) => {
      return {
        token,
        user: res,
        isAuthenticated: true,
      };
    })
    .catch((error) => {
      secureLocalStorage.removeItem('token');
      throw error;
    });
});

export const { setAuthState, setUser, logout } = authSlice.actions;

export default authSlice.reducer;
