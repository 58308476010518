import { Link } from 'react-router-dom';

const location = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17.9998 6V6.01"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.9998 13L14.4998 7.99997C14.1628 7.391 13.9906 6.70466 14.0001 6.00876C14.0096 5.31287 14.2006 4.63149 14.554 4.03197C14.9075 3.43245 15.4113 2.93552 16.0156 2.59029C16.6199 2.24506 17.3038 2.06348 17.9998 2.06348C18.6957 2.06348 19.3796 2.24506 19.9839 2.59029C20.5882 2.93552 21.092 3.43245 21.4455 4.03197C21.7989 4.63149 21.9899 5.31287 21.9994 6.00876C22.0089 6.70466 21.8367 7.391 21.4998 7.99997L17.9998 13Z"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5005 4.75L9.00049 4L3.00049 7V20L9.00049 17L15.0005 20L21.0005 17V15"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.00024 4V17"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0002 15.0005V20.0005"
      stroke="white"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function TopBanner() {
  return (
    <div className="hidden md:wrapper bg-dark text-[white] px-[16px] 1300:px-0">
      <div className="content flex items-center justify-between h-[36px] bg-dark">
        <div className="flex items-center justify-center gap-[5px]">
          {location}
          <p className="font-[500] text-[14px] leading-[19.6px] font-jost">
            7, RD 386 - Les 7 chemins 69390 Vourles (Brignais-Sud)
          </p>
        </div>
        <div className="flex items-center">
          <Link
            to={'/aide'}
            className="font-[500] text-[14px] leading-[19.6px] font-jost"
          >
            Contactez nous
          </Link>
        </div>
      </div>
    </div>
  );
}
