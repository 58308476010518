import React from 'react';

const Amount = ({ value, width = 100 }) => (
    <>
        {isNaN(value) ? (
            <div
                className={`w-[${width}px] h-[20px] bg-gray-300 animate-pulse`}
            ></div>
        ) : (
            Intl.NumberFormat('fr-FR', {
                style: 'currency',
                currency: 'EUR',
            }).format(value)
        )}
    </>
);

export default Amount;
