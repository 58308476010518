import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div className="px-2 mx-auto my-10 max-w-lg text-center">
      <h1 className="text-2xl font-bold text-center">
        Vous cherchez votre chemin ?
      </h1>
      <img src="/pictos/404.png" alt="Page introuvable" />
      <p className="text-center text-md font-bold">Cette page n'existe pas.</p>
      <p className="text-center text-md">
        Mais vous pouvez retourner à la page d'accueil.
      </p>
      <Link
        to="/"
        className="mt-4 px-[30px] inline-block border duration-300 rounded-[8px] py-[8px] text-white bg-orange hover:text-orange border-orange hover:bg-transparent"
      >
        Retour à l'accueil
      </Link>
    </div>
  );
}
