const NotreHistoire = () => {
  return (
    <div className="wrapper px-[12px] py-[12px]">
      <div className="wrapper bg-blue rounded-[12px]  max-w-[1920px]">
        <div className="content flex flex-col md:gap-[24px] sm:gap-[30px] lg:gap-[80px] md:py-[24px] 1400:px-0 px-[16px]">
          <div className="grid grid-cols-12 gap-4 ">
            <div className="col-span-12 lg:col-span-6">
              <div className=" mt-10 md:mt-0">
                <img className="" src="/Group79.png" alt="" />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-6 mt-0 md:mt-10 text-white flex justify-center ">
              <div className=" py-4">
                <h2 className="font-clash text-[20px] sm:text-[30px] leading-[1] 900:text-[60px] 900:leading-[64px] font-[600] text-white">
                  Une expertise unique dans la pièce d’occasion
                </h2>

                <div className=" text-[18px] mt-5">
                  <p className="mb-3">
                    Chez Stop Pièces Auto, la pièce d’occasion est bien plus
                    qu’une solution économique : c’est un choix intelligent.
                    Nous sélectionnons, contrôlons et testons rigoureusement
                    chaque pièce pour garantir sa qualité et sa compatibilité
                    avec votre véhicule.
                  </p>
                  <p className="mb-3">
                    Nous proposons une vaste gamme de pièces d’occasion adaptées
                    à tous types de véhicules, y compris :
                    <ul className="list-disc list-inside">
                      <li>
                        Pièces mécaniques : moteurs, boîtes de vitesses,
                        systèmes de freinage.
                      </li>
                      <li>
                        Pièces électroniques : alternateurs, calculateurs,
                        systèmes d’éclairage.
                      </li>
                      <li>
                        Pièces de carrosserie : portières, pare-chocs,
                        rétroviseurs.
                      </li>
                    </ul>
                  </p>
                  <p className="mb-3">
                    Notre engagement est de vous offrir des produits fiables,
                    accessibles, et prêts à être réutilisés pour prolonger la
                    durée de vie de vos véhicules.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotreHistoire;
