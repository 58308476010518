export const Truck = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M4.75 26.9167C4.75 27.7565 5.08363 28.562 5.67749 29.1558C6.27136 29.7497 7.07681 30.0833 7.91667 30.0833C8.75652 30.0833 9.56197 29.7497 10.1558 29.1558C10.7497 28.562 11.0833 27.7565 11.0833 26.9167C11.0833 26.0768 10.7497 25.2714 10.1558 24.6775C9.56197 24.0836 8.75652 23.75 7.91667 23.75C7.07681 23.75 6.27136 24.0836 5.67749 24.6775C5.08363 25.2714 4.75 26.0768 4.75 26.9167ZM4.75 26.9167V19H19M11.0833 28.5H23.75M30.0833 28.5H33.25V19M33.25 19C33.25 16.9004 32.4159 14.8867 30.9313 13.4021C29.4466 11.9174 27.433 11.0833 25.3333 11.0833H23.75M33.25 19H26.125L23.75 11.0833M23.75 11.0833H19V28.5M6.33333 19V9.5M6.33333 9.5L34.8333 4.75V7.91667M6.33333 9.5L12.6667 12.6667V19M23.75 26.9167C23.75 27.7565 24.0836 28.562 24.6775 29.1558C25.2714 29.7497 26.0768 30.0833 26.9167 30.0833C27.7565 30.0833 28.562 29.7497 29.1558 29.1558C29.7497 28.562 30.0833 27.7565 30.0833 26.9167C30.0833 26.0768 29.7497 25.2714 29.1558 24.6775C28.562 24.0836 27.7565 23.75 26.9167 23.75C26.0768 23.75 25.2714 24.0836 24.6775 24.6775C24.0836 25.2714 23.75 26.0768 23.75 26.9167Z"
          stroke="#007FFF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const Car = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M7.91667 26.9167C7.91667 27.7565 8.2503 28.562 8.84416 29.1558C9.43803 29.7497 10.2435 30.0833 11.0833 30.0833C11.9232 30.0833 12.7286 29.7497 13.3225 29.1558C13.9164 28.562 14.25 27.7565 14.25 26.9167M7.91667 26.9167C7.91667 26.0768 8.2503 25.2714 8.84416 24.6775C9.43803 24.0836 10.2435 23.75 11.0833 23.75C11.9232 23.75 12.7286 24.0836 13.3225 24.6775C13.9164 25.2714 14.25 26.0768 14.25 26.9167M7.91667 26.9167H4.75V17.4167M14.25 26.9167H23.75M23.75 26.9167C23.75 27.7565 24.0836 28.562 24.6775 29.1558C25.2714 29.7497 26.0768 30.0833 26.9167 30.0833C27.7565 30.0833 28.562 29.7497 29.1558 29.1558C29.7497 28.562 30.0833 27.7565 30.0833 26.9167M23.75 26.9167C23.75 26.0768 24.0836 25.2714 24.6775 24.6775C25.2714 24.0836 26.0768 23.75 26.9167 23.75C27.7565 23.75 28.562 24.0836 29.1558 24.6775C29.7497 25.2714 30.0833 26.0768 30.0833 26.9167M30.0833 26.9167H33.25V20.5833C33.25 19.7435 32.9164 18.938 32.3225 18.3442C31.7286 17.7503 30.9232 17.4167 30.0833 17.4167H28.5M4.75 17.4167L7.91667 9.5H22.1667L28.5 17.4167M4.75 17.4167H28.5M19 17.4167V9.5"
          stroke="#007FFF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const ProfileIcon = () => {
  return (
    <svg
      width="69"
      height="69"
      viewBox="0 0 69 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="69"
        height="69"
        rx="34.5"
        fill="#FF5600"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8333 27.4167C28.8333 25.9138 29.4304 24.4724 30.4931 23.4097C31.5558 22.347 32.9971 21.75 34.5 21.75C36.0029 21.75 37.4442 22.347 38.5069 23.4097C39.5696 24.4724 40.1667 25.9138 40.1667 27.4167C40.1667 28.9196 39.5696 30.3609 38.5069 31.4236C37.4442 32.4863 36.0029 33.0833 34.5 33.0833C32.9971 33.0833 31.5558 32.4863 30.4931 31.4236C29.4304 30.3609 28.8333 28.9196 28.8333 27.4167ZM28.8333 35.9167C26.9547 35.9167 25.153 36.6629 23.8247 37.9913C22.4963 39.3197 21.75 41.1214 21.75 43C21.75 44.1272 22.1978 45.2082 22.9948 46.0052C23.7918 46.8022 24.8728 47.25 26 47.25H43C44.1272 47.25 45.2082 46.8022 46.0052 46.0052C46.8022 45.2082 47.25 44.1272 47.25 43C47.25 41.1214 46.5037 39.3197 45.1753 37.9913C43.847 36.6629 42.0453 35.9167 40.1667 35.9167H28.8333Z"
        fill="#FF5600"
      />
    </svg>
  );
};

export const InfoIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${props.fill}`}
    >
      <path
        d="M5.33333 16.2611V16.2222C5.33333 15.3971 5.66111 14.6058 6.24456 14.0223C6.828 13.4389 7.61933 13.1111 8.44444 13.1111H11.5556C12.3807 13.1111 13.172 13.4389 13.7554 14.0223C14.3389 14.6058 14.6667 15.3971 14.6667 16.2222V16.2611M10 10.7778C10.6188 10.7778 11.2123 10.5319 11.6499 10.0944C12.0875 9.65678 12.3333 9.06328 12.3333 8.44444C12.3333 7.82561 12.0875 7.23211 11.6499 6.79453C11.2123 6.35694 10.6188 6.11111 10 6.11111C9.38116 6.11111 8.78767 6.35694 8.35008 6.79453C7.9125 7.23211 7.66667 7.82561 7.66667 8.44444C7.66667 9.06328 7.9125 9.65678 8.35008 10.0944C8.78767 10.5319 9.38116 10.7778 10 10.7778ZM10 3C15.6 3 17 4.4 17 10C17 15.6 15.6 17 10 17C4.4 17 3 15.6 3 10C3 4.4 4.4 3 10 3Z"
        // stroke={props.fill}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ProfileDetails = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className={`${props.fill}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.3335 3.33325H8.3335V8.33325H3.3335V3.33325Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 3.33325H16.6668V8.33325H11.6668V3.33325Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3335 11.6666H8.3335V16.6666H3.3335V11.6666Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 14.1666C11.6668 14.8296 11.9302 15.4655 12.3991 15.9344C12.8679 16.4032 13.5038 16.6666 14.1668 16.6666C14.8299 16.6666 15.4658 16.4032 15.9346 15.9344C16.4034 15.4655 16.6668 14.8296 16.6668 14.1666C16.6668 13.5035 16.4034 12.8677 15.9346 12.3988C15.4658 11.93 14.8299 11.6666 14.1668 11.6666C13.5038 11.6666 12.8679 11.93 12.3991 12.3988C11.9302 12.8677 11.6668 13.5035 11.6668 14.1666Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const LocationIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={`${props.fill}`}
    >
      <path
        d="M7.50016 9.16667C7.50016 9.82971 7.76355 10.4656 8.2324 10.9344C8.70124 11.4033 9.33712 11.6667 10.0002 11.6667C10.6632 11.6667 11.2991 11.4033 11.7679 10.9344C12.2368 10.4656 12.5002 9.82971 12.5002 9.16667C12.5002 8.50363 12.2368 7.86774 11.7679 7.3989C11.2991 6.93006 10.6632 6.66667 10.0002 6.66667C9.33712 6.66667 8.70124 6.93006 8.2324 7.3989C7.76355 7.86774 7.50016 8.50363 7.50016 9.16667Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7143 13.8808L11.1785 17.4167C10.866 17.7289 10.4423 17.9042 10.0006 17.9042C9.55885 17.9042 9.13518 17.7289 8.82267 17.4167L5.286 13.8808C4.35368 12.9485 3.71878 11.7606 3.46157 10.4674C3.20437 9.17419 3.33641 7.83376 3.841 6.61561C4.3456 5.39745 5.20008 4.35628 6.2964 3.62376C7.39272 2.89123 8.68164 2.50024 10.0002 2.50024C11.3187 2.50024 12.6076 2.89123 13.7039 3.62376C14.8003 4.35628 15.6547 5.39745 16.1593 6.61561C16.6639 7.83376 16.796 9.17419 16.5388 10.4674C16.2816 11.7606 15.6466 12.9485 14.7143 13.8808Z"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const CubeIcon = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={`${props.fill}`}
    >
      <path
        d="M16.6668 6.25L10.0002 2.5L3.3335 6.25M16.6668 6.25V13.75L10.0002 17.5M16.6668 6.25L10.0002 10M10.0002 17.5L3.3335 13.75V6.25M10.0002 17.5V10M3.3335 6.25L10.0002 10"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
