export default function AddressCard({ address, onClick, selected = false }) {
  return (
    <div
      className={`bg-white gap-3 items-start border-2 w-full cursor-pointer rounded-xl p-3 grid grid-flow-col ${
        selected ? 'border-orange' : 'border-transparent'
      }`}
      onClick={onClick}
    >
      <div
        className={`${
          selected
            ? 'bg-orange border-transparent'
            : 'bg-transparent border-[#CBCDCD]'
        } w-5 h-5 rounded-full grid place-items-center border`}
      >
        <div className="w-2 h-2 rounded-full bg-white"></div>
      </div>
      <div>
        <p>
          <strong>
            {address.first_name} {address.last_name}
          </strong>
        </p>
        <p>{address.line_one}</p>
        <p>
          {address.postcode} {address.city}, {address.country.name}
        </p>
        <p>{address.contact_phone}</p>
      </div>
    </div>
  );
}
