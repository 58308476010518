import {
  Switch,
  Transition,
  Button,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { useState } from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { VscChromeClose } from 'react-icons/vsc';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Ancien mot de passe requis'),
  newPassword: Yup.string()
    .required('Nouveau mot de passe requis')
    .min(6, 'Le mot de passe doit comporter au moins 6 caractères'),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref('newPassword'), null],
      'Les mots de passe doivent correspondre',
    )
    .required('Confirmer le mot de passe requis'),
});

export default function ProfileInfoModal({
  showModal,
  setShowModal,
  setIspasswordChanged,
}) {
  const [show, setShow] = useState(false);
  return (
    <>
      <Dialog
        open={showModal}
        as="div"
        className="relative z-30 focus:outline-none"
        onClose={() => setShowModal(false)}
      >
        <Transition
          appear
          show={showModal}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogBackdrop className="fixed inset-0 bg-black/30 smm:bg-white" />
        </Transition>

        <div className="fixed inset-0 z-10 w-screen h-full overflow-y-auto">
          <div className="flex min-h-full items-center  justify-center p-4">
            <button
              className="text-xl hidden text-black absolute top-4 smm:rounded-xl smm:bg-gray smm:w-12 smm:flex justify-center items-center smm:h-12 right-4"
              onClick={() => setShowModal(false)}
            >
              <VscChromeClose />
            </button>
            <Transition
              appear
              show={showModal}
              enter="transition-transform duration-300"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition-transform duration-300"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <DialogPanel className="w-full max-w-md smm:h-full rounded-xl bg-white text-black p-6 backdrop-blur-2xl">
                <button
                  className="text-xl text-black absolute top-4 smm:rounded-xl smm:bg-gray smm:w-12 smm:hidden block  smm:h-12 right-4"
                  onClick={() => setShowModal(false)}
                >
                  <VscChromeClose />
                </button>
                <DialogTitle
                  as="h3"
                  className="text-[22px] font-semibold font-jost"
                >
                  Modifier le mot de passe
                </DialogTitle>
                <Formik
                  initialValues={{
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: '',
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    // Handle successful submission here
                    setShowModal(false);
                    setIspasswordChanged(true);
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="mt-5 space-y-5">
                        <div className="border flex items-center pr-4 bg-white border-[#CBCDCD] rounded-lg h-14 w-full outline-none pl-4">
                          <Field
                            required
                            type={show ? 'text' : 'password'}
                            name="oldPassword"
                            className="outline-none h-full w-full"
                            placeholder="Ancien mot de passe"
                          />
                          <button
                            type="button"
                            className="text-[#abb3b9]"
                            onClick={() => setShow(!show)}
                          >
                            {!show ? <FiEye /> : <FiEyeOff />}
                          </button>
                        </div>
                        <ErrorMessage
                          name="oldPassword"
                          component="div"
                          className="text-red-500"
                        />

                        <div className="border flex items-center pr-4 bg-white border-[#CBCDCD] rounded-lg h-14 w-full outline-none pl-4">
                          <Field
                            required
                            type={show ? 'text' : 'password'}
                            name="newPassword"
                            className="outline-none h-full w-full"
                            placeholder="Nouveau mot de passe"
                          />
                          <button
                            type="button"
                            className="text-[#abb3b9]"
                            onClick={() => setShow(!show)}
                          >
                            {!show ? <FiEye /> : <FiEyeOff />}
                          </button>
                        </div>
                        <ErrorMessage
                          name="newPassword"
                          component="div"
                          className="text-red-500"
                        />

                        <div className="border flex items-center pr-4 bg-white border-[#CBCDCD] rounded-lg h-14 w-full outline-none pl-4">
                          <Field
                            required
                            type={show ? 'text' : 'password'}
                            name="confirmPassword"
                            className="outline-none h-full w-full"
                            placeholder="Confirmer le mot de passe"
                          />
                          <button
                            type="button"
                            className="text-[#abb3b9]"
                            onClick={() => setShow(!show)}
                          >
                            {!show ? <FiEye /> : <FiEyeOff />}
                          </button>
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="text-red-500"
                        />
                      </div>
                      <div className="mt-8 flex gap-4">
                        <Button
                          type="submit"
                          className="inline-flex justify-center items-center gap-2 rounded-lg h-12 text-sm/6 font-semibold shadow-inner shadow-white/10 bg-orange text-white w-full"
                        >
                          Sauvegarder
                        </Button>
                        <button
                          type="button"
                          className="bg-gray h-12 w-full rounded-lg"
                          onClick={() => setShowModal(false)}
                        >
                          Annuler
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </DialogPanel>
            </Transition>
          </div>
        </div>
      </Dialog>
    </>
  );
}
